import React, { useCallback } from "react";
import { BaseUnstakingFlowData } from "../../../shared/types";
import BigNumber from "bignumber.js";

export type SolanaFlowData = BaseUnstakingFlowData & {
  acceptedTerms: boolean;
  unsignedRawTransaction: string;
  transactionHash: string;
};

export const unstakingInitialValues: SolanaFlowData = {
  amount: BigNumber(0),
  acceptedTerms: false,
  unsignedRawTransaction: "",
  transactionHash: "",
  errors: [],
};

type UpdateFlowData = (newFlowData: Partial<SolanaFlowData>) => void;

export const useUnstakingFlowData = (
  initialFlowData = unstakingInitialValues
) => {
  const [flowData, setFlowData] =
    React.useState<SolanaFlowData>(initialFlowData);

  const updateFlowData: UpdateFlowData = useCallback(
    (newFlowData) => {
      setFlowData({ ...flowData, ...newFlowData });
    },
    [flowData]
  );

  return { flowData, updateFlowData };
};
