import { useEffect } from "react";

const WEB3MODAL_COMPONENT = "w3m-modal";

export const useCreateWeb3Modal = () => {
  useEffect(() => {
    const existingWeb3Modal = document.querySelector(WEB3MODAL_COMPONENT);
    if (!existingWeb3Modal) {
      const web3Modal = document.createElement(WEB3MODAL_COMPONENT);
      document.body.appendChild(web3Modal);
    }
  }, []);
};
