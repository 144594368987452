export enum OverlayPalette {
  success = "success",
  info = "info",
  error = "error",
}

export const OverlayText: Record<OverlayPalette, string> = {
  error: "text-error",
  info: "text-white",
  success: "text-green-800",
};
