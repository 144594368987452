import React from "react";
import { twMerge as tw } from "tailwind-merge";
// import Image from "next/image";
import { BodyText, Heading, ToggleGroup, Button } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { Protocol } from "@figmentjs/protocols";
import { EthereumStakingFlowData } from "../../../flows/ethereum/siwe/use-flow-data/use-flow-data";
import { Link, WalletDetails } from "../../../../components";

type Props = {
  flowData: EthereumStakingFlowData;
  onBack: () => void;
  onNext: (values: Partial<EthereumStakingFlowData>) => void;
};

export enum Location {
  Canada = "Canada",
  Ireland = "Ireland",
}

export const ValidatorLocation: React.FC<Props> = ({
  flowData,
  onBack,
  onNext,
}) => {
  const [location, setLocation] = React.useState<Location>(flowData.location);
  const dotStyles =
    "absolute w-4 h-4 border-2 border-white rounded-full bg-green-700 transform-all duration-500 delay-50 ease-in-out";

  return (
    <>
      <WalletDetails protocol={Protocol.ETHEREUM} />
      <div className="pt-4 px-4 pb-2 flex flex-col items-center">
        <div className="space-y-1 text-center mb-4">
          <Heading level="h5" font={HeadingFont.space} weight="semibold">
            Validator Location
          </Heading>
          <BodyText as="p">
            Where would you like your validators to be hosted?
          </BodyText>
        </div>

        <ToggleGroup
          items={[
            {
              content: "Canada",
              value: Location.Canada,
            },
            {
              content: "Ireland",
              value: Location.Ireland,
            },
          ]}
          value={location}
          onValueChange={(value) => setLocation(value as Location)}
        />
      </div>
      <div className="relative">
        <div
          // Canada Dot
          className={tw(
            dotStyles,
            "top-[32%] left-[17.5%]",
            location === Location.Canada
              ? "scale-100 opacity-100"
              : "scale-0 opacity-0"
          )}
        />
        <div
          // Ireland Dot
          className={tw(
            dotStyles,
            "top-[39.5%] left-[55%]",
            location === Location.Ireland
              ? "scale-100 opacity-100"
              : "scale-0 opacity-0"
          )}
        />
        {/* Once the initial PR to include the image in the /app/public folder is merged, we can it as a "remote" image in <Image /> */}
        {/* <Image
          src="https://app.figment.io/images/validator-location-map.png"
          alt="Validator map"
          width="380"
          height="140"
        /> */}
        <img
          src="https://app.figment.io/images/validator-location-map.png"
          alt="Validator map"
        />
      </div>

      <div className="px-4 pb-4">
        <Link
          href="https://figment.io/insights/figments-ethereum-multi-region-approach-and-why-it-matters/"
          text="Figment's multi-region strategy"
        />
        <div className="flex justify-evenly gap-2 w-full">
          <Button palette="tertiary" size="small" fullWidth onClick={onBack}>
            Back
          </Button>
          <Button size="small" fullWidth onClick={() => onNext({ location })}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
};
