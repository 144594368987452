import React from "react";
import { BodyText, Button, Heading, Icon } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import {
  Protocol,
  getTransactionExplorerUrl,
  protocols,
} from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import BigNumber from "bignumber.js";

type Props = {
  onClose: () => void;
  amount: BigNumber;
};

export const WithdrawSuccess: React.FC<Props> = ({ onClose, amount }) => {
  const { network, transactionHash } = Wallet.useWallet();
  const ticker = protocols[Protocol.SOLANA].ticker;

  return (
    <div className="p-4">
      <div className="flex gap-2 items-center justify-center text-center border-b border-b-green-100 pb-4">
        <Icon icon="MdCheckCircle" size="4xl" color="green-800" />
        <Heading
          level="h5"
          color="green"
          font={HeadingFont.space}
          weight="bold"
        >
          {amount.toString()} {ticker} Withdrawn
        </Heading>
      </div>
      <div className="py-4">
        <BodyText weight="semibold">What&apos;s Next?</BodyText>
        <div className="pt-1">
          <BodyText size="sm">
            Your stake account is fully withdrawn. Your funds should be in your
            wallet.
          </BodyText>
        </div>
      </div>
      <div className="w-full pt-4">
        <Button
          size="small"
          fullWidth
          href={getTransactionExplorerUrl(
            Protocol.SOLANA,
            transactionHash!,
            network
          )}
          openInNewTab
        >
          View Transaction
        </Button>
      </div>
      <div className="flex justify-center pt-4">
        <Button
          palette="text"
          size="tiny"
          textColor="text-basic-800"
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </div>
  );
};
