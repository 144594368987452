import * as React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Icon } from "../../graphics/Icon";
import { twMerge as tw } from "tailwind-merge";
import { Heading } from "../../typography/Heading";
import { BodyText } from "../../typography/BodyText";
import {
  DialogContentProps,
  DialogHeaderProps,
  DialogProps,
  DialogTriggerProps,
  DialogCloseProps,
} from "./Dialog.types";

const Dialog: React.FC<DialogProps> = ({ testId, ...props }) => (
  <div {...(testId ? { "data-testid": testId } : {})}>
    <DialogPrimitive.Root {...props}>{props.children}</DialogPrimitive.Root>
  </div>
);

const DialogContent: React.FC<DialogContentProps> = ({
  children,
  style,
  containerClassName,
  ...props
}) => (
  <DialogPrimitive.Portal>
    <div className={containerClassName}>
      <DialogPrimitive.Overlay className="fixed inset-0 z-modal bg-black/50 top-0 left-0 right-0 bottom-0 grid place-items-center overflow-y-auto">
        <DialogPrimitive.Content
          {...props}
          className={tw(
            "fixed z-modal",
            "max-h-[96vh] overflow-y-auto p-11 w-11/12 rounded-lg md:max-w-4xl md:w-full",
            "top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]",
            "bg-white dark:bg-gray-800 pointer-events-auto",
            "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75",
            style
          )}
        >
          <div>{children}</div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Overlay>
    </div>
  </DialogPrimitive.Portal>
);

DialogContent.displayName = "DialogContent";

const DialogHeader: React.FC<DialogHeaderProps> = ({
  title,
  subtitle,
  closeClassName,
  className,
  icon,
  isClosable = true,
}) => (
  <>
    {isClosable && (
      <DialogPrimitive.Close
        title="Close dialog"
        className={tw(
          "absolute top-2 right-2 inline-flex items-center justify-center rounded-full p-2 bg-[transparent] border-0",
          "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 hover:cursor-pointer",
          closeClassName
        )}
      >
        <Icon icon="MdClose" size="xl" />
      </DialogPrimitive.Close>
    )}
    <div className={tw("text-center", className)}>
      {icon && (
        <div className="flex justify-center pb-4">
          <Icon icon={icon.icon} size={icon.size} color={icon.color} />
        </div>
      )}
      <DialogPrimitive.Title asChild>
        <div>
          <Heading level="h5">{title}</Heading>
        </div>
      </DialogPrimitive.Title>
      <DialogPrimitive.Description asChild>
        <div>
          <BodyText size="base">{subtitle}</BodyText>
        </div>
      </DialogPrimitive.Description>
    </div>
  </>
);

const DialogTrigger: React.FC<DialogTriggerProps> = ({ children }) => (
  <DialogPrimitive.Trigger asChild>
    <div>{children}</div>
  </DialogPrimitive.Trigger>
);

const DialogClose: React.FC<DialogCloseProps> = ({ children }) => (
  <DialogPrimitive.Close title="Close dialog" asChild>
    <div>{children}</div>
  </DialogPrimitive.Close>
);

export {
  Dialog as default,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogClose,
};
