import { getFirstDefinedValue } from "@figmentjs/utils";

export const baseURL = getFirstDefinedValue<string>([
  // We don't need to add `NEXT_PUBLIC_*` EVs to turbo.json.
  /* eslint-disable turbo/no-undeclared-env-vars */
  process.env.NEXT_PUBLIC_BASE_URL,
  process.env.NEXT_PUBLIC_VERCEL_URL &&
    `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`,
  /* eslint-enable turbo/no-undeclared-env-vars */
  "http://localhost:6012",
]);
