import React from "react";
import { Protocol, protocols } from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import { WalletDetailsView } from "../../../../../components";

type Props = {
  action: string;
};

export const LsETHWalletDetails: React.FC<Props> = ({ action }) => {
  const { account, network, disconnect } =
    Wallet.useWallet<Protocol.ETHEREUM>();
  const { lsEthBalance } = Wallet.useLiquidStaking();

  return (
    <WalletDetailsView
      protocol={Protocol.LIQUID_COLLECTIVE}
      icon={protocols.LIQUID_COLLECTIVE.icon}
      account={account}
      network={network}
      action={action}
      ticker="LsETH"
      balance={lsEthBalance || "0"}
      disconnect={disconnect}
    />
  );
};
