import * as React from "react";
import { useSearchParams } from "next/navigation";
import { Network, Protocol } from "@figmentjs/protocols";
import { ShimmerItem, Icon, BodyText } from "@figmentjs/web-core";
import {
  EthereumNetworks,
  useValidatorsTableStakingPositionQuery,
  EthereumAccount,
} from "../../../graphql/core/generated/gql";
import { Props } from "./validators-table";
import { Address, hasRestakingTasks, CurrencyToggle } from "..";
import { AddressType } from "../address-base";
import { getAddressExplorerUrl } from "@figmentjs/protocols";

export const TableHeader = ({
  address,
  onBack,
  network,
  renderUnstakingButton,
  renderRestakingButton,
  currency,
  setCurrency,
  ticker,
  count = null,
}: Props & {
  currency: string;
  setCurrency: (currency: string) => void;
  ticker: string;
  count?: number | null;
}) => {
  const { data, isLoading } = useValidatorsTableStakingPositionQuery(
    {
      address: address!,
      network:
        network === Network.HOLESKY
          ? EthereumNetworks.Holesky
          : EthereumNetworks.Mainnet,
    },
    { enabled: !!address && !!network }
  );

  const queryParams = useSearchParams();

  return (
    <div className="px-2 py-4 sm:p-4">
      {address ? (
        isLoading ? (
          <ShimmerItem width={200} height={32} />
        ) : (
          <div className="flex justify-between">
            <div className="flex gap-4 justify-between">
              <div
                className="flex gap-2 items-center cursor-pointer hover:underline"
                onClick={() => onBack?.(queryParams)}
              >
                <Icon color="green" size="xl" icon="MdArrowBack" />
                <BodyText weight="semibold">Back</BodyText>
              </div>
              <Address
                address={address}
                type={
                  data?.ethereumWithdrawalAccount?.isEigenpod
                    ? AddressType.EIGENPOD_ADDRESS
                    : AddressType.WITHDRAWAL_ADDRESS
                }
                protocol={Protocol.ETHEREUM}
                href={getAddressExplorerUrl(
                  Protocol.ETHEREUM,
                  address,
                  network
                )}
                overrideTheme
                size="lg"
              />
            </div>
            <div className="flex gap-2 items-center">
              <CurrencyToggle
                currency={currency}
                onValueChange={setCurrency}
                ticker={ticker}
              />
              {renderUnstakingButton?.(address)}
              {data?.ethereumWithdrawalAccount &&
                hasRestakingTasks(
                  data.ethereumWithdrawalAccount as EthereumAccount
                ) &&
                renderRestakingButton?.(address)}
            </div>
          </div>
        )
      ) : (
        <div className="flex justify-between">
          <div className="flex flex-row items-center mr-auto">
            <BodyText weight="semibold">
              <span className="mr-2">Validators</span>
            </BodyText>
            {count !== null && (
              <div className="text-base bg-basic-800 text-white px-2 rounded-full">
                {count}
              </div>
            )}
          </div>
          <CurrencyToggle
            currency={currency}
            onValueChange={setCurrency}
            ticker={ticker}
          />
        </div>
      )}
    </div>
  );
};
