import { useCallback } from "react";
import {
  UniversalFlowTrackEventProperties,
  UniversalFlowTrackEvent,
  client,
  SegmentSettings,
  injectMiddleware,
  FigAppTrackEvent,
  FigAppTrackEventProperties,
} from "@figmentjs/analytics/client";
import { baseURL } from "../../utils/constants";

/* Segment Custom Configuration for Proxy and Cookie + LocalStorage Keys
 * Documentation on Proxy: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/custom-proxy/
 * Documentation on Custom Cookies and LocalStorage Keys: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/cookie-validity-update/#user-settings
 */
const SEGMENT_PROXY_URL = "/api/segment/v1";
const settings: SegmentSettings = {
  integrations: {
    "Segment.io": {
      apiHost: `${baseURL}${SEGMENT_PROXY_URL}`.split("//")[1],
      protocol: baseURL.startsWith("https") ? "https" : "http",
    },
  },
  user: {
    cookie: {
      key: "fig_ajs_user_id",
    },
    localStorage: {
      key: "fig_ajs_user_traits",
    },
  },
};
const customAnalyticsClient = client.getAnalytics(settings);

injectMiddleware(customAnalyticsClient);

export const useSegment = () => {
  const trackEvent = useCallback(
    (
      event: UniversalFlowTrackEvent | FigAppTrackEvent,
      properties?:
        | UniversalFlowTrackEventProperties
        | FigAppTrackEventProperties
    ) => {
      client.track(
        {
          event: `${event.object} | ${event.action}`,
          type: "track",
          properties,
        },
        undefined, // handle properties
        customAnalyticsClient
      );
    },
    []
  );

  return {
    trackEvent,
  };
};
