import React, { useEffect } from "react";
import { twMerge as tw } from "tailwind-merge";
import {
  BodyText,
  Heading,
  Button,
  ToggleGroup,
  Icon,
} from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { Network, Protocol } from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import { useRollbar } from "@figmentjs/rollbar-client";
import { Link, WalletDetails } from "../../../../components";
import { EthereumStakingFlowData } from "../../../flows/ethereum/siwe/use-flow-data/use-flow-data";
import {
  EthereumNetworks,
  useEigenPodCreateMutation,
} from "../../../../../graphql/core/generated/gql";

type Props = {
  flowData: EthereumStakingFlowData;
  onBack: () => void;
  onNext: (values: Partial<EthereumStakingFlowData>) => void;
};

enum ToggleValue {
  Off = "OFF",
  On = "ON",
}

export const EigenLayerRestaking: React.FC<Props> = ({
  flowData,
  onBack,
  onNext,
}) => {
  const [isRestakingEnabled, setIsRestakingEnabled] = React.useState(
    flowData.isRestakingEnabled
  );
  const { account, network } = Wallet.useWallet();
  const rollbar = useRollbar();
  const { mutateAsync, data, isLoading, error } = useEigenPodCreateMutation({
    retry: 3,
    onSuccess: (data) => {
      if (data?.eigenpodCreate.userErrors?.length) {
        rollbar.warn(
          "EigenPodCreateMutation User Errors",
          JSON.stringify(data.eigenpodCreate.userErrors)
        );
      }
    },
  });

  useEffect(() => {
    const main = async () => {
      if (account && network) {
        mutateAsync({
          network:
            network === Network.HOLESKY
              ? EthereumNetworks.Holesky
              : EthereumNetworks.Mainnet,
          ownerAddress: account,
        });
      }
    };

    main();
  }, [account, network, mutateAsync]);

  return (
    <>
      <WalletDetails protocol={Protocol.ETHEREUM} />
      <div className="p-4 flex flex-col items-center">
        <div className="space-y-1 text-center mb-4">
          <Heading level="h5" font={HeadingFont.space} weight="semibold">
            EigenLayer Restaking
          </Heading>
          <BodyText as="p">
            Secure additional protocols and unlock rewards streams with
            EigenLayer restaking.
          </BodyText>
        </div>
        <ToggleGroup
          items={[
            {
              content: "Off",
              value: ToggleValue.Off,
            },
            {
              content: "On",
              value: ToggleValue.On,
            },
          ]}
          value={isRestakingEnabled ? ToggleValue.On : ToggleValue.Off}
          onValueChange={(value) =>
            setIsRestakingEnabled((value as ToggleValue) === ToggleValue.On)
          }
        />
        <div className="mt-2 w-full">
          <div className="relative h-36">
            <div
              className={tw(
                "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-28 mx-auto scale-[.8] transition-all duration-100 ease-in",
                isRestakingEnabled
                  ? "opacity-1 grayscale-0"
                  : "opacity-70 grayscale"
              )}
            >
              <Icon icon="EigenLayerWordMark" color="green-800" size="9xl" />
            </div>
            <div
              className={tw(
                "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-28 mt-1 transition-all duration-300 ease-out",
                isRestakingEnabled
                  ? "opacity-1 scale-[1.3] rotate-0"
                  : "opacity-0 scale-[1.45] rotate-[-125deg]"
              )}
            >
              <Icon icon="Recycle" color="green-300" size="9xl" />
            </div>
            <div
              className={tw(
                "relative overflow-hidden transition-all duration-[500ms] ease-out",
                isRestakingEnabled ? "h-0 opacity-0" : "h-36 opacity-1"
              )}
            >
              <div className="absolute top-[30px] left-[50%] w-32 h-28 -ml-[52px] rotate-[39deg] origin-top-left">
                <div className="w-full h-[4px] bg-white"></div>
                <div className="w-full h-[2px] bg-basic-800"></div>
              </div>
            </div>
          </div>
        </div>
        <Link
          href="https://figment.io/staking/protocols/eigenlayer/"
          text="EigenLayer restaking on Figment"
        />
        <div className="flex justify-evenly gap-2 w-full">
          <Button palette="tertiary" size="small" fullWidth onClick={onBack}>
            Back
          </Button>
          <Button
            size="small"
            fullWidth
            loading={isRestakingEnabled && isLoading}
            disabled={isRestakingEnabled && !!error}
            onClick={async () => {
              if (!isRestakingEnabled) {
                return onNext({
                  isRestakingEnabled,
                });
              }

              if (data?.eigenpodCreate.data?.deployed) {
                return onNext({
                  isRestakingEnabled,
                  eigenPodAddress: data?.eigenpodCreate.data?.address,
                });
              }

              return onNext({
                isRestakingEnabled,
                unsignedCreateEigenPodTx: data?.eigenpodCreate.data
                  ?.unsignedRawCreatepodTx as `0x${string}`,
              });
            }}
          >
            Next
          </Button>
        </div>
        {!!error && (
          <div className="mt-2">
            <BodyText color="error" as="p">
              There was an issue with fetching your EigenPod status, please back
              out of this step and re-enter it.
            </BodyText>
          </div>
        )}
      </div>
    </>
  );
};
