import React from "react";
import { BodyText, Button, Heading, Icon } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { Protocol, getAddressExplorerUrl } from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import { UnstakingFeedback } from "../../shared/unstaking-feedback/unstaking-feedback";

type Props = {
  stakeAccountAddress: string;
  onClose: () => void;
};

export const UndelegateSuccess: React.FC<Props> = ({
  stakeAccountAddress,
  onClose,
}) => {
  const [isFeedbackActive, setIsFeedbackActive] = React.useState(false);
  const { network } = Wallet.useWallet();

  return (
    <div className="p-4">
      <div className="flex gap-2 items-center justify-center text-center border-b border-b-green-100 pb-4">
        <Icon icon="MdCheckCircle" size="4xl" color="green-800" />
        <Heading
          level="h5"
          color="green"
          font={HeadingFont.space}
          weight="bold"
        >
          Unstake Initiated
        </Heading>
      </div>
      <div className="py-4">
        <BodyText weight="semibold">What&apos;s Next?</BodyText>
        <div className="pt-1">
          <BodyText size="sm">
            It can take up to 3 days for your
            <div className="inline mx-1">
              <Button
                palette="text"
                size="tiny"
                openInNewTab
                href={getAddressExplorerUrl(
                  Protocol.SOLANA,
                  stakeAccountAddress,
                  network
                )}
              >
                stake account
              </Button>
            </div>
            to be deactivated. Until then it is earning rewards.
          </BodyText>
        </div>
        <div className="pt-4">
          <BodyText size="sm">
            When your stake account is deactivated, the SOL balance can be
            withdrawn. A “Withdraw” button will appear next to your Solana
            position.
          </BodyText>
        </div>
      </div>
      <div className="flex flex-col w-full gap-4">
        <UnstakingFeedback
          label="Reason for undelegating?"
          onActivate={() => setIsFeedbackActive(true)}
          onSubmit={onClose}
        />
        {!isFeedbackActive && (
          <Button size="small" fullWidth onClick={onClose}>
            Close
          </Button>
        )}
      </div>
    </div>
  );
};
