import { Protocol } from "@figmentjs/protocols";
import {
  SiweStakingFlow,
  EthereumActivityTable,
  BabylonActivityTable,
  SiwbStakingFlow,
} from "@figmentjs/figment-elements";
import { usePathname, useSearchParams } from "next/navigation";

type ProtocolElements = {
  stakingWidget: React.ReactElement;
  activityTable: React.ReactElement;
};

const getProtocolElements = ({
  isTestnetMode,
}: {
  isTestnetMode?: boolean;
}): { [key in Protocol]?: ProtocolElements } => ({
  [Protocol.ETHEREUM]: {
    stakingWidget: <SiweStakingFlow isTestnetMode={isTestnetMode} />,
    activityTable: <EthereumActivityTable transparent={true} />,
  },
  [Protocol.BABYLON]: {
    stakingWidget: <SiwbStakingFlow isTestnetMode={isTestnetMode} />,
    activityTable: <BabylonActivityTable transparent={true} />,
  },
});

export const useProtocolElements = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const segments = pathname?.split("/");
  const selectedProtocol = segments?.[3]?.toUpperCase() as Protocol;

  const isTestnetMode = searchParams && searchParams.get("isTestnetMode");

  const protocolElements = getProtocolElements({
    isTestnetMode: isTestnetMode === "true",
  })[selectedProtocol || Protocol.ETHEREUM];

  return {
    selectedProtocol,
    protocolElements,
  };
};
