"use client";

import { useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

export const SIGNATURE_ACCESS_TOKEN_STORAGE_KEY = "signatureAccessToken";

type Props = {
  account?: string;
};

export const useSignatureAccessToken = ({ account }: Props) => {
  const [isClient, setIsClient] = useState(false);

  // https://nextjs.org/docs/messages/react-hydration-error#solution-1-using-useeffect-to-run-on-the-client-only
  // We need to make sure that we're "on the client" before we use `useSignatureAccessToken`, we get hydration errors otherwise.
  useEffect(() => {
    setIsClient(true);
  }, []);

  const [signatureAccessToken, setSignatureAccessToken] = useLocalStorage<
    string | undefined | null
  >(`${SIGNATURE_ACCESS_TOKEN_STORAGE_KEY}:${account}`, undefined);

  return {
    signatureAccessToken: isClient ? signatureAccessToken : undefined,
    setSignatureAccessToken,
  };
};
