import { BigNumber } from "bignumber.js";
import React, { useState } from "react";
import { FigAppActionEnum } from "@figmentjs/analytics/apps";
import { Protocol, protocols } from "@figmentjs/protocols";
import { Button } from "@figmentjs/web-core";
import {
  AmountInput,
  CancelledStep,
  ConfirmTransaction,
  ErrorStep,
  SignTransaction,
} from "../../../../components";
import { useRedeemEthFlowData } from "../../../flows/ls-eth/redeem-eth/use-flow-data";
import {
  LsETHConversionDetails,
  LsETHFlow,
  LsETHWalletDetails,
} from "../shared";
import { RedeemEthFlowStep } from "../../../flows/ls-eth/redeem-eth/flow/redeem-eth-flow.types";

type Props = {
  onCancel: () => void;
  onSetIsCloseableStep: (isCloseableStep: boolean) => void;
  onTrackEvent: ({
    action,
    step,
    amount,
  }: {
    action: FigAppActionEnum;
    step?: string;
    amount?: string;
  }) => void;
};

export const RedemptionAmount: React.FC<Props> = ({
  onCancel,
  onSetIsCloseableStep,
  onTrackEvent,
}) => {
  const protocol = Protocol.LIQUID_COLLECTIVE;
  const [amount, setAmount] = useState<BigNumber | undefined>();
  const {
    createLiquidCollectiveRedeemRequest,
    isCreateLiquidCollectiveRedeemRequestBroadcasting,
    isCreateLiquidCollectiveRedeemRequestLoading,
    lsEthBalance,
    lsEthConversionRate,
    createLiquidCollectiveRedemptionTransactionHash,
    isWalletConnecting,
    isWalletConnected,
    redemptionProjection,
    isRedemptionProjectionLoading,
    connectWallet,
    resetFlow,
    error,
  } = useRedeemEthFlowData();

  const amountInEth = lsEthConversionRate
    ? BigNumber(amount || 0)
        .dividedBy(lsEthConversionRate || 0)
        .dp(5)
    : 0;
  const isAmountInputDisabled = lsEthBalance?.isLessThanOrEqualTo(0);

  if (error === "TX_CANCELED") {
    onTrackEvent({
      action: FigAppActionEnum.STEP_RENDERED,
      step: "REDEEM_LS_ETH_CANCELLED",
    });
    onSetIsCloseableStep(true);
    return (
      <div className="p-4 pt-6">
        <CancelledStep
          onTryAgain={() => {
            setAmount(undefined);
            onTrackEvent({
              action: FigAppActionEnum.TRY_AGAIN_BTN_CLICKED,
              step: "LIQUID_STAKING_REDEEM_ETH_CANCELLED",
            });
            resetFlow({ step: "AMOUNT" });
          }}
        />
      </div>
    );
  } else if (error) {
    onTrackEvent({
      action: FigAppActionEnum.STEP_RENDERED,
      step: "LIQUID_STAKING_REDEEM_ETH_FAILED",
    });
    onSetIsCloseableStep(true);
    return (
      <div className="p-4 pt-6">
        <ErrorStep
          error={{
            message: error,
            isWalletError: true,
          }}
          onClose={() => {
            onTrackEvent({
              action: FigAppActionEnum.MODAL_CLOSED,
              step: "REDEEM_LS_ETH_FAILED",
            });
            resetFlow({ step: "AMOUNT" });
            onCancel();
          }}
        />
      </div>
    );
  } else if (
    isCreateLiquidCollectiveRedeemRequestBroadcasting &&
    !createLiquidCollectiveRedemptionTransactionHash
  ) {
    onSetIsCloseableStep(false);
    return (
      <div className="p-4 pt-6">
        <SignTransaction />
      </div>
    );
  } else if (createLiquidCollectiveRedemptionTransactionHash) {
    return (
      <div className="p-4 pt-6">
        <ConfirmTransaction />
      </div>
    );
  }

  return (
    <>
      <LsETHWalletDetails action="Redeem ETH for LsETH" />
      <div className="p-4">
        <div className="pb-4">
          <AmountInput
            ticker={protocols[protocol].ticker}
            tokenPrice={lsEthConversionRate || 0}
            tokenPriceFormatter={() => {
              return `${amountInEth.toString()} ETH`;
            }}
            balance={BigNumber(lsEthBalance || "0")}
            decimalScale={protocols[protocol].units[0].magnitude!}
            onChange={(newAmount) => {
              setAmount(newAmount ? new BigNumber(newAmount) : undefined);
            }}
            disabled={isAmountInputDisabled}
          />
        </div>
        <LsETHConversionDetails
          amountInEth={amountInEth}
          amountInLsEth={amount ? BigNumber(amount) : 0}
          isLoading={isRedemptionProjectionLoading}
          flow={LsETHFlow.REDEEM}
          redemptionProjection={
            redemptionProjection?.liquidCollectiveRedeemManagerProjection
              .projectedFulfilledAt
          }
        />
        <div className="mt-4">
          {!isWalletConnected ? (
            <Button
              fullWidth
              size="small"
              onClick={connectWallet}
              loading={isWalletConnecting}
            >
              Connect Wallet
            </Button>
          ) : (
            <>
              <Button
                disabled={
                  isAmountInputDisabled ||
                  !amount ||
                  amount?.isLessThanOrEqualTo(0)
                }
                onClick={async () => {
                  onTrackEvent({
                    action: FigAppActionEnum.CONFIRM_UNSTAKE_BTN_CLICKED,
                    step: RedeemEthFlowStep.AMOUNT,
                    amount: amount!.toString(),
                  });
                  createLiquidCollectiveRedeemRequest({
                    amount: amount!.toString(),
                  });
                }}
                fullWidth
                size="small"
                loading={
                  isCreateLiquidCollectiveRedeemRequestLoading ||
                  isCreateLiquidCollectiveRedeemRequestBroadcasting
                }
              >
                {isAmountInputDisabled
                  ? `Insufficient ${protocols[protocol].ticker}`
                  : "Redeem ETH"}
              </Button>
              <div className="mt-4 flex justify-center">
                <Button
                  palette="text"
                  size="tiny"
                  textColor="text-basic-800"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
