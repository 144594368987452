"use client";

import React from "react";
import { Network, protocols } from "@figmentjs/protocols";
import { Spinner } from "@figmentjs/web-core";
import tailwindConfig from "@figmentjs/web-core/src/theme/tailwind.config";
import { useEthereumRewardsEarnedChartQuery } from "../../../graphql/core/generated/gql";
import { RewardsEarnedChart } from "./components";

type Props = {
  isTestnetMode: boolean;
  showLinkToDownloadsPage?: boolean;
};

export const EthereumRewardsEarnedChart: React.FC<Props> = ({
  isTestnetMode,
  showLinkToDownloadsPage = false,
}) => {
  const { data, isLoading } = useEthereumRewardsEarnedChartQuery({
    network: isTestnetMode ? Network.HOLESKY : Network.MAINNET,
  });

  if (isLoading) {
    return (
      <div className="py-40 w-100 flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="bg-white">
      <RewardsEarnedChart
        rewardsEarned={data!}
        isTestnetMode={isTestnetMode}
        showLinkToDownloadsPage={showLinkToDownloadsPage}
        ticker={
          isTestnetMode
            ? protocols.ETHEREUM.networks.holesky.ticker
            : protocols.ETHEREUM.ticker
        }
        colors={{
          consensusLayer: tailwindConfig.theme.colors.ethereum[600],
          executionLayer: tailwindConfig.theme.colors.green[700],
        }}
        legendLabels={{
          consensusLayer: "Consensus Layer",
          executionLayer: "Execution Layer",
        }}
      />
    </div>
  );
};
