import { graphql } from "msw";
import {
  StakeBtcMutation,
  ConfirmStakedBtcMutation,
} from "../../../graphql/core/generated/gql";

export const StakeBtcMutationMock = graphql.mutation<StakeBtcMutation>(
  "StakeBtc",
  (req, res, ctx) => {
    return res(
      ctx.data({
        babylonStakeBtc: {
          data: {
            stakeId: "12345",
            signingPayload: "0x123456",
          },
          userErrors: [],
        },
      })
    );
  }
);

export const ConfirmStakedBtcMutationMock =
  graphql.mutation<ConfirmStakedBtcMutation>(
    "ConfirmStakedBtc",
    (req, res, ctx) => {
      return res(
        ctx.data({
          babylonStakeBroadcasted: {
            userErrors: [],
          },
        })
      );
    }
  );
