import React, { useCallback } from "react";
import { BaseStakingFlowData } from "../../../shared/types";
import BigNumber from "bignumber.js";

export type SolanaFlowData = BaseStakingFlowData & {
  acceptedTerms: boolean;
  unsignedRawTransaction: string;
  stakeAccountPubkey: string;
};

export const initialValues: SolanaFlowData = {
  amount: BigNumber(0),
  acceptedTerms: false,
  unsignedRawTransaction: "",
  transactionHash: "",
  stakeAccountPubkey: "",
  errors: [],
};

type UpdateFlowData = (newFlowData: Partial<SolanaFlowData>) => void;

export const useFlowData = (initialFlowData = initialValues) => {
  const [flowData, setFlowData] =
    React.useState<SolanaFlowData>(initialFlowData);

  const updateFlowData: UpdateFlowData = useCallback(
    (newFlowData) => {
      setFlowData({ ...flowData, ...newFlowData });
    },
    [flowData]
  );

  return { flowData, updateFlowData };
};
