import React, { memo, useEffect } from "react";
import { FigAppActionEnum } from "@figmentjs/analytics/client";
import { RedemptionAmount, RedeemSuccess } from "../../../../steps/ls-eth";
import { useRedeemEthFlowData } from "../use-flow-data";
import { RedeemEthFlowStep } from "./redeem-eth-flow.types";

type Props = {
  onDone: () => void;
  onSetIsCloseableStep: (isCloseableStep: boolean) => void;
  onTrackEvent: ({
    action,
    step,
    amount,
  }: {
    action: FigAppActionEnum;
    step?: string;
    amount?: string;
  }) => void;
};

const LsETHRedeemEthFlow: React.FC<Props> = ({
  onSetIsCloseableStep,
  onTrackEvent,
  onDone,
}) => {
  const { step } = useRedeemEthFlowData();

  useEffect(() => {
    onTrackEvent({
      action: FigAppActionEnum.STEP_RENDERED,
      step: step,
    });
  }, [onTrackEvent, step]);

  return (
    <div>
      {((): React.ReactNode => {
        switch (step) {
          case RedeemEthFlowStep.AMOUNT:
            return (
              <RedemptionAmount
                onSetIsCloseableStep={onSetIsCloseableStep}
                onCancel={onDone}
                onTrackEvent={onTrackEvent}
              />
            );

          case RedeemEthFlowStep.SUCCESS: {
            onSetIsCloseableStep(true);
            return <RedeemSuccess onClose={onDone} />;
          }

          default:
            return null;
        }
      })()}
    </div>
  );
};

export default memo(LsETHRedeemEthFlow);
