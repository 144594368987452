import { BigNumber } from "bignumber.js";
import React from "react";
import { friendlyDate } from "@figmentjs/utils/src/formatters/date";
import Wallet, { formatEther } from "@figmentjs/wallet";
import { BodyText } from "@figmentjs/web-core";
import { Details, Detail, DetailType } from "../../../../../components";

export enum LsETHFlow {
  REDEEM = "REDEEM",
  CLAIM = "CLAIM",
}

type Props = {
  amountInEth: BigNumber | 0;
  amountInLsEth: BigNumber | 0;
  flow: LsETHFlow;
  isLoading: boolean;
  redemptionProjection?: string;
};

export const LsETHConversionDetails: React.FC<Props> = ({
  amountInEth,
  amountInLsEth,
  flow,
  isLoading,
  redemptionProjection,
}) => {
  const { lsEthConversionRate } = Wallet.useLiquidStaking();
  const lsEthToEthConversionRate = lsEthConversionRate
    ? BigNumber(1).dividedBy(formatEther(lsEthConversionRate)).dp(5)
    : 0;

  const friendlyRedemptionProjection = redemptionProjection
    ? friendlyDate(redemptionProjection)
    : null;

  const details: Detail[] = React.useMemo(
    () =>
      flow === LsETHFlow.REDEEM
        ? [
            {
              type: DetailType.DEFAULT,
              label: "Conversion Rate",
              value: {
                text: lsEthToEthConversionRate
                  ? lsEthToEthConversionRate.toString()
                  : "-",
              },
            },
            {
              type: DetailType.DEFAULT,
              label: "Redemption Estimate *",
              value: {
                text: `${amountInEth.toString()} ETH`,
              },
            },
            {
              type: DetailType.DEFAULT,
              label: "Available In",
              value: {
                text: friendlyRedemptionProjection?.time,
                appendedText: friendlyRedemptionProjection?.unit,
              },
            },
          ]
        : [
            {
              type: DetailType.DEFAULT,
              label: "Amount *",
              value: {
                text: `${amountInLsEth.toString()} LsETH`,
                appendedText: `(${amountInEth.toString()} ETH)`,
              },
            },
            {
              type: DetailType.DEFAULT,
              label: "Conversion Rate",
              value: {
                text: lsEthToEthConversionRate
                  ? lsEthToEthConversionRate.toString()
                  : "-",
              },
            },
          ],
    [
      flow,
      lsEthToEthConversionRate,
      amountInEth,
      friendlyRedemptionProjection?.time,
      friendlyRedemptionProjection?.unit,
      amountInLsEth,
    ]
  );

  return (
    <>
      <Details details={details} isLoading={isLoading} showBottomBorder />
      <div className="py-4">
        <BodyText size="sm">
          * Amount of claimable ETH may vary based on the protocol conversion
          rate at the time of claim.
        </BodyText>
      </div>
    </>
  );
};
