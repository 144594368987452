import React from "react";
import {
  Protocol,
  getValidatorExplorerUrl,
  SolanaNetworkValidatorMap,
} from "@figmentjs/protocols";
import { BaseStakingFlowData } from "../../../flows/shared/types";
import { DetailType, Detail } from "../../../../components";
import { Amount } from "../../shared";
import { useWallet } from "@figmentjs/wallet";

type Props = {
  flowData: BaseStakingFlowData;
  estimatedFee: string;
  activationTime: {
    time: string;
    unit: string;
  };
  exitTime: {
    time: string;
    unit: string;
  };
  rewardsRate: number;
  commission: number;
  customValidator?: {
    pubkey: string;
    label: string;
    commission: number;
  };
  price: string;
  onSubmit: (values: any) => void;
  isLoading: boolean;
};

export const SolanaAmount: React.FC<Props> = ({
  flowData,
  estimatedFee,
  activationTime,
  exitTime,
  rewardsRate,
  commission,
  price,
  customValidator,
  onSubmit,
  isLoading,
}) => {
  const { network } = useWallet<Protocol.SOLANA>();

  const details: Detail[] = React.useMemo(
    () => [
      {
        type: DetailType.DEFAULT,
        label: "Gross Rewards Rate",
        value: { text: `${rewardsRate}%` },
      },
      {
        type: DetailType.HREF,
        label: "Validator",
        value: {
          text: customValidator ? customValidator.label : "Figment",
          prependedIcon: customValidator ? undefined : "FigmentLogoIcon",
          href: getValidatorExplorerUrl(
            Protocol.SOLANA,
            customValidator
              ? customValidator.pubkey
              : SolanaNetworkValidatorMap[network!],
            network
          ),
        },
      },
      {
        type: DetailType.DEFAULT,
        label: "Commission",
        value: {
          text: `${customValidator ? customValidator.commission : commission}%`,
        },
      },
      {
        type: DetailType.DEFAULT,
        label: "Activation Time",
        value: {
          text: `~${activationTime.time}`,
          appendedText: activationTime.unit,
        },
      },
    ],
    [rewardsRate, commission, activationTime, exitTime, customValidator]
  );

  return (
    <Amount
      protocol={Protocol.SOLANA}
      useSlider={false}
      price={price}
      flowData={flowData}
      estimatedFee={estimatedFee}
      isLoading={isLoading}
      onSubmit={onSubmit}
      details={details}
    />
  );
};
