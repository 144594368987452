import {
  SolanaStakingDelegationCreateMutation,
  SolanaStakingTxSaveMutation,
} from "../../../graphql/core/generated/gql";
import { graphql } from "msw";

export const SolanaStakingDelegationCreateSuccess =
  graphql.mutation<SolanaStakingDelegationCreateMutation>(
    "solanaStakingDelegationCreate",
    (req, res, ctx) => {
      return res(
        ctx.data({
          solanaStakingDelegateTransactionCreate: {
            data: {
              stakeAccountPubkey:
                "YY4qmJYuNzFciu8E938buqV3Kq1piDxZXpN5trAHjKI5",
              signingPayload:
                "02000709e2ba3a63bae58a6f879663360baab0551c3711516d9be2801fd27d7ed1c33a2e8ec44ac65fccde0df38aa936324d7c43d22216dda4c2e8751efb94decac099050000000000000000000000000000000000000000000000000000000000000000ddf42a04800a54de2e583f94f17b089725b772d1333526271241532776d2ffc606a1d8179137542a983437bdfe2a7ab2557f535c8a78722b68a49dc00000000006a1d817a502050b680791e6ce6db88e1e5b7150f61fc6790a4eb4d10000000006a7d51718c774c928566398691d5eb68b5eb8a39b4b6d5c73555b210000000006a7d517192c5c51218cc94c3d4af17f58daee089ba1fd44e3dbd98a0000000006a7d517193584d0feed9bb3431d13206be544281b57b8566cc5375ff400000023b457beef59670ca7705050eb6e882fa4f3fcec9ba28596fd1a81cb153d012f030202000134000000000094357700000000c80000000000000006a1d8179137542a983437bdfe2a7ab2557f535c8a78722b68a49dc000000000040201077400000000e2ba3a63bae58a6f879663360baab0551c3711516d9be2801fd27d7ed1c33a2ee2ba3a63bae58a6f879663360baab0551c3711516d9be2801fd27d7ed1c33a2e00000000000000000000000000000000e2ba3a63bae58a6f879663360baab0551c3711516d9be2801fd27d7ed1c33a2e04060103060805000402000000",
              unsignedTransactionSerialized:
                "0200000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000d9acd323e41b2f66b32a296bc082196cc3c50f37f7b45e037390e35b06444bff49114ed4e37bc191bcee0d6f5153e8a7697a31c48dce3689d291c9696d47e50f02000709e2ba3a63bae58a6f879663360baab0551c3711516d9be2801fd27d7ed1c33a2e8ec44ac65fccde0df38aa936324d7c43d22216dda4c2e8751efb94decac099050000000000000000000000000000000000000000000000000000000000000000ddf42a04800a54de2e583f94f17b089725b772d1333526271241532776d2ffc606a1d8179137542a983437bdfe2a7ab2557f535c8a78722b68a49dc00000000006a1d817a502050b680791e6ce6db88e1e5b7150f61fc6790a4eb4d10000000006a7d51718c774c928566398691d5eb68b5eb8a39b4b6d5c73555b210000000006a7d517192c5c51218cc94c3d4af17f58daee089ba1fd44e3dbd98a0000000006a7d517193584d0feed9bb3431d13206be544281b57b8566cc5375ff400000023b457beef59670ca7705050eb6e882fa4f3fcec9ba28596fd1a81cb153d012f030202000134000000000094357700000000c80000000000000006a1d8179137542a983437bdfe2a7ab2557f535c8a78722b68a49dc000000000040201077400000000e2ba3a63bae58a6f879663360baab0551c3711516d9be2801fd27d7ed1c33a2ee2ba3a63bae58a6f879663360baab0551c3711516d9be2801fd27d7ed1c33a2e00000000000000000000000000000000e2ba3a63bae58a6f879663360baab0551c3711516d9be2801fd27d7ed1c33a2e04060103060805000402000000",
            },
          },
        })
      );
    }
  );

export const SolanaStakingTxSaveMutationMock =
  graphql.mutation<SolanaStakingTxSaveMutation>(
    "solanaStakingTxSave",
    (req, res, ctx) => {
      return res(ctx.data({ solanaStakingTxSave: { userErrors: null } }));
    }
  );
