export enum EthereumFlowStep {
  AMOUNT = "AMOUNT",
  CHECK = "CHECK",
  LOCATION = "LOCATION",
  RESTAKING = "RESTAKING",
  DEPLOY_EIGENPOD = "DEPLOY_EIGENPOD",
  EIGENPOD_ADDRESS = "EIGENPOD_ADDRESS",
  WITHDRAWAL_ADDRESS = "WITHDRAWAL_ADDRESS",
  EXECUTION_REWARDS_ADDRESS = "EXECUTION_REWARDS_ADDRESS",
  SUMMARY = "SUMMARY",
  SUCCESS = "SUCCESS",
  CANCELLED = "CANCELLED",
  ERROR = "ERROR",
}
