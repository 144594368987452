export enum EthereumSiweFlowStep {
  AMOUNT = "AMOUNT",
  LOCATION = "LOCATION",
  RESTAKING = "RESTAKING",
  DEPLOY_EIGENPOD = "DEPLOY_EIGENPOD",
  EIGENPOD_ADDRESS = "EIGENPOD_ADDRESS",
  WITHDRAWAL_ADDRESS = "WITHDRAWAL_ADDRESS",
  EXECUTION_REWARDS_ADDRESS = "EXECUTION_REWARDS_ADDRESS",
  VERIFY_OWNERSHIP = "VERIFY_OWNERSHIP",
  SUMMARY = "SUMMARY",
  SUCCESS = "SUCCESS",
  CANCELLED = "CANCELLED",
  ERROR = "ERROR",
}
