import { graphql } from "msw";
import { BalanceAndRewardsCardsQuery } from "../../../graphql/core/generated/gql";

export const BalanceAndRewardsCardsQueryMock =
  graphql.query<BalanceAndRewardsCardsQuery>(
    "BalanceAndRewardsCards",
    (req, res, ctx) => {
      return res(
        ctx.data({
          rewardsSummary: {
            totalRewards: "0.0323413432",
            totalRewardsUsd: "65.16",
          },
          ethereumBalanceSummary: {
            balance: "64.12343943284375",
            balanceUsd: "129208.73",
          },
          solanaRewardsSummary: {
            totalRewards: "0.015",
            totalRewardsUsd: "65.16",
          },
          solanaBalanceSummary: {
            balance: "23.0006898249821",
            balanceUsd: "42233.73",
          },
        })
      );
    }
  );

export const BalanceAndRewardsCardsQueryLoadingMock =
  graphql.query<BalanceAndRewardsCardsQuery>(
    "BalanceAndRewardsCards",
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.delay(10000));
    }
  );

export const BalanceAndRewardsCardsQueryEmptyMock =
  graphql.query<BalanceAndRewardsCardsQuery>(
    "BalanceAndRewardsCards",
    (req, res, ctx) => {
      return res(
        ctx.data({
          rewardsSummary: {},
          ethereumBalanceSummary: {},
          solanaRewardsSummary: {},
          solanaBalanceSummary: {},
        })
      );
    }
  );
