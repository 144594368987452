export enum AddressType {
  EIGENPOD_ADDRESS = "EIGENPOD_ADDRESS",
  WITHDRAWAL_ADDRESS = "WITHDRAWAL_ADDRESS",
  EXECUTION_REWARDS_ADDRESS = "EXECUTION_REWARDS_ADDRESS",
  VALIDATOR_ADDRESS = "VALIDATOR_ADDRESS",
  STAKE_ACCOUNT = "STAKE_ACCOUNT",
  WALLET = "WALLET",
}

export enum Size {
  sm = "sm",
  lg = "lg",
}
