import React, { memo } from "react";
import { TableShimmerProps } from "./TableShimmer.types";

const ShimmerItem: React.FC = () => (
  <div className="animate-pulse bg-basic-200 rounded-md h-7" />
);

export const TableShimmer: React.FC<TableShimmerProps> = ({ rows, testId }) => {
  return (
    <div
      className="h-fit w-full grid gap-x-8 gap-y-10 grid-cols-3"
      {...(testId ? { "data-testid": testId } : {})}
    >
      {[...Array(rows)].map((item, index) => (
        <React.Fragment key={`shimmer-${index}`}>
          <ShimmerItem />
          <ShimmerItem />
          <ShimmerItem />
        </React.Fragment>
      ))}
    </div>
  );
};

export default memo(TableShimmer);
