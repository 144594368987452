import React, { useEffect, useCallback } from "react";
import { Network, Protocol } from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import { useEthereumStakingFlowQuery } from "../../../../../../graphql/core/generated/gql";
import {
  ValidatorLocation,
  EigenLayerRestaking,
  DeployEigenPod,
  EigenPodAddress,
  WithdrawalAddress,
  ExecutionRewardsAddress,
  EthereumStakingSummary,
  EthereumAmount,
} from "../../../../steps/ethereum";
import { Success } from "../../../../steps/shared";
import { useStepper } from "../../../../../hooks/use-stepper";
import { useFlowData, initialValues } from "../use-flow-data";
import { EthereumFlowStep } from "./flow.types";
import {
  UniversalWidgetActionEnum as Actions,
  UniversalFlowTrackEventProperties,
  UniversalWidgetObjectEnum,
} from "@figmentjs/analytics/client";
import { useSegment } from "../../../../../hooks/use-segment/use-segment";

type Props = {
  isStakeDisabled?: boolean;
  onCheck?: (values: {
    isTestnet: boolean;
    onBack: () => void;
    onNext: () => void;
  }) => React.FC<any>;
};

export const Flow: React.FC<Props> = ({ isStakeDisabled, onCheck }) => {
  const { step, setStep } = useStepper<EthereumFlowStep>(
    EthereumFlowStep.AMOUNT
  );
  const { flowData, updateFlowData } = useFlowData();
  const { network, setTransactionHash, walletName, account } =
    Wallet.useWallet<Protocol.ETHEREUM>();
  const {
    data: ethereumStakingFlowData,
    isLoading: isEthereumStakingFlowLoading,
  } = useEthereumStakingFlowQuery();

  const { trackEvent } = useSegment();
  const trackStakingFlowEvent = useCallback(
    (action: Actions, properties: UniversalFlowTrackEventProperties) => {
      trackEvent(
        { object: UniversalWidgetObjectEnum.STAKING_FLOW, action },
        {
          amountToStake: flowData.amount.toNumber(),
          protocol: Protocol.ETHEREUM,
          network,
          walletName,
          ...properties,
        }
      );
    },
    [flowData.amount, network, trackEvent, walletName]
  );
  useEffect(() => {
    trackStakingFlowEvent(Actions.STEP_RENDERED, { step });
  }, [step, trackStakingFlowEvent]);

  const reset = () => {
    updateFlowData(initialValues);
    setStep(EthereumFlowStep.AMOUNT);
    setTransactionHash();
  };

  useEffect(() => {
    if (!account) {
      reset();
    }
  }, [account]);

  return (
    <div className="rounded-md bg-white overflow-hidden shadow-3xl">
      {((): React.ReactNode => {
        switch (step) {
          case EthereumFlowStep.AMOUNT:
            return (
              <>
                <EthereumAmount
                  data={ethereumStakingFlowData}
                  flowData={flowData}
                  isLoading={isEthereumStakingFlowLoading}
                  onSubmit={(values) => {
                    updateFlowData(values);

                    if (onCheck) {
                      setStep(EthereumFlowStep.CHECK);
                    } else {
                      setStep(EthereumFlowStep.LOCATION);
                    }
                  }}
                  isStakeDisabled={isStakeDisabled}
                />
                <link
                  rel="preload"
                  href="https://app.figment.io/images/validator-location-map.png"
                  as="image"
                />
              </>
            );
          case EthereumFlowStep.CHECK:
            if (onCheck) {
              const CheckComponent = onCheck({
                isTestnet: network === Network.HOLESKY,
                onBack: () => setStep(EthereumFlowStep.AMOUNT),
                onNext: () => setStep(EthereumFlowStep.LOCATION),
              });

              return <CheckComponent />;
            }
          // Need this comment to avoid lint error: Expected a 'break' statement before 'case' no-fallthrough
          /* falls through */
          case EthereumFlowStep.LOCATION:
            return (
              <ValidatorLocation
                flowData={flowData}
                onBack={() => setStep(EthereumFlowStep.AMOUNT)}
                onNext={(values) => {
                  updateFlowData(values);
                  setStep(EthereumFlowStep.RESTAKING);
                }}
              />
            );
          case EthereumFlowStep.RESTAKING:
            return (
              <EigenLayerRestaking
                flowData={flowData}
                onBack={() => setStep(EthereumFlowStep.LOCATION)}
                onNext={(values) => {
                  updateFlowData(values);

                  if (values.isRestakingEnabled) {
                    if (values.eigenPodAddress) {
                      setStep(EthereumFlowStep.EIGENPOD_ADDRESS);
                      return;
                    }

                    setStep(EthereumFlowStep.DEPLOY_EIGENPOD);
                    return;
                  }

                  setStep(EthereumFlowStep.WITHDRAWAL_ADDRESS);
                }}
              />
            );
          case EthereumFlowStep.DEPLOY_EIGENPOD:
            return (
              <DeployEigenPod
                flowData={flowData}
                onBack={() => setStep(EthereumFlowStep.RESTAKING)}
                onNext={(values) => {
                  updateFlowData(values);
                  setStep(EthereumFlowStep.EIGENPOD_ADDRESS);
                }}
              />
            );
          case EthereumFlowStep.EIGENPOD_ADDRESS:
            return (
              <EigenPodAddress
                flowData={flowData}
                onBack={() => setStep(EthereumFlowStep.RESTAKING)}
                onNext={() =>
                  setStep(EthereumFlowStep.EXECUTION_REWARDS_ADDRESS)
                }
              />
            );
          case EthereumFlowStep.WITHDRAWAL_ADDRESS:
            return (
              <WithdrawalAddress
                flowData={flowData}
                onBack={() => setStep(EthereumFlowStep.RESTAKING)}
                onNext={(values) => {
                  updateFlowData(values);
                  setStep(EthereumFlowStep.EXECUTION_REWARDS_ADDRESS);
                }}
              />
            );
          case EthereumFlowStep.EXECUTION_REWARDS_ADDRESS:
            return (
              <ExecutionRewardsAddress
                flowData={flowData}
                onBack={() => {
                  if (flowData.isRestakingEnabled) {
                    setStep(EthereumFlowStep.EIGENPOD_ADDRESS);
                    return;
                  }

                  setStep(EthereumFlowStep.WITHDRAWAL_ADDRESS);
                }}
                onNext={(values) => {
                  updateFlowData(values);
                  setStep(EthereumFlowStep.SUMMARY);
                }}
              />
            );
          case EthereumFlowStep.SUMMARY:
            return (
              <EthereumStakingSummary
                flowData={flowData}
                price={ethereumStakingFlowData?.ethereumPrice.value || 0}
                onBack={() =>
                  setStep(EthereumFlowStep.EXECUTION_REWARDS_ADDRESS)
                }
                onSuccess={(values) => {
                  updateFlowData(values);
                  setStep(EthereumFlowStep.SUCCESS);
                }}
              />
            );
          case EthereumFlowStep.SUCCESS:
            return (
              <Success
                protocol={Protocol.ETHEREUM}
                amount={flowData.amount}
                transactionHash={flowData.transactionHash}
                onDone={reset}
                buttonText="Stake More"
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};
