import * as RadioGroup from "@radix-ui/react-radio-group";
import React, { memo, useMemo } from "react";
import { twMerge as tw } from "tailwind-merge";
import { ErrorText, HelperText } from "../shared/components";
import { InputRadioGroupProps, RadioGroupOption } from "./InputRadio.types";
import { FontSize } from "../../../theme/types";

/**
 * Renders a radio group input.
 *
 * @param InputRadioProps
 * @returns The InputRadio component
 */
const InputRadio: React.FC<InputRadioGroupProps> = ({
  defaultValue,
  disabled: inputDisabled,
  error,
  helperText,
  id,
  layout = "column",
  name,
  onBlur,
  onChange,
  options,
  value,
  size,
}) => {
  const initialValue = useMemo(
    () =>
      defaultValue?.length
        ? defaultValue
        : options.find((option) => option.default)?.value,
    [options, defaultValue]
  );

  return (
    <div className="w-full mt-1">
      <RadioGroup.Root
        className={tw(
          "flex gap-3",
          layout === "column" ? "flex-col" : "flex-row"
        )}
        defaultValue={initialValue}
        disabled={inputDisabled}
        name={id}
        onBlur={onBlur}
        onValueChange={onChange}
        title={name || id}
        value={value}
      >
        {options.map((option: RadioGroupOption, index) => {
          const { value, disabled, name } = option;
          const optionDisabled = disabled || inputDisabled;
          const id = `${index}:${value}`;
          const label = name || value;

          return (
            <div key={id} className="flex flex-row items-center">
              <RadioGroup.Item
                className={tw(
                  "relative bg-white w-6 h-6 rounded-full border border-basic-600",
                  optionDisabled
                    ? "border-basic-100 cursor-not-allowed"
                    : "shadow-sm hover:bg-green-100 hover:shadow-md focus:border-green",
                  size === "small" ? "w-5 h-5" : "w-6 h-6"
                )}
                disabled={optionDisabled}
                id={id}
                value={value}
              >
                <RadioGroup.Indicator
                  className={tw(
                    "absolute flex items-center justify-center  bg-green rounded-full",
                    size === "small"
                      ? "top-[0.130rem] left-[0.130rem] w-3 h-3"
                      : "top-1 left-1 w-3.5 h-3.5"
                  )}
                />
              </RadioGroup.Item>
              <label
                htmlFor={id}
                className={tw(
                  "px-2 font-normal",
                  size
                    ? FontSize[
                        size === "small"
                          ? "sm"
                          : size === "large"
                          ? "lg"
                          : "base"
                      ]
                    : "",
                  size === "small" ? "mt-[1px]" : "mt-0"
                )}
              >
                {label}
              </label>
            </div>
          );
        })}
      </RadioGroup.Root>

      {helperText && (
        <div className="mx-0 my-1">
          <HelperText>{helperText}</HelperText>
        </div>
      )}

      {error && (
        <div className="mx-0 my-1">
          <ErrorText>{error}</ErrorText>
        </div>
      )}
    </div>
  );
};

export default memo(InputRadio);
