import { graphql } from "msw";
import {
  EthereumActivityTableStakingActivityQuery,
  Networks,
  DepositStatuses,
  ExitRequestStatuses,
  SolanaActivityTableStakingActivityQuery,
  SolanaNetworks,
  StakingActivityActivityTypes,
  StakingActivityStatuses,
  StakingActivityProtocols,
  BabylonActivityTableStakingActivityQuery,
} from "../../../graphql/core/generated/gql";
import { addDays } from "@figmentjs/utils/src/formatters/date";
import { addHours } from "date-fns";

export const EthereumActivityTableStakingActivityQueryMock =
  graphql.query<EthereumActivityTableStakingActivityQuery>(
    "EthereumActivityTableStakingActivity",
    (req, res, ctx) => {
      return res(
        ctx.data({
          stakingActivity: {
            pageInfo: {
              hasNextPage: true,
            },
            nodes: [
              {
                id: "1",
                __typename: "EthereumStakingDeposit",
                network: Networks.Mainnet,
                amountEth: "1408",
                depositStatus: DepositStatuses.Deposited,
                createdAt: new Date(),
                estimatedActiveAt: addDays(new Date(), 5),
                activityAt: addDays(new Date(), 2),
                txHash:
                  "0x110b85aaa4b0ade445978af6e6d99ba57412691e489b088f0b8a0d4db67def5f",
              },
              {
                id: "2",
                __typename: "EthereumStakingDeposit",
                network: Networks.Mainnet,
                amountEth: "1408",
                depositStatus: DepositStatuses.Activating,
                createdAt: new Date(),
                estimatedActiveAt: addDays(new Date(), 5),
                activityAt: addDays(new Date(), 2),
                txHash:
                  "0x04c4d98afa935cc6224650d3d30bf4e554aa20b7424dc760ada1f19c428a9c00",
              },
              {
                id: "3",
                __typename: "EthereumStakingDeposit",
                network: Networks.Goerli,
                amountEth: "1408",
                depositStatus: DepositStatuses.Activated,
                createdAt: new Date(),
                activityAt: addDays(new Date(), 2),
                txHash:
                  "0x66f07eb802886118513c3e3b1ba75e69387552cf9a30942a5ee210b0450341f2",
              },
              {
                id: "4",
                __typename: "EthereumExitRequest",
                network: Networks.Goerli,
                amountEth: "32",
                exitStatus: ExitRequestStatuses.Exiting,
                createdAt: addDays(new Date(), 3),
                estimatedWithdrawableAt: addDays(new Date(), 6),
                estimatedPrincipalReturnAt: addDays(new Date(), 9),
                activityAt: addDays(new Date(), 2),
              },
              {
                id: "5",
                __typename: "EthereumExitRequest",
                network: Networks.Goerli,
                amountEth: "32",
                exitStatus: ExitRequestStatuses.PendingWithdrawal,
                createdAt: addDays(new Date(), 2),
                estimatedWithdrawableAt: addDays(new Date(), 5),
                estimatedPrincipalReturnAt: addDays(new Date(), 9),
                activityAt: addDays(new Date(), 2),
              },
              {
                id: "6",
                __typename: "EthereumExitRequest",
                network: Networks.Goerli,
                amountEth: "32",
                exitStatus: ExitRequestStatuses.PendingWithdrawal,
                createdAt: addDays(new Date(), 2),
                estimatedWithdrawableAt: undefined,
                estimatedPrincipalReturnAt: addDays(new Date(), 9),
                activityAt: addDays(new Date(), 2),
              },
              {
                id: "7",
                __typename: "EthereumExitRequest",
                network: Networks.Goerli,
                amountEth: "32",
                exitStatus: ExitRequestStatuses.Completed,
                createdAt: new Date(),
                estimatedWithdrawableAt: undefined,
                estimatedPrincipalReturnAt: addDays(new Date(), 9),
                activityAt: addDays(new Date(), 2),
              },
              {
                id: "8",
                __typename: "EthereumExitRequest",
                network: Networks.Holesky,
                amountEth: "32",
                exitStatus: ExitRequestStatuses.ExitRequested,
                createdAt: new Date(),
                estimatedWithdrawableAt: undefined,
                estimatedPrincipalReturnAt: addDays(new Date(), 9),
                activityAt: addDays(new Date(), 2),
              },
              {
                id: "1",
                __typename: "EthereumStakingDeposit",
                network: Networks.Mainnet,
                amountEth: "1408",
                depositStatus: DepositStatuses.Unfinalized,
                createdAt: new Date(),
                estimatedActiveAt: addDays(new Date(), 4),
                activityAt: addDays(new Date(), 2),
                txHash:
                  "0x786151523ca6ae4f323d777b33c45da357e85a7a31018f5a5c9739d237afef40",
              },
            ],
          },
        })
      );
    }
  );

export const EthereumActivityTableStakingActivityQueryEmptyMock =
  graphql.query<EthereumActivityTableStakingActivityQuery>(
    "EthereumActivityTableStakingActivity",
    (req, res, ctx) => {
      return res(
        ctx.data({
          stakingActivity: {
            pageInfo: {
              hasNextPage: false,
            },
            nodes: [],
          },
        })
      );
    }
  );

export const SolanaActivityTableStakingActivityQueryMock =
  graphql.query<SolanaActivityTableStakingActivityQuery>(
    "SolanaActivityTableStakingActivity",
    (req, res, ctx) => {
      return res(
        ctx.data({
          stakingActivityLogs: {
            pageInfo: {
              hasNextPage: true,
            },
            nodes: [
              {
                activityType: StakingActivityActivityTypes.Delegation,
                amount: "1.000000000",
                createdAt: new Date(),
                estimatedCompletedAt: null,
                id: "5d7990d5-4c04-4737-a588-d404668054de",
                network: SolanaNetworks.Devnet,
                status: StakingActivityStatuses.Complete,
                protocol: StakingActivityProtocols.Solana,
              },
              {
                activityType: StakingActivityActivityTypes.Delegation,
                amount: "0.050000000",
                createdAt: new Date(),
                estimatedCompletedAt: addDays(new Date(), 4),
                id: "1d844f74-01ed-49e9-883a-7f86151e7dc8",
                network: SolanaNetworks.Devnet,
                status: StakingActivityStatuses.Pending,
                protocol: StakingActivityProtocols.Solana,
              },
              {
                activityType: StakingActivityActivityTypes.Delegation,
                amount: "0.050000000",
                createdAt: new Date(),
                estimatedCompletedAt: null,
                id: "31dce27a-89b7-4c88-937f-b8441322aeb1",
                network: SolanaNetworks.Devnet,
                status: StakingActivityStatuses.Complete,
                protocol: StakingActivityProtocols.Solana,
              },
              {
                activityType: StakingActivityActivityTypes.Delegation,
                amount: "0.050000000",
                createdAt: new Date(),
                estimatedCompletedAt: null,
                id: "760a7969-b0da-40ad-9728-3a2d7f84bc9e",
                network: SolanaNetworks.Devnet,
                status: StakingActivityStatuses.Complete,
                protocol: StakingActivityProtocols.Solana,
              },
              {
                activityType: StakingActivityActivityTypes.Delegation,
                amount: "0.050000000",
                createdAt: new Date(),
                estimatedCompletedAt: null,
                id: "5267d574-6f5e-4d3d-8fa4-cb985b035ccd",
                network: SolanaNetworks.Devnet,
                status: StakingActivityStatuses.Complete,
                protocol: StakingActivityProtocols.Solana,
              },
              {
                activityType: StakingActivityActivityTypes.Withdrawal,
                amount: "0.050000000",
                createdAt: new Date(),
                estimatedCompletedAt: addHours(new Date(), 9),
                id: "8de40d34-6e7f-429b-942c-584aa4119e9f",
                network: SolanaNetworks.Devnet,
                status: StakingActivityStatuses.Complete,
                protocol: StakingActivityProtocols.Solana,
              },
              {
                activityType: StakingActivityActivityTypes.Undelegation,
                amount: "0.030000000",
                createdAt: new Date(),
                estimatedCompletedAt: addDays(new Date(), 3),
                id: "8f7790b2-a9e6-469d-930d-c9bc58b47a77",
                network: SolanaNetworks.Devnet,
                status: StakingActivityStatuses.Pending,
                protocol: StakingActivityProtocols.Solana,
              },
              {
                activityType: StakingActivityActivityTypes.Undelegation,
                amount: "0.030000000",
                createdAt: new Date(),
                estimatedCompletedAt: addDays(new Date(), 7),
                id: "8f7790b2-a9e6-469d-930d-c9bc58b47a77",
                network: SolanaNetworks.Devnet,
                status: StakingActivityStatuses.Complete,
                protocol: StakingActivityProtocols.Solana,
              },
            ],
          },
        })
      );
    }
  );

export const SolanaActivityTableStakingActivityQueryEmptyMock =
  graphql.query<SolanaActivityTableStakingActivityQuery>(
    "SolanaActivityTableStakingActivity",
    (req, res, ctx) => {
      return res(
        ctx.data({
          stakingActivityLogs: {
            pageInfo: {
              hasNextPage: false,
            },
            nodes: [],
          },
        })
      );
    }
  );

export const BabylonActivityTableStakingActivityQueryMock =
  graphql.query<BabylonActivityTableStakingActivityQuery>(
    "BabylonActivityTableStakingActivity",
    (req, res, ctx) => {
      return res(
        ctx.data({
          stakingActivityLogs: {
            pageInfo: {
              hasNextPage: true,
            },
            nodes: [
              {
                activityType: StakingActivityActivityTypes.Stake,
                amount: "1.000000000",
                createdAt: new Date(2024, 2, 3, 0, 0, 0, 0).toString(),
                estimatedCompletedAt: null,
                id: "5d7990d5-4c04-4737-a588-d404668054de",
                network: "signet",
                status: StakingActivityStatuses.Complete,
                protocol: StakingActivityProtocols.Babylon,
                txHash:
                  "9e82a1e8fb015fd5c73f2f34bdd7dbebbb61dc66f8e02a6a7494d1939acd5bf7",
                user: {
                  email: "fig@figment.io",
                },
              },
              {
                activityType: StakingActivityActivityTypes.Stake,
                amount: "0.050000000",
                createdAt: new Date(2024, 2, 3, 0, 0, 0, 0).toString(),
                estimatedCompletedAt: null,
                id: "1d844f74-01ed-49e9-883a-7f86151e7dc8",
                network: "signet",
                status: StakingActivityStatuses.Pending,
                protocol: StakingActivityProtocols.Babylon,
                txHash:
                  "9e82a1e8fb015fd5c73f2f34bdd7dbebbb61dc66f8e02a6a7494d1939acd5bf7",
                user: {
                  email: "fig@figment.io",
                },
              },
              {
                activityType: StakingActivityActivityTypes.Stake,
                amount: "0.050000000",
                createdAt: new Date(2024, 2, 3, 0, 0, 0, 0).toString(),
                estimatedCompletedAt: null,
                id: "31dce27a-89b7-4c88-937f-b8441322aeb1",
                network: "signet",
                status: StakingActivityStatuses.Complete,
                protocol: StakingActivityProtocols.Babylon,
                txHash:
                  "9e82a1e8fb015fd5c73f2f34bdd7dbebbb61dc66f8e02a6a7494d1939acd5bf7",
                user: {
                  email: "fig@figment.io",
                },
              },
              {
                activityType: StakingActivityActivityTypes.Stake,
                amount: "0.050000000",
                createdAt: new Date(2024, 2, 3, 0, 0, 0, 0).toString(),
                estimatedCompletedAt: null,
                id: "760a7969-b0da-40ad-9728-3a2d7f84bc9e",
                network: "signet",
                status: StakingActivityStatuses.Complete,
                protocol: StakingActivityProtocols.Babylon,
                txHash:
                  "9e82a1e8fb015fd5c73f2f34bdd7dbebbb61dc66f8e02a6a7494d1939acd5bf7",
                user: {
                  email: "fig@figment.io",
                },
              },
              {
                activityType: StakingActivityActivityTypes.Stake,
                amount: "0.050000000",
                createdAt: new Date(2024, 2, 3, 0, 0, 0, 0).toString(),
                estimatedCompletedAt: null,
                id: "5267d574-6f5e-4d3d-8fa4-cb985b035ccd",
                network: "signet",
                status: StakingActivityStatuses.Complete,
                protocol: StakingActivityProtocols.Babylon,
                txHash:
                  "9e82a1e8fb015fd5c73f2f34bdd7dbebbb61dc66f8e02a6a7494d1939acd5bf7",
                user: {
                  email: "fig@figment.io",
                },
              },
              {
                activityType: StakingActivityActivityTypes.Stake,
                amount: "0.050000000",
                createdAt: new Date(2024, 2, 3, 0, 0, 0, 0).toString(),
                estimatedCompletedAt: null,
                id: "8de40d34-6e7f-429b-942c-584aa4119e9f",
                network: "signet",
                status: StakingActivityStatuses.Pending,
                protocol: StakingActivityProtocols.Babylon,
                txHash:
                  "9e82a1e8fb015fd5c73f2f34bdd7dbebbb61dc66f8e02a6a7494d1939acd5bf7",
                user: {
                  email: "fig@figment.io",
                },
              },
              {
                activityType: StakingActivityActivityTypes.Stake,
                amount: "0.030000000",
                createdAt: new Date(2024, 2, 3, 0, 0, 0, 0).toString(),
                estimatedCompletedAt: null,
                id: "8f7790b2-a9e6-469d-930d-c9bc58b47a77",
                network: "signet",
                status: StakingActivityStatuses.Pending,
                protocol: StakingActivityProtocols.Babylon,
                txHash:
                  "3c067100e61ace9faec200fd45495c9718753db7d88d15fe48ec805c1495dd7f",
                user: {
                  email: "fig@figment.io",
                },
              },
            ],
          },
        })
      );
    }
  );

export const BabylonActivityTableStakingActivityQueryEmptyMock =
  graphql.query<BabylonActivityTableStakingActivityQuery>(
    "BabylonActivityTableStakingActivity",
    (req, res, ctx) => {
      return res(
        ctx.data({
          stakingActivityLogs: {
            pageInfo: {
              hasNextPage: false,
            },
            nodes: [],
          },
        })
      );
    }
  );
