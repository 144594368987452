import React, { useEffect } from "react";
import { Protocol } from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import {
  WithdrawSummary,
  WithdrawSolanaAccount,
  WithdrawSuccess,
} from "../../../../steps/solana/";
import { useStepper } from "../../../../../hooks/use-stepper";
import {
  unstakingInitialValues,
  useUnstakingFlowData,
} from "../../hooks/use-flow-data";
import { ErrorStep } from "../../../../../components";
import { SolanaWithdrawalFlowStep } from "./flow.types";
import BigNumber from "bignumber.js";
import { WrongWallet } from "../../../../steps/solana/wrong-wallet";
import { FigAppActionEnum } from "@figmentjs/analytics/client";

type Props = {
  onCancel: () => void;
  onSuccessButtonClick: () => void;
  stakeAccountPubkey: string;
  amount: string;
  withdrawAuthorityAddress: string;
  stakedByEmail?: string | null;
  onTrackEvent: ({
    action,
    step,
    amount,
  }: {
    action: FigAppActionEnum;
    step?: string;
    amount?: string;
  }) => void;
};

export const Flow: React.FC<Props> = ({
  onCancel,
  onSuccessButtonClick,
  stakeAccountPubkey,
  amount,
  withdrawAuthorityAddress,
  stakedByEmail,
  onTrackEvent,
}) => {
  const { step, setStep } = useStepper<SolanaWithdrawalFlowStep>(
    SolanaWithdrawalFlowStep.SUMMARY
  );

  useEffect(() => {
    onTrackEvent({
      action: FigAppActionEnum.STEP_RENDERED,
      step: step,
    });
  }, [onTrackEvent, step]);

  const { flowData, updateFlowData } = useUnstakingFlowData({
    ...unstakingInitialValues,
    amount: BigNumber(amount),
  });
  const { account, setTransactionHash } = Wallet.useWallet<Protocol.SOLANA>();

  const reset = () => {
    updateFlowData(unstakingInitialValues);
    setStep(SolanaWithdrawalFlowStep.SUMMARY);
    setTransactionHash();
  };

  useEffect(() => {
    if (!account) {
      reset();
    }
  }, [account]);

  if (account && account !== withdrawAuthorityAddress) {
    return (
      <div className="p-4">
        <WrongWallet
          address={withdrawAuthorityAddress}
          email={stakedByEmail}
          onCancel={onCancel}
        />
      </div>
    );
  }

  return (
    <div className="rounded-md bg-white overflow-hidden shadow-3xl">
      {((): React.ReactNode => {
        switch (step) {
          case SolanaWithdrawalFlowStep.SUMMARY:
            return (
              <WithdrawSummary
                onConfirmUnstake={(values) => {
                  updateFlowData(values);
                  setStep(SolanaWithdrawalFlowStep.SIGNING);
                }}
                stakeAccountPubkey={stakeAccountPubkey}
                onCancel={onCancel}
                flowData={flowData}
                onTrackEvent={onTrackEvent}
                onError={(values) => {
                  updateFlowData(values);
                  setStep(SolanaWithdrawalFlowStep.ERROR);
                }}
              />
            );
          case SolanaWithdrawalFlowStep.SIGNING:
            return (
              <WithdrawSolanaAccount
                unsignedRawTransaction={flowData.unsignedRawTransaction}
                stakeAccountPubkey={stakeAccountPubkey}
                amount={flowData.amount}
                onTrackEvent={onTrackEvent}
                onSuccess={() => {
                  setStep(SolanaWithdrawalFlowStep.SUCCESS);
                }}
                onCancelled={onCancel}
                onError={() => {
                  setStep(SolanaWithdrawalFlowStep.ERROR);
                }}
              />
            );
          case SolanaWithdrawalFlowStep.SUCCESS:
            return (
              <WithdrawSuccess
                onClose={onSuccessButtonClick}
                amount={flowData.amount}
              />
            );
          case SolanaWithdrawalFlowStep.ERROR:
            return (
              <div className="p-4">
                <ErrorStep
                  onClose={() => setStep(SolanaWithdrawalFlowStep.SUMMARY)}
                  error={
                    flowData.errors?.[0] || { message: "An error occurred" }
                  }
                />
              </div>
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};
