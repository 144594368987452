import { ToggleGroupItem } from "@figmentjs/web-core/src/components/forms/ToggleGroup/ToggleGroup.types";

export type DurationToggle = {
  [key in SupportedDurations]: ToggleGroupItem & { dateLabelFormat: string };
};

export enum SupportedDurations {
  OneMonth = "one_month",
  OneYear = "one_year",
  SixMonths = "six_months",
}

export type CurrencyToggle = {
  [key in Currencies]: ToggleGroupItem;
};

export enum Currencies {
  USD = "USD",
}

export type AggregateRewards = {
  timestamp?: string | null;
  total?: string | null;
  totalUsd?: string | null;
  consensusTotal?: string | null;
  consensusTotalUsd?: string | null;
  executionTotal?: string | null;
  executionTotalUsd?: string | null;
};

export type RewardsEarned = {
  rewardsEarnedOneMonth: {
    rewards?: Array<AggregateRewards> | null;
  };
  rewardsEarnedSixMonths: {
    rewards?: Array<AggregateRewards> | null;
  };
  rewardsEarnedOneYear: {
    rewards?: Array<AggregateRewards> | null;
  };
};
