import React, { memo } from "react";
import { BodyText } from "@figmentjs/web-core";
import { getTruncatedAddress } from "@figmentjs/utils";
import { twMerge as tw } from "tailwind-merge";
import { Protocol } from "@figmentjs/protocols";
import { protocolAddressTheme } from "../address/address.themes";
import { Size } from "./address-base.types";
import { FontSize } from "@figmentjs/web-core/src/theme/types";

type Props = {
  address: string;
  prefix?: React.ReactElement[];
  suffix?: React.ReactElement[];
  truncate?: boolean;
  protocol: Protocol;
  overrideTheme?: boolean;
  href?: string;
  size?: keyof typeof Size;
};

const sizeMap = {
  [Size.sm]: "base",
  [Size.lg]: "xl",
};

const AddressBase: React.FC<Props> = ({
  address,
  prefix,
  suffix,
  truncate = true,
  protocol,
  overrideTheme = false,
  href,
  size = Size.sm,
}) => {
  const theme = overrideTheme ? protocolAddressTheme(protocol) : null;

  return (
    <div
      className={tw(
        "group/address w-fit flex items-center justify-center border border-basic-200 rounded bg-basic-100 h-full pl-1 pr-2 hover:pr-1 py-px break-all",
        !truncate && "py-2",
        overrideTheme && `${theme?.background} ${theme?.border}`,
        size === Size.lg && "py-0.5"
      )}
    >
      {prefix}
      <BodyText
        size={sizeMap[size] as keyof typeof FontSize}
        font="mono"
        color={overrideTheme ? `${theme!.text}` : "green-800"}
        weight="semibold"
      >
        {href ? (
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {truncate ? getTruncatedAddress({ address, protocol }) : address}
          </a>
        ) : truncate ? (
          getTruncatedAddress({ address, protocol })
        ) : (
          address
        )}
      </BodyText>
      {suffix}
    </div>
  );
};

export default memo(AddressBase);
