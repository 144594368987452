import { graphql } from "msw";
import {
  EthereumStakingPositionsTableQuery,
  SolanaStakingPositionsTableQuery,
  StakeAccountStatus,
} from "../../../graphql/core/generated/gql";

// UNCOMMENT AND USE THIS IN validatorCountsByStatus
// WHEN YOU NEED REAL DATA
// function randomInteger(min: number, max: number) {
//   return Math.floor(Math.random() * (max - min + 1)) + min;
// }

const validatorCountsByStatus = () => {
  return {
    activeExiting: 1,
    activeOngoing: 1,
    activeSlashed: 0,
    deposited: 0,
    depositedNotFinalized: 0,
    exitedSlashed: 0,
    exitedUnslashed: 0,
    fundingRequested: 0,
    pendingInitialized: 0,
    pendingQueued: 1,
    provisioned: 0,
    withdrawalDone: 1,
    withdrawalPossible: 0,
  };
};

export const EthereumStakingPositionsTableQueryMock =
  graphql.query<EthereumStakingPositionsTableQuery>(
    "EthereumStakingPositionsTable",
    (req, res, ctx) => {
      const { after, before, last } = req.variables;
      const page =
        after === "MTA" || (!!last && !after && !before) ? "LAST" : "FIRST";
      const pageInfo =
        page === "FIRST"
          ? {
              startCursor: "MQ",
              endCursor: "MTA",
              hasNextPage: true,
              hasPreviousPage: false,
            }
          : {
              startCursor: "MTA",
              endCursor: "MTB",
              hasNextPage: false,
              hasPreviousPage: true,
            };

      return res(
        ctx.data({
          ethereumWithdrawalAccounts: {
            totalCount: 14,
            pageInfo,
            nodes: [
              {
                id: "d7571f08-f169-4886-8abb-d7217f794be0",
                network: "mainnet",
                protocol: "ethereum",
                address: "ddd2",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                stakedBalance: "0.0",
                stakedBalanceUsd: "0.0",
                isEigenpod: true,
                totalActiveValidators: 2,
                totalVerifiedValidators: 2,
                totalValidators: 2,
                totalExitableValidators: 2,
                validatorCountsByStatus: validatorCountsByStatus(),
              },
              {
                id: "968a0aa1-8630-4219-96c5-eecac3ca7a2a",
                network: "mainnet",
                protocol: "ethereum",
                address: "0x275e8f09f090cf9b8e77008643e33d477fbb05e6",
                totalRewards: "1.0",
                totalRewardsUsd: "2000.0",
                stakedBalance: "32.0",
                stakedBalanceUsd: "64000.0",
                isEigenpod: true,
                totalActiveValidators: 2,
                totalVerifiedValidators: 2,
                totalValidators: 2,
                totalExitableValidators: 2,
                validatorCountsByStatus: validatorCountsByStatus(),
              },
              {
                id: "ad54972a-bbde-4bd9-943c-7537aea96663",
                network: "mainnet",
                protocol: "ethereum",
                address: "0xc0b0d5f4a73b348a1dd2ded11ed9e409fe7685a8",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                stakedBalance: "0.0",
                stakedBalanceUsd: "0.0",
                isEigenpod: false,
                totalActiveValidators: 2,
                totalVerifiedValidators: 0,
                totalValidators: 2,
                totalExitableValidators: 2,
                validatorCountsByStatus: validatorCountsByStatus(),
              },
              {
                id: "10a3133f-6726-4c14-b364-275defd1a253",
                network: "mainnet",
                protocol: "ethereum",
                address: "0x47325c3291633de9bd83e4ed00ed4a78b553d268",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                stakedBalance: "0.0",
                stakedBalanceUsd: "0.0",
                isEigenpod: false,
                totalActiveValidators: 2,
                totalVerifiedValidators: 0,
                totalValidators: 2,
                totalExitableValidators: 2,
                validatorCountsByStatus: validatorCountsByStatus(),
              },
              {
                id: "38f4cc98-aa9f-4b9c-8322-bba2d1dc5aa7",
                network: "mainnet",
                protocol: "ethereum",
                address: "0x4351938cF093bd24bFC52C5D9ea9de020cca700a",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                stakedBalance: "0.0",
                stakedBalanceUsd: "0.0",
                isEigenpod: false,
                totalActiveValidators: 2,
                totalVerifiedValidators: 0,
                totalValidators: 2,
                totalExitableValidators: 2,
                validatorCountsByStatus: validatorCountsByStatus(),
              },
              {
                id: "e1bcbe63-6363-4da9-aa4b-c2dbeedaf3a2",
                network: "mainnet",
                protocol: "ethereum",
                address: "0xdc780aac63683d9bd1e5be8dd8453d03f802ed85",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                stakedBalance: "0.0",
                stakedBalanceUsd: "0.0",
                isEigenpod: false,
                totalActiveValidators: 2,
                totalVerifiedValidators: 0,
                totalValidators: 2,
                totalExitableValidators: 2,
                validatorCountsByStatus: validatorCountsByStatus(),
              },
              {
                id: "788a8d3e-a6a9-428e-afa0-f02f5af25890",
                network: "mainnet",
                protocol: "ethereum",
                address: "0x3e30fc2cc800af86e88b69d32d9daf7eccb3a520",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                stakedBalance: "0.0",
                stakedBalanceUsd: "0.0",
                isEigenpod: false,
                totalActiveValidators: 2,
                totalVerifiedValidators: 0,
                totalValidators: 2,
                totalExitableValidators: 2,
                validatorCountsByStatus: validatorCountsByStatus(),
              },
              {
                id: "89746d73-44d2-47df-a5c8-0c1930c53d5e",
                network: "mainnet",
                protocol: "ethereum",
                address: "0xdb007d02efef6640f9cfcfc8d2e0ba39fe4457de",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                stakedBalance: "0.0",
                stakedBalanceUsd: "0.0",
                isEigenpod: false,
                totalActiveValidators: 2,
                totalVerifiedValidators: 0,
                totalValidators: 2,
                totalExitableValidators: 2,
              },
              {
                id: "04d55b74-4704-4281-ba7f-8b4b13e0f9ce",
                network: "mainnet",
                protocol: "ethereum",
                address: "0x45D829d82CDd29169E4feE081c750b385eC00dE5",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                stakedBalance: "0.0",
                stakedBalanceUsd: "0.0",
                isEigenpod: false,
                totalActiveValidators: 2,
                totalVerifiedValidators: 0,
                totalValidators: 2,
                totalExitableValidators: 2,
                validatorCountsByStatus: validatorCountsByStatus(),
              },
              {
                id: "c143a3e9-01f8-48b7-8aa2-b7c130c2f48d",
                network: "mainnet",
                protocol: "ethereum",
                address: "bjk1903",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                stakedBalance: "0.0",
                stakedBalanceUsd: "0.0",
                isEigenpod: false,
                totalActiveValidators: 2,
                totalVerifiedValidators: 0,
                totalValidators: 2,
                totalExitableValidators: 2,
                validatorCountsByStatus: validatorCountsByStatus(),
              },
            ],
          },
        })
      );
    }
  );

export const SolanaStakingPositionsTableQueryMock =
  graphql.query<SolanaStakingPositionsTableQuery>(
    "SolanaStakingPositionsTable",
    (req, res, ctx) => {
      const { after, before, last } = req.variables;
      const page =
        after === "MTA" || (!!last && !after && !before) ? "LAST" : "FIRST";
      const pageInfo =
        page === "FIRST"
          ? {
              startCursor: "MQ",
              endCursor: "MTA",
              hasNextPage: true,
              hasPreviousPage: false,
            }
          : {
              startCursor: "MTA",
              endCursor: "MTB",
              hasNextPage: false,
              hasPreviousPage: true,
            };

      return res(
        ctx.data({
          solanaStakeAccounts: {
            totalCount: 14,
            pageInfo,
            nodes: [
              {
                id: "014a4f18-cec6-471f-963c-b840178ec73d",
                network: "mainnet",
                address: "GW2DuxbxD8e2Nt6gaTxNeycmiZgqnLA2eEZX6KBGkZyc",
                totalRewards: "4.450684768",
                totalRewardsUsd: "725.92",
                balance: null,
                balanceUsd: null,
                status: StakeAccountStatus.Activating,
                stakeAuthorityAddress:
                  "BGpKUJ8GzQxiBcuXxDGTYbDHUetzygzML6GMhgot6e4r",
              },
              {
                id: "63fb3095-0366-4355-9e54-328f41730ab0",
                network: "mainnet",
                address: "6B1LkTfNUrL8ZVPD2WgkF2U7bZAgRsi7jBMyCVi3Btpf",
                totalRewards: "92.79286691",
                totalRewardsUsd: "15134.84",
                balance: null,
                balanceUsd: null,
                status: StakeAccountStatus.Active,
                stakeAuthorityAddress:
                  "BGpKUJ8GzQxiBcuXxDGTYbDHUetzygzML6GMhgot6e4r",
              },
              {
                id: "f81d16ce-2b10-49cd-807b-c06e8f281403",
                network: "mainnet",
                address: "2yoofMr4N78WPxVK8MU8Yy26dfRZbPw5WDzTRCA9uv95",
                totalRewards: "0.402678745",
                totalRewardsUsd: "65.68",
                balance: "4.450684768",
                balanceUsd: "725.92",
                status: StakeAccountStatus.Deactivating,
                stakeAuthorityAddress:
                  "BGpKUJ8GzQxiBcuXxDGTYbDHUetzygzML6GMhgot6e4r",
              },
              {
                id: "d7571f08-f169-4886-8abb-d7217f794be0",
                network: "mainnet",
                address: "6RQ8sqSt789AFiu8W3rwztwWUS9MA6LAwi6GmzH6EKRS",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                balance: "0.0",
                balanceUsd: "0.0",
                status: StakeAccountStatus.Inactive,
                stakeAuthorityAddress:
                  "BGpKUJ8GzQxiBcuXxDGTYbDHUetzygzML6GMhgot6e4r",
              },
              {
                id: "968a0aa1-8630-4219-96c5-eecac3ca7a2a",
                network: "mainnet",
                address: "7RQ8sqSt789AFiu8W3rwztwWUS9MA6LAwi6GmzH6EKRS",
                totalRewards: "1.0",
                totalRewardsUsd: "2000.0",
                balance: "132442312.450684768",
                balanceUsd: "234725.92",
                status: StakeAccountStatus.Withdrawn,
                stakeAuthorityAddress:
                  "BGpKUJ8GzQxiBcuXxDGTYbDHUetzygzML6GMhgot6e4r",
              },
              {
                id: "ad54972a-bbde-4bd9-943c-7537aea96663",
                network: "mainnet",
                address: "7qQ8sqSt789AFiu8W3rwztwWUS9MA6LAwi6GmzH6EKRS",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                balance: "0.0",
                balanceUsd: "0.0",
                stakeAuthorityAddress:
                  "BGpKUJ8GzQxiBcuXxDGTYbDHUetzygzML6GMhgot6e4r",
              },
              {
                id: "10a3133f-6726-4c14-b364-275defd1a253",
                network: "mainnet",
                address: "7qQ8sqSt789AFiu8W3rwztwWUS9MA6LAwi6GmzH6Easd",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                balance: "0.0",
                balanceUsd: "0.0",
                stakeAuthorityAddress:
                  "BGpKUJ8GzQxiBcuXxDGTYbDHUetzygzML6GMhgot6e4r",
              },
              {
                id: "38f4cc98-aa9f-4b9c-8322-bba2d1dc5aa7",
                network: "mainnet",
                address: "7qQ8sqSt789AFiu8W3rwztwWUS9MA6LAwi6GmzH6a8sda",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                balance: "0.0",
                balanceUsd: "0.0",
                stakeAuthorityAddress:
                  "BGpKUJ8GzQxiBcuXxDGTYbDHUetzygzML6GMhgot6e4r",
              },
              {
                id: "e1bcbe63-6363-4da9-aa4b-c2dbeedaf3a2",
                network: "mainnet",
                address: "uqQ8sqSt789AFiu8W3rwztw89faMA6LAwi6GmzH6a8sda",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                balance: "0.0",
                balanceUsd: "0.0",
                stakeAuthorityAddress:
                  "BGpKUJ8GzQxiBcuXxDGTYbDHUetzygzML6GMhgot6e4r",
              },
              {
                id: "788a8d3e-a6a9-428e-afa0-f02f5af25890",
                network: "mainnet",
                address: "asQ8sqSt789AFiu8W3rwztw89faMA6LAwi6GmzH6a8888",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                balance: "0.0",
                balanceUsd: "0.0",
                stakeAuthorityAddress:
                  "BGpKUJ8GzQxiBcuXxDGTYbDHUetzygzML6GMhgot6e4r",
              },
              {
                id: "89746d73-44d2-47df-a5c8-0c1930c53d5e",
                network: "mainnet",
                address: "ffQ8sqSt789AFiu8W3rwztw89faMA6LAwi6GmzH6a8777",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                balance: "0.0",
                balanceUsd: "0.0",
                stakeAuthorityAddress:
                  "BGpKUJ8GzQxiBcuXxDGTYbDHUetzygzML6GMhgot6e4r",
              },
              {
                id: "04d55b74-4704-4281-ba7f-8b4b13e0f9ce",
                network: "mainnet",
                address: "iiQ8sqSt789AFiu8W3rwztw89faMA6LAwi6GmzH6a88bb",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                balance: "0.0",
                balanceUsd: "0.0",
                stakeAuthorityAddress:
                  "BGpKUJ8GzQxiBcuXxDGTYbDHUetzygzML6GMhgot6e4r",
              },
              {
                id: "c143a3e9-01f8-48b7-8aa2-b7c130c2f48d",
                network: "mainnet",
                address: "uiQ8sqSt789AFiu8W3rwztw89faMA6LAwi6GmzH6a1132",
                totalRewards: "0.0",
                totalRewardsUsd: "0.0",
                balance: "0.0",
                balanceUsd: "0.0",
                stakeAuthorityAddress:
                  "BGpKUJ8GzQxiBcuXxDGTYbDHUetzygzML6GMhgot6e4r",
              },
            ],
          },
        })
      );
    }
  );
