import React, { memo } from "react";
import { BodyText } from "../../../../typography";
import { HelperTextProps } from "./HelperText.types";

/**
 * A component for displaying helper text within a form field.
 * @param HelperTextProps.
 * @returns The HelperText component.
 */
const HelperText: React.FC<HelperTextProps> = ({ children }) => {
  return (
    <BodyText size="xs" color="basic-900">
      {children}
    </BodyText>
  );
};

export default memo(HelperText);
