"use client";

import React, { memo } from "react";
import { Button } from "../../../../buttons";
import { Icon } from "../../../../graphics";
import { Tooltip } from "../../../../overlays";
import { InputAdornmentProps } from "./InputAdornment.types";

interface MaybeTooltipProps {
  children: React.ReactElement;
  placement: "left" | "right";
  tooltip?: string;
}

const MaybeTooltip: React.FC<MaybeTooltipProps> = ({
  children,
  placement,
  tooltip,
}) => {
  return tooltip ? (
    <Tooltip placement={placement} overlayText={tooltip}>
      {children}
    </Tooltip>
  ) : (
    children
  );
};

interface MaybeButtonProps {
  children: React.ReactElement;
  tooltip?: string;
  onClick?: () => void;
}

const MaybeButton: React.FC<MaybeButtonProps> = ({
  children,
  tooltip,
  onClick,
}) => {
  return onClick ? (
    <div className="flex items-stretch">
      <Button palette="text" onClick={onClick} title={tooltip}>
        {children}
      </Button>
    </div>
  ) : (
    children
  );
};

const InputAdornment: React.FC<InputAdornmentProps> = (
  { adornment },
  placement
) => {
  const { icon, onClick, tooltip } = adornment;
  const postion = placement === "start" ? "right" : "left";

  return (
    <MaybeTooltip tooltip={tooltip} placement={postion}>
      <MaybeButton onClick={onClick}>
        <Icon icon={icon} size="xl" color="basic-900" />
      </MaybeButton>
    </MaybeTooltip>
  );
};

export default memo(InputAdornment);
