import React, { useEffect, useState } from "react";
import { BigNumber } from "bignumber.js";
import { currencyFormatter } from "@figmentjs/utils";
import { Heading, BodyText, Slider, ShimmerItem } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { Protocol } from "@figmentjs/protocols";
import { useNetworkConfig } from "../../hooks";

const MAX_VALIDATORS = 250;
const ETH_TOKEN_STEP = 32;

type GetSliderMax = (props: {
  balance: BigNumber;
  protocol: Protocol;
}) => BigNumber;

const getSliderMax: GetSliderMax = ({ balance, protocol }) => {
  if (protocol === Protocol.ETHEREUM) {
    const maxPossibleValidators = balance
      .div(ETH_TOKEN_STEP)
      .integerValue(BigNumber.ROUND_DOWN);
    const maxSafeValidators = BigNumber.min(
      maxPossibleValidators,
      MAX_VALIDATORS
    );

    return maxSafeValidators.times(ETH_TOKEN_STEP);
  }

  return balance.integerValue(BigNumber.ROUND_DOWN);
};

type Props = {
  balance: BigNumber;
  disabled: boolean;
  amount: BigNumber;
  isLoading: boolean;
  tokenPrice: string;
  onValueChange: (value: number) => void;
  initialValue: BigNumber;
  protocol: Protocol;
  isUnstaking?: boolean;
};

export const SliderInput: React.FC<Props> = ({
  balance,
  disabled,
  amount,
  isLoading,
  tokenPrice,
  onValueChange,
  initialValue,
  protocol,
  isUnstaking,
}) => {
  const [value, setValue] = useState<BigNumber>(initialValue);
  const { ticker } = useNetworkConfig({ protocol });

  const tokenStep = protocol === Protocol.ETHEREUM ? ETH_TOKEN_STEP : 1;

  useEffect(() => {
    if (disabled) {
      setValue(new BigNumber(0));
    } else {
      setValue(initialValue);
    }
  }, [disabled, setValue, initialValue]);

  return (
    <div className="flex flex-col">
      <span className="flex gap-1">
        <Heading
          level="h3"
          color={
            isUnstaking
              ? "basic-800"
              : disabled || amount.isEqualTo(0)
              ? "basic-600"
              : "green-700"
          }
          font={HeadingFont.space}
          weight="semibold"
        >
          {disabled ? 0 : amount.toString()}
        </Heading>
        <Heading
          level="h3"
          color={isUnstaking ? "basic-600" : "basic-800"}
          font={HeadingFont.space}
          weight="semibold"
        >
          {ticker}
        </Heading>
      </span>
      <div>
        {isLoading ? (
          <ShimmerItem width={64} />
        ) : (
          <span className="flex gap-1">
            <BodyText
              weight="semibold"
              color={amount.isEqualTo(0) ? "basic-600" : "basic-800"}
            >
              {currencyFormatter.format(
                new BigNumber(tokenPrice)
                  .times(disabled ? 0 : amount)
                  .toNumber()
              )}
            </BodyText>
          </span>
        )}
      </div>
      <div className="w-full mt-2">
        <Slider
          step={tokenStep}
          onValueChange={(value: number[]) => {
            onValueChange(value[0]);
            setValue(new BigNumber(value[0]));
          }}
          min={0}
          max={getSliderMax({
            protocol,
            balance,
          }).toNumber()}
          disabled={disabled}
          displayMinAndMax={false}
          defaultValue={[initialValue.toNumber()]}
          value={[value.toNumber()]}
        />
      </div>
    </div>
  );
};
