import React from "react";
import { BodyText, Button, Heading, Icon } from "@figmentjs/web-core";
import { Protocol, getTransactionExplorerUrl } from "@figmentjs/protocols";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { Details } from "../../../../components";
import BigNumber from "bignumber.js";
import Wallet from "@figmentjs/wallet";
import { useNetworkConfig } from "../../../../hooks";

type Props = {
  amount: BigNumber;
  protocol: Protocol;
  transactionHash?: string;
  details?: React.ComponentProps<typeof Details>["details"];
  onDone: () => void;
  buttonText?: string;
  isSIWE?: boolean;
};

export const Success: React.FC<Props> = ({
  amount,
  protocol,
  details,
  transactionHash,
  onDone,
  buttonText = "View Activity",
  isSIWE,
}) => {
  const {
    transactionHash: walletTransactionHash,
    network,
    setTransactionHash,
  } = Wallet.useWallet();
  const { ticker } = useNetworkConfig({ protocol });

  const txHash = walletTransactionHash || transactionHash;

  return (
    <div className="p-4">
      <div className="flex gap-2 items-center justify-center text-center border-b border-b-green-100 pb-4">
        <Icon icon="MdCheckCircle" size="4xl" color="green-800" />
        <Heading
          level="h5"
          color="green"
          font={HeadingFont.space}
          weight="bold"
        >
          {amount.toString()} {ticker} Staked
        </Heading>
      </div>
      <div className="py-4">
        <BodyText weight="semibold">What&apos;s Next?</BodyText>
        <div className="pt-1">
          {isSIWE ? (
            <>
              <BodyText size="sm">
                You can follow the status of your activating validators in the
                activity table.
              </BodyText>
              {txHash && (
                <div className="mt-4 mb-2 w-fit">
                  <Button
                    palette="text"
                    size="tiny"
                    href={getTransactionExplorerUrl(protocol, txHash, network)}
                    iconAfter="MdOpenInNew"
                    openInNewTab
                  >
                    View transaction
                  </Button>
                </div>
              )}
            </>
          ) : protocol === Protocol.SOLANA ? (
            <>
              <BodyText size="sm">Your</BodyText>
              <div className="inline mx-1">
                <Button
                  palette="text"
                  size="tiny"
                  openInNewTab
                  href={getTransactionExplorerUrl(protocol, txHash!, network)}
                >
                  staking transaction
                </Button>
              </div>
              <BodyText size="sm">
                has been broadcasted! You will start earning rewards in the next
                few days.
              </BodyText>
            </>
          ) : (
            <>
              <BodyText size="sm">You will receive an email once the</BodyText>
              <div className="inline mx-1">
                <Button
                  palette="text"
                  size="tiny"
                  openInNewTab
                  href={getTransactionExplorerUrl(protocol, txHash!, network)}
                >
                  transaction
                </Button>
              </div>
              <BodyText size="sm">
                has been confirmed on chain, usually within 15 minutes.
              </BodyText>
            </>
          )}
        </div>
      </div>
      {details && (
        <div className="pb-4">
          <Details details={details} containerClassName="gap-4 mb-4" />
        </div>
      )}
      <div className="w-full ">
        <Button
          size="small"
          fullWidth
          onClick={() => {
            setTransactionHash();
            onDone();
          }}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
