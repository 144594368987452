import * as RadixToast from "@radix-ui/react-toast";
import React, { memo } from "react";
import { twMerge as tw } from "tailwind-merge";
import { ActionConfig, ToastProps, ToastSize } from "./Toast.types";
import { Banner } from "../Banner";
import { Icon } from "../../graphics";
import { OverlayPalette, OverlayText } from "../shared/Overlay.props";
import { activeButtonStylesMap, linkStyles } from "../../buttons/Button/Button";

export const DEFAULT_NOTIFICATION_DURATION = 1500;

const toastBaseStyles = "bg-none w-fit rounded-md shadow-lg min-w-[360px] my-4";
const accessibleStyles =
  "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75";

const toastFontSizeMap: { [key in ToastSize]: string } = {
  // these are the same, not a mistake but leaving separate for ease of changing later
  medium: "text-base leading-5",
  small: "text-base leading-5",
};

export const ActionText: Record<OverlayPalette, string> = {
  error: "text-black",
  info: "text-white",
  success: "text-black",
};

export const ActionButton: React.FC<{
  palette: keyof typeof OverlayPalette;
  action: ActionConfig;
  size: keyof typeof ToastSize;
}> = ({ palette, action, size }) => {
  const Tag: keyof JSX.IntrinsicElements = "href" in action ? "a" : "button";
  return (
    // TODO: [FIGAPP-384] Replace Radix Action with a ButtonWithRef component
    <RadixToast.Action
      altText={typeof action.title === "string" ? action.title : "Action"}
      className="pb-0.5 ml-4"
      asChild
    >
      <Tag
        className={tw(
          activeButtonStylesMap["text"],
          toastFontSizeMap[size],
          ActionText[palette],
          linkStyles,
          "pointer-events-auto"
        )}
        href={"href" in action ? action.href : undefined}
        onClick={"onAction" in action ? action.onAction : undefined}
      >
        {action.title}
      </Tag>
    </RadixToast.Action>
  );
};

/**
 * Renders the Toast component.
 *
 * @param ToastProps
 * @returns The Toast component
 */
const Toast: React.FC<ToastProps> = ({
  palette = "success",
  size = "medium",
  message,
  duration = DEFAULT_NOTIFICATION_DURATION,
  noIcon,
  action,
  hideDismiss = false,
  open,
  onOpenChange,
}) => {
  return (
    <>
      <RadixToast.Root
        open={open}
        onOpenChange={onOpenChange}
        duration={duration}
        className={tw(
          toastFontSizeMap[size],
          toastBaseStyles,
          accessibleStyles
        )}
      >
        <RadixToast.Title className="flex items-center justify-start w-full">
          <Banner
            title={message}
            palette={palette}
            small={size !== "medium"}
            noIcon={noIcon}
            actionComponent={
              action && (
                <ActionButton palette={palette} action={action} size={size} />
              )
            }
            closeComponent={
              !hideDismiss && (
                <RadixToast.Close
                  title="Dismiss"
                  className={tw(
                    "flex items-center justify-center ml-4 pointer-events-auto",
                    activeButtonStylesMap["text"],
                    OverlayText[palette]
                  )}
                >
                  <Icon icon="MdClose" size="xl" />
                </RadixToast.Close>
              )
            }
          />
        </RadixToast.Title>
      </RadixToast.Root>
      <RadixToast.Viewport />
    </>
  );
};

export default memo(Toast);
