import React, { Fragment } from "react";
import {
  Timestamp,
  Icon,
  BodyText,
  Button,
  Tooltip,
} from "@figmentjs/web-core";
import { twMerge as tw } from "tailwind-merge";
import {
  daysBetween,
  friendlyDate,
} from "@figmentjs/utils/src/formatters/date";

type Props = {
  transparent?: boolean;
  ticker: string;
  activities: {
    id: string;
    amount: string;
    createdAt: string;
    isStaking: boolean;
    isComplete: boolean;
    status?: ActivityTableStatus;
    eta?: {
      start?: string | null;
      end?: string | null;
    };
    txUrl?: string | null;
    user?: string;
  }[];
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  onFetchNextPage: () => void;
};

export type ActivityTableStatus = {
  text: string;
  subText?: (
    start: ActivityTime,
    end: ActivityTime,
    differenceInDays?: "" | number | null
  ) => React.ReactNode;
  color: string;
  tooltipText?: (text: string) => string;
};

export type ActivityTime =
  | ""
  | {
      time: string;
      unit: string;
    }
  | null
  | undefined;

export const ACTIVITY_TABLE_PAGE_SIZE = 5;

export const ActivityTableBase: React.FC<Props> = ({
  ticker,
  transparent,
  activities,
  hasNextPage,
  isFetchingNextPage,
  onFetchNextPage,
}) => {
  return (
    <div className="w-full">
      <div
        className={tw(
          "flex flex-col w-full gap-4 p-4 rounded-md",
          transparent ? "bg-[rgba(255,255,255,.55)]" : "bg-white"
        )}
      >
        {activities.map((activity) => {
          const start = activity.eta?.start && friendlyDate(activity.eta.start);
          const end = activity.eta?.end && friendlyDate(activity.eta.end);
          const differenceInDays =
            activity.eta?.start &&
            activity.eta?.end &&
            daysBetween(activity.eta.start, activity.eta.end);

          const action = activity.isStaking ? "Staked" : "Unstaked";
          const user = activity.user;

          return (
            <Fragment key={activity.id}>
              <div className="flex items-center w-full">
                <div className="flex flex-[.7_.7_0%] sm:flex-[.35_.35_0%] items-center">
                  <div className="whitespace-nowrap">
                    <BodyText>
                      <Timestamp
                        dateTime={activity.createdAt}
                        format="MMMM d"
                      />
                    </BodyText>
                  </div>
                </div>
                <div className="flex-[.9_.9_0%] sm:flex-[.35_.35_0%]">
                  <div className="flex">
                    <Tooltip
                      overlayText={user ? `${action} by ${user}` : `${action}`}
                      placement="top"
                      compact
                    >
                      <div className="flex gap-x-1 items-center">
                        <Icon
                          icon={
                            activity.isStaking
                              ? "MdArrowUpward"
                              : "MdArrowDownward"
                          }
                          color={activity.isStaking ? "green-700" : "basic-600"}
                          size="xl"
                        />
                        <BodyText weight="semibold">{activity.amount}</BodyText>{" "}
                        <BodyText color="basic-800">{ticker}</BodyText>
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="flex flex-1 items-center gap-x-2 flex-wrap">
                  <div className="relative w-1.5 h-1.5">
                    <div
                      className={tw(
                        "absolute w-1.5 h-1.5 rounded-full",
                        activity.status?.color ??
                          (activity.isComplete
                            ? "bg-green-500"
                            : "bg-yellow-1000")
                      )}
                    />
                    {!activity.isComplete && (
                      <div className="absolute w-1.5 h-1.5 bg-yellow-1000 rounded-full animate-ping" />
                    )}
                  </div>
                  <div className="flex flex-col sm:flex-row sm:gap-1 items-center">
                    <div className="flex flex-wrap max-w-0 sm:max-w-full">
                      {activity.status?.tooltipText ? (
                        <Tooltip
                          overlayContent={
                            <div className="flex flex-col gap-2 w-52">
                              <div className="block leading-3">
                                <div
                                  className={tw(
                                    "float-left mr-1 mt-[5px] w-2.5 h-2.5 rounded-full",
                                    `${activity.status.color}`
                                  )}
                                />
                                <BodyText color="white" size="sm">
                                  {activity.status.tooltipText(
                                    end
                                      ? `${end.time} ${end.unit}`
                                      : start
                                      ? `${start.time} ${start.unit}`
                                      : ""
                                  )}
                                </BodyText>
                              </div>
                              {activity.txUrl && (
                                <div className="border-t border-basic-800 pt-2">
                                  <a
                                    href={activity.txUrl || ""}
                                    className="flex gap-2 items-center text-white text-sm hover:underline"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Transaction
                                    <Icon
                                      icon="MdOutlineOpenInNew"
                                      color="white"
                                    />
                                  </a>
                                </div>
                              )}
                            </div>
                          }
                        >
                          <BodyText>
                            {activity.status.text ||
                              (activity.isComplete ? "Complete" : "Pending")}
                          </BodyText>
                        </Tooltip>
                      ) : (
                        <BodyText>
                          {activity.status?.text ||
                            (activity.isComplete ? "Complete" : "Pending")}
                        </BodyText>
                      )}
                    </div>
                    <div className="hidden sm:block">
                      {!activity.isComplete &&
                        activity.status?.subText &&
                        activity.status.subText(start, end, differenceInDays)}
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
      {hasNextPage && (
        <div className="mt-2 sm:mt-6 flex justify-center">
          <Button
            palette="text"
            size="tiny"
            textColor="black"
            disabled={isFetchingNextPage}
            onClick={onFetchNextPage}
          >
            More activity
          </Button>
        </div>
      )}
    </div>
  );
};
