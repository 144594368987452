import React, { useEffect } from "react";
import { SignTransaction, ConfirmTransaction } from "../../../../components";
import { RebroadcastTransaction } from "../../shared/rebroadcast-transaction";
import { SolanaFlowData } from "../../../flows/solana/default/use-flow-data/use-flow-data";
import Wallet from "@figmentjs/wallet";
import { Protocol, SolanaNetworkValidatorMap } from "@figmentjs/protocols";
import { Spinner } from "@figmentjs/web-core";
import {
  StakingActivityActivityTypes,
  useSolanaStakingTxSaveMutation,
} from "../../../../../graphql/core/generated/gql";
import BigNumber from "bignumber.js";
import { networkMap } from "../solana-staking-summary/solana-staking-summary";
import { useStakeSol } from "../../../flows/solana/hooks";

type Props = {
  amount: BigNumber;
  stakeAccountPubkey: string;
  voteAccountPubkey?: string;
  onSuccess: (values: Partial<SolanaFlowData>) => void;
  onError: (values: Partial<SolanaFlowData>) => void;
  onCancelled: () => void;
  onRebroadcastSuccess: (values: Partial<SolanaFlowData>) => void;
};

export const CreateStakeAccount: React.FC<Props> = ({
  amount,
  stakeAccountPubkey,
  voteAccountPubkey,
  onSuccess,
  onError,
  onCancelled,
  onRebroadcastSuccess,
}) => {
  const {
    isSendingTransaction,
    transactionHash,
    setTransactionHash,
    sendTransactionError,
    network,
    account,
  } = Wallet.useWallet<Protocol.SOLANA>();

  const { mutateAsync: confirmTransaction } = useSolanaStakingTxSaveMutation();

  const { stake, isLoading } = useStakeSol({
    input: {
      network: networkMap[network!],
      amountSol: amount.toNumber(),
      fundingAccountPubkey: account!,
      voteAccountPubkey:
        voteAccountPubkey || SolanaNetworkValidatorMap[network!],
    },
    onError: () => {},
    onCreation: onRebroadcastSuccess,
  });

  const handleRebroadcast = async () => {
    await stake();
  };

  useEffect(() => {
    const main = async () => {
      if (
        transactionHash &&
        stakeAccountPubkey &&
        !isSendingTransaction &&
        !sendTransactionError
      ) {
        const { solanaStakingTxSave } = await confirmTransaction({
          network: networkMap[network!],
          amountSol: amount.toNumber(),
          stakeAccountPubkey,
          activityType: StakingActivityActivityTypes.Delegation,
          txHash: transactionHash,
        });

        onSuccess({
          errors: solanaStakingTxSave?.userErrors,
          transactionHash,
        });
      }
    };

    main();
  }, [
    isSendingTransaction,
    transactionHash,
    sendTransactionError,
    setTransactionHash,
    onSuccess,
    confirmTransaction,
  ]);

  if (sendTransactionError === "TX_TIMEOUT") {
    return (
      <RebroadcastTransaction
        onRebroadcast={handleRebroadcast}
        isLoading={isLoading}
      />
    );
  }

  if (sendTransactionError === "TX_CANCELED") {
    onCancelled();
    return null;
  } else if (sendTransactionError) {
    onError({
      errors: [
        { message: sendTransactionError, code: "", isWalletError: true },
      ],
    });
    return null;
  } else if (isSendingTransaction && !transactionHash) {
    return (
      <div className="p-4">
        <SignTransaction />
      </div>
    );
  } else if (transactionHash) {
    return (
      <div className="p-4">
        <ConfirmTransaction
          title="Confirming Transaction"
          subtitle="This can take up to a minute. Do not close or refresh the page."
        />
      </div>
    );
  }

  return (
    <div className="py-24 flex justify-center items-center">
      <Spinner />
    </div>
  );
};
