"use client";

import React, { useRef } from "react";
import { BigNumber } from "bignumber.js";
import { compareAsc } from "date-fns";
import { twMerge } from "tailwind-merge";
import tailwindConfig from "@figmentjs/web-core/src/theme/tailwind.config";
import {
  currencyFormatter,
  currencyFormatterCompact,
  dateFormatter,
  tokenFormatter,
} from "@figmentjs/utils";
import { BarChart, BodyText } from "@figmentjs/web-core";
import {
  RewardsEarned,
  AggregateRewards,
  SupportedDurations,
  Currencies,
} from "./rewards-earned-chart.types";
import { getDate, xTickFormatter, yDomainFunc } from "./chart.utils";

type Props = {
  aggregatedRewards: RewardsEarned;
  duration: SupportedDurations;
  dateLabelFormat: string;
  labels: {
    consensusLayer?: string;
    executionLayer?: string;
  };
  isTestnetMode: boolean;
  ticker: string;
  colors: {
    consensusLayer: string;
    executionLayer: string;
  };
  legendLabels?: {
    consensusLayer: string;
    executionLayer: string;
  };
  currency: string;
};

const ChartKeys = (currency: string) => {
  switch (currency) {
    case Currencies.USD:
      return {
        consensusTotalUsd: "consensusTotalUsd",
        executionTotalUsd: "executionTotalUsd",
      };
    default:
      return {
        consensusTotal: "consensusTotal",
        executionTotal: "executionTotal",
      };
  }
};

const Chart: React.FC<Props> = ({
  aggregatedRewards,
  duration,
  dateLabelFormat = "MMM d",
  labels,
  isTestnetMode,
  ticker,
  colors,
  legendLabels,
  currency,
}) => {
  const rewardsEarned =
    duration === SupportedDurations.OneMonth
      ? aggregatedRewards.rewardsEarnedOneMonth
      : duration === SupportedDurations.OneYear
      ? aggregatedRewards.rewardsEarnedOneYear
      : aggregatedRewards.rewardsEarnedSixMonths;

  const keys = ChartKeys(currency);
  const chartKeys = Object.values(keys);

  const containerRef = useRef<HTMLDivElement>(null);

  if (
    !rewardsEarned.rewards?.length ||
    rewardsEarned.rewards
      .reduce((sum, current) => {
        return sum.plus(current.total || 0);
      }, BigNumber(0))
      .lte(0)
  ) {
    return (
      <div className="grid place-items-center m-12">
        <BodyText size="sm">No rewards yet</BodyText>
      </div>
    );
  }

  return (
    <div className="mt-7" ref={containerRef} data-testid={`chart-${duration}`}>
      <BarChart<AggregateRewards, typeof chartKeys[number]>
        /**
         * Setting the key to the raw data used in the chart to ensure
         * that the chart re-renders when data changes
         * (without this, it does not)
         */
        key={JSON.stringify(rewardsEarned)}
        data={rewardsEarned.rewards}
        keys={chartKeys}
        xDomainFunc={(data) =>
          data.map(getDate).sort((a, b) => compareAsc(new Date(a), new Date(b)))
        }
        numYTicks={6}
        xTickFormatter={(date, index) =>
          xTickFormatter({
            date,
            index,
            width: containerRef?.current?.clientWidth || 0,
            dateLabelFormat,
            duration,
          })
        }
        getXValue={getDate}
        yDomainFunc={() => yDomainFunc(rewardsEarned.rewards, currency)}
        yTickFormatter={(value: number) =>
          currency === Currencies.USD
            ? currencyFormatterCompact.format(value)
            : `${tokenFormatter.format(value)}`
        }
        getValue={(dataPoint, key) => {
          return parseFloat(dataPoint[key as keyof AggregateRewards] || "0");
        }}
        customColors={{
          consensusTotalUsd: colors.consensusLayer,
          executionTotalUsd: colors.executionLayer,
          consensusTotal: colors.consensusLayer,
          executionTotal: colors.executionLayer,
        }}
        customLegendLabels={{
          [keys.consensusTotalUsd!]: legendLabels?.consensusLayer || "",
          [keys.executionTotalUsd!]: legendLabels?.executionLayer || "",
          [keys.consensusTotal!]: legendLabels?.consensusLayer || "",
          [keys.executionTotal!]: legendLabels?.executionLayer || "",
        }}
        renderTooltipChildren={({ tooltipData }) => {
          const getPresentedTokenAmount = (tokenAmount: BigNumber) =>
            tokenFormatter.format(tokenAmount.toNumber());

          const rows = [
            {
              title: labels.executionLayer,
              total: new BigNumber(tooltipData.executionTotal || "0"),
              totalUsd: new BigNumber(tooltipData.executionTotalUsd || "0"),
              color: colors.executionLayer,
              negativeColor: tailwindConfig.theme.colors.error.DEFAULT,
            },
            {
              title: labels.consensusLayer,
              total: new BigNumber(tooltipData.consensusTotal || "0"),
              totalUsd: new BigNumber(tooltipData.consensusTotalUsd || "0"),
              color: colors.consensusLayer,
              negativeColor: tailwindConfig.theme.colors.error.DEFAULT,
            },
          ];

          return (
            <>
              <div>
                <div className="pt-2 px-4">
                  <BodyText color="basic-600" size="sm">
                    {dateFormatter({
                      inputDate: tooltipData.timestamp!,
                      format:
                        duration === SupportedDurations.OneMonth
                          ? "MMMM d, y"
                          : "MMMM y",
                      timeZone: "UTC",
                    })}
                  </BodyText>
                </div>
              </div>
              <div className="pt-1.5 pb-3 px-4">
                <div>
                  <BodyText color="white" size="base" weight="bold" as="p">
                    Rewards
                  </BodyText>
                  <div className="pt-0 pb-2">
                    <div className="flex items-center space-x-2 flex-nowrap">
                      <BodyText color="white" size="sm" font="mono">
                        {getPresentedTokenAmount(
                          new BigNumber(tooltipData.total || "0")
                        ) +
                          " " +
                          ticker}
                      </BodyText>
                      {!isTestnetMode && (
                        <BodyText color="basic-600" size="sm" font="mono">
                          {currencyFormatter.format(
                            parseFloat(
                              new BigNumber(
                                tooltipData.totalUsd || "0"
                              ).toString()
                            )
                          )}
                        </BodyText>
                      )}
                    </div>
                  </div>
                </div>
                {rows.map((row, index) => (
                  <div
                    key={row.title}
                    className="ml-0.5 pl-3 border-solid border-l-[1px] border-basic-800 pt-2"
                  >
                    <div className="flex flex-row align-text-top gap-2">
                      <div
                        className="w-2 h-2 rounded-full"
                        style={{
                          backgroundColor: row.total.isNegative()
                            ? row.negativeColor
                            : row.color,
                        }}
                      />
                      <div className="-mt-1.5">
                        <BodyText color="white" size="sm" weight="bold" as="p">
                          {row.title}
                        </BodyText>
                        <div
                          className={twMerge(
                            "pt-0",
                            index !== rows.length - 1 && "pb-2"
                          )}
                        >
                          <div className="flex items-center space-x-2 flex-nowrap">
                            <BodyText color="white" size="sm" font="mono">
                              {getPresentedTokenAmount(row.total) +
                                " " +
                                ticker}
                            </BodyText>
                            {!isTestnetMode && (
                              <BodyText color="basic-600" size="sm" font="mono">
                                {currencyFormatter.format(
                                  parseFloat(row.totalUsd.toString() || "0")
                                )}
                              </BodyText>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

export default Chart;
