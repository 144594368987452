import * as Checkbox from "@radix-ui/react-checkbox";
import React, { memo, useEffect, useState } from "react";
import { twMerge as tw } from "tailwind-merge";
import { Icon } from "../../graphics";
import { ErrorText, HelperText } from "../shared/components";
import { InputCheckboxProps } from "./InputCheckbox.types";
import debounce from "lodash/debounce";

/**
 * Renders a checkbox input field.
 *
 * @param InputCheckboxProps
 * @returns The InputCheckbox component
 */
const InputCheckbox: React.FC<InputCheckboxProps> = ({
  autoFocus,
  defaultValue,
  disabled,
  error,
  helperText,
  children,
  name,
  onBlur,
  onChange,
  onFocus,
  testId,
  value,
  iconColor = "green-800",
  iconSize = "3xl",
}) => {
  // TODO - add support for the "indeterminate" state.
  const [checked, setChecked] = useState<boolean>(
    value || defaultValue || false
  );

  const handleCheckChange = debounce((checked: boolean) => {
    setChecked(checked);
    onChange && onChange({ value: checked });
  }, 500);

  useEffect(() => {
    if (value !== checked && value !== undefined) {
      setChecked(value);
    }
  }, [checked, value]);

  const labelClassName = tw(
    "flex flex-row relative align-middle",
    disabled && "cursor-not-allowed"
  );

  const iconClassName = tw(disabled && "cursor-not-allowed");

  return (
    <div className="w-full">
      <label data-testid={testId} className={labelClassName}>
        <Checkbox.Root
          disabled={disabled}
          autoFocus={autoFocus}
          defaultChecked={checked}
          onBlur={onBlur}
          onFocus={onFocus}
          onCheckedChange={handleCheckChange}
          value={value ? 1 : 0}
          name={name}
        >
          <div className={iconClassName}>
            {checked ? (
              <Icon
                size={iconSize}
                icon="MdCheckBox"
                color={error ? "error" : iconColor}
              />
            ) : (
              <Icon
                size={iconSize}
                icon="MdCheckBoxOutlineBlank"
                color={error ? "error" : "basic-300"}
              />
            )}
          </div>
        </Checkbox.Root>
        <div className="ml-2 flex items-center">{children}</div>
      </label>
      {helperText && (
        <div className="mx-1 my-0">
          <HelperText>{helperText}</HelperText>
        </div>
      )}
      {error && (
        <div className="mx-1 my-0">
          <ErrorText>{error}</ErrorText>
        </div>
      )}
    </div>
  );
};

export default memo(InputCheckbox);
