import { BaseNetwork, Protocol, protocols } from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";

type Props = {
  protocol: Protocol;
};

export const useNetworkConfig = ({ protocol }: Props) => {
  const { network, displayedNetwork } = Wallet.useWallet();

  const networkIndex = displayedNetwork || network || BaseNetwork.MAINNET;

  return protocols[protocol].networks[networkIndex as BaseNetwork];
};
