import { useQuery, hashQueryKey } from "@tanstack/react-query";
import Wallet, { useCurrentWalletAddress } from "@figmentjs/wallet";
import { useSignatureAccessToken } from "../use-signature-access-token";

export const validateAccessTokenQueryKey = ["validate-access-token"];

export const useValidateAccessToken = () => {
  const { account } = Wallet.useWallet();
  const { currentWalletAddress } = useCurrentWalletAddress();
  const { signatureAccessToken } = useSignatureAccessToken({
    account,
  });

  const accessTokenHeaderKey = `X-Figment-Signature-Access-Token-${currentWalletAddress!}`;

  const { isLoading, data } = useQuery({
    enabled: !!currentWalletAddress,
    queryKey: validateAccessTokenQueryKey,
    cacheTime: 0,
    queryKeyHashFn: () =>
      hashQueryKey(["validate-access-token", signatureAccessToken]),
    queryFn: () =>
      fetch("/api/validate-access-token", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...(currentWalletAddress
            ? { "X-Figment-Wallet-Address": currentWalletAddress }
            : {}),
          ...(signatureAccessToken
            ? { [accessTokenHeaderKey]: signatureAccessToken }
            : {}),
        },
      })
        .then(async (res) => res.json())
        .catch(() => ({ data: { isValid: false } })),
  });

  return { isLoading, data };
};
