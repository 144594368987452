import React from "react";
import { BodyText, Button } from "@figmentjs/web-core";
import { Details, DetailType } from "../../../../components/details";
import Wallet from "@figmentjs/wallet";
import {
  Protocol,
  protocols,
  SolanaNetwork,
  SolanaNetworkValidatorMap,
} from "@figmentjs/protocols";
import { useSolanaStakingFlowData } from "../../../../staking/flows/solana/default/flow/use-solana-staking-flow-data";
import {
  SolanaNetworks,
  useUndelegateSolanaMutation,
} from "../../../../../graphql/core/generated/gql";
import { SolanaFlowData } from "../../../flows/solana/hooks/use-flow-data/use-flow-data";
import { WalletDetails } from "../../../../components/wallet-details";
import { FigAppActionEnum } from "@figmentjs/analytics/client";

export type Props = {
  onConfirmUnstake: (values: Partial<SolanaFlowData>) => void;
  onCancel: () => void;
  onBack?: () => void;
  onError: (values: Partial<SolanaFlowData>) => void;
  stakeAccountPubkey: string;
  flowData: SolanaFlowData;
  customValidator?: {
    pubkey: string;
    label: string;
    commission: number;
  };
  onTrackEvent: ({
    action,
    step,
    amount,
  }: {
    action: FigAppActionEnum;
    step?: string;
    amount?: string;
  }) => void;
};

export const UndelegateSummary: React.FC<Props> = ({
  onConfirmUnstake,
  onCancel,
  onBack,
  onError,
  stakeAccountPubkey,
  flowData,
  customValidator,
  onTrackEvent,
}) => {
  const { account, connect, isConnecting, network, sendTransaction } =
    Wallet.useWallet<Protocol.SOLANA>();
  const { price, isSolanaStakingFlowLoading, exitTime } =
    useSolanaStakingFlowData({
      account,
      network,
    });

  const networkMap: {
    [key in SolanaNetwork]?: SolanaNetworks;
  } = {
    [SolanaNetwork.DEVNET]: SolanaNetworks.Devnet,
    [SolanaNetwork.MAINNET]: SolanaNetworks.Mainnet,
    [SolanaNetwork.TESTNET]: SolanaNetworks.Testnet,
  };

  const { mutateAsync: solanaUndelegate } = useUndelegateSolanaMutation();

  const handleConfirm = async () => {
    const { solanaStakingDeactivateTransactionCreate } = await solanaUndelegate(
      {
        network: networkMap[network!]!,
        stakeAccountPubkey: stakeAccountPubkey,
      }
    );
    if (solanaStakingDeactivateTransactionCreate?.userErrors?.length) {
      onError({
        errors: solanaStakingDeactivateTransactionCreate?.userErrors || [],
      });
      onTrackEvent({
        action: FigAppActionEnum.CONFIRM_UNSTAKE_BTN_CLICKED,
        step: "UNDELEGATE_SOLANA_STEP",
        amount: flowData.amount!.toString(),
      });
      return;
    }

    sendTransaction({
      payload:
        solanaStakingDeactivateTransactionCreate!.data!
          .unsignedTransactionSerialized,
    });

    onConfirmUnstake({
      unsignedRawTransaction:
        solanaStakingDeactivateTransactionCreate?.data
          ?.unsignedTransactionSerialized,
    });
  };

  const details = [
    {
      type: DetailType.TOKEN,
      label: "Amount",
      value: {
        tokenDetails: {
          amount: flowData.amount.toString(),
          ticker: protocols[Protocol.SOLANA].networks[network!].ticker,
          usdAmount: flowData.amount
            .times(price || "0")
            .toFormat(2)
            .toString(),
        },
      },
    },
    {
      type: DetailType.ADDRESS,
      label: "Withdrawal Address",
      value: {
        text: stakeAccountPubkey,
      },
    },
    {
      type: DetailType.ADDRESS,
      label: "Validator",
      value: {
        text: customValidator?.pubkey || SolanaNetworkValidatorMap[network!],
      },
    },
    {
      type: DetailType.DEFAULT,
      label: "Unstaking Period",
      value: {
        text: `${exitTime.time}`,
        appendedText: exitTime.unit,
      },
    },
  ];
  return (
    <>
      <WalletDetails protocol={Protocol.SOLANA} action="Undelegate SOL" />
      <div className="p-4">
        <div>
          <Details details={details} showTopBorder={false} showBottomBorder />
        </div>
        <div className="pt-4">
          <span>
            <BodyText size="sm" weight="semibold">
              IMPORTANT:{" "}
            </BodyText>
            <BodyText size="sm">
              Unstaking your SOL is an irreversible action. Once your SOL is
              unstaked, it will stop earning rewards.
            </BodyText>
          </span>
        </div>
        <div className="py-4 space-y-4">
          <div className="flex justify-evenly gap-2">
            {onBack && (
              <Button
                palette="tertiary"
                size="small"
                type="submit"
                fullWidth
                onClick={onBack}
              >
                Back
              </Button>
            )}
            {!account ? (
              <Button
                palette="primary"
                size="small"
                fullWidth
                onClick={connect}
                loading={isConnecting}
              >
                Connect Wallet
              </Button>
            ) : (
              <Button
                palette="primary"
                size="small"
                fullWidth
                onClick={handleConfirm}
                loading={isSolanaStakingFlowLoading}
              >
                Confirm Unstake
              </Button>
            )}
          </div>
          <div className="flex justify-center">
            <Button
              palette="text"
              size="tiny"
              textColor="text-basic-800"
              onClick={onCancel}
              autoFocus
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
