import { request } from "graphql-request";
import { CURRENT_WALLET_ADDRESS_STORAGE_KEY } from "@figmentjs/wallet";
import { SIGNATURE_ACCESS_TOKEN_STORAGE_KEY } from "../../src/hooks/use-signature-access-token";

// When running tests, the API endpoint needs to be an absolute URL.
const baseUrl = ["test", "ci"].includes(process.env.NODE_ENV!)
  ? "http://localhost:6009"
  : "";

const ENDPOINT = `${baseUrl}/api/graphql`;

/**
 * Custom fetcher to append auth token to request.
 *
 * When using `graphql-request` directly with `graphql-codegen` (`fetcher: graphql-request` in codegen.yml),
 * it is required to pass `client` on each query, which is troublesome.
 * Therefore we create a custom fetcher to provide the endpoint directly to simplify code.
 */
export const coreGQLFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  headers?: HeadersInit
) => {
  return async () => {
    const requestHeaders = new Headers(headers);

    const currentWalletAddress = window.localStorage.getItem(
      CURRENT_WALLET_ADDRESS_STORAGE_KEY
    );

    if (currentWalletAddress && currentWalletAddress !== "undefined") {
      requestHeaders.set(
        "X-Figment-Wallet-Address",
        JSON.parse(currentWalletAddress)
      );
      const signatureAccessToken = window.localStorage.getItem(
        `${SIGNATURE_ACCESS_TOKEN_STORAGE_KEY}:${JSON.parse(
          currentWalletAddress
        )}`
      );
      if (signatureAccessToken && signatureAccessToken !== "undefined") {
        requestHeaders.set(
          `X-Figment-Signature-Access-Token-${JSON.parse(
            currentWalletAddress!
          )}`,
          JSON.parse(signatureAccessToken)
        );
      }
    }

    requestHeaders.set(
      "X-Figment-Parent-Url",
      window.ethereum?.isLedgerLive ? "ledger-live" : document.referrer
    );
    const urlParams = new URLSearchParams(window.location.search);
    const rawDappToken = urlParams.get("dappToken");
    const dappToken = rawDappToken === "undefined" ? undefined : rawDappToken;
    if (dappToken) {
      requestHeaders.set("X-Figment-Dapp-Token", dappToken);
    }

    const data = await request<TData, TVariables>({
      url: ENDPOINT,
      document: query,
      variables,
      requestHeaders,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);

    return data;
  };
};
