import React from "react";
import {
  Protocol,
  SolanaNetwork,
  getTransactionExplorerUrl,
  protocols,
} from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import {
  useInfiniteSolanaActivityTableStakingActivityQuery,
  SolanaNetworks,
  StakingActivityProtocols,
  StakingActivityActivityTypes,
  StakingActivityStatuses,
  StakingActivityNodeFragment,
} from "../../../../graphql/core/generated/gql";
import {
  ACTIVITY_TABLE_PAGE_SIZE,
  ActivityTableBase as ActivityTable,
  ActivityTableStatus,
  ActivityTime,
} from "../activity-table-base/activity-table-base";
import { ActivityTableEmpty } from "../activity-table-empty/activity-table-empty";
import { ActivityTableLoading } from "../activity-table-loading/activity-table-loading";
import BigNumber from "bignumber.js";
import { BodyText } from "@figmentjs/web-core";
import { ActivitySubtext } from "../components/activity-subtext";

const networkMap: {
  [key in SolanaNetwork]?: SolanaNetworks;
} = {
  [SolanaNetwork.DEVNET]: SolanaNetworks.Devnet,
  [SolanaNetwork.MAINNET]: SolanaNetworks.Mainnet,
  [SolanaNetwork.TESTNET]: SolanaNetworks.Testnet,
};

const getStatus = (
  activityRecord: StakingActivityNodeFragment
): ActivityTableStatus => {
  switch (`${activityRecord.activityType}-${activityRecord.status}`) {
    case `${StakingActivityActivityTypes.Delegation}-${StakingActivityStatuses.Complete}`:
      return {
        text: "Active",
        subText: undefined,
        color: "bg-green-500",
        tooltipText: undefined,
      };
    case `${StakingActivityActivityTypes.Delegation}-${StakingActivityStatuses.Pending}`:
      return {
        text: "Activating",
        subText: (start: ActivityTime) => {
          return (
            start && (
              <ActivitySubtext>
                <BodyText weight="semibold" size="sm" color="basic-800">
                  {`${start.time} ${start.unit}`}
                </BodyText>
                <BodyText size="sm" color="basic-800">
                  {" "}
                  until active
                </BodyText>
              </ActivitySubtext>
            )
          );
        },
        color: "bg-yellow-1000",
        tooltipText: undefined,
      };
    case `${StakingActivityActivityTypes.Undelegation}-${StakingActivityStatuses.Pending}`:
      return {
        text: "Exiting",
        subText: (start: ActivityTime) => {
          return (
            start && (
              <ActivitySubtext>
                <BodyText weight="semibold" size="sm" color="basic-800">
                  {`${start.time} ${start.unit}`}
                </BodyText>
                <BodyText size="sm" color="basic-800">
                  {" "}
                  until exit
                </BodyText>
              </ActivitySubtext>
            )
          );
        },
        color: "bg-yellow-1000",
        tooltipText: undefined,
      };
    case `${StakingActivityActivityTypes.Undelegation}-${StakingActivityStatuses.Complete}`:
      return {
        text: "Exited",
        subText: undefined,
        color: "bg-basic-600",
        tooltipText: undefined,
      };
    case `${StakingActivityActivityTypes.Withdrawal}-${StakingActivityStatuses.Complete}`:
      return {
        text: "Withdrawn",
        subText: undefined,
        color: "bg-basic-600",
        tooltipText: undefined,
      };
    default:
      return {
        text: "Active",
        subText: undefined,
        color: "bg-green-500",
        tooltipText: undefined,
      };
  }
};

const getActivityEta = (
  activityRecord: StakingActivityNodeFragment
): { start?: string | null; end?: string | null } =>
  activityRecord.activityType === StakingActivityActivityTypes.Delegation &&
  (activityRecord.status !== StakingActivityStatuses.Complete ||
    !activityRecord.status)
    ? { start: activityRecord.estimatedCompletedAt }
    : {
        start: activityRecord.estimatedCompletedAt,
        end: undefined,
      };

type Props = {
  networkOverride?: SolanaNetwork;
  transparent?: boolean;
};

export const SolanaActivityTable: React.FC<Props> = ({
  networkOverride,
  transparent,
}) => {
  const { network } = Wallet.useWallet<Protocol.SOLANA>();

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteSolanaActivityTableStakingActivityQuery(
      "after",
      {
        network: networkMap[networkOverride || network!],
        protocol: StakingActivityProtocols.Solana,
        first: ACTIVITY_TABLE_PAGE_SIZE,
      },
      {
        // cacheTime: 0 ensures cached data is cleared when the component unmounts,
        // which prevents duplicate data from being displayed when the component is re-mounted.
        cacheTime: 0,
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage.stakingActivityLogs.pageInfo.hasNextPage &&
          lastPage.stakingActivityLogs.pageInfo.endCursor
            ? { after: lastPage.stakingActivityLogs.pageInfo.endCursor }
            : null,
      }
    );

  if (isLoading) {
    return <ActivityTableLoading />;
  }

  if (!data?.pages?.[0]?.stakingActivityLogs?.nodes?.length) {
    return <ActivityTableEmpty />;
  }

  const activities = data.pages
    .flatMap((group) => group.stakingActivityLogs.nodes)
    .map((activity) => {
      const isStaking =
        activity.activityType === StakingActivityActivityTypes.Delegation;
      const isComplete = activity.status === StakingActivityStatuses.Complete;

      const eta = getActivityEta(activity);
      const status = getStatus(activity);

      const txUrl = activity.txHash
        ? getTransactionExplorerUrl(
            Protocol.SOLANA,
            activity.txHash,
            activity.network as SolanaNetwork
          )
        : null;

      return {
        id: activity.id,
        amount: BigNumber(activity.amount).dp(5).toString(),
        createdAt: activity.createdAt,
        isStaking: isStaking,
        isComplete: isComplete,
        eta: eta,
        user: activity.user?.email,
        txUrl,
        status,
      };
    });

  return (
    <ActivityTable
      ticker={
        protocols[Protocol.SOLANA].networks[
          data.pages[0].stakingActivityLogs.nodes[0].network as SolanaNetworks
        ].ticker
      }
      transparent={transparent}
      activities={activities}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      onFetchNextPage={fetchNextPage}
    />
  );
};
