import React, { useEffect, useState } from "react";
import { FigAppActionEnum } from "@figmentjs/analytics/apps";
import { BigNumber } from "bignumber.js";
import { BodyText, Heading, Icon, Button } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import Wallet, { useTransactionFee } from "@figmentjs/wallet";
import {
  Details,
  DetailType,
  SignTransaction,
  ConfirmTransaction,
  ErrorStep,
  CancelledStep,
  Link,
} from "../../../../components";
import { Network, Protocol } from "@figmentjs/protocols";

type Props = {
  eigenlayerDelegationPayload?: `0x${string}`;
  isEigenlayerDelegationLoading: boolean;
  onCancel: () => void;
  onDelegateSuccess: () => void;
  onSetIsCloseableStep: (isCloseableStep: boolean) => void;
  onTrackEvent: ({
    action,
    step,
  }: {
    action: FigAppActionEnum;
    step?: string;
  }) => void;
};

export const DelegateEigenpodToOperator: React.FC<Props> = ({
  eigenlayerDelegationPayload,
  isEigenlayerDelegationLoading,
  onCancel,
  onDelegateSuccess,
  onSetIsCloseableStep,
  onTrackEvent,
}) => {
  const [isBroadcastTriggered, setIsBroadcastTriggered] = useState(false);
  const {
    sendTransaction,
    isSendingTransaction,
    transactionHash,
    setTransactionHash,
    sendTransactionError,
    account,
    network,
  } = Wallet.useWallet<Protocol.ETHEREUM>();

  useEffect(() => {
    if (transactionHash && !isSendingTransaction && !sendTransactionError) {
      setTransactionHash();
      onDelegateSuccess();
      onSetIsCloseableStep(true);
    }
  }, [
    isSendingTransaction,
    transactionHash,
    sendTransactionError,
    setTransactionHash,
    onDelegateSuccess,
    onSetIsCloseableStep,
  ]);

  const { fee, isLoading } = useTransactionFee({
    account: account! as `0x${string}`,
    network: network as Network,
    payload: eigenlayerDelegationPayload,
    amount: "0",
  });

  if (isBroadcastTriggered && sendTransactionError === "TX_CANCELED") {
    onTrackEvent({
      action: FigAppActionEnum.STEP_RENDERED,
      step: "DELEGATE_EIGENPOD_TO_OPERATOR_TRANSACTION_CANCELLED",
    });
    setTransactionHash();
    onSetIsCloseableStep(true);
    return (
      <CancelledStep
        onTryAgain={() => {
          onTrackEvent({
            action: FigAppActionEnum.TRY_AGAIN_BTN_CLICKED,
            step: "DELEGATE_EIGENPOD_TO_OPERATOR_TRANSACTION_CANCELLED",
          });
          setIsBroadcastTriggered(false);
        }}
      />
    );
  } else if (isBroadcastTriggered && sendTransactionError) {
    onTrackEvent({
      action: FigAppActionEnum.STEP_RENDERED,
      step: "DELEGATE_EIGENPOD_TO_OPERATOR_FAILED",
    });
    setTransactionHash();
    onSetIsCloseableStep(true);
    return (
      <ErrorStep
        error={{ message: sendTransactionError, isWalletError: true }}
        onClose={onCancel}
      />
    );
  } else if (isSendingTransaction && !transactionHash) {
    return <SignTransaction />;
  } else if (transactionHash) {
    return <ConfirmTransaction />;
  }

  return (
    <>
      <div className="text-center space-y-4">
        <Heading
          level="h5"
          weight="bold"
          color="green"
          font={HeadingFont.space}
        >
          Operator Delegation
        </Heading>
        <BodyText as="p">
          Operators support Actively Validated Services (AVS). Delegating to an
          operator earns restaking rewards for the AVS.
        </BodyText>
      </div>
      <div className="border-t border-green-100 my-4" />
      <div className="rounded-lg border border-black p-4 py-3 flex items-center gap-2">
        <div className="mr-2">
          <Icon icon="FigmentLogoIcon" size="lg" />
        </div>

        <BodyText weight="semibold">Figment Public</BodyText>
        <BodyText color="basic-800">EigenDA</BodyText>
      </div>
      <Details
        details={[
          {
            type: DetailType.DEFAULT,
            label: "Transaction Fee",
            value: { text: fee ? BigNumber(fee).toFormat(5) : "-" },
          },
        ]}
        showTopBorder={false}
        showBottomBorder
        isLoading={isLoading || isEigenlayerDelegationLoading}
      />
      <Link
        href="https://figment.io/insights/eigenlayer-faq/"
        text="Article: Selecting an operator"
      />
      <div className="flex gap-2">
        <Button size="small" palette="tertiary" fullWidth onClick={onCancel}>
          Cancel
        </Button>
        <Button
          size="small"
          fullWidth
          loading={isSendingTransaction || isEigenlayerDelegationLoading}
          testId="delegate-eigenpod-to-operator-submit-button"
          onClick={async () => {
            if (eigenlayerDelegationPayload) {
              sendTransaction({
                payload: eigenlayerDelegationPayload,
                amount: "0",
              });
              setIsBroadcastTriggered(true);
              onSetIsCloseableStep(false);
            }
          }}
        >
          Delegate
        </Button>
      </div>
    </>
  );
};
