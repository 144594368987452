import React, { useEffect, useCallback } from "react";
import { Network, Protocol } from "@figmentjs/protocols";
import Wallet, { useConnectLedgerProvider } from "@figmentjs/wallet";
import { useEthereumStakingFlowQuery } from "../../../../../../graphql/core/generated/gql";
import { VerifyOwnership } from "../../../../../components";
import {
  ValidatorLocation,
  EigenLayerRestaking,
  DeployEigenPod,
  EigenPodAddress,
  WithdrawalAddress,
  ExecutionRewardsAddress,
  EthereumStakingSummary,
  EthereumAmount,
} from "../../../../steps/ethereum";
import { Success } from "../../../../steps/shared";
import { useStepper } from "../../../../../hooks/use-stepper";
import { useFlowData, initialValues } from "../use-flow-data";
import { EthereumSiweFlowStep } from "./flow.types";
import {
  UniversalWidgetActionEnum as Actions,
  UniversalFlowTrackEventProperties,
  UniversalWidgetObjectEnum,
} from "@figmentjs/analytics/client";
import { useSegment } from "../../../../../hooks/use-segment/use-segment";

type Props = {
  isTestnetMode?: boolean;
};

export const Flow: React.FC<Props> = ({ isTestnetMode }) => {
  const { step, setStep } = useStepper<EthereumSiweFlowStep>(
    EthereumSiweFlowStep.AMOUNT
  );
  const { flowData, updateFlowData } = useFlowData();
  const { network, setTransactionHash, walletName, account } =
    Wallet.useWallet<Protocol.ETHEREUM>();
  const {
    data: ethereumStakingFlowData,
    isLoading: isEthereumStakingFlowLoading,
  } = useEthereumStakingFlowQuery();

  useConnectLedgerProvider();

  const { trackEvent } = useSegment();
  const trackStakingFlowEvent = useCallback(
    (action: Actions, properties: UniversalFlowTrackEventProperties) => {
      trackEvent(
        { object: UniversalWidgetObjectEnum.STAKING_FLOW, action },
        {
          amountToStake: flowData.amount.toNumber(),
          protocol: Protocol.ETHEREUM,
          network,
          walletName,
          ...properties,
        }
      );
    },
    [flowData.amount, network, trackEvent, walletName]
  );
  useEffect(() => {
    trackStakingFlowEvent(Actions.STEP_RENDERED, { step });
  }, [step, trackStakingFlowEvent]);

  const reset = () => {
    updateFlowData(initialValues);
    setStep(EthereumSiweFlowStep.AMOUNT);
    setTransactionHash();
  };

  useEffect(() => {
    if (!account) {
      reset();
    }
  }, [account]);

  return (
    <div className="rounded-md bg-white overflow-hidden shadow-3xl">
      {((): React.ReactNode => {
        switch (step) {
          case EthereumSiweFlowStep.AMOUNT:
            return (
              <>
                <EthereumAmount
                  data={ethereumStakingFlowData}
                  flowData={flowData}
                  commission={8}
                  isLoading={isEthereumStakingFlowLoading}
                  onSubmit={(values) => {
                    updateFlowData({
                      ...values,
                      withdrawalAddress: account,
                      executionRewardsAddress: account,
                    });
                    setStep(EthereumSiweFlowStep.VERIFY_OWNERSHIP);
                  }}
                  isStakeDisabled={isTestnetMode && network === Network.MAINNET}
                />
                <link
                  rel="preload"
                  href="https://app.figment.io/images/validator-location-map.png"
                  as="image"
                />
              </>
            );
          case EthereumSiweFlowStep.VERIFY_OWNERSHIP:
            return (
              <VerifyOwnership
                protocol={Protocol.ETHEREUM}
                onBack={() => setStep(EthereumSiweFlowStep.AMOUNT)}
                onNext={() => {
                  setStep(EthereumSiweFlowStep.LOCATION);
                }}
              />
            );
          case EthereumSiweFlowStep.LOCATION:
            return (
              <ValidatorLocation
                flowData={flowData}
                onBack={() => setStep(EthereumSiweFlowStep.AMOUNT)}
                onNext={(values) => {
                  updateFlowData(values);
                  // https://figmentio.atlassian.net/browse/FIGAPP-1960
                  // to simplify unstaking, we are temporarily bypassing several
                  // staking steps and skipping straight to the verify ownership step
                  setStep(EthereumSiweFlowStep.SUMMARY);
                }}
              />
            );
          case EthereumSiweFlowStep.RESTAKING:
            return (
              <EigenLayerRestaking
                flowData={flowData}
                onBack={() => setStep(EthereumSiweFlowStep.LOCATION)}
                onNext={(values) => {
                  updateFlowData(values);

                  if (values.isRestakingEnabled) {
                    if (values.eigenPodAddress) {
                      setStep(EthereumSiweFlowStep.EIGENPOD_ADDRESS);
                      return;
                    }

                    setStep(EthereumSiweFlowStep.DEPLOY_EIGENPOD);
                    return;
                  }

                  setStep(EthereumSiweFlowStep.WITHDRAWAL_ADDRESS);
                }}
              />
            );
          case EthereumSiweFlowStep.DEPLOY_EIGENPOD:
            return (
              <DeployEigenPod
                flowData={flowData}
                onBack={() => setStep(EthereumSiweFlowStep.RESTAKING)}
                onNext={(values) => {
                  updateFlowData(values);
                  setStep(EthereumSiweFlowStep.EIGENPOD_ADDRESS);
                }}
              />
            );
          case EthereumSiweFlowStep.EIGENPOD_ADDRESS:
            return (
              <EigenPodAddress
                flowData={flowData}
                onBack={() => setStep(EthereumSiweFlowStep.RESTAKING)}
                onNext={() =>
                  setStep(EthereumSiweFlowStep.EXECUTION_REWARDS_ADDRESS)
                }
              />
            );
          case EthereumSiweFlowStep.WITHDRAWAL_ADDRESS:
            return (
              <WithdrawalAddress
                flowData={flowData}
                onBack={() => setStep(EthereumSiweFlowStep.RESTAKING)}
                onNext={(values) => {
                  updateFlowData(values);
                  setStep(EthereumSiweFlowStep.EXECUTION_REWARDS_ADDRESS);
                }}
              />
            );
          case EthereumSiweFlowStep.EXECUTION_REWARDS_ADDRESS:
            return (
              <ExecutionRewardsAddress
                flowData={flowData}
                onBack={() => {
                  if (flowData.isRestakingEnabled) {
                    setStep(EthereumSiweFlowStep.EIGENPOD_ADDRESS);
                    return;
                  }

                  setStep(EthereumSiweFlowStep.WITHDRAWAL_ADDRESS);
                }}
                onNext={(values) => {
                  updateFlowData(values);

                  // TODO: optimize by checking if the access token is valid in this step, then skipping VERIFY_OWNERSHIP if so.
                  setStep(EthereumSiweFlowStep.VERIFY_OWNERSHIP);
                }}
              />
            );
          case EthereumSiweFlowStep.SUMMARY:
            return (
              <EthereumStakingSummary
                flowData={flowData}
                price={ethereumStakingFlowData?.ethereumPrice.value || 0}
                showValidatorLocation={false}
                onBack={() => setStep(EthereumSiweFlowStep.LOCATION)}
                onSuccess={(values) => {
                  updateFlowData(values);
                  setStep(EthereumSiweFlowStep.SUCCESS);
                }}
              />
            );
          case EthereumSiweFlowStep.SUCCESS:
            return (
              <Success
                protocol={Protocol.ETHEREUM}
                amount={flowData.amount}
                transactionHash={flowData.transactionHash}
                onDone={reset}
                buttonText="Stake More"
                isSIWE
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};
