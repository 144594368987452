import React, { useEffect, useCallback } from "react";
import BigNumber from "bignumber.js";
import { Protocol, SolanaNetwork } from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import { useQueryClient } from "@tanstack/react-query";
import {
  StakingActivityProtocols,
  useInfiniteSolanaActivityTableStakingActivityQuery,
} from "../../../../../../graphql/core/generated/gql";
import { SolanaAmount } from "../../../../steps/solana/solana-amount";
import { SolanaStakingSummary } from "../../../../steps/solana/solana-staking-summary";
import { CreateStakeAccount } from "../../../../steps/solana";
import { Success } from "../../../../steps/shared";
import { useStepper } from "../../../../../hooks/use-stepper";
import { useFlowData, initialValues } from "../use-flow-data";
import { SolanaFlowStep } from "./flow.types";
import {
  UniversalWidgetActionEnum as Actions,
  UniversalFlowTrackEventProperties,
  UniversalWidgetObjectEnum,
} from "@figmentjs/analytics/client";
import { useSegment } from "../../../../../hooks/use-segment/use-segment";
import { useSolanaStakingFlowData } from "./use-solana-staking-flow-data";
import { CancelledStep, ErrorStep } from "../../../../../components";

const ETORO_ORG_ID = "ccc71c0b-ee2d-45b4-921f-1c88b0eacf5b";

type Props = {
  orgId?: string;
  onCheck?: (values: {
    isTestnet: boolean;
    onBack: () => void;
    onNext: () => void;
  }) => React.FC<any>;
};

export const Flow: React.FC<Props> = ({ orgId, onCheck }) => {
  const { step, setStep } = useStepper<SolanaFlowStep>(SolanaFlowStep.AMOUNT);
  const { flowData, updateFlowData } = useFlowData();
  const { account, network, setTransactionHash, walletName } =
    Wallet.useWallet<Protocol.SOLANA>();
  const queryClient = useQueryClient();

  const customValidator =
    orgId == ETORO_ORG_ID && network == SolanaNetwork.MAINNET
      ? {
          pubkey: "FsT844wGgZg7MLR9Uc9T9qHQxmSoFVanhfgAEQvS966r",
          label: "eToro by Figment",
          commission: 5,
        }
      : undefined;

  const {
    estimatedFee,
    activationTime,
    exitTime,
    rewardsRate,
    commission,
    price,
    isSolanaStakingFlowLoading,
  } = useSolanaStakingFlowData({ account, network, customValidator });

  const { trackEvent } = useSegment();
  const trackStakingFlowEvent = useCallback(
    (action: Actions, properties: UniversalFlowTrackEventProperties) => {
      trackEvent(
        { object: UniversalWidgetObjectEnum.STAKING_FLOW, action },
        {
          amountToStake: flowData.amount.toNumber(),
          protocol: Protocol.SOLANA,
          network,
          walletName,
          ...properties,
        }
      );
    },
    [flowData.amount, network, trackEvent, walletName]
  );
  useEffect(() => {
    trackStakingFlowEvent(Actions.STEP_RENDERED, { step });
  }, [step, trackStakingFlowEvent]);

  const reset = () => {
    updateFlowData(initialValues);
    setStep(SolanaFlowStep.AMOUNT);
    setTransactionHash();
  };

  useEffect(() => {
    if (!account) {
      reset();
    }
  }, [account]);

  return (
    <div className="rounded-md bg-white overflow-hidden shadow-3xl">
      {((): React.ReactNode => {
        switch (step) {
          case SolanaFlowStep.AMOUNT:
            return (
              <SolanaAmount
                onSubmit={(values) => {
                  updateFlowData(values);

                  if (onCheck) {
                    setStep(SolanaFlowStep.CHECK);
                  } else {
                    setStep(SolanaFlowStep.SUMMARY);
                  }
                }}
                flowData={flowData}
                isLoading={isSolanaStakingFlowLoading}
                estimatedFee={estimatedFee}
                activationTime={activationTime}
                exitTime={exitTime}
                rewardsRate={rewardsRate}
                commission={commission}
                price={price}
                customValidator={customValidator}
              />
            );
          case SolanaFlowStep.CHECK:
            if (onCheck) {
              const CheckComponent = onCheck({
                isTestnet: network === SolanaNetwork.DEVNET,
                onBack: () => setStep(SolanaFlowStep.AMOUNT),
                onNext: () => setStep(SolanaFlowStep.SUMMARY),
              });

              return <CheckComponent />;
            }
          // Need this comment to avoid lint error: Expected a 'break' statement before 'case' no-fallthrough
          /* falls through */
          case SolanaFlowStep.SUMMARY:
            return (
              <SolanaStakingSummary
                amount={flowData.amount}
                usdAmount={BigNumber(flowData.amount || 0)
                  .times(price)
                  .toFormat(2)
                  .toString()}
                customValidator={customValidator}
                onBack={() => setStep(SolanaFlowStep.AMOUNT)}
                onConfirm={(values) => {
                  updateFlowData(values);
                  setStep(SolanaFlowStep.SIGNING);
                }}
                onError={(values) => {
                  updateFlowData(values);
                  setStep(SolanaFlowStep.ERROR);
                }}
              />
            );
          case SolanaFlowStep.SIGNING:
            return (
              <CreateStakeAccount
                amount={flowData.amount}
                stakeAccountPubkey={flowData.stakeAccountPubkey}
                voteAccountPubkey={customValidator?.pubkey}
                onSuccess={(values) => {
                  queryClient.resetQueries({
                    queryKey:
                      useInfiniteSolanaActivityTableStakingActivityQuery.getKey(
                        {
                          protocol: StakingActivityProtocols.Solana,
                        }
                      ),
                    exact: false,
                  });
                  updateFlowData(values);
                  setStep(SolanaFlowStep.SUCCESS);
                }}
                onRebroadcastSuccess={(values) => {
                  updateFlowData(values);
                }}
                onError={(values) => {
                  updateFlowData(values);
                  setStep(SolanaFlowStep.ERROR);
                }}
                onCancelled={() => setStep(SolanaFlowStep.CANCELLED)}
              />
            );
          case SolanaFlowStep.SUCCESS:
            return (
              <Success
                protocol={Protocol.SOLANA}
                amount={flowData.amount}
                onDone={reset}
                buttonText="Stake More"
              />
            );
          case SolanaFlowStep.ERROR:
            return (
              <div className="p-4">
                <ErrorStep
                  onClose={() => {
                    setStep(SolanaFlowStep.AMOUNT);
                    updateFlowData({ errors: [] });
                    setTransactionHash();
                  }}
                  error={flowData.errors![0]}
                />
              </div>
            );
          case SolanaFlowStep.CANCELLED:
            return (
              <div className="p-4">
                <CancelledStep
                  onTryAgain={() => setStep(SolanaFlowStep.AMOUNT)}
                />
              </div>
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};
