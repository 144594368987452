import React, { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { useQueryClient } from "@tanstack/react-query";
import { BabylonNetwork, Protocol, protocols } from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import {
  isTaproot,
  toSatoshi,
  DEFAULT_STAKING_DURATION,
} from "@figmentjs/wallet/src/use-wallet/use-bitcoin-wallet/use-bitcoin-wallet.utils";
import { Summary } from "../../shared/summary";
import { BaseStakingFlowData } from "../../../flows/shared/types";
import {
  WalletDetails,
  SignTransaction,
  ErrorStep,
  CancelledStep,
  DetailType,
} from "../../../../components";
import {
  BitcoinNetworks,
  MutationError,
  useConfirmStakedBtcMutation,
  useInfiniteBabylonActivityTableStakingActivityQuery,
  useStakeBtcMutation,
  StakingActivityProtocols,
} from "../../../../../graphql/core/generated/gql";
import { RebroadcastTransaction } from "../../shared";
import { useSegment } from "../../../../hooks";
import {
  UniversalWidgetActionEnum,
  UniversalWidgetObjectEnum,
} from "@figmentjs/analytics/apps";
// TODO: add mainnet to this map once the Core API supports it
export const networkMap: { [key in BabylonNetwork]?: BitcoinNetworks } = {
  [BabylonNetwork.SIGNET]: BitcoinNetworks.Signet,
  [BabylonNetwork.MAINNET]: BitcoinNetworks.Mainnet,
};

export type StakingSummaryProps = {
  amount: BigNumber;
  usdAmount: string;
  stakingDuration?: number;
  onConfirm: (values: Partial<BaseStakingFlowData>) => void;
  onBack: () => void;
};

export const BabylonStakingSummary: React.FC<StakingSummaryProps> = ({
  amount,
  usdAmount,
  stakingDuration,
  onConfirm,
  onBack,
}) => {
  const [userErrors, setUserErrors] = useState<MutationError[] | null>();
  const { ticker } = protocols[Protocol.BABYLON];
  const { trackEvent } = useSegment();
  const {
    account,
    getUTXOs,
    sendTransaction,
    isSendingTransaction,
    transactionHash,
    sendTransactionError,
    setTransactionHash,
    network,
    publicKeyNoCoord,
  } = Wallet.useWallet<Protocol.BABYLON>();
  const {
    mutateAsync: stakeBtc,
    isLoading: isStakeBtcLoading,
    data,
  } = useStakeBtcMutation();
  const [isBroadcastTriggered, setIsBroadcastTriggered] = useState(false);
  const {
    mutateAsync: confirmStakedBtc,
    isLoading: isConfirmStakedBtcLoading,
  } = useConfirmStakedBtcMutation();
  const queryClient = useQueryClient();

  const satoshiAmount = toSatoshi(amount).toNumber();

  useEffect(() => {
    const main = async () => {
      if (
        transactionHash &&
        !isSendingTransaction &&
        !sendTransactionError &&
        data?.babylonStakeBtc?.data?.stakeId
      ) {
        const { babylonStakeBroadcasted } = await confirmStakedBtc({
          stakeId: data?.babylonStakeBtc?.data?.stakeId,
          txHash: transactionHash,
        });

        queryClient.resetQueries({
          queryKey: useInfiniteBabylonActivityTableStakingActivityQuery.getKey({
            protocol: StakingActivityProtocols.Babylon,
          }),
          exact: false,
        });

        setTransactionHash(transactionHash);

        onConfirm({
          errors: babylonStakeBroadcasted?.userErrors,
          transactionHash,
        });
        setIsBroadcastTriggered(false);
      }
    };

    main();
  }, [
    isSendingTransaction,
    transactionHash,
    sendTransactionError,
    data?.babylonStakeBtc?.data?.stakeId,
    onConfirm,
  ]);

  const handleConfirm = async () => {
    trackEvent(
      {
        object: UniversalWidgetObjectEnum.STAKING_FLOW,
        action: UniversalWidgetActionEnum.CONFIRM_STAKE_BTN_CLICKED,
      },
      {
        protocol: Protocol.BABYLON,
        network: network,
        amountToStake: amount.toNumber(),
      }
    );
    const utxos = await getUTXOs(satoshiAmount);

    if (!publicKeyNoCoord || !account) {
      return;
    }

    const { babylonStakeBtc } = await stakeBtc({
      stakingDuration: stakingDuration || DEFAULT_STAKING_DURATION,
      stakerPk: publicKeyNoCoord,
      stakingAmount: satoshiAmount,
      changeAddress: account,
      network: networkMap[network!]!,
      address: account,
      taprootPubkey: isTaproot(account) ? publicKeyNoCoord : undefined,
      utxos,
    });

    if (!babylonStakeBtc?.data?.signingPayload) {
      return setUserErrors(babylonStakeBtc?.userErrors);
    }

    sendTransaction({ payload: babylonStakeBtc.data.signingPayload });
    setIsBroadcastTriggered(true);
  };

  const isLoading =
    isStakeBtcLoading || isConfirmStakedBtcLoading || isSendingTransaction;

  if (userErrors?.length) {
    return (
      <div className="p-4">
        <ErrorStep
          error={{
            message: userErrors[0].message,
          }}
          onClose={() => {
            setUserErrors(null);
            setIsBroadcastTriggered(false);
          }}
        />
      </div>
    );
  } else if (isBroadcastTriggered && sendTransactionError === "TX_CANCELED") {
    return (
      <div className="p-4">
        <CancelledStep
          onTryAgain={() => {
            setIsBroadcastTriggered(false);
            setTransactionHash();
          }}
        />
      </div>
    );
  } else if (isBroadcastTriggered && sendTransactionError === "TX_TIMEOUT") {
    return (
      <div className="p-4">
        <RebroadcastTransaction
          onRebroadcast={handleConfirm}
          isLoading={isLoading}
        />
      </div>
    );
  } else if (isBroadcastTriggered && sendTransactionError) {
    return (
      <div className="p-4">
        <ErrorStep
          error={{ message: sendTransactionError, isWalletError: true }}
          onClose={() => {
            setIsBroadcastTriggered(false);
            setTransactionHash();
          }}
        />
      </div>
    );
  } else if (isBroadcastTriggered) {
    return (
      <div className="p-4">
        <SignTransaction />
      </div>
    );
  }

  return (
    <>
      <WalletDetails protocol={Protocol.BABYLON} />
      <Summary
        onBack={onBack}
        onConfirm={handleConfirm}
        isConfirmButtonLoading={isLoading}
        details={[
          {
            type: DetailType.TOKEN,
            label: "Amount",
            value: {
              tokenDetails: { amount: amount.toString(), ticker, usdAmount },
            },
          },
          {
            type: DetailType.DEFAULT,
            label: "Staking Period",
            value: {
              text: "65",
              appendedText: "weeks",
            },
          },
          {
            type: DetailType.HREF,
            label: "Finality Provider",
            value: {
              text: "Figment",
              prependedIcon: "FigmentLogoIcon",
              href: "https://btcstaking.testnet.babylonchain.io/",
            },
          },
        ]}
      />
    </>
  );
};
