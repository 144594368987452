import React, { useEffect } from "react";
import { BabylonNetwork, Protocol } from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import {
  BodyText,
  Button,
  Dialog,
  DialogContent,
  Icon,
  Tooltip,
} from "@figmentjs/web-core";
import { BaseStakingFlowData } from "../../../flows/shared/types";
import { DetailType, Detail } from "../../../../components";
import { Amount } from "../../shared";
import { BabylonNetworkOverviewQuery } from "../../../../../graphql/core/generated/gql";
import { toBitcoin } from "@figmentjs/wallet/src/use-wallet/use-bitcoin-wallet/use-bitcoin-wallet.utils";
import BigNumber from "bignumber.js";
import { friendlyDateFromMinutes } from "@figmentjs/utils/src/formatters/date";

type Props = {
  flowData: BaseStakingFlowData;
  estimatedFee: string;
  commission: number;
  price: string;
  onSubmit: (values: any) => void;
  isLoading: boolean;
  isStakeDisabled?: boolean;
  data: BabylonNetworkOverviewQuery | undefined;
};

export const BabylonAmount: React.FC<Props> = ({
  flowData,
  estimatedFee,
  // commission,
  price,
  onSubmit,
  isLoading,
  isStakeDisabled,
  data,
}) => {
  const { hasSupportedWallet, displayedNetwork } =
    Wallet.useWallet<Protocol.BABYLON>();
  const [open, setOpen] = React.useState(false);

  const stakingPeriod = friendlyDateFromMinutes(
    BigNumber(data?.babylonNetworkOverview.maxStakingTime || 0)
      .times(10)
      .toNumber(),
    displayedNetwork === BabylonNetwork.MAINNET ? "hour" : "month"
  );

  const details: Detail[] = React.useMemo(
    () => [
      {
        type: DetailType.DEFAULT,
        label: "Commission",
        value: { text: "5%" },
      },
      {
        type: DetailType.DEFAULT,
        label: "Staking Period",
        value: {
          text: `~${stakingPeriod.time}`,
          appendedText: stakingPeriod.unit,
        },
      },
      {
        type: DetailType.HREF,
        label: "Finality Provider",
        value: {
          text: "Figment",
          prependedIcon: "FigmentLogoIcon",
          href:
            displayedNetwork === BabylonNetwork.MAINNET
              ? "https://btcstaking.babylonlabs.io/"
              : "https://btcstaking.testnet.babylonchain.io/",
        },
      },
    ],
    [stakingPeriod]
  );

  useEffect(() => {
    if (hasSupportedWallet) {
      setOpen(false);
    }
  }, [hasSupportedWallet]);

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent style="md:max-w-xl p-5">
          <div className="flex flex-col">
            <Icon icon="OneKey" size="9xl" />
            <div className="my-4 space-y-4">
              <BodyText as="p" size="lg">
                Figment supports the OneKey wallet for BTC staking on Babylon.
              </BodyText>
              <div>
                <BodyText as="p" color="basic-800">
                  Note: if you&apos;ve installed OneKey but are still seeing
                  this after refreshing, make sure that you have:{" "}
                </BodyText>
                <BodyText weight="bold" color="basic-800">
                  Default wallet settings &gt; Set OneKey as Default Wallet &gt;
                  On
                </BodyText>
                <BodyText as="p" color="basic-800">
                  in your OneKey settings.
                </BodyText>
              </div>
            </div>
            <div className="w-full flex gap-2 flex-wrap xs:flex-nowrap">
              <Button
                size="small"
                palette="tertiary"
                fullWidth
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>

              <div className="w-full">
                <Tooltip overlayText="Once OneKey is installed, please refresh the page.">
                  <Button
                    size="small"
                    href="https://onekey.so/download/?client=browserExtension"
                    openInNewTab
                    fullWidth
                  >
                    <div className="w-full text-center">
                      Get OneKey Browser Extension
                    </div>
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Amount
        protocol={Protocol.BABYLON}
        useSlider={false}
        price={price}
        flowData={flowData}
        estimatedFee={estimatedFee}
        isLoading={isLoading}
        onSubmit={onSubmit}
        details={details}
        hideMaxButton={true}
        minimumAmount={toBitcoin(
          BigNumber(data?.babylonNetworkOverview?.minStakingAmount || 0)
        ).toNumber()}
        maximumAmount={toBitcoin(
          BigNumber(data?.babylonNetworkOverview?.maxStakingAmount || 0)
        ).toNumber()}
        onConnectWalletClick={
          hasSupportedWallet ? undefined : () => setOpen(true)
        }
        isStakeDisabled={isStakeDisabled}
      />
    </>
  );
};
