import React from "react";
import { BodyText, Icon, Heading, Button } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";

type Props = {
  onTryAgain: () => void;
};

export const CancelledStep: React.FC<Props> = ({ onTryAgain }) => {
  return (
    <>
      <div className="flex flex-col items-center space-y-2 text-center">
        <Icon icon="MdCancel" size="4xl" color="error" />
        <Heading
          level="h5"
          color="green"
          font={HeadingFont.space}
          weight="bold"
        >
          Transaction Canceled
        </Heading>
      </div>
      <div className="py-4 text-center">
        <BodyText>
          When you&apos;re ready, you can try signing the transaction again.
        </BodyText>
      </div>
      <div className="pt-4 w-full">
        <Button size="small" fullWidth onClick={onTryAgain}>
          Try Again
        </Button>
      </div>
    </>
  );
};
