import React from "react";
import {
  EthereumNetworks,
  useStakingPositionQuery,
} from "../../../graphql/core/generated/gql";
import Wallet from "@figmentjs/wallet";
import { currencyFormatter, tokenFormatter } from "@figmentjs/utils";
import BigNumber from "bignumber.js";
import { StakingPositionCard } from "./staking-position-card";
import { Network, Protocol, protocols } from "@figmentjs/protocols";
import { StakingPositionButton } from "./staking-position-button";
import { BodyText } from "@figmentjs/web-core";

type Props = {
  onUnstake: () => void;
};

export const StakingPosition: React.FC<Props> = ({ onUnstake }) => {
  const { account, network } = Wallet.useWallet();
  const { data, isLoading } = useStakingPositionQuery(
    {
      ethAccount: account!,
      ethereumRewardsNetwork: network!,
      ethereumBalanceNetwork:
        network === Network.HOLESKY
          ? EthereumNetworks.Holesky
          : EthereumNetworks.Mainnet,
    },
    {
      enabled: !!(account && network),
    }
  );

  const { ticker } = protocols[Protocol.ETHEREUM];

  const stakedBalanceEth = tokenFormatter.format(
    BigNumber(data?.ethereumBalanceSummary.balance || 0).toNumber()
  );
  const stakedBalanceUsd = currencyFormatter.format(
    data?.ethereumBalanceSummary.balanceUsd || 0
  );
  const rewardsEth = tokenFormatter.format(
    BigNumber(data?.rewardsSummary.totalRewards || 0).toNumber()
  );
  const rewardsUsd = currencyFormatter.format(
    data?.rewardsSummary.totalRewardsUsd || 0
  );

  const isUnstakeButtonDisabled =
    isLoading || data?.validatorCounts.exitable === 0;

  return (
    <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 w-full">
      <StakingPositionCard
        title="Total Staked"
        ticker={ticker}
        amount={stakedBalanceEth}
        secondaryAmount={stakedBalanceUsd}
        isLoading={isLoading}
        aside={
          <StakingPositionButton
            disabled={isUnstakeButtonDisabled}
            onUnstake={onUnstake}
          />
        }
      />
      <StakingPositionCard
        title="All Time Rewards"
        ticker={ticker}
        amount={rewardsEth}
        secondaryAmount={rewardsUsd}
        isLoading={isLoading}
        info={
          <div className="flex flex-col gap-3">
            <BodyText color="white" size="base">
              You might see a difference with your wallet as rewards first
              accrue on validators before being automatically transferred every
              ~9 days.
            </BodyText>
            <BodyText color="white" size="base">
              If you have any questions,
              <a
                className="text-green-500"
                href="mailto:support@figment.io"
                target="_blank"
              >
                {" "}
                reach out here
              </a>
              .
            </BodyText>
          </div>
        }
      />
    </div>
  );
};

export default StakingPosition;
