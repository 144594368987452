import React, { memo } from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";
import { twMerge as tw } from "tailwind-merge";
import { BodyText } from "../../typography";
import { ErrorText } from "../shared/components";
import { SliderProps } from "./Slider.types";

const Slider: React.FC<SliderProps> = ({
  disabled,
  min,
  max,
  step,
  value,
  defaultValue,
  onValueChange,
  testId,
  displayMinAndMax = true,
  error,
}) => (
  <div className="flex flex-col py-2" data-testid={testId}>
    <SliderPrimitive.Root
      min={min}
      max={max}
      step={step}
      value={value}
      defaultValue={defaultValue}
      onValueChange={onValueChange}
      disabled={disabled}
      className="relative flex items-center h-2 w-full"
    >
      <SliderPrimitive.Track
        className={tw(
          "relative grow bg-green-300 h-2 w-full rounded-3xl",
          disabled && "bg-basic-300"
        )}
      >
        <SliderPrimitive.Range className="absolute h-full rounded-3xl bg-green-700" />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb
        className={tw(
          "block w-6 h-6 bg-white rounded-full border border-green-700 shadow hover:outline-none hover:cursor-pointer focus:outline-none",
          disabled && "border-0 hover:cursor-default"
        )}
      />
    </SliderPrimitive.Root>
    <div className="h-[22px]">
      {displayMinAndMax && (
        <div className="flex justify-between pt-1">
          <BodyText size="xs" color="basic-800">
            {min}
          </BodyText>
          <BodyText size="xs" color="basic-800">
            {max}
          </BodyText>
        </div>
      )}
    </div>
    {error && (
      <div className="mx-2 my-0">
        <ErrorText>{error}</ErrorText>
      </div>
    )}
  </div>
);

export default memo(Slider);
