import { graphql } from "msw";
import { SolanaStakingFlowQuery } from "../../../graphql/core/generated/gql";

export const SolanaStakingFlowQueryMock = graphql.query<SolanaStakingFlowQuery>(
  "SolanaStakingFlow",
  (req, res, ctx) => {
    return res(
      ctx.data({
        solanaStakingMaxDelegationEstimate: {
          estimatedFee: "10000",
          minBalanceForRentExemption: "2282880",
        },
        solanaFigmentPublicCommissionRate: { rate: 0.07 },
        solanaRewardsRate: { date: "2024-04-29", value: 6.5 },
        solanaEstimatedDeactivationTime: {
          date: "2024-05-02T17:30:07Z",
          numSeconds: 82065,
        },
        solanaEstimatedActivationTime: {
          date: "2024-05-02T17:30:07Z",
          numSeconds: 82065,
        },
        solanaPrice: {
          value: 143,
        },
      })
    );
  }
);
