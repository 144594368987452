import React from "react";
import { BodyText, Heading, Button, InputCheckbox } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { Protocol } from "@figmentjs/protocols";
import Wallet, { isAddress } from "@figmentjs/wallet";
import { Link, WalletDetails, AddressInput } from "../../../../components";
import { EthereumStakingFlowData } from "../../../flows/ethereum/siwe/use-flow-data/use-flow-data";

type Props = {
  flowData: EthereumStakingFlowData;
  onBack: () => void;
  onNext: (values: Partial<EthereumStakingFlowData>) => void;
};

export const WithdrawalAddress: React.FC<Props> = ({
  flowData,
  onBack,
  onNext,
}) => {
  const { account } = Wallet.useWallet();

  const initialAddress = flowData.withdrawalAddress
    ? flowData.withdrawalAddress
    : Number(account)
    ? account
    : null;
  const [address, setAddress] = React.useState(initialAddress);
  const [isEditing, setIsEditing] = React.useState(
    initialAddress ? false : true
  );
  const [isAddressConfirmed, setIsAddressConfirmed] = React.useState(false);

  return (
    <>
      <WalletDetails protocol={Protocol.ETHEREUM} />
      <div className="p-4 flex flex-col items-center">
        <div className="space-y-1 text-center mb-4">
          <Heading level="h5" font={HeadingFont.space} weight="semibold">
            Withdrawal Address
          </Heading>
          <BodyText as="p">
            Your withdrawal address will receive your principal stake when you
            choose to unstake. By default, we use your connected wallet.
          </BodyText>
        </div>
        <AddressInput
          address={address || ""}
          onSave={(newAddress) => setAddress(newAddress)}
          validate={(newAddress) => ({
            isValid: isAddress(newAddress),
          })}
          onEdit={setIsEditing}
          truncateAddress={true}
          protocol={Protocol.ETHEREUM}
        />
        {address !== initialAddress ? (
          <div className="my-4">
            <InputCheckbox
              value={isAddressConfirmed}
              onChange={({ value }) => setIsAddressConfirmed(value)}
            >
              <BodyText size="sm">
                The above address is accurate and controlled by me or my
                organization
              </BodyText>
            </InputCheckbox>
          </div>
        ) : (
          <div className="mt-4">
            <Link
              href="https://figment.io/insights/ethereum-withdrawals-a-comprehensive-faq/"
              text="Ethereum Withdrawals: A Comprehensive FAQ"
            />
          </div>
        )}

        <div className="flex justify-evenly gap-2 w-full">
          <Button palette="tertiary" size="small" fullWidth onClick={onBack}>
            Back
          </Button>
          <Button
            size="small"
            fullWidth
            onClick={() => onNext({ withdrawalAddress: address! })}
            disabled={
              isEditing || (address !== initialAddress && !isAddressConfirmed)
            }
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};
