import React from "react";
import { BodyText, Button } from "@figmentjs/web-core";
import { Details, DetailType } from "../../../../components/details";
import Wallet from "@figmentjs/wallet";
import { Protocol, protocols, SolanaNetwork } from "@figmentjs/protocols";
import { useSolanaStakingFlowData } from "../../../../staking/flows/solana/default/flow/use-solana-staking-flow-data";
import {
  SolanaNetworks,
  useSolanaStakingWithdrawTransactionCreateMutation,
} from "../../../../../graphql/core/generated/gql";
import { SolanaFlowData } from "../../../flows/solana/hooks/use-flow-data/use-flow-data";
import { WalletDetails } from "../../../../components";
import { FigAppActionEnum } from "@figmentjs/analytics/client";

export type Props = {
  onConfirmUnstake: (values: Partial<SolanaFlowData>) => void;
  onCancel: () => void;
  onBack?: () => void;
  onError: (values: Partial<SolanaFlowData>) => void;
  stakeAccountPubkey: string;
  flowData: SolanaFlowData;
  onTrackEvent: ({
    action,
    step,
    amount,
  }: {
    action: FigAppActionEnum;
    step?: string;
    amount?: string;
  }) => void;
};

export const WithdrawSummary: React.FC<Props> = ({
  onConfirmUnstake,
  onCancel,
  onBack,
  onError,
  stakeAccountPubkey,
  flowData,
  onTrackEvent,
}) => {
  const { account, connect, isConnecting, network, sendTransaction } =
    Wallet.useWallet<Protocol.SOLANA>();
  const { price, isSolanaStakingFlowLoading } = useSolanaStakingFlowData({
    account,
    network,
  });

  const networkMap: {
    [key in SolanaNetwork]?: SolanaNetworks;
  } = {
    [SolanaNetwork.DEVNET]: SolanaNetworks.Devnet,
    [SolanaNetwork.MAINNET]: SolanaNetworks.Mainnet,
    [SolanaNetwork.TESTNET]: SolanaNetworks.Testnet,
  };

  const { mutateAsync: solanaWithdraw } =
    useSolanaStakingWithdrawTransactionCreateMutation();

  const handleConfirm = async () => {
    const { solanaStakingWithdrawTransactionCreate } = await solanaWithdraw({
      network: networkMap[network!]!,
      stakeAccountPubkey: stakeAccountPubkey,
      recipientPubkey: account!,
      amountSol: flowData.amount.toNumber(),
    });
    if (solanaStakingWithdrawTransactionCreate?.userErrors?.length) {
      onError({
        errors: solanaStakingWithdrawTransactionCreate?.userErrors || [],
      });
      onTrackEvent({
        action: FigAppActionEnum.CONFIRM_UNSTAKE_BTN_CLICKED,
        step: "WITHDRAW_SOLANA_SUMMARY_STEP",
        amount: flowData.amount!.toString(),
      });
      return;
    }

    sendTransaction({
      payload:
        solanaStakingWithdrawTransactionCreate!.data!
          .unsignedTransactionSerialized,
    });

    onConfirmUnstake({
      unsignedRawTransaction:
        solanaStakingWithdrawTransactionCreate?.data
          ?.unsignedTransactionSerialized,
    });
  };

  const details = [
    {
      type: DetailType.TOKEN,
      label: "Amount",
      value: {
        tokenDetails: {
          amount: flowData.amount.toString(),
          ticker: protocols[Protocol.SOLANA].networks[network!].ticker,
          usdAmount: flowData.amount
            .times(price || "0")
            .toFormat(2)
            .toString(),
        },
      },
    },
    {
      type: DetailType.ADDRESS,
      label: "From (Stake Account)",
      value: {
        text: stakeAccountPubkey,
      },
    },
    ...(account
      ? [
          {
            type: DetailType.ADDRESS,
            label: "To (Wallet)",
            value: {
              text: account,
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <WalletDetails protocol={Protocol.SOLANA} action="Withdraw SOL" />
      <div className="px-4">
        <Details
          details={details}
          showTopBorder={false}
          showBottomBorder={true}
        />
        <div className="pt-4">
          <span>
            <BodyText size="sm" weight="semibold">
              IMPORTANT:{" "}
            </BodyText>
            <BodyText size="sm">
              Withdrawing your SOL is an irreversible action.
            </BodyText>
          </span>
        </div>
        <div className="py-4 space-y-4">
          <div className="flex justify-evenly gap-2">
            {onBack && (
              <Button
                palette="tertiary"
                size="small"
                type="submit"
                fullWidth
                onClick={onBack}
              >
                Back
              </Button>
            )}
            {!account ? (
              <Button
                palette="primary"
                size="small"
                fullWidth
                onClick={connect}
                loading={isConnecting}
              >
                Connect Wallet
              </Button>
            ) : (
              <Button
                palette="primary"
                size="small"
                fullWidth
                onClick={handleConfirm}
                loading={isSolanaStakingFlowLoading}
              >
                Confirm Unstake
              </Button>
            )}
          </div>
          <div className="flex justify-center">
            <Button
              palette="text"
              size="tiny"
              textColor="text-basic-800"
              onClick={onCancel}
              autoFocus
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
