import React from "react";
import { BodyText, Button, Heading, Icon } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import Wallet from "@figmentjs/wallet";
import Address from "../../../../components/address/address";
import { Network, Protocol, getEigenLayerAppUrl } from "@figmentjs/protocols";

type Props = {
  eigenpodAddress: `0x${string}`;
  onViewPosition: () => void;
};

export const Success: React.FC<Props> = ({
  eigenpodAddress,
  onViewPosition,
}) => {
  const { network } = Wallet.useWallet();
  const eigenLayerAppURL = getEigenLayerAppUrl(network! as Network)!;

  return (
    <>
      <div className="flex flex-col items-center space-y-2 text-center border-b border-b-green-100 pb-4">
        <Icon icon="MdCheckCircle" size="4xl" color="green-800" />
        <Heading
          level="h5"
          color="green"
          font={HeadingFont.space}
          weight="bold"
        >
          Successfully Delegated Your Restaked ETH!
        </Heading>
      </div>
      <div className="py-4">
        <BodyText weight="semibold">What&apos;s Next?</BodyText>
        <div className="pt-1">
          <Address
            address={eigenpodAddress}
            protocol={Protocol.ETHEREUM}
            type="EIGENPOD_ADDRESS"
            inline
          />{" "}
          <BodyText size="sm">
            is successfully delegated to Figment&apos;s Operator.
          </BodyText>
        </div>
        <div className="pt-4">
          <BodyText size="sm">View your restaking rewards on the</BodyText>
          <div className="inline mx-1">
            <Button
              palette="text"
              size="tiny"
              href={eigenLayerAppURL}
              openInNewTab
            >
              EigenLayer dapp
            </Button>
            .
          </div>
        </div>
      </div>
      <div className="pt-4 w-full border-t border-t-green-100">
        <Button size="small" fullWidth onClick={() => onViewPosition()}>
          View Position
        </Button>
      </div>
    </>
  );
};
