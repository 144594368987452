"use client";

import BigNumber from "bignumber.js";
import * as React from "react";
import { Network, Protocol } from "@figmentjs/protocols";
import { useRollbar } from "@figmentjs/rollbar-client";
import { toWei } from "@figmentjs/utils";
import Wallet, { formatEther } from "@figmentjs/wallet";
import { useQueryClient } from "@tanstack/react-query";
import { RedeemEthFlowStep } from "../flow/redeem-eth-flow.types";
import {
  EthereumNetworks,
  useLiquidCollectiveRedeemRequestCreateMutation,
  useLsEthRedemptionsQuery,
  useLiquidCollectiveRedeemManagerProjectionQuery,
  LiquidCollectiveRedeemManagerProjectionQuery,
} from "../../../../../../graphql/core/generated/gql";

type UseRedeemEth = {
  step: RedeemEthFlowStep;
  setRedeemEthFlowStep: (flowStep: RedeemEthFlowStep) => void;
  isWalletConnecting: boolean;
  isWalletConnected: boolean;
  connectWallet: () => void;
  isCreateLiquidCollectiveRedeemRequestLoading: boolean;
  isCreateLiquidCollectiveRedeemRequestBroadcasting: boolean;
  createLiquidCollectiveRedeemRequest: ({ amount }: { amount: string }) => void;
  createLiquidCollectiveRedemptionTransactionHash?: string;
  resetFlow: (args?: { step: keyof typeof RedeemEthFlowStep }) => void;
  lsEthBalance?: BigNumber;
  lsEthConversionRate?: number;
  error?: string;
  redemptionProjection?: LiquidCollectiveRedeemManagerProjectionQuery;
  isRedemptionProjectionLoading: boolean;
};

const defaultValues: UseRedeemEth = {
  step: RedeemEthFlowStep.AMOUNT,
  setRedeemEthFlowStep: () => {
    throw new Error("setRedeemEthFlowStep not implemented");
  },
  isCreateLiquidCollectiveRedeemRequestLoading: false,
  isCreateLiquidCollectiveRedeemRequestBroadcasting: false,
  createLiquidCollectiveRedeemRequest: async () => {
    throw new Error("createLiquidCollectiveRedeemRequest not implemented");
  },
  lsEthBalance: new BigNumber(0),
  isWalletConnecting: false,
  isWalletConnected: false,
  connectWallet: () => {
    throw new Error("connectWallet not implemented");
  },
  resetFlow: () => {
    throw new Error("resetFlow not implemented");
  },
  isRedemptionProjectionLoading: false,
};

const UseRedeemEthContext = React.createContext<UseRedeemEth>(defaultValues);

export const RedeemEthFlowProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [step, setRedeemEthFlowStep] = React.useState<RedeemEthFlowStep>(
    RedeemEthFlowStep.AMOUNT
  );
  const rollbar = useRollbar();
  const {
    connect,
    isConnecting,
    account,
    network,
    isSendingTransaction: isCreateLiquidCollectiveRedeemRequestBroadcasting,
    sendTransaction,
    transactionHash,
    setTransactionHash,
    sendTransactionError,
    setSendTransactionError,
  } = Wallet.useWallet<Protocol.ETHEREUM>();
  const queryClient = useQueryClient();

  const { lsEthBalance, lsEthConversionRate, isInitialized } =
    Wallet.useLiquidStaking();
  const {
    data: redemptionProjection,
    isLoading: isRedemptionProjectionLoading,
  } = useLiquidCollectiveRedeemManagerProjectionQuery({
    network:
      network === Network.HOLESKY
        ? EthereumNetworks.Holesky
        : EthereumNetworks.Mainnet,
  });
  const {
    mutate: createLiquidCollectiveRedeemRequest,
    isLoading: createLiquidCollectiveRedeemRequestLoading,
  } = useLiquidCollectiveRedeemRequestCreateMutation();

  React.useEffect(() => {
    if (
      transactionHash &&
      !isCreateLiquidCollectiveRedeemRequestBroadcasting &&
      !sendTransactionError
    ) {
      setTransactionHash();
      setRedeemEthFlowStep(RedeemEthFlowStep.SUCCESS);
      queryClient.invalidateQueries({
        queryKey: useLsEthRedemptionsQuery.getKey({
          network:
            network === Network.MAINNET
              ? EthereumNetworks.Mainnet
              : EthereumNetworks.Holesky,
          address: account!,
        }),
      });
    }
  }, [
    isCreateLiquidCollectiveRedeemRequestBroadcasting,
    transactionHash,
    sendTransactionError,
    setTransactionHash,
    queryClient,
    network,
    account,
  ]);

  return (
    <UseRedeemEthContext.Provider
      value={{
        step,
        setRedeemEthFlowStep,
        lsEthBalance: isInitialized
          ? BigNumber(lsEthBalance || "0")
          : undefined,
        lsEthConversionRate: Number(formatEther(lsEthConversionRate)),
        isCreateLiquidCollectiveRedeemRequestLoading:
          createLiquidCollectiveRedeemRequestLoading,
        createLiquidCollectiveRedeemRequest: async ({ amount }) => {
          createLiquidCollectiveRedeemRequest(
            {
              network:
                network === Network.MAINNET
                  ? EthereumNetworks.Mainnet
                  : EthereumNetworks.Holesky,
              address: account!,
              amount: toWei(`0x${amount.toString()}`),
            },
            {
              onSuccess: (response) => {
                sendTransaction({
                  payload: response.liquidCollectiveRedeemRequestCreate.data!
                    .unsignedTransactionSerialized as `0x${string}`,
                  amount: "0",
                });
              },
              onError: (error) => {
                rollbar.error(error as Error);
                setSendTransactionError((error as Error).message);
              },
            }
          );
        },
        isCreateLiquidCollectiveRedeemRequestBroadcasting,
        createLiquidCollectiveRedemptionTransactionHash: transactionHash,
        resetFlow: (args) => {
          setTransactionHash();
          setSendTransactionError(undefined);
          if (args?.step) {
            setRedeemEthFlowStep(RedeemEthFlowStep[args.step]);
          }
        },
        isWalletConnecting: !!isConnecting,
        isWalletConnected: !!account,
        connectWallet: connect,
        error: sendTransactionError,
        redemptionProjection,
        isRedemptionProjectionLoading,
      }}
    >
      {children}
    </UseRedeemEthContext.Provider>
  );
};

export const useRedeemEthFlowData = () => {
  const context = React.useContext(UseRedeemEthContext);

  return context;
};
