import React from "react";
import { BodyText, Heading, Icon } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";

export const SignTransaction: React.FC = () => {
  return (
    <div className="flex flex-col items-center space-y-4">
      <Icon color="green" size="4xl" icon="MdOutlineAccountBalanceWallet" />
      <Heading
        color="green-1000"
        level="h5"
        font={HeadingFont.space}
        weight="semibold"
      >
        Continue In Your Wallet
      </Heading>
      <BodyText color="green-1000">Sign the transaction to continue</BodyText>
    </div>
  );
};
