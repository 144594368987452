import React from "react";
import dynamic from "next/dynamic";
import {
  getAddressExplorerUrl,
  Protocol,
  protocols,
} from "@figmentjs/protocols";
import { getTruncatedAddress } from "@figmentjs/utils";
import Wallet from "@figmentjs/wallet";
import { BodyText, Icon, Tooltip, ShimmerItem } from "@figmentjs/web-core";
import { useNetworkConfig } from "../../hooks";

type Props = {
  protocol: Protocol;
  isUnstaking?: boolean;
  action?: string;
};

const WalletDetailsViewComponent: React.FC<
  Exclude<Props, "isUnstaking"> & {
    icon: React.ComponentProps<typeof Icon>["icon"];
    ticker: string;
    account?: string;
    balance?: string;
    disconnect?: () => void;
    network: ReturnType<typeof Wallet.useWallet>["network"];
    action: string;
  }
> = ({
  protocol,
  action,
  icon,
  ticker,
  account,
  balance,
  disconnect,
  network,
  isUnstaking,
}) => {
  return (
    <div className="flex justify-between min-h-[60px] py-2.5 px-4 bg-basic-100">
      <div className="flex items-center gap-2">
        <Icon icon={icon} size="3xl" />
        <BodyText weight="semibold">{action}</BodyText>
      </div>
      {!isUnstaking && account && (
        <div className="flex flex-col text-right">
          <div className="flex justify-end items-center gap-1 h-5">
            <Tooltip
              placement="top"
              overlayText="View on block explorer"
              compact
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer text-basic-800 hover:text-black"
                href={getAddressExplorerUrl(protocol, account, network)}
              >
                <BodyText size="sm" color="inherit">
                  {getTruncatedAddress({ address: account, protocol })}
                </BodyText>
              </a>
            </Tooltip>
            {disconnect && (
              <Tooltip placement="top" overlayText="Disconnect wallet" compact>
                <div className="flex">
                  <button
                    onClick={disconnect}
                    className="cursor-pointer w-3 text-basic-800 hover:text-black"
                  >
                    <Icon icon="PowerOnOff" size="3xl" color="inherit" />
                  </button>
                </div>
              </Tooltip>
            )}
          </div>

          <BodyText size="sm" weight="semibold" testId="wallet-balance">
            {balance ? (
              <>
                {parseFloat(Number(balance).toFixed(4))} {ticker}
              </>
            ) : (
              <div className="flex justify-end">
                <ShimmerItem width={72} />
              </div>
            )}
          </BodyText>
        </div>
      )}
    </div>
  );
};

export const WalletDetailsView = dynamic(
  () => Promise.resolve(WalletDetailsViewComponent),
  {
    ssr: false,
    loading: () => <div className="min-h-[60px] bg-basic-100" />,
  }
);

export const WalletDetails: React.FC<Props> = ({
  protocol,
  isUnstaking,
  action,
}) => {
  const { account, balance, disconnect, network } = Wallet.useWallet();
  const { icon } = protocols[protocol];
  const { ticker } = useNetworkConfig({ protocol });

  return (
    <WalletDetailsView
      protocol={protocol}
      isUnstaking={isUnstaking}
      icon={icon}
      ticker={ticker}
      account={account}
      balance={balance}
      disconnect={disconnect}
      network={network}
      action={action || (isUnstaking ? `Unstake ${ticker}` : `Stake ${ticker}`)}
    />
  );
};
