import React, { useEffect } from "react";
import { Protocol, SolanaNetwork } from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import { useStepper } from "../../../../../hooks/use-stepper";
import {
  useUnstakingFlowData,
  unstakingInitialValues,
} from "../../hooks/use-flow-data";
import { ErrorStep } from "../../../../../components";
import { SolanaUndelegateFlowStep } from "./flow.types";
import { WrongWallet } from "../../../../steps/solana/wrong-wallet";
import BigNumber from "bignumber.js";
import {
  UndelegateSuccess,
  UndelegateSolanaAccount,
  UndelegateSummary,
} from "../../../../steps/solana";
import { FigAppActionEnum } from "@figmentjs/analytics/apps";

const ETORO_ORG_ID = "ccc71c0b-ee2d-45b4-921f-1c88b0eacf5b";

type Props = {
  onCancel: () => void;
  onSuccessButtonClick: () => void;
  stakeAccountPubkey: string;
  amount: string;
  stakeAuthorityAddress: string;
  stakedByEmail?: string | null;
  orgId?: string;
  onTrackEvent: ({
    action,
    step,
    amount,
  }: {
    action: FigAppActionEnum;
    step?: string;
    amount?: string;
  }) => void;
};

export const Flow: React.FC<Props> = ({
  orgId,
  onCancel,
  onSuccessButtonClick,
  stakeAccountPubkey,
  amount,
  stakeAuthorityAddress,
  stakedByEmail,
  onTrackEvent,
}) => {
  const { step, setStep } = useStepper<SolanaUndelegateFlowStep>(
    SolanaUndelegateFlowStep.SUMMARY
  );
  const { flowData, updateFlowData } = useUnstakingFlowData({
    ...unstakingInitialValues,
    amount: BigNumber(amount),
  });
  const { account, network, setTransactionHash } =
    Wallet.useWallet<Protocol.SOLANA>();

  const customValidator =
    orgId == ETORO_ORG_ID && network == SolanaNetwork.MAINNET
      ? {
          pubkey: "FsT844wGgZg7MLR9Uc9T9qHQxmSoFVanhfgAEQvS966r",
          label: "eToro by Figment",
          commission: 5,
        }
      : undefined;

  useEffect(() => {
    onTrackEvent({
      action: FigAppActionEnum.STEP_RENDERED,
      step: step,
    });
  }, [onTrackEvent, step]);

  const reset = () => {
    updateFlowData(unstakingInitialValues);
    setStep(SolanaUndelegateFlowStep.SUMMARY);
    setTransactionHash();
  };

  useEffect(() => {
    if (!account) {
      reset();
    }
  }, [account]);

  if (account && account !== stakeAuthorityAddress) {
    return (
      <div className="p-4">
        <WrongWallet
          address={stakeAuthorityAddress}
          email={stakedByEmail}
          onCancel={onCancel}
        />
      </div>
    );
  }

  return (
    <div className="rounded-md bg-white overflow-hidden shadow-3xl">
      {((): React.ReactNode => {
        switch (step) {
          case SolanaUndelegateFlowStep.SUMMARY:
            return (
              <UndelegateSummary
                onConfirmUnstake={(values) => {
                  updateFlowData(values);
                  setStep(SolanaUndelegateFlowStep.SIGNING);
                }}
                onCancel={onCancel}
                flowData={flowData}
                stakeAccountPubkey={stakeAccountPubkey}
                onTrackEvent={onTrackEvent}
                onError={(values) => {
                  updateFlowData(values);
                  setStep(SolanaUndelegateFlowStep.ERROR);
                }}
                customValidator={customValidator}
              />
            );
          case SolanaUndelegateFlowStep.SIGNING:
            return (
              <UndelegateSolanaAccount
                unsignedRawTransaction={flowData.unsignedRawTransaction}
                onSuccess={() => {
                  setStep(SolanaUndelegateFlowStep.SUCCESS);
                }}
                onCancelled={onCancel}
                stakeAccountPubkey={stakeAccountPubkey}
                onTrackEvent={onTrackEvent}
                flowData={flowData}
                onError={(values) => {
                  updateFlowData(values);
                  setStep(SolanaUndelegateFlowStep.ERROR);
                }}
              />
            );
          case SolanaUndelegateFlowStep.SUCCESS:
            return (
              <UndelegateSuccess
                stakeAccountAddress={account!}
                onClose={onSuccessButtonClick}
              />
            );
          case SolanaUndelegateFlowStep.ERROR:
            return (
              <div className="p-4">
                <ErrorStep
                  onClose={() => setStep(SolanaUndelegateFlowStep.SUMMARY)}
                  error={
                    flowData.errors?.[0] || { message: "An error occurred" }
                  }
                />
              </div>
            );

          default:
            return null;
        }
      })()}
    </div>
  );
};
