import React, { memo } from "react";
import * as RadixToggleGroup from "@radix-ui/react-toggle-group";
import { twMerge as tw } from "tailwind-merge";
import {
  ToggleGroupPalette,
  ToggleGroupProps,
  ToggleGroupSize,
} from "./ToggleGroup.types";
import { BodyText } from "../../typography";
import { FontColor, FontSize } from "../../../theme/types";

const toggleGroupSizeMap: { [key in ToggleGroupSize]: string } = {
  small: "pb-0.5 px-4",
  base: "py-2 px-7",
};

const toggleGroupStylesMap = {
  primary: "bg-green-300 outline outline-1 outline-green-700",
  secondary: "bg-white outline outline-1 outline-green-300",
  tertiary: "bg-green-100 outline outline-1 outline-green-300",
};

const toggleGroupTransitionHeight = {
  small: "h-6",
  base: "h-[38px]",
};

const toggleGroupBackgroundColor: { [key in ToggleGroupPalette]: string } = {
  primary: "bg-green-100",
  secondary: "bg-green-100",
  tertiary: "bg-white",
};

const toggleGroupOptionTextColor = (
  isSelectedOption: boolean
): { [key in ToggleGroupPalette]: keyof typeof FontColor } => {
  return {
    primary: "green-1000",
    secondary: isSelectedOption ? "green-1000" : "green-800",
    tertiary: isSelectedOption ? "green-1000" : "green-800",
  };
};

const toggleGroupFontSize: { [key in ToggleGroupSize]: keyof typeof FontSize } =
  {
    small: "sm",
    base: "base",
  };

const ToggleGroup: React.FC<ToggleGroupProps> = ({
  onValueChange,
  items,
  value,
  size = "base",
  palette = "primary",
  testId,
}) => {
  const selectedRef = React.useRef<HTMLButtonElement | null>(null);
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [width, setWidth] = React.useState(0);
  const [offset, setOffset] = React.useState(0);

  const handleValueChange = (value: string) => {
    if (!isInitialized) {
      setIsInitialized(true);
    }

    //ensure one of the toggles is always selected
    if (value !== "") {
      onValueChange(value);
    }
  };

  React.useEffect(() => {
    if (selectedRef.current) {
      const itemRect = selectedRef.current.getBoundingClientRect();
      const toggleRect =
        selectedRef.current.parentElement?.getBoundingClientRect();

      setWidth(itemRect.width);
      setOffset(itemRect.left - toggleRect!.left);
    }
  }, [value, items]);

  return (
    <div
      className={tw(
        "relative rounded-full w-fit h-fit",
        toggleGroupBackgroundColor[palette]
      )}
      data-testid={testId}
    >
      <RadixToggleGroup.Root
        type="single"
        onValueChange={handleValueChange}
        value={value}
      >
        {items.map((item) => {
          const isSelectedOption = item.value === value;
          const textColor =
            toggleGroupOptionTextColor(isSelectedOption)[palette];

          return (
            <RadixToggleGroup.Item
              key={item.value}
              value={item.value}
              className={tw(toggleGroupSizeMap[size])}
              ref={value === item.value ? selectedRef : null}
            >
              <div className="relative z-close-button">
                <BodyText color={textColor} size={toggleGroupFontSize[size]}>
                  {item.content}
                </BodyText>
              </div>
            </RadixToggleGroup.Item>
          );
        })}
      </RadixToggleGroup.Root>
      <div
        style={{ width: `${width}px`, transform: `translateX(${offset}px)` }}
        className={tw(
          "absolute inset-x-0 top-[1px] rounded-full",
          isInitialized && "transition-transform duration-300",
          toggleGroupTransitionHeight[size],
          toggleGroupStylesMap[palette]
        )}
      />
    </div>
  );
};

export default memo(ToggleGroup);
