import React, { useState, useEffect } from "react";
import * as Siwe from "siwe";
import { useQueryClient } from "@tanstack/react-query";
import { BodyText, Heading, Button, Spinner } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { Protocol, protocols } from "@figmentjs/protocols";
import Wallet, { useSignMessage, SAFE_CONNECTOR_NAME } from "@figmentjs/wallet";
import { WalletDetails } from "..";
import {
  useNonce,
  useSignatureAccessToken,
  useValidateAccessToken,
  validateAccessTokenQueryKey,
  useGenerateNonce,
} from "../../hooks";
import { getCoreAPINetwork } from "../../utils";

type Props = {
  protocol: Protocol;
  onBack: () => void;
  onNext: () => void;
};

export const VerifyOwnership: React.FC<Props> = ({
  protocol,
  onBack,
  onNext,
}) => {
  const { account, network, walletName } =
    Wallet.useWallet<Protocol.ETHEREUM>();
  const [verificationFailed, setVerificationFailed] = useState(false);
  const [siweMessage, setSiweMessage] = useState("");
  const [isSignInWithSafeLoading, setIsSignInWithSafeLoading] = useState(false);
  const [isSignInWithEthereumLoading, setIsSignInWithEthereumLoading] =
    useState(false);
  const { nonce, setNonce } = useNonce();
  const { setSignatureAccessToken } = useSignatureAccessToken({
    account,
  });
  const {
    signMessage,
    data: signature,
    isLoading: isSignMessageLoading,
  } = useSignMessage();

  const queryClient = useQueryClient();

  const { isLoading: isGenerateNonceLoading, mutateAsync: generateNonce } =
    useGenerateNonce();

  const {
    isLoading: isValidateAccessTokenLoading,
    data: validateAccessTokenData,
  } = useValidateAccessToken();

  useEffect(() => {
    if (validateAccessTokenData?.isValid) {
      onNext();
    }
  }, [validateAccessTokenData, onNext]);

  useEffect(() => {
    const main = async () => {
      if (signature && siweMessage && nonce && account) {
        let accessToken;

        const parentUrl = window.ethereum?.isLedgerLive
          ? "ledger-live"
          : document.referrer;
        const urlParams = new URLSearchParams(window.location.search);
        const rawDappToken = urlParams.get("dappToken");
        const dappToken =
          rawDappToken === "undefined" ? undefined : rawDappToken;

        if (walletName === SAFE_CONNECTOR_NAME) {
          setIsSignInWithSafeLoading(true);

          const response = await fetch("/api/sign-in-with-safe", {
            method: "POST",
            body: JSON.stringify({
              message: siweMessage,
              signature,
              safe_account_address: account,
              network: getCoreAPINetwork(network),
            }),
            headers: {
              "content-type": "application/json",
              "X-Figment-Nonce": nonce,
              "X-Figment-Parent-Url": parentUrl,
              ...(dappToken ? { "X-Figment-Dapp-Token": dappToken } : {}),
            },
          });
          const data = await response.json();

          setIsSignInWithSafeLoading(false);

          accessToken = data.accessToken;
        } else {
          setIsSignInWithEthereumLoading(true);

          const response = await fetch("/api/sign-in-with-ethereum", {
            method: "POST",
            body: JSON.stringify({
              message: siweMessage,
              signature,
              address: account,
            }),
            headers: {
              "content-type": "application/json",
              "X-Figment-Nonce": nonce,
              "X-Figment-Parent-Url": parentUrl,
              ...(dappToken ? { "X-Figment-Dapp-Token": dappToken } : {}),
            },
          });
          const data = await response.json();

          setIsSignInWithEthereumLoading(false);

          accessToken = data?.accessToken;
        }

        setSignatureAccessToken(accessToken);

        if (!accessToken) {
          setVerificationFailed(true);
          return;
        }

        queryClient.invalidateQueries(validateAccessTokenQueryKey);

        onNext();
      }
    };

    main();
  }, [
    signature,
    siweMessage,
    account,
    onNext,
    setSignatureAccessToken,
    nonce,
    walletName,
    network,
    queryClient,
  ]);

  const isLoading =
    isSignInWithEthereumLoading ||
    isSignInWithSafeLoading ||
    isGenerateNonceLoading ||
    isSignMessageLoading;

  const createSiweMessage = (nonce: string, chainId: number) => {
    const message = new Siwe.SiweMessage({
      domain: process.env.NEXT_PUBLIC_SIGN_IN_WITH_ETHEREUM_DOMAIN,
      address: account,
      statement: "",
      uri: "https://figment.io",
      version: "1",
      chainId,
      nonce,
    });

    return message.prepareMessage();
  };

  const verifyOwnership = async () => {
    setVerificationFailed(false);

    const nonceData = await generateNonce();
    const { encryptedNonce, nonce } = nonceData;

    setNonce(encryptedNonce);

    const chainId = protocols.ETHEREUM.networks[network!]?.chainId;
    const message = createSiweMessage(nonce, chainId!);

    setSiweMessage(message);

    // TODO #1: use signMessageAsync
    signMessage({
      message,
    });
  };

  if (isValidateAccessTokenLoading) {
    return (
      <div className="py-24 flex justify-center items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <WalletDetails protocol={protocol} />
      <div className="p-4 flex flex-col items-center">
        <div className="space-y-1 text-center mb-4">
          <Heading level="h5" font={HeadingFont.space} weight="semibold">
            Verify Ownership
          </Heading>
          <BodyText as="p">
            To avoid malicious use, Figment needs to confirm you&apos;re the
            owner of this address. Sign the message using your wallet.
          </BodyText>
        </div>
        {verificationFailed && (
          <div className="mt-4">
            <BodyText color="error">
              Verification failed. If you&apos;re the owner of{" "}
              <BodyText weight="bold">{account}</BodyText> and you believe
              something isn&apos;t working properly, please contact us at{" "}
              <a
                href="mailto:support@figment.io"
                className="text-green-800 underline hover:no-underline"
              >
                support@figment.io
              </a>
              .
            </BodyText>
          </div>
        )}
        <div className="flex justify-evenly gap-2 w-full mt-8">
          <Button palette="tertiary" size="small" fullWidth onClick={onBack}>
            Back
          </Button>
          <Button
            size="small"
            onClick={verifyOwnership}
            loading={isLoading}
            fullWidth
          >
            Sign Message
          </Button>
        </div>
      </div>
    </>
  );
};
