import React, { memo } from "react";
import { BodyText } from "../../../../typography";
import { ErrorTextProps } from "./ErrorText.types";

/**
 * A component for displaying error text within a form field.
 * @param ErrorTextProps.
 * @returns The ErrorText component.
 */
const ErrorText: React.FC<ErrorTextProps> = ({ children }) => {
  return (
    <BodyText weight="light" size="xs" color="error">
      {children}
    </BodyText>
  );
};

export default memo(ErrorText);
