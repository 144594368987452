import React from "react";
import { twMerge as tw } from "tailwind-merge";

export const ActivityTableLoading: React.FC<{ transparent?: boolean }> = ({
  transparent,
}) => {
  return (
    <div
      className={tw(
        "flex gap-4 w-full p-4 rounded-md",
        transparent ? "bg-[rgba(255,255,255,.55)]" : "bg-white"
      )}
    >
      <div className="w-full h-6 bg-white bg-opacity-50 rounded-md animate-pulse"></div>
      <div className="w-full h-6 bg-white bg-opacity-50 rounded-md animate-pulse"></div>
      <div className="w-full h-6 bg-white bg-opacity-50 rounded-md animate-pulse"></div>
    </div>
  );
};
