"use client";

import { useLocalStorage } from "usehooks-ts";
import { Currencies } from "../../components/currency-toggle/currency-toggle.types";

export const CURRENCY_STORAGE_KEY = "isUsd";

export const useCurrency = () => {
  const [currency, setCurrency] = useLocalStorage(CURRENCY_STORAGE_KEY, false);

  return {
    isUsd: currency,
    setIsUsd: (currency: string) => {
      setCurrency(currency === Currencies.USD);
    },
  };
};
