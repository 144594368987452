import React, { memo } from "react";
import { twMerge as tw } from "tailwind-merge";
import { Icon } from "@figmentjs/web-core";
import { AddressType, AddressBase, Size } from "../address-base";
import { CopyAddressToClipboard } from "../copy-address-to-clipboard";
import { Protocol, protocols } from "@figmentjs/protocols";
import { protocolAddressTheme } from "./address.themes";

type Props = {
  address: string;
  protocol: Protocol;
  type?: keyof typeof AddressType;
  inline?: boolean;
  overrideTheme?: boolean;
  href?: string;
  size?: keyof typeof Size;
};

const Address: React.FC<Props> = ({
  address,
  type = AddressType.WITHDRAWAL_ADDRESS,
  protocol,
  inline,
  overrideTheme = false,
  href,
  size = Size.sm,
}) => {
  return (
    <div className={tw("h-5.5", inline && "inline-block")}>
      <AddressBase
        address={address}
        protocol={protocol}
        prefix={[
          ...(type === AddressType.EIGENPOD_ADDRESS
            ? [
                <span key="eigenlayer-logo" className="px-px mr-1.5">
                  <Icon
                    icon="EigenLayerLogo"
                    size={size === Size.sm ? "sm" : "lg"}
                  />
                </span>,
              ]
            : [
                <span className="mr-1.5" key="protocol-logo">
                  <Icon
                    icon={protocols[protocol].icon}
                    size={size === Size.sm ? "base" : "xl"}
                  />
                </span>,
              ]),
        ]}
        suffix={[
          <div
            key="copy-address-to-clipboard"
            className="ml-1.5 hidden group-hover/address:block"
          >
            <CopyAddressToClipboard
              key="copy-address-to-clipboard"
              address={address}
              type={type}
              color={
                overrideTheme ? protocolAddressTheme(protocol).text : "green"
              }
            />
          </div>,
        ]}
        overrideTheme={overrideTheme}
        href={href}
        size={size}
      />
    </div>
  );
};

export default memo(Address);
