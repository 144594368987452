import { useMutation } from "@tanstack/react-query";

export const useGenerateNonce = () => {
  const { isLoading, data, mutateAsync } = useMutation({
    mutationKey: ["generate-nonce"],
    mutationFn: () =>
      fetch("/api/generate-nonce", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
      }).then(async (res) => res.json()),
  });

  return { isLoading, data, mutateAsync };
};
