import { SolanaNetwork, SolanaNetworkValidatorMap } from "@figmentjs/protocols";
import {
  SolanaNetworks,
  useSolanaStakingFlowQuery,
} from "../../../../../../graphql/core/generated/gql";
import BigNumber from "bignumber.js";
import { friendlyDateFromSeconds } from "@figmentjs/utils/src/formatters/date";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";

type Props = {
  account?: string;
  network?: SolanaNetwork;
  customValidator?: {
    pubkey: string;
  };
};

export const useSolanaStakingFlowData = ({
  account,
  network,
  customValidator,
}: Props) => {
  const { data: solanaStakingFlowData, isLoading: isSolanaStakingFlowLoading } =
    useSolanaStakingFlowQuery(
      account
        ? {
            network:
              network === SolanaNetwork.DEVNET
                ? SolanaNetworks.Devnet
                : SolanaNetworks.Mainnet,
            fundingAccountPubkey: account!,
            voteAccountPubkey: account!,
          }
        : {
            network: SolanaNetworks.Mainnet,
            fundingAccountPubkey: customValidator
              ? customValidator.pubkey
              : SolanaNetworkValidatorMap.mainnet,
            voteAccountPubkey: customValidator
              ? customValidator.pubkey
              : SolanaNetworkValidatorMap.mainnet,
          }
    );
  const estimatedFee = BigNumber(
    solanaStakingFlowData?.solanaStakingMaxDelegationEstimate.estimatedFee ||
      "0"
  )
    .plus(
      solanaStakingFlowData?.solanaStakingMaxDelegationEstimate
        .minBalanceForRentExemption || "0"
    )
    .dividedBy(LAMPORTS_PER_SOL)
    .toString();

  const activationTime = friendlyDateFromSeconds(
    solanaStakingFlowData?.solanaEstimatedActivationTime.numSeconds
  );

  const exitTime = friendlyDateFromSeconds(
    solanaStakingFlowData?.solanaEstimatedDeactivationTime.numSeconds
  );

  const rewardsRate = solanaStakingFlowData?.solanaRewardsRate.value || 0;

  const commission = BigNumber(
    solanaStakingFlowData?.solanaFigmentPublicCommissionRate.rate || 0
  )
    .times(100)
    .toNumber();

  const price = (solanaStakingFlowData?.solanaPrice.value || 0).toString();

  return {
    estimatedFee,
    activationTime,
    exitTime,
    rewardsRate,
    commission,
    price,
    isSolanaStakingFlowLoading,
  };
};
