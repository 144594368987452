import React from "react";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import { Pie } from "@visx/shape";
import { useTooltip, Tooltip } from "@visx/tooltip";
import { tooltipStyles } from "../shared/utils/constants";
import { DataPoint, DonutChartProps } from "./donut-chart.types";

/**
 * The donut chart component.
 *
 * @param DonutChartProps
 * @returns The DonutChart component
 */
const DonutChart = <TDataPoint extends DataPoint>({
  data,
  renderTooltipChildren,
  thickness = 40,
  animate,
  padAngle,
  cornerRadius,
}: DonutChartProps<TDataPoint>): React.ReactElement => {
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TDataPoint>();

  return (
    <>
      {tooltipOpen && tooltipData && (
        <Tooltip
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <div>{renderTooltipChildren({ tooltipData })}</div>
        </Tooltip>
      )}
      <ParentSize debounceTime={50}>
        {({ width, height }) => {
          return (
            <Arc
              animate={animate}
              padAngle={padAngle}
              cornerRadius={cornerRadius}
              width={width}
              height={height}
              data={data}
              thickness={thickness}
              onShowTooltip={(
                event: React.MouseEvent<SVGPathElement, MouseEvent>,
                data: TDataPoint
              ) => {
                showTooltip({
                  tooltipData: data as TDataPoint & {
                    color: string;
                  },
                  tooltipTop: event.pageY,
                  tooltipLeft: event.pageX,
                });
              }}
              onHideTooltip={hideTooltip}
            />
          );
        }}
      </ParentSize>
    </>
  );
};

export default DonutChart;

export type ArcProps<TDataPoint extends DataPoint> = {
  width: number;
  height: number;
  data: DataPoint[];
  thickness?: number;
  onShowTooltip: (
    event: React.MouseEvent<SVGPathElement, MouseEvent>,
    data: TDataPoint
  ) => void;
  onHideTooltip: () => void;
  animate?: boolean;
  padAngle?: number;
  cornerRadius?: number;
};

const Arc = <TDataPoint extends DataPoint>({
  width,
  height,
  data,
  thickness = 10,
  onShowTooltip,
  onHideTooltip,
  animate,
  padAngle,
  cornerRadius,
}: ArcProps<TDataPoint>): React.ReactElement => {
  const [focusedArc, setFocusedArc] = React.useState<DataPoint | null>(null);
  const radius = Math.min(width, height) / 2;
  const centerY = height / 2;
  const centerX = width / 2;
  const isEmpty = data.every((dataPoint) => dataPoint.value === 0);

  return (
    <svg width={width} height={height}>
      <Group top={centerY} left={centerX}>
        <Pie
          padAngle={padAngle}
          data={isEmpty ? [{ label: "", value: 1, color: "#f9f9f9" }] : data}
          pieValue={(d) => d.value}
          outerRadius={radius}
          cornerRadius={cornerRadius}
          innerRadius={({ data }) => {
            const size =
              !isEmpty && animate && data.label === focusedArc?.label
                ? thickness * 1.6
                : thickness;

            return radius - size;
          }}
        >
          {({ arcs, path }) => (
            <g>
              {arcs.map((arc, i) => (
                <g key={`pie-arc-${i}`}>
                  <path
                    onMouseLeave={() => {
                      setFocusedArc(null);
                      onHideTooltip();
                    }}
                    onMouseEnter={() => {
                      setFocusedArc(arc.data as TDataPoint);
                    }}
                    onMouseMove={(event) => {
                      if (isEmpty) return;

                      onShowTooltip(event, arc.data as TDataPoint);
                    }}
                    d={path(arc)!}
                    fill={arc.data.color}
                    data-testid={`pie-arc-${arc.data.color}`}
                  />
                </g>
              ))}
            </g>
          )}
        </Pie>
      </Group>
    </svg>
  );
};
