import React from "react";
import { BodyText, Button, Heading, Icon } from "@figmentjs/web-core";
import { Protocol, getTransactionExplorerUrl } from "@figmentjs/protocols";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { Details } from "../../../../components";
import BigNumber from "bignumber.js";
import Wallet from "@figmentjs/wallet";
import { useNetworkConfig } from "../../../../hooks";

type Props = {
  amount: BigNumber;
  transactionHash?: string;
  details?: React.ComponentProps<typeof Details>["details"];
  onDone: () => void;
  buttonText?: string;
};

export const BabylonSuccess: React.FC<Props> = ({
  amount,
  details,
  transactionHash,
  onDone,
  buttonText = "View Activity",
}) => {
  const {
    transactionHash: walletTransactionHash,
    network,
    setTransactionHash,
  } = Wallet.useWallet();
  const { ticker } = useNetworkConfig({ protocol: Protocol.BABYLON });

  const txHash = walletTransactionHash || transactionHash;

  return (
    <div className="p-4">
      <div className="flex gap-2 items-center justify-center text-center border-b border-b-green-100 pb-4">
        <Icon icon="MdCheckCircle" size="4xl" color="green-800" />
        <Heading
          level="h5"
          color="green"
          font={HeadingFont.space}
          weight="bold"
        >
          Delegating {amount.toString()} {ticker}
        </Heading>
      </div>
      <div className="py-4">
        <BodyText weight="semibold">What&apos;s Next?</BodyText>
        <div className="pt-1">
          <BodyText size="sm">You will receive an email once the</BodyText>
          <div className="inline mx-1">
            <Button
              palette="text"
              size="tiny"
              openInNewTab
              href={getTransactionExplorerUrl(
                Protocol.BABYLON,
                txHash!,
                network
              )}
            >
              transaction
            </Button>
          </div>
          <BodyText size="sm">
            has been confirmed on chain. This can take up to 30 minutes.
          </BodyText>
        </div>
      </div>
      {details && (
        <div className="pb-4">
          <Details details={details} containerClassName="gap-4 mb-4" />
        </div>
      )}
      <div className="w-full ">
        <Button
          size="small"
          fullWidth
          onClick={() => {
            setTransactionHash();
            onDone();
          }}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
