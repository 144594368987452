import BigNumber from "bignumber.js";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { twMerge as tw } from "tailwind-merge";
import NumberFormat from "react-number-format";
import { BodyText, Button } from "@figmentjs/web-core";
import { currencyFormatter } from "@figmentjs/utils";

type Props = {
  ticker: string;
  onChange: (amount?: string | null) => void;
  tokenPrice: number;
  tokenPriceFormatter?: (value: string) => string;
  balance: BigNumber;
  decimalScale: number;
  disabled?: boolean;
  hideMaxButton?: boolean;
};

const CHAR_WIDTH_MAP: { [key: string]: number } = {
  ".": 0.5,
  "1": 0.67,
  "2": 0.93,
  "3": 0.94,
  "4": 0.98,
  "5": 0.93,
  "6": 0.95,
  "7": 0.85,
  "8": 0.95,
  "9": 0.95,
  "0": 1,
};

/**
 * Given a string, returns the width of the string
 * in the `ch` measurement unit.
 * @param amount string
 * @returns
 */
const getInputWidth = (amount: string | null) => {
  if (amount === null) {
    return 1;
  }

  const inputWidth = Array.from(amount).reduce(
    (acc: number, curr: string) => acc + CHAR_WIDTH_MAP[curr],
    0
  );

  return Math.max(inputWidth, 1);
};

const AmountInput: React.FC<Props> = ({
  onChange,
  ticker,
  balance,
  decimalScale,
  tokenPrice,
  tokenPriceFormatter = currencyFormatter.format,
  disabled,
  hideMaxButton = false,
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const [amount, setAmount] = useState<string | null>(null);
  const tokenPriceAmount = BigNumber(amount || 0)
    .times(tokenPrice)
    .toString();
  const inputWidth = useCallback(() => {
    return getInputWidth(amount) + "ch";
  }, [amount]);
  const handleInput = useCallback(
    (value?: string) => {
      const newAmount = value || null;
      setAmount(newAmount);
      onChange(newAmount);
    },
    [onChange]
  );

  useEffect(() => {
    if (disabled) {
      // Reset the amount to undefined when the input is disabled
      handleInput(undefined);
    } else {
      // Focus the input field when the input goes from disabled to enabled
      inputRef.current?.focus();
    }
    // This effect should only run when the `disabled` prop changes,
    // which is why we're omitting `handleInput` from the dependencies array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <div className="flex justify-between items-center caret-green-600">
      <div className="relative overflow-auto pr-2">
        <div
          className={tw(
            "flex items-center h-10 appearance-none bg-white select-none font-space font-semibold",
            disabled ? "cursor-not-allowed" : "cursor-text",
            amount && amount.length >= 9
              ? "text-2xl"
              : amount && amount.length >= 6
              ? "text-3xl"
              : "text-4xl"
          )}
          onClick={() => {
            // Handles positioning the cursor and/or
            // selecting the input value when the input field
            // area is clicked.
            if (disabled) {
              return;
            }

            inputRef.current?.focus();
            if (amount !== null || amount !== undefined) {
              inputRef.current?.setSelectionRange(
                0,
                inputRef.current.value.length
              );
            }
          }}
        >
          <NumberFormat
            // The NumberFormat component's value prop is cached somewhere internally,
            // and the previous value the component goes from being disabled to enabled.
            // To circumvent this, we're now setting the key prop based off of the `isDisabled`
            // prop to force a re-render and circumvent the caching.
            key={disabled?.toString()}
            autoFocus
            value={amount}
            disabled={disabled}
            className={tw(
              "outline-none whitespace-nowrap overflow-hidden h-14 appearance-none bg-white disabled:cursor-not-allowed disabled:text-basic-600 placeholder:text-basic-600",
              amount !== "0" ? "text-green-700" : "text-basic-600"
            )}
            decimalScale={decimalScale}
            displayType="input"
            isAllowed={(values) => {
              const isAllowed =
                BigNumber(values.value || 0).lte(Number.MAX_SAFE_INTEGER) &&
                BigNumber(values.value || 0).lte(balance);
              return isAllowed;
            }}
            inputMode="numeric"
            placeholder="0"
            style={{
              width: inputWidth(),
            }}
            onBlur={() => handleInput(inputRef.current?.value)}
            onValueChange={(values) => handleInput(values.value)}
            getInputRef={inputRef}
            allowNegative={false}
            data-testid="amount-input-field"
          />
          <div className="flex-none text-basic-800 ml-0.5">{ticker}</div>
        </div>
        <div className="select-none">
          <BodyText weight="semibold">
            {tokenPriceFormatter(tokenPriceAmount)}
          </BodyText>
        </div>
      </div>
      {!hideMaxButton && (
        <Button
          palette="quaternary"
          iconBefore="MdArrowUpward"
          size="xtiny"
          disabled={disabled}
          onClick={() => {
            setAmount(balance.toString());
          }}
          testId="amount-input-max-button"
        >
          Max
        </Button>
      )}
    </div>
  );
};

export default memo(AmountInput);
