import { rest } from "msw";

export const IsAccessTokenValidQueryMock = rest.post(
  "/api/validate-access-token",
  async (req, res, ctx) => {
    return res(
      ctx.json({
        isValid: true,
      })
    );
  }
);

export const IsAccessTokenValidQueryNotValidMock = rest.post(
  "/api/validate-access-token",
  async (req, res, ctx) => {
    return res(
      ctx.json({
        isValid: false,
      })
    );
  }
);

export const NonceCreateMutationMock = rest.post(
  "/api/generate-nonce",
  async (req, res, ctx) => {
    return res(
      ctx.json({
        nonce: "nonce",
        encryptedNonce: "encryptedNonce",
      })
    );
  }
);

export const SignInWithEthereumMutationMock = rest.post(
  "/api/sign-in-with-ethereum",
  async (req, res, ctx) => {
    return res(
      ctx.json({
        accessToken: "accessToken",
      })
    );
  }
);
