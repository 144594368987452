import * as React from "react";
import copy from "copy-to-clipboard";
import { FontColor, FontSize } from "../../../theme/types";
import { useToast } from "../Toast";
import { Tooltip } from "../Tooltip";
import { Icon } from "../../graphics";

type Props = {
  contentToSave: string;
  notificationMessage: string;
  size?: keyof typeof FontSize;
  withPadding?: boolean;
  color?: keyof typeof FontColor;
};

// TODO: [FIGAPP-849] Use web-core's CopyToClipboard
//https://figmentio.atlassian.net/browse/FIGAPP-849
const CopyToClipboard: React.FC<Props> = ({
  contentToSave,
  notificationMessage,
  size = "sm",
  withPadding = true,
  color = "green",
}) => {
  const { addToast } = useToast();

  const saveToClipboard = async (contentToSave: string) => {
    try {
      await navigator.clipboard.writeText(contentToSave);

      addToast({
        message: notificationMessage,
        palette: "success",
      });
    } catch (e) {
      // navigator.clipboard is not supported on Android, so we fallback to copy-to-clipboard
      const isCopySuccessful = copy(contentToSave);

      if (isCopySuccessful) {
        addToast({
          message: notificationMessage,
          palette: "success",
        });
        return;
      }

      addToast({
        message: "Error copying, please try again",
        palette: "error",
      });
    }
  };

  return (
    <div className="flex items-center">
      <div
        className={`${withPadding ? "px-2" : ""} hover:cursor-pointer group`}
        onClick={(e) => {
          e.stopPropagation();
          saveToClipboard(contentToSave);
        }}
      >
        <div className="hover:[&>*]:text-basic-600">
          <Tooltip placement="right" overlayText="Copy to clipboard">
            <Icon icon="MdContentCopy" size={size} color={color} />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CopyToClipboard);
