import { graphql } from "msw";
import {
  EthereumRewardsEarnedChartQuery,
  SolanaRewardsEarnedChartQuery,
} from "../../../graphql/core/generated/gql";

const rewards = {
  rewardsEarnedOneMonth: {
    rewards: [
      {
        timestamp: "2024-01-29T00:00:00Z",
        total: "0.722726555013504334",
        totalUsd: "2392.09",
        consensusTotal: "0.663617797",
        consensusTotalUsd: "2196.45",
        executionTotal: "0.059108758013504334",
        executionTotalUsd: "195.64",
      },
      {
        timestamp: "2024-01-30T00:00:00Z",
        total: "0.674657919",
        totalUsd: "2232.99",
        consensusTotal: "0.674657919",
        consensusTotalUsd: "2232.99",
        executionTotal: "0.0",
        executionTotalUsd: "0.0",
      },
      {
        timestamp: "2024-01-31T00:00:00Z",
        total: "1.252721573243015831",
        totalUsd: "4146.28",
        consensusTotal: "0.809190637",
        consensusTotalUsd: "2678.27",
        executionTotal: "0.443530936243015831",
        executionTotalUsd: "1468.01",
      },
      {
        timestamp: "2024-02-01T00:00:00Z",
        total: "0.80574617643299411",
        totalUsd: "2666.87",
        consensusTotal: "0.74881443",
        consensusTotalUsd: "2478.44",
        executionTotal: "0.05693174643299411",
        executionTotalUsd: "188.43",
      },
      {
        timestamp: "2024-02-02T00:00:00Z",
        total: "5.297388871896306301",
        totalUsd: "17533.37",
        consensusTotal: "0.904177346",
        consensusTotalUsd: "2992.66",
        executionTotal: "4.393211525896306301",
        executionTotalUsd: "14540.72",
      },
      {
        timestamp: "2024-02-03T00:00:00Z",
        total: "0.829116811",
        totalUsd: "2744.22",
        consensusTotal: "0.829116811",
        consensusTotalUsd: "2744.22",
        executionTotal: "0.0",
        executionTotalUsd: "0.0",
      },
      {
        timestamp: "2024-02-04T00:00:00Z",
        total: "0.916502902816988299",
        totalUsd: "3033.45",
        consensusTotal: "0.661035649",
        consensusTotalUsd: "2187.91",
        executionTotal: "0.255467253816988299",
        executionTotalUsd: "845.55",
      },
      {
        timestamp: "2024-02-05T00:00:00Z",
        total: "0.900386203406047032",
        totalUsd: "2980.11",
        consensusTotal: "0.74733207",
        consensusTotalUsd: "2473.53",
        executionTotal: "0.153054133406047032",
        executionTotalUsd: "506.58",
      },
      {
        timestamp: "2024-02-06T00:00:00Z",
        total: "1.487897725551656173",
        totalUsd: "4924.67",
        consensusTotal: "0.864394138",
        consensusTotalUsd: "2860.98",
        executionTotal: "0.623503587551656173",
        executionTotalUsd: "2063.68",
      },
      {
        timestamp: "2024-02-07T00:00:00Z",
        total: "1.170537870592563651",
        totalUsd: "3874.26",
        consensusTotal: "0.747511826",
        consensusTotalUsd: "2474.13",
        executionTotal: "0.423026044592563651",
        executionTotalUsd: "1400.14",
      },
      {
        timestamp: "2024-02-08T00:00:00Z",
        total: "0.570982529",
        totalUsd: "1889.85",
        consensusTotal: "0.570982529",
        consensusTotalUsd: "1889.85",
        executionTotal: "0.0",
        executionTotalUsd: "0.0",
      },
      {
        timestamp: "2024-02-09T00:00:00Z",
        total: "1.355712335548624696",
        totalUsd: "4487.16",
        consensusTotal: "0.699455146",
        consensusTotalUsd: "2315.07",
        executionTotal: "0.656257189548624696",
        executionTotalUsd: "2172.09",
      },
      {
        timestamp: "2024-02-10T00:00:00Z",
        total: "0.846763698966594367",
        totalUsd: "2802.63",
        consensusTotal: "0.656305073",
        consensusTotalUsd: "2172.25",
        executionTotal: "0.190458625966594367",
        executionTotalUsd: "630.38",
      },
      {
        timestamp: "2024-02-11T00:00:00Z",
        total: "0.567989026",
        totalUsd: "1879.94",
        consensusTotal: "0.567989026",
        consensusTotalUsd: "1879.94",
        executionTotal: "0.0",
        executionTotalUsd: "0.0",
      },
      {
        timestamp: "2024-02-12T00:00:00Z",
        total: "0.721595254716302668",
        totalUsd: "2388.35",
        consensusTotal: "0.654603371",
        consensusTotalUsd: "2166.62",
        executionTotal: "0.066991883716302668",
        executionTotalUsd: "221.73",
      },
      {
        timestamp: "2024-02-13T00:00:00Z",
        total: "0.877499347943730687",
        totalUsd: "2904.36",
        consensusTotal: "0.693388331",
        consensusTotalUsd: "2294.99",
        executionTotal: "0.184111016943730687",
        executionTotalUsd: "609.37",
      },
      {
        timestamp: "2024-02-14T00:00:00Z",
        total: "0.65853865123567907",
        totalUsd: "2179.64",
        consensusTotal: "0.606789543",
        consensusTotalUsd: "2008.36",
        executionTotal: "0.05174910823567907",
        executionTotalUsd: "171.28",
      },
      {
        timestamp: "2024-02-15T00:00:00Z",
        total: "1.114675756365155109",
        totalUsd: "3689.37",
        consensusTotal: "0.799882323",
        consensusTotalUsd: "2647.46",
        executionTotal: "0.314793433365155109",
        executionTotalUsd: "1041.91",
      },
      {
        timestamp: "2024-02-16T00:00:00Z",
        total: "0.623878466",
        totalUsd: "2064.92",
        consensusTotal: "0.623878466",
        consensusTotalUsd: "2064.92",
        executionTotal: "0.0",
        executionTotalUsd: "0.0",
      },
      {
        timestamp: "2024-02-17T00:00:00Z",
        total: "0.932563441268838842",
        totalUsd: "3086.61",
        consensusTotal: "0.691705942",
        consensusTotalUsd: "2289.42",
        executionTotal: "0.240857499268838842",
        executionTotalUsd: "797.19",
      },
      {
        timestamp: "2024-02-18T00:00:00Z",
        total: "0.965890602787992541",
        totalUsd: "3196.92",
        consensusTotal: "0.646504892",
        consensusTotalUsd: "2139.81",
        executionTotal: "0.319385710787992541",
        executionTotalUsd: "1057.11",
      },
      {
        timestamp: "2024-02-19T00:00:00Z",
        total: "0.900804396978200862",
        totalUsd: "2981.5",
        consensusTotal: "0.736580467",
        consensusTotalUsd: "2437.94",
        executionTotal: "0.164223929978200862",
        executionTotalUsd: "543.55",
      },
      {
        timestamp: "2024-02-20T00:00:00Z",
        total: "0.989950229199735489",
        totalUsd: "3276.55",
        consensusTotal: "0.649117403",
        consensusTotalUsd: "2148.46",
        executionTotal: "0.340832826199735489",
        executionTotalUsd: "1128.09",
      },
      {
        timestamp: "2024-02-21T00:00:00Z",
        total: "0.730118929104465285",
        totalUsd: "2416.56",
        consensusTotal: "0.647848841",
        consensusTotalUsd: "2144.26",
        executionTotal: "0.082270088104465285",
        executionTotalUsd: "272.3",
      },
      {
        timestamp: "2024-02-22T00:00:00Z",
        total: "0.641459933911332133",
        totalUsd: "2123.11",
        consensusTotal: "0.605933692",
        consensusTotalUsd: "2005.53",
        executionTotal: "0.035526241911332133",
        executionTotalUsd: "117.59",
      },
      {
        timestamp: "2024-02-23T00:00:00Z",
        total: "0.693770700168254219",
        totalUsd: "2296.25",
        consensusTotal: "0.652669586",
        consensusTotalUsd: "2160.22",
        executionTotal: "0.041101114168254219",
        executionTotalUsd: "136.04",
      },
      {
        timestamp: "2024-02-24T00:00:00Z",
        total: "0.77907359931647157",
        totalUsd: "2578.59",
        consensusTotal: "0.693466512",
        consensusTotalUsd: "2295.25",
        executionTotal: "0.08560708731647157",
        executionTotalUsd: "283.34",
      },
      {
        timestamp: "2024-02-25T00:00:00Z",
        total: "0.683363089272061729",
        totalUsd: "2261.81",
        consensusTotal: "0.606201279",
        consensusTotalUsd: "2006.41",
        executionTotal: "0.077161810272061729",
        executionTotalUsd: "255.39",
      },
      {
        timestamp: "2024-02-26T00:00:00Z",
        total: "-0.978274912500332976",
        totalUsd: "-2126.45",
        consensusTotal: "-0.878274912500332976",
        consensusTotalUsd: "-1909.08",
        executionTotal: "-0.1",
        executionTotalUsd: "-217.37",
      },
      {
        timestamp: "2024-02-27T00:00:00Z",
        total: "-0.978274912500332976",
        totalUsd: "-2126.45",
        consensusTotal: "-1.178274912500332976",
        consensusTotalUsd: "-1691.71",
        executionTotal: "0.2",
        executionTotalUsd: "434.74",
      },
      {
        timestamp: "2024-02-28T00:00:00Z",
        total: "0.526783017",
        totalUsd: "1743.55",
        consensusTotal: "0.526783017",
        consensusTotalUsd: "1743.55",
        executionTotal: "0.0",
        executionTotalUsd: "0.0",
      },
    ],
  },

  rewardsEarnedOneYear: {
    rewards: [
      {
        timestamp: "2023-02-01T00:00:00Z",
        total: "73.689110457012414629",
        totalUsd: "243897.29",
        consensusTotal: "55.202466487",
        consensusTotalUsd: "182709.93",
        executionTotal: "18.486643970012414629",
        executionTotalUsd: "61187.36",
      },
      {
        timestamp: "2023-03-01T00:00:00Z",
        total: "79.313647988527909106",
        totalUsd: "262513.47",
        consensusTotal: "57.979465721",
        consensusTotalUsd: "191901.28",
        executionTotal: "21.334182267527909106",
        executionTotalUsd: "70612.19",
      },
      {
        timestamp: "2023-04-01T00:00:00Z",
        total: "77.784830361962235949",
        totalUsd: "257453.36",
        consensusTotal: "55.641415043",
        consensusTotalUsd: "184162.76",
        executionTotal: "22.143415318962235949",
        executionTotalUsd: "73290.6",
      },
      {
        timestamp: "2023-05-01T00:00:00Z",
        total: "96.337944241326645591",
        totalUsd: "318860.73",
        consensusTotal: "46.427681468",
        consensusTotalUsd: "153667.02",
        executionTotal: "49.910262773326645591",
        executionTotalUsd: "165193.71",
      },
      {
        timestamp: "2023-06-01T00:00:00Z",
        total: "54.930179517417328283",
        totalUsd: "181808.71",
        consensusTotal: "42.429702064",
        consensusTotalUsd: "140434.44",
        executionTotal: "12.500477453417328283",
        executionTotalUsd: "41374.26",
      },
      {
        timestamp: "2023-07-01T00:00:00Z",
        total: "62.344073196476427309",
        totalUsd: "206347.32",
        consensusTotal: "41.059945096",
        consensusTotalUsd: "135900.8",
        executionTotal: "21.284128100476427309",
        executionTotalUsd: "70446.52",
      },
      {
        timestamp: "2023-08-01T00:00:00Z",
        total: "53.093350403622775174",
        totalUsd: "175729.14",
        consensusTotal: "37.725785593",
        consensusTotalUsd: "124865.35",
        executionTotal: "15.367564810622775174",
        executionTotalUsd: "50863.79",
      },
      {
        timestamp: "2023-09-01T00:00:00Z",
        total: "41.071269456886589006",
        totalUsd: "135938.28",
        consensusTotal: "34.456524322",
        consensusTotalUsd: "114044.71",
        executionTotal: "6.614745134886589006",
        executionTotalUsd: "21893.58",
      },
      {
        timestamp: "2023-10-01T00:00:00Z",
        total: "41.04821189034895801",
        totalUsd: "135861.97",
        consensusTotal: "32.59830805",
        consensusTotalUsd: "107894.35",
        executionTotal: "8.44990384034895801",
        executionTotalUsd: "27967.61",
      },
      {
        timestamp: "2023-11-01T00:00:00Z",
        total: "32.818103944586835026",
        totalUsd: "108621.84",
        consensusTotal: "24.618306493",
        consensusTotalUsd: "81482.03",
        executionTotal: "8.199797451586835026",
        executionTotalUsd: "27139.81",
      },
      {
        timestamp: "2023-12-01T00:00:00Z",
        total: "27.506475435237973487",
        totalUsd: "91041.33",
        consensusTotal: "21.924887126",
        consensusTotalUsd: "72567.31",
        executionTotal: "5.581588309237973487",
        executionTotalUsd: "18474.02",
      },
      {
        timestamp: "2024-01-01T00:00:00Z",
        total: "29.650099870109258199",
        totalUsd: "98136.33",
        consensusTotal: "21.496520091",
        consensusTotalUsd: "71149.49",
        executionTotal: "8.153579779109258199",
        executionTotalUsd: "26986.84",
      },
      {
        timestamp: "2024-02-01T00:00:00Z",
        total: "28.086626021521652319",
        totalUsd: "92961.52",
        consensusTotal: "19.207875248",
        consensusTotalUsd: "63574.5",
        executionTotal: "8.878750773521652319",
        executionTotalUsd: "29387.02",
      },
    ],
  },
  rewardsEarnedSixMonths: {
    rewards: [
      {
        timestamp: "2023-08-01T00:00:00Z",
        total: "53.093350403622775174",
        totalUsd: "175729.14",
        consensusTotal: "37.725785593",
        consensusTotalUsd: "124865.35",
        executionTotal: "15.367564810622775174",
        executionTotalUsd: "50863.79",
      },
      {
        timestamp: "2023-09-01T00:00:00Z",
        total: "41.071269456886589006",
        totalUsd: "135938.28",
        consensusTotal: "34.456524322",
        consensusTotalUsd: "114044.71",
        executionTotal: "6.614745134886589006",
        executionTotalUsd: "21893.58",
      },
      {
        timestamp: "2023-10-01T00:00:00Z",
        total: "41.04821189034895801",
        totalUsd: "135861.97",
        consensusTotal: "32.59830805",
        consensusTotalUsd: "107894.35",
        executionTotal: "8.44990384034895801",
        executionTotalUsd: "27967.61",
      },
      {
        timestamp: "2023-11-01T00:00:00Z",
        total: "32.818103944586835026",
        totalUsd: "108621.84",
        consensusTotal: "24.618306493",
        consensusTotalUsd: "81482.03",
        executionTotal: "8.199797451586835026",
        executionTotalUsd: "27139.81",
      },
      {
        timestamp: "2023-12-01T00:00:00Z",
        total: "27.506475435237973487",
        totalUsd: "91041.33",
        consensusTotal: "21.924887126",
        consensusTotalUsd: "72567.31",
        executionTotal: "5.581588309237973487",
        executionTotalUsd: "18474.02",
      },
      {
        timestamp: "2024-01-01T00:00:00Z",
        total: "29.650099870109258199",
        totalUsd: "98136.33",
        consensusTotal: "21.496520091",
        consensusTotalUsd: "71149.49",
        executionTotal: "8.153579779109258199",
        executionTotalUsd: "26986.84",
      },
      {
        timestamp: "2024-02-01T00:00:00Z",
        total: "28.086626021521652319",
        totalUsd: "92961.52",
        consensusTotal: "19.207875248",
        consensusTotalUsd: "63574.5",
        executionTotal: "8.878750773521652319",
        executionTotalUsd: "29387.02",
      },
    ],
  },
};

const negativeRewards = {
  rewardsEarnedOneMonth: {
    rewards: [
      {
        timestamp: "2024-02-10T00:00:00Z",
        total: "1.119725848597974261",
        totalUsd: "4536.04",
        consensusTotal: "1.103132094",
        consensusTotalUsd: "4468.82",
        executionTotal: "0.016593754597974261",
        executionTotalUsd: "67.22",
      },
      {
        timestamp: "2024-02-11T00:00:00Z",
        total: "1.278738781894883834",
        totalUsd: "5180.2",
        consensusTotal: "1.174491279",
        consensusTotalUsd: "4757.89",
        executionTotal: "0.104247502894883834",
        executionTotalUsd: "422.31",
      },
      {
        timestamp: "2024-02-12T00:00:00Z",
        total: "1.200076155422061254",
        totalUsd: "4861.54",
        consensusTotal: "1.116286972",
        consensusTotalUsd: "4522.11",
        executionTotal: "0.083789183422061254",
        executionTotalUsd: "339.43",
      },
      {
        timestamp: "2024-02-13T00:00:00Z",
        total: "1.410013817788519541",
        totalUsd: "5712.0",
        consensusTotal: "1.314118002",
        consensusTotalUsd: "5323.53",
        executionTotal: "0.095895815788519541",
        executionTotalUsd: "388.48",
      },
      {
        timestamp: "2024-02-14T00:00:00Z",
        total: "1.138851130247248355",
        totalUsd: "4613.51",
        consensusTotal: "1.084913035",
        consensusTotalUsd: "4395.01",
        executionTotal: "0.053938095247248355",
        executionTotalUsd: "218.5",
      },
      {
        timestamp: "2024-02-15T00:00:00Z",
        total: "1.615305170629633845",
        totalUsd: "6543.64",
        consensusTotal: "1.205459965",
        consensusTotalUsd: "4883.35",
        executionTotal: "0.409845205629633845",
        executionTotalUsd: "1660.29",
      },
      {
        timestamp: "2024-02-16T00:00:00Z",
        total: "1.03612524893610447",
        totalUsd: "4197.37",
        consensusTotal: "1.018831596",
        consensusTotalUsd: "4127.31",
        executionTotal: "0.01729365293610447",
        executionTotalUsd: "70.06",
      },
      {
        timestamp: "2024-02-17T00:00:00Z",
        total: "1.288648640946011257",
        totalUsd: "5220.35",
        consensusTotal: "1.197595163",
        consensusTotalUsd: "4851.49",
        executionTotal: "0.091053477946011257",
        executionTotalUsd: "368.86",
      },
      {
        timestamp: "2024-02-18T00:00:00Z",
        total: "1.39102192700344044",
        totalUsd: "5635.06",
        consensusTotal: "1.277299354",
        consensusTotalUsd: "5174.37",
        executionTotal: "0.11372257300344044",
        executionTotalUsd: "460.69",
      },
      {
        timestamp: "2024-02-19T00:00:00Z",
        total: "1.333864201321485628",
        totalUsd: "5403.52",
        consensusTotal: "1.23890152",
        consensusTotalUsd: "5018.82",
        executionTotal: "0.094962681321485628",
        executionTotalUsd: "384.7",
      },
      {
        timestamp: "2024-02-20T00:00:00Z",
        total: "1.302836681399979576",
        totalUsd: "5277.82",
        consensusTotal: "1.157724202",
        consensusTotalUsd: "4689.97",
        executionTotal: "0.145112479399979576",
        executionTotalUsd: "587.85",
      },
      {
        timestamp: "2024-02-21T00:00:00Z",
        total: "1.32910542870278222",
        totalUsd: "5384.24",
        consensusTotal: "1.174491031",
        consensusTotalUsd: "4757.89",
        executionTotal: "0.15461439770278222",
        executionTotalUsd: "626.35",
      },
      {
        timestamp: "2024-02-22T00:00:00Z",
        total: "1.143486730048367242",
        totalUsd: "4632.29",
        consensusTotal: "1.123057397",
        consensusTotalUsd: "4549.53",
        executionTotal: "0.020429333048367242",
        executionTotalUsd: "82.76",
      },
      {
        timestamp: "2024-02-23T00:00:00Z",
        total: "1.232088287042611621",
        totalUsd: "4991.22",
        consensusTotal: "1.18829338",
        consensusTotalUsd: "4813.81",
        executionTotal: "0.043794907042611621",
        executionTotalUsd: "177.41",
      },
      {
        timestamp: "2024-02-24T00:00:00Z",
        total: "1.053821755612808034",
        totalUsd: "4269.06",
        consensusTotal: "1.028763033",
        consensusTotalUsd: "4167.54",
        executionTotal: "0.025058722612808034",
        executionTotalUsd: "101.51",
      },
      {
        timestamp: "2024-02-25T00:00:00Z",
        total: "1.146621250640226095",
        totalUsd: "4644.99",
        consensusTotal: "1.09037656",
        consensusTotalUsd: "4417.14",
        executionTotal: "0.056244690640226095",
        executionTotalUsd: "227.85",
      },
      {
        timestamp: "2024-02-26T00:00:00Z",
        total: "1.280014411674598294",
        totalUsd: "5185.37",
        consensusTotal: "0.944074213",
        consensusTotalUsd: "3824.47",
        executionTotal: "0.335940198674598294",
        executionTotalUsd: "1360.9",
      },
      {
        timestamp: "2024-02-27T00:00:00Z",
        total: "0.819331619979473671",
        totalUsd: "3319.13",
        consensusTotal: "0.771353863",
        consensusTotalUsd: "3124.77",
        executionTotal: "0.047977756979473671",
        executionTotalUsd: "194.36",
      },
      {
        timestamp: "2024-02-28T00:00:00Z",
        total: "0.85100645518326497",
        totalUsd: "3447.45",
        consensusTotal: "0.750324575",
        consensusTotalUsd: "3039.58",
        executionTotal: "0.10068188018326497",
        executionTotalUsd: "407.86",
      },
      {
        timestamp: "2024-02-29T00:00:00Z",
        total: "1.166917174238867722",
        totalUsd: "4727.21",
        consensusTotal: "1.104243873",
        consensusTotalUsd: "4473.32",
        executionTotal: "0.062673301238867722",
        executionTotalUsd: "253.89",
      },
      {
        timestamp: "2024-03-01T00:00:00Z",
        total: "1.081756044125192659",
        totalUsd: "4382.22",
        consensusTotal: "1.0518513",
        consensusTotalUsd: "4261.08",
        executionTotal: "0.029904744125192659",
        executionTotalUsd: "121.14",
      },
      {
        timestamp: "2024-03-02T00:00:00Z",
        total: "1.174819698014799331",
        totalUsd: "4759.22",
        consensusTotal: "1.093570074",
        consensusTotalUsd: "4430.08",
        executionTotal: "0.081249624014799331",
        executionTotalUsd: "329.14",
      },
      {
        timestamp: "2024-03-03T00:00:00Z",
        total: "1.280100866211866825",
        totalUsd: "5185.72",
        consensusTotal: "1.175817032",
        consensusTotalUsd: "4763.26",
        executionTotal: "0.104283834211866825",
        executionTotalUsd: "422.46",
      },
      {
        timestamp: "2024-03-04T00:00:00Z",
        total: "0.716644779493077748",
        totalUsd: "2903.15",
        consensusTotal: "0.66985784",
        consensusTotalUsd: "2713.61",
        executionTotal: "0.046786939493077748",
        executionTotalUsd: "189.54",
      },
      {
        timestamp: "2024-03-05T00:00:00Z",
        total: "0.330984561495892966",
        totalUsd: "1340.83",
        consensusTotal: "0.311463156",
        consensusTotalUsd: "1261.75",
        executionTotal: "0.019521405495892966",
        executionTotalUsd: "79.08",
      },
      {
        timestamp: "2024-03-06T00:00:00Z",
        total: "0.140166240651325573",
        totalUsd: "567.82",
        consensusTotal: "0.125605194",
        consensusTotalUsd: "508.83",
        executionTotal: "0.014561046651325573",
        executionTotalUsd: "58.99",
      },
      {
        timestamp: "2024-03-07T00:00:00Z",
        total: "0.850010834855440974",
        totalUsd: "3443.42",
        consensusTotal: "0.708750108",
        consensusTotalUsd: "2871.16",
        executionTotal: "0.141260726855440974",
        executionTotalUsd: "572.25",
      },
      {
        timestamp: "2024-03-08T00:00:00Z",
        total: "3.358702064783886714",
        totalUsd: "13606.19",
        consensusTotal: "0.072592439",
        consensusTotalUsd: "294.07",
        executionTotal: "3.286109625783886714",
        executionTotalUsd: "13312.11",
      },
      {
        timestamp: "2024-03-09T00:00:00Z",
        total: "0.152669469753217645",
        totalUsd: "618.47",
        consensusTotal: "0.094151441",
        consensusTotalUsd: "381.41",
        executionTotal: "0.058518028753217645",
        executionTotalUsd: "237.06",
      },
      {
        timestamp: "2024-03-10T00:00:00Z",
        total: "-3.428306886497104371",
        totalUsd: "-13888.16",
        consensusTotal: "-5.810316318",
        consensusTotalUsd: "-23537.74",
        executionTotal: "2.382009431502895629",
        executionTotalUsd: "9649.58",
      },
      {
        timestamp: "2024-03-11T00:00:00Z",
        total: "-23.983853015305545889",
        totalUsd: "-97159.19",
        consensusTotal: "-24.26735913",
        consensusTotalUsd: "-98307.68",
        executionTotal: "0.283506114694454111",
        executionTotalUsd: "1148.49",
      },
    ],
  },
  rewardsEarnedSixMonths: {
    rewards: [
      {
        timestamp: "2023-09-01T00:00:00Z",
        total: "0.542108419803220946",
        totalUsd: "2196.09",
        consensusTotal: "0.539988795",
        consensusTotalUsd: "2187.51",
        executionTotal: "0.002119624803220946",
        executionTotalUsd: "8.59",
      },
      {
        timestamp: "2023-10-01T00:00:00Z",
        total: "7.51618390955901866",
        totalUsd: "30448.25",
        consensusTotal: "7.112498727",
        consensusTotalUsd: "28812.91",
        executionTotal: "0.40368518255901866",
        executionTotalUsd: "1635.34",
      },
      {
        timestamp: "2023-11-01T00:00:00Z",
        total: "15.160039675234888648",
        totalUsd: "61413.7",
        consensusTotal: "4.895153729",
        consensusTotalUsd: "19830.39",
        executionTotal: "10.264885946234888648",
        executionTotalUsd: "41583.31",
      },
      {
        timestamp: "2023-12-01T00:00:00Z",
        total: "158.17292081374403282",
        totalUsd: "640762.49",
        consensusTotal: "39.536071472",
        consensusTotalUsd: "160161.62",
        executionTotal: "118.63684934174403282",
        executionTotalUsd: "480600.87",
      },
      {
        timestamp: "2024-01-01T00:00:00Z",
        total: "60.542346594000735787",
        totalUsd: "245258.57",
        consensusTotal: "36.663904549",
        consensusTotalUsd: "148526.4",
        executionTotal: "23.878442045000735787",
        executionTotalUsd: "96732.17",
      },
      {
        timestamp: "2024-02-01T00:00:00Z",
        total: "35.416333781197496651",
        totalUsd: "143472.46",
        consensusTotal: "32.784295865",
        consensusTotalUsd: "132810.01",
        executionTotal: "2.632037916197496651",
        executionTotalUsd: "10662.45",
      },
      {
        timestamp: "2024-03-01T00:00:00Z",
        total: "-18.326305342417949825",
        totalUsd: "-74240.33",
        consensusTotal: "-24.774016864",
        consensusTotalUsd: "-100360.17",
        executionTotal: "6.447711521582050175",
        executionTotalUsd: "26119.84",
      },
    ],
  },
  rewardsEarnedOneYear: {
    rewards: [
      {
        timestamp: "2023-03-01T00:00:00Z",
        total: "0.071028104",
        totalUsd: "287.74",
        consensusTotal: "0.071028104",
        consensusTotalUsd: "287.74",
        executionTotal: "0.0",
        executionTotalUsd: "0.0",
      },
      {
        timestamp: "2023-04-01T00:00:00Z",
        total: "0.130127354",
        totalUsd: "527.15",
        consensusTotal: "0.130127354",
        consensusTotalUsd: "527.15",
        executionTotal: "0.0",
        executionTotalUsd: "0.0",
      },
      {
        timestamp: "2023-05-01T00:00:00Z",
        total: "0.13957126",
        totalUsd: "565.41",
        consensusTotal: "0.13957126",
        consensusTotalUsd: "565.41",
        executionTotal: "0.0",
        executionTotalUsd: "0.0",
      },
      {
        timestamp: "2023-06-01T00:00:00Z",
        total: "0.773888463805990904",
        totalUsd: "3135.04",
        consensusTotal: "0.321594378",
        consensusTotalUsd: "1302.79",
        executionTotal: "0.452294085805990904",
        executionTotalUsd: "1832.25",
      },
      {
        timestamp: "2023-07-01T00:00:00Z",
        total: "0.259551846",
        totalUsd: "1051.45",
        consensusTotal: "0.259551846",
        consensusTotalUsd: "1051.45",
        executionTotal: "0.0",
        executionTotalUsd: "0.0",
      },
      {
        timestamp: "2023-08-01T00:00:00Z",
        total: "0.392042237836801111",
        totalUsd: "1588.17",
        consensusTotal: "0.35741561",
        consensusTotalUsd: "1447.9",
        executionTotal: "0.034626627836801111",
        executionTotalUsd: "140.27",
      },
      {
        timestamp: "2023-09-01T00:00:00Z",
        total: "0.542108419803220946",
        totalUsd: "2196.09",
        consensusTotal: "0.539988795",
        consensusTotalUsd: "2187.51",
        executionTotal: "0.002119624803220946",
        executionTotalUsd: "8.59",
      },
      {
        timestamp: "2023-10-01T00:00:00Z",
        total: "7.51618390955901866",
        totalUsd: "30448.25",
        consensusTotal: "7.112498727",
        consensusTotalUsd: "28812.91",
        executionTotal: "0.40368518255901866",
        executionTotalUsd: "1635.34",
      },
      {
        timestamp: "2023-11-01T00:00:00Z",
        total: "15.160039675234888648",
        totalUsd: "61413.7",
        consensusTotal: "4.895153729",
        consensusTotalUsd: "19830.39",
        executionTotal: "10.264885946234888648",
        executionTotalUsd: "41583.31",
      },
      {
        timestamp: "2023-12-01T00:00:00Z",
        total: "158.17292081374403282",
        totalUsd: "640762.49",
        consensusTotal: "39.536071472",
        consensusTotalUsd: "160161.62",
        executionTotal: "118.63684934174403282",
        executionTotalUsd: "480600.87",
      },
      {
        timestamp: "2024-01-01T00:00:00Z",
        total: "60.542346594000735787",
        totalUsd: "245258.57",
        consensusTotal: "36.663904549",
        consensusTotalUsd: "148526.4",
        executionTotal: "23.878442045000735787",
        executionTotalUsd: "96732.17",
      },
      {
        timestamp: "2024-02-01T00:00:00Z",
        total: "35.416333781197496651",
        totalUsd: "143472.46",
        consensusTotal: "32.784295865",
        consensusTotalUsd: "132810.01",
        executionTotal: "2.632037916197496651",
        executionTotalUsd: "10662.45",
      },
      {
        timestamp: "2024-03-01T00:00:00Z",
        total: "-18.326305342417949825",
        totalUsd: "-74240.33",
        consensusTotal: "-24.774016864",
        consensusTotalUsd: "-100360.17",
        executionTotal: "6.447711521582050175",
        executionTotalUsd: "26119.84",
      },
    ],
  },
};

export const mockRewardsEarnedQueryResponse: SolanaRewardsEarnedChartQuery = {
  rewardsEarnedOneMonth: rewards.rewardsEarnedOneMonth,
  rewardsEarnedOneYear: rewards.rewardsEarnedOneYear,
  rewardsEarnedSixMonths: rewards.rewardsEarnedSixMonths,
};

export const mockRewardsEarnedMajorNegativeRewardsQueryResponse: SolanaRewardsEarnedChartQuery =
  {
    rewardsEarnedOneMonth: negativeRewards.rewardsEarnedOneMonth,
    rewardsEarnedOneYear: negativeRewards.rewardsEarnedOneYear,
    rewardsEarnedSixMonths: negativeRewards.rewardsEarnedSixMonths,
  };

export const SolanaRewardsEarnedChartQueryMock =
  graphql.query<SolanaRewardsEarnedChartQuery>(
    "SolanaRewardsEarnedChart",
    (req, res, ctx) => res(ctx.data(mockRewardsEarnedQueryResponse))
  );

export const SolanaRewardsEarnedChartQueryWithMajorNegativeRewardsMock =
  graphql.query<SolanaRewardsEarnedChartQuery>(
    "SolanaRewardsEarnedChart",
    (req, res, ctx) =>
      res(ctx.data(mockRewardsEarnedMajorNegativeRewardsQueryResponse))
  );

export const SolanaRewardsEarnedChartEmptyQueryMock =
  graphql.query<SolanaRewardsEarnedChartQuery>(
    "SolanaRewardsEarnedChart",
    (req, res, ctx) =>
      res(
        ctx.data({
          rewardsEarnedOneMonth: {
            rewards: null,
          },
          rewardsEarnedOneYear: {
            rewards: null,
          },
          rewardsEarnedSixMonths: {
            rewards: null,
          },
        })
      )
  );

export const EthereumRewardsEarnedChartQueryMock =
  graphql.query<EthereumRewardsEarnedChartQuery>(
    "EthereumRewardsEarnedChart",
    (req, res, ctx) => res(ctx.data(mockRewardsEarnedQueryResponse))
  );

export const EthereumRewardsEarnedChartQueryWithMajorNegativeRewardsMock =
  graphql.query<EthereumRewardsEarnedChartQuery>(
    "EthereumRewardsEarnedChart",
    (req, res, ctx) =>
      res(ctx.data(mockRewardsEarnedMajorNegativeRewardsQueryResponse))
  );

export const EthereumRewardsEarnedChartEmptyQueryMock =
  graphql.query<EthereumRewardsEarnedChartQuery>(
    "EthereumRewardsEarnedChart",
    (req, res, ctx) =>
      res(
        ctx.data({
          rewardsEarnedOneMonth: {
            rewards: null,
          },
          rewardsEarnedOneYear: {
            rewards: null,
          },
          rewardsEarnedSixMonths: {
            rewards: null,
          },
        })
      )
  );
