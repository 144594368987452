import React from "react";
import { BodyText, Button, Heading, Icon } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { DetailType, Details } from "../../../../components";

type Props = {
  address: string;
  email?: string | null | undefined;
  onCancel: () => void;
};

export const WrongWallet: React.FC<Props> = ({ email, address, onCancel }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="relative">
        <div className="absolute -left-[3px]">
          <Icon color="error" size="sm" icon="MdCancel" />
        </div>
        <Icon color="green" size="4xl" icon="MdOutlineAccountBalanceWallet" />
      </div>
      <div className="pb-2">
        <Heading
          color="green-1000"
          level="h5"
          font={HeadingFont.space}
          weight="semibold"
        >
          Wrong Wallet
        </Heading>
      </div>
      <div className="pb-4 text-center">
        <BodyText color="green-1000">
          The connected wallet is not associated with the stake account. Connect
          the wallet below to unstake.
        </BodyText>
      </div>
      <div className="w-full">
        <Details
          details={[
            ...(email
              ? [
                  {
                    type: DetailType.DEFAULT,
                    label: "Staked by",
                    value: { text: email },
                  },
                ]
              : []),
            {
              type: DetailType.ADDRESS,
              label: "Wallet address",
              value: { text: address, addressType: "WITHDRAWAL_ADDRESS" },
            },
          ]}
          showTopBorder
        />
      </div>
      <div className="pt-8 flex justify-center w-full">
        <Button type="submit" onClick={onCancel} size="small" fullWidth>
          Close
        </Button>
      </div>
    </div>
  );
};
