import React from "react";
import { BodyText } from "@figmentjs/web-core";
import { twMerge as tw } from "tailwind-merge";

export const ActivityTableEmpty: React.FC<{ transparent?: boolean }> = ({
  transparent,
}) => {
  return (
    <div
      className={tw(
        "flex gap-4 p-4 justify-center items-center w-full rounded-md",
        transparent ? "bg-[rgba(255,255,255,.55)]" : "bg-white"
      )}
    >
      <BodyText color="black">No activity yet</BodyText>
    </div>
  );
};
