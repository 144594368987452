import React, { useEffect, useRef, useState } from "react";
import { BodyText, Button, Icon, InputText } from "@figmentjs/web-core";
import { AddressBase, AddressType } from "../address-base";
import { CopyAddressToClipboard } from "../copy-address-to-clipboard";
import { Protocol } from "@figmentjs/protocols";

type Props = {
  address?: string;
  type?: keyof typeof AddressType;
  truncateAddress: boolean;
  onSave: (address: string) => void;
  validate?: (address: string) => { isValid: boolean; message?: string };
  onEdit: (value: boolean) => void;
  protocol: Protocol;
};

export const AddressInput: React.FC<Props> = ({
  address: initialAddress,
  type = AddressType.WITHDRAWAL_ADDRESS,
  truncateAddress,
  onSave,
  validate,
  onEdit,
  protocol,
}) => {
  const [address, setAddress] = useState<string | undefined>(initialAddress);
  const [hasAddressBeenUpdated, setHasAddressBeenUpdated] =
    useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const validation = address && validate ? validate(address) : undefined;
  const isAddressValid = validation
    ? validation.isValid
    : address
    ? !!address.trim()
    : false;
  const [isEditing, setIsEditing] = useState<boolean>(!isAddressValid);
  const isTouched = address !== initialAddress && address !== "";
  const hasError = !isAddressValid && isTouched;

  useEffect(() => {
    inputRef.current?.select?.();
    onEdit(isEditing);
  }, [inputRef, isEditing, onEdit]);

  return isEditing ? (
    <div className="max-w-[420px] w-full">
      <div className="text-center mb-1">
        {hasError ? (
          <BodyText color="error" weight="semibold">
            {validation?.message || "Invalid address"}
          </BodyText>
        ) : (
          <BodyText color="black" weight="semibold">
            It&apos;s important to specify an address you control.
          </BodyText>
        )}
      </div>
      <InputText
        size="small"
        data-testid="edit-address-input-text"
        onChange={(event) => setAddress(event.currentTarget.value)}
        defaultValue={address}
        ref={inputRef}
        error={hasError ? " " : undefined}
      />
      <div className="flex justify-end mt-2">
        {initialAddress && (
          <div className="pr-2">
            <Button
              size="tiny"
              palette="tertiary"
              testId="edit-address-cancel-button"
              fullWidth
              onClick={() => {
                setAddress(initialAddress);
                setHasAddressBeenUpdated(false);
                setIsEditing(false);
              }}
            >
              <span className="px-1.5">Cancel</span>
            </Button>
          </div>
        )}
        <div>
          <Button
            size="tiny"
            testId="edit-address-save-button"
            fullWidth
            disabled={!isAddressValid}
            onClick={() => {
              setIsEditing(false);
              setHasAddressBeenUpdated(true);
              onSave(address!);
            }}
          >
            <span className="px-1.5">Save</span>
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <>
      {hasAddressBeenUpdated && (
        <div className="text-center mb-1 max-w-[420px]">
          <BodyText color="success" weight="semibold">
            Address saved!
          </BodyText>
        </div>
      )}
      <AddressBase
        address={address!}
        truncate={truncateAddress}
        protocol={protocol}
        suffix={[
          <div
            key="edit-address-edit-button"
            className="ml-1.5 hover:cursor-pointer group"
            role="button"
            data-testid="edit-address-edit-button"
            onClick={() => {
              setHasAddressBeenUpdated(false);
              setIsEditing(true);
            }}
          >
            <Icon icon="MdOutlineEdit" size="lg" />
          </div>,
          <div key="copy-address-to-clipboard" className="ml-1.5">
            <CopyAddressToClipboard address={address!} type={type} size="lg" />
          </div>,
        ]}
      />
    </>
  );
};
