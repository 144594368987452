import React, { useState } from "react";
import { BigNumber } from "bignumber.js";
import { Button } from "@figmentjs/web-core";
import { Protocol } from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import {
  WalletDetails,
  SliderInput,
  Details,
  Detail,
  Link,
} from "../../../../components";
import { BaseUnstakingFlowData } from "../../../flows/shared/types";

export type OnSubmit = (values: Partial<BaseUnstakingFlowData>) => void;

type Props = {
  isLoading: boolean;
  price?: string;
  onSubmit: OnSubmit;
  onCancel: () => void;
  flowData: BaseUnstakingFlowData;
  details: Detail[];
  maxUnstakeAmount: BigNumber;
};

export const Amount: React.FC<Props> = ({
  isLoading,
  price,
  onSubmit,
  onCancel,
  flowData,
  details,
  maxUnstakeAmount,
}) => {
  const [amount, setAmount] = useState<BigNumber>(flowData.amount);
  const { isSendingTransaction } = Wallet.useWallet();

  return (
    <>
      <WalletDetails protocol={Protocol.ETHEREUM} isUnstaking />
      <div className="p-4">
        <SliderInput
          amount={amount}
          disabled={false}
          protocol={Protocol.ETHEREUM}
          isLoading={isLoading}
          tokenPrice={price || "0"}
          onValueChange={(value) => setAmount(new BigNumber(value))}
          balance={maxUnstakeAmount}
          initialValue={flowData.amount}
          isUnstaking
        />
        <Details
          details={details}
          isLoading={isLoading}
          containerClassName="gap-4"
        />
        <div className="mt-8">
          <Link
            href="https://figment.io/insights/ethereum-withdrawals-a-comprehensive-faq/"
            text="Ethereum Withdrawals: A Comprehensive FAQ"
          />
        </div>
        <div className="mt-4">
          <Button
            disabled={!amount.toNumber()}
            onClick={() => onSubmit({ amount })}
            fullWidth
            size="small"
            loading={isSendingTransaction}
          >
            Unstake
          </Button>
        </div>
        <div className="mt-4 flex justify-center">
          <Button
            palette="text"
            size="tiny"
            textColor="text-basic-800"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};
