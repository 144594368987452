import { graphql } from "msw";
import { LsEthRedemptionsQuery } from "../../../graphql/core/generated/gql";
import { addDays } from "date-fns";

export const LsEthRedemptionsMock = graphql.query<LsEthRedemptionsQuery>(
  "LsETHRedemptions",
  (req, res, ctx) => {
    return res(
      ctx.data({
        liquidCollectiveRedeemRequests: [
          {
            claimableAmountLseth: "0",
            claimedAmountLseth: "0",
            height: "272041690083790273815",
            id: "52",
            maxRedeemableAmountEth: "124698810479447",
            network: "holesky",
            owner: "0x4351938cf093bd24bfc52c5d9ea9de020cca700a",
            requestedAt: 1761348,
            statusClaim: "NOT_CLAIMED",
            statusSatisfaction: "PENDING_SATISFACTION",
            totalAmountLseth: "123400000000000",
            withdrawalEventId: -1,
            projectedRedeemableAt: addDays(new Date(), 5).toString(),
            requestedAtTimestamp: "2024-01-29T00:00:00Z",
          },
          {
            claimableAmountLseth: "0",
            claimedAmountLseth: "0",
            height: "272041578983790273815",
            id: "51",
            maxRedeemableAmountEth: "112269350439761",
            network: "holesky",
            owner: "0x4351938cf093bd24bfc52c5d9ea9de020cca700a",
            requestedAt: 1761345,
            statusClaim: "NOT_CLAIMED",
            statusSatisfaction: "PENDING_SATISFACTION",
            totalAmountLseth: "111100000000000",
            withdrawalEventId: -1,
            projectedRedeemableAt: addDays(new Date(), 5).toString(),
            requestedAtTimestamp: "2024-01-29T00:00:00Z",
          },
          {
            claimableAmountLseth: "0",
            claimedAmountLseth: "0",
            height: "272040578983790273815",
            id: "50",
            maxRedeemableAmountEth: "1010525206478503",
            network: "holesky",
            owner: "0x4351938cf093bd24bfc52c5d9ea9de020cca700a",
            requestedAt: 1761250,
            statusClaim: "NOT_CLAIMED",
            statusSatisfaction: "PENDING_SATISFACTION",
            totalAmountLseth: "1000000000000000",
            withdrawalEventId: -1,
            projectedRedeemableAt: addDays(new Date(), 5).toString(),
            requestedAtTimestamp: "2024-01-28T00:00:00Z",
          },
          {
            claimableAmountLseth: "0",
            claimedAmountLseth: "0",
            height: "272040467983790273815",
            id: "49",
            maxRedeemableAmountEth: "112168297919113",
            network: "holesky",
            owner: "0x4351938cf093bd24bfc52c5d9ea9de020cca700a",
            requestedAt: 1761215,
            statusClaim: "NOT_CLAIMED",
            statusSatisfaction: "PENDING_SATISFACTION",
            totalAmountLseth: "111000000000000",
            withdrawalEventId: -1,
            projectedRedeemableAt: addDays(new Date(), 5).toString(),
            requestedAtTimestamp: "2024-01-27T00:00:00Z",
          },
          {
            claimableAmountLseth: "1000000000000000",
            claimedAmountLseth: "0",
            height: "272039467983790273815",
            id: "48",
            maxRedeemableAmountEth: "1010447965563915",
            network: "holesky",
            owner: "0x4351938cf093bd24bfc52c5d9ea9de020cca700a",
            requestedAt: 1741885,
            statusClaim: "NOT_CLAIMED",
            statusSatisfaction: "FULLY_SATISFIED",
            totalAmountLseth: "1000000000000000",
            withdrawalEventId: 20,
            projectedRedeemableAt: addDays(new Date(), 5).toString(),
            requestedAtTimestamp: "2024-01-27T00:00:00Z",
          },
          {
            claimableAmountLseth: "1000000000000000",
            claimedAmountLseth: "0",
            height: "272039467983790273815",
            id: "48",
            maxRedeemableAmountEth: "1010447965563915",
            network: "holesky",
            owner: "0x4351938cf093bd24bfc52c5d9ea9de020cca700a",
            requestedAt: 1741885,
            statusClaim: "FULLY_CLAIMED",
            statusSatisfaction: "FULLY_SATISFIED",
            totalAmountLseth: "1000000000000000",
            withdrawalEventId: 20,
            projectedRedeemableAt: addDays(new Date(), 5).toString(),
            requestedAtTimestamp: "2024-01-26T00:00:00Z",
          },
        ],
      })
    );
  }
);

export const LsEthRedemptionsEmptyMock = graphql.query<LsEthRedemptionsQuery>(
  "LsETHRedemptions",
  (req, res, ctx) => {
    return res(
      ctx.data({
        liquidCollectiveRedeemRequests: [],
      })
    );
  }
);
