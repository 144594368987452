import { graphql } from "msw";
import {
  RestakingFlowQuery,
  EigenpodVerificationCreateMutation,
  Networks,
} from "../../../graphql/core/generated/gql";

export const RestakingFlowQueryWithUnverifiedValidatorsMock =
  graphql.query<RestakingFlowQuery>("RestakingFlow", (req, res, ctx) => {
    return res(
      ctx.data({
        ethereumWithdrawalAccount: {
          id: "1234",
          isEigenpod: true,
          eigenpodOwnerAddress: "0x4351938cF093bd24bFC52C5D9ea9de020cca700a",
          delegatedTo: "0x9d56343e24cd60fd60b55b75b20f992cfefa2e2e",
          totalActiveValidators: 2,
          totalVerifiedValidators: 1,
        },
      })
    );
  });

export const RestakingFlowQueryWithVerifiedValidatorsMock =
  graphql.query<RestakingFlowQuery>("Invitations", (req, res, ctx) => {
    return res(
      ctx.data({
        ethereumWithdrawalAccount: {
          id: "5678",
          isEigenpod: true,
          delegatedTo: "0x9d56343e24cd60fd60b55b75b20f992cfefa2e2e",
          totalActiveValidators: 2,
          totalVerifiedValidators: 2,
        },
      })
    );
  });

export const EigenpodVerificationCreateMutationMock =
  graphql.mutation<EigenpodVerificationCreateMutation>(
    "EigenpodVerificationCreate",
    (req, res, ctx) => {
      return res(
        ctx.data({
          eigenpodVerificationCreate: {
            data: {
              eigenpodAddress: "",
              network: Networks.Holesky,
              proofs: "",
              transactionPayload:
                "0x02f902da8242680f8459682f0085025463d1c88305dc8f94a627f94a8f94e4713d38f52ac3a6377b0a111d478901bc16d674ec800000b902a44f498c730000000000000000000000000000000000000000000000000000000000000080000000000000000000000000000000000000000000000000000000000000012000000000000000000000000000000000000000000000000000000000000001a000000000000000000000000000000000000000000000000000000000000002600000000000000000000000000000000000000000000000000000000000000001000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000000000000000000308616b4eac170f6ad0a60a1c851590406589fbf4c52a5b036f54343a745cac77e70a56ee56f14a38707a386f29241863100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000000000000000000000200000000000000000000000000000000000000000000000000000000000000020010000000000000000000000f264465a0a14cc121d2cc528f4bbaebbfd157bb60000000000000000000000000000000000000000000000000000000000000001000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000000000000000000608ea6c8e76f66bb40a9a286c125c8b81397ef980821699c0486c44d4363bc5420d0a9679ad5d9347b6dfc5b470470fba702144cca4e7fdb5132a4637d9d429898a2ac3fd2563184296e1c50b5bc0436eccc2f8c3e861e7d2037b543a573cf79b80000000000000000000000000000000000000000000000000000000000000001b201f779a6c004a6df09b36a69774a13d57c36e30eba1a99a8e236af32d73fcac0",
              transactionInputs: "",
            },
            userErrors: [],
          },
        })
      );
    }
  );
