"use client";

import { useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

export const NONCE_STORAGE_KEY = "encryptedNonce";

export const useNonce = () => {
  const [isClient, setIsClient] = useState(false);

  // https://nextjs.org/docs/messages/react-hydration-error#solution-1-using-useeffect-to-run-on-the-client-only
  // We need to make sure that we're "on the client" before we use `useNonce`, we get hydration errors otherwise.
  useEffect(() => {
    setIsClient(true);
  }, []);

  const [nonce, setNonce] = useLocalStorage<string | undefined>(
    NONCE_STORAGE_KEY,
    undefined
  );

  return {
    nonce: isClient ? nonce : undefined,
    setNonce,
  };
};
