const config = require("./tailwind.core.config.js");

const PACKAGES_DIR = `${__dirname}/../../..`;
const APP_DIR = process.cwd();

/** Tailwind content files across all apps:
 * - `PACKAGES_DIR` targets the /packages directory
 * - `APP_DIR` targets each app dynamically (by calling `process.cwd()`
 *   within the tailwind.config file for the current app)
 */

const content = [
  `${PACKAGES_DIR}/web-core/src/**/*.{js,jsx,ts,tsx}`,
  `${PACKAGES_DIR}/app-layout/src/**/*.{js,ts,jsx,tsx}`,
  `${PACKAGES_DIR}/auth0-provider/src/**/*.{js,ts,jsx,tsx}`,
  `${PACKAGES_DIR}/staking-components/src/**/*.{js,ts,jsx,tsx}`,
  `${PACKAGES_DIR}/figment-elements/src/**/*.{js,ts,jsx,tsx}`,
  `${APP_DIR}/app/**/*.{js,ts,jsx,tsx}`,
  `${APP_DIR}/components/**/*.{js,ts,jsx,tsx}`,
  `${APP_DIR}/modules/**/*.{js,ts,jsx,tsx}`,
  `${APP_DIR}/pages/**/*.{js,ts,jsx,tsx}`,
  `${APP_DIR}/utils/**/*.{js,ts,jsx,tsx}`,
  `!${APP_DIR}/node_modules`,
  `!${APP_DIR}/next`,
];

module.exports = {
  ...config,
  content,
};
