import React from "react";
import { BodyText, Icon, Heading, Button } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";

type Props = {
  onRebroadcast: () => void;
  isLoading: boolean;
};

export const RebroadcastTransaction: React.FC<Props> = ({
  onRebroadcast,
  isLoading,
}) => {
  return (
    <div className="p-4">
      <div className="flex flex-col items-center space-y-2 text-center">
        <Icon icon="MdError" size="4xl" color="pending" />
        <Heading
          level="h5"
          color="green"
          font={HeadingFont.space}
          weight="bold"
        >
          Transaction Timeout
        </Heading>
      </div>
      <div className="pt-8 pb-4 text-center">
        <BodyText>
          The transaction was not signed in time and is stale. Try signing
          again.
        </BodyText>
      </div>
      <div className="flex justify-center w-full max-w-sm">
        <Button
          size="small"
          onClick={onRebroadcast}
          fullWidth
          loading={isLoading}
        >
          Try Again
        </Button>
      </div>
    </div>
  );
};
