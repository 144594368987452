import { rest } from "msw";

export const SignInWithBitcoinMutationMock = rest.post(
  "/api/sign-in-with-bitcoin",
  async (req, res, ctx) => {
    return res(
      ctx.json({
        accessToken: "accessToken",
      })
    );
  }
);
