import { ToastProps as RadixPopoverProps } from "@radix-ui/react-toast";
import { OverlayPalette } from "../shared/Overlay.props";

export enum ToastPlacement {
  TOP_LEFT = "TOP_LEFT",
  BOTTOM_LEFT = "BOTTOM_LEFT",
  TOP_RIGHT = "TOP_RIGHT",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
  TOP_CENTER = "TOP_CENTER",
  BOTTOM_CENTER = "BOTTOM_CENTER",
}

interface LinkAction {
  title: string | React.ReactNode;
  href: string;
}

interface ButtonAction {
  title: string;
  onAction: () => void;
}

export type ActionConfig = LinkAction | ButtonAction;

export enum ToastSize {
  medium = "medium",
  small = "small",
}

export type ToastProps = RadixPopoverProps & {
  /**
   * Unique identifier automatically generated when a toast is created
   *
   */
  id?: string;
  /**
   * The toast's color palette (e.g. success, info, warning)
   * Defaults to `success`.
   */
  palette?: keyof typeof OverlayPalette;
  /**
   * The toast's size (e.g. medium, small)
   * Defaults to `medium`.
   */
  size?: keyof typeof ToastSize;
  /**
   * The toast's text content.
   */
  message: string;
  /**
   * The duration in milliseconds that the toast will be visible for
   */
  duration?: number;
  /**
   * Add a link next to the banner's title. Provide an `href` for a link or an `onAction` function for a button.
   */
  action?: ActionConfig;
  /**
   * Render an icon within the toast, before the toast text.
   */
  noIcon?: boolean;
  /**
   * Hide's the close (X) button. False by default.
   */
  hideDismiss?: boolean;
};
