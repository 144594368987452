import React, { memo } from "react";
import { twMerge as tw } from "tailwind-merge";

import { DropdownItemProps } from "./Dropdown.types";

const DropdownItem: React.FC<DropdownItemProps> = ({ item, small }) => {
  const defaultStyles =
    "font-semibold text-green flex hover:bg-green cursor-pointer hover:text-white";
  const sizeStyles = small
    ? "text-sm py-1 pl-4 pr-10"
    : "text-base py-2 pl-5 pr-16";
  const destructiveStyles =
    item.destructive && "text-error hover:bg-error hover:text-white";
  const allStyles = tw(
    defaultStyles,
    sizeStyles,
    destructiveStyles,
    "w-full align-text-left"
  );

  const { onAction, content } = item;

  return typeof content === "string" ? (
    <button onClick={onAction} className={allStyles}>
      {content}
    </button>
  ) : (
    <div className={allStyles}>{content}</div>
  );
};

export default memo(DropdownItem);
