import React, { memo, useCallback, useEffect, useState } from "react";
import { FigAppActionEnum } from "@figmentjs/analytics/client";
import Wallet from "@figmentjs/wallet";
import { Spinner } from "@figmentjs/web-core";
import { ErrorStep, UnsupportedNetwork } from "../../../../../components";
import {
  VerifyRestakedValidators,
  DelegateEigenpodToOperator,
  Success,
  WalletIsNotEigenPodOwner,
} from "../../../../steps/ethereum";
import { useFinishRestakingFlowData } from "../use-flow-data";
import { RestakingFlowStep } from "./restaking-flow.types";

type Props = {
  onWalletDisconnected: () => void;
  onDone: () => void;
  onViewPosition: () => void;
  onSetIsCloseableStep: (isCloseableStep: boolean) => void;
  onTrackEvent: ({
    action,
    step,
  }: {
    action: FigAppActionEnum;
    step?: string;
  }) => void;
};

const RestakingFlow: React.FC<Props> = ({
  onWalletDisconnected,
  onDone,
  onViewPosition,
  onSetIsCloseableStep,
  onTrackEvent,
}) => {
  const {
    eigenPodAddress,
    eigenpodOwnerAddress,
    step,
    hasOperator,
    setRestakingFlowStep,
    isEigenPodVerificationLoading,
    eigenPodVerificationPayload,
    isEigenlayerDelegationLoading,
    eigenlayerDelegationPayload,
    error,
  } = useFinishRestakingFlowData();

  const { network, account } = Wallet.useWallet();
  const [lastAccount, setLastAccount] = useState(account);

  const handleSetStep = useCallback(
    (step: RestakingFlowStep) => {
      setRestakingFlowStep(step);
      onSetIsCloseableStep(true);
    },
    [onSetIsCloseableStep, setRestakingFlowStep]
  );

  useEffect(() => {
    const isAccountDisconnected = !account;
    const isUnsupportedNetwork = account && !network;

    if (isAccountDisconnected || (lastAccount && lastAccount !== account)) {
      onWalletDisconnected();
    } else if (isUnsupportedNetwork) {
      handleSetStep(RestakingFlowStep.UNSUPPORTED_CHAIN);
    }

    onTrackEvent({
      action: FigAppActionEnum.STEP_RENDERED,
      step: step,
    });
  }, [
    account,
    handleSetStep,
    lastAccount,
    network,
    onTrackEvent,
    onWalletDisconnected,
    step,
  ]);

  useEffect(() => {
    if (lastAccount !== account) {
      setLastAccount(account);
    }
  }, [lastAccount, account]);

  return (
    <div>
      {((): React.ReactNode => {
        switch (step) {
          case RestakingFlowStep.INITIALIZING:
            return (
              <div className="py-24 flex justify-center items-center">
                <Spinner />
              </div>
            );

          case RestakingFlowStep.VERIFY_STAKED_ETH:
            return (
              <VerifyRestakedValidators
                eigenPodAddress={eigenPodAddress}
                isEigenPodVerificationLoading={isEigenPodVerificationLoading}
                eigenPodVerificationPayload={eigenPodVerificationPayload}
                onSetIsCloseableStep={onSetIsCloseableStep}
                onVerifySuccess={() => {
                  if (hasOperator) {
                    handleSetStep(RestakingFlowStep.SUCCESS);
                  } else {
                    handleSetStep(RestakingFlowStep.DELEGATE_TO_OPERATOR);
                  }
                }}
                onCancel={onDone}
                onTrackEvent={onTrackEvent}
              />
            );

          case RestakingFlowStep.DELEGATE_TO_OPERATOR: {
            return (
              <DelegateEigenpodToOperator
                isEigenlayerDelegationLoading={isEigenlayerDelegationLoading}
                eigenlayerDelegationPayload={eigenlayerDelegationPayload}
                onSetIsCloseableStep={onSetIsCloseableStep}
                onDelegateSuccess={() => {
                  handleSetStep(RestakingFlowStep.SUCCESS);
                }}
                onCancel={onDone}
                onTrackEvent={onTrackEvent}
              />
            );
          }

          case RestakingFlowStep.SUCCESS:
            return (
              <Success
                eigenpodAddress={eigenPodAddress}
                onViewPosition={onViewPosition}
              />
            );

          case RestakingFlowStep.UNSUPPORTED_CHAIN: {
            return (
              <UnsupportedNetwork
                onNetworkChange={() =>
                  setRestakingFlowStep(RestakingFlowStep.INITIALIZING)
                }
              />
            );
          }

          case RestakingFlowStep.WALLET_IS_NOT_EIGENPOD_OWNER: {
            return (
              <WalletIsNotEigenPodOwner
                eigenpodOwnerAddress={eigenpodOwnerAddress}
                onClose={onDone}
              />
            );
          }

          case RestakingFlowStep.ERROR: {
            return <ErrorStep error={error!} onClose={onDone} />;
          }

          default:
            return null;
        }
      })()}
    </div>
  );
};

export default memo(RestakingFlow);
