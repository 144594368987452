import React from "react";
import { BodyText, Heading, Button, InputCheckbox } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { Protocol } from "@figmentjs/protocols";
import Wallet, { isAddress } from "@figmentjs/wallet";
import { Link, WalletDetails, AddressInput } from "../../../../components";
import { EthereumStakingFlowData } from "../../../flows/ethereum/siwe/use-flow-data/use-flow-data";

type Props = {
  flowData: EthereumStakingFlowData;
  onBack: () => void;
  onNext: (values: Partial<EthereumStakingFlowData>) => void;
};

export const ExecutionRewardsAddress: React.FC<Props> = ({
  flowData,
  onBack,
  onNext,
}) => {
  const { account } = Wallet.useWallet();

  const initialAddress = flowData.executionRewardsAddress
    ? flowData.executionRewardsAddress
    : Number(account)
    ? account
    : null;
  const [address, setAddress] = React.useState(initialAddress);
  const [isEditing, setIsEditing] = React.useState(
    initialAddress ? false : true
  );
  const [isAddressConfirmed, setIsAddressConfirmed] = React.useState(false);

  return (
    <>
      <WalletDetails protocol={Protocol.ETHEREUM} />
      <div className="p-4 flex flex-col items-center">
        <div className="space-y-1 text-center mb-4">
          <Heading level="h5" font={HeadingFont.space} weight="semibold">
            Execution Layer Rewards Address
          </Heading>
          <BodyText as="p">
            This address will receive the transaction fees, block rewards, and
            MEV (Maximal Extractable Value) rewards. This can be different from
            the funding address and withdrawal address. By default, we use your
            connected wallet.
          </BodyText>
        </div>
        <AddressInput
          address={address || ""}
          onSave={(newAddress) => setAddress(newAddress)}
          validate={(newAddress) => {
            const isValid = isAddress(newAddress);

            if (!isValid) {
              return { isValid: false };
            }

            if (
              flowData.isRestakingEnabled &&
              flowData.eigenPodAddress === newAddress
            ) {
              return {
                isValid: false,
                message:
                  "This address can't be the same as your EigenPod address",
              };
            }

            return { isValid: true };
          }}
          onEdit={setIsEditing}
          type="EXECUTION_REWARDS_ADDRESS"
          truncateAddress={true}
          protocol={Protocol.ETHEREUM}
        />
        {address !== initialAddress ? (
          <div className="my-4">
            <InputCheckbox
              value={isAddressConfirmed}
              onChange={({ value }) => setIsAddressConfirmed(value)}
            >
              <BodyText size="sm">
                The above address is accurate and controlled by me or my
                organization
              </BodyText>
            </InputCheckbox>
          </div>
        ) : (
          <div className="mt-4">
            <Link
              href="https://figment.io/insights/ethereum-understanding-post-merge-rewards/"
              text="Understanding Post-Merge Rewards"
            />
          </div>
        )}
        <div className="flex justify-evenly gap-2 w-full">
          <Button palette="tertiary" size="small" fullWidth onClick={onBack}>
            Back
          </Button>
          <Button
            size="small"
            fullWidth
            onClick={() => onNext({ executionRewardsAddress: address! })}
            disabled={
              isEditing || (address !== initialAddress && !isAddressConfirmed)
            }
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};
