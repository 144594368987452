import React, { useEffect, useCallback } from "react";
import BigNumber from "bignumber.js";
import { BabylonNetwork, Protocol } from "@figmentjs/protocols";
import {
  CurrencyTickers,
  useBabylonNetworkOverviewQuery,
  useProtocolPriceQuery,
} from "../../../../../../graphql/core/generated/gql";
import Wallet from "@figmentjs/wallet";
import {
  BabylonSuccess,
  BabylonAmount,
  BabylonStakingSummary,
  VerifyOwnership,
} from "../../../../steps/babylon";
import { useStepper } from "../../../../../hooks/use-stepper";
import { useBabylonFlowData, babylonInitialValues } from "../use-flow-data";
import { BabylonFlowStep } from "./flow.types";
import {
  UniversalWidgetActionEnum as Actions,
  UniversalFlowTrackEventProperties,
  UniversalWidgetObjectEnum,
} from "@figmentjs/analytics/client";
import { useSegment } from "../../../../../hooks/use-segment/use-segment";
import { networkMap } from "../../../../steps/babylon/babylon-staking-summary/babylon-staking-summary";

type Props = {
  isStakeDisabled?: boolean;
  isTestnetMode?: boolean;
};

export const Flow: React.FC<Props> = ({ isStakeDisabled, isTestnetMode }) => {
  const { step, setStep } = useStepper<BabylonFlowStep>(BabylonFlowStep.AMOUNT);
  const { flowData, updateFlowData } = useBabylonFlowData();
  const { network, setTransactionHash, walletName } =
    Wallet.useWallet<Protocol.BABYLON>();
  const { data } = useProtocolPriceQuery({ ticker: CurrencyTickers.Btc });
  const {
    data: babylonNetworkOverviewData,
    isLoading: isBabylonNetworkOverviewLoading,
  } = useBabylonNetworkOverviewQuery(
    { network: networkMap[network!]! },
    { enabled: !!network }
  );
  const price = (data?.protocolPrice.value || 0).toString();
  const { trackEvent } = useSegment();
  const trackStakingFlowEvent = useCallback(
    (action: Actions, properties: UniversalFlowTrackEventProperties) => {
      trackEvent(
        { object: UniversalWidgetObjectEnum.STAKING_FLOW, action },
        {
          amountToStake: flowData.amount.toNumber(),
          protocol: Protocol.BABYLON,
          network,
          walletName,
          ...properties,
        }
      );
    },
    [flowData.amount, network, trackEvent, walletName]
  );
  useEffect(() => {
    trackStakingFlowEvent(Actions.STEP_RENDERED, { step });
  }, [step, trackStakingFlowEvent]);

  const reset = () => {
    updateFlowData(babylonInitialValues);
    setStep(BabylonFlowStep.AMOUNT);
    setTransactionHash();
  };

  return (
    <div className="rounded-md bg-white overflow-hidden shadow-3xl">
      {((): React.ReactNode => {
        switch (step) {
          case BabylonFlowStep.AMOUNT:
            return (
              <BabylonAmount
                onSubmit={(values) => {
                  updateFlowData(values);
                  setStep(BabylonFlowStep.VERIFY_OWNERSHIP);
                }}
                flowData={flowData}
                isLoading={isBabylonNetworkOverviewLoading}
                estimatedFee="0.00006"
                commission={5}
                price={price}
                isStakeDisabled={
                  isStakeDisabled ||
                  (isTestnetMode && network === BabylonNetwork.MAINNET)
                }
                data={babylonNetworkOverviewData}
              />
            );
          case BabylonFlowStep.VERIFY_OWNERSHIP:
            return (
              <VerifyOwnership
                protocol={Protocol.BABYLON}
                onBack={() => setStep(BabylonFlowStep.AMOUNT)}
                onNext={() => {
                  setStep(BabylonFlowStep.SUMMARY);
                }}
              />
            );
          // Need this comment to avoid lint error: Expected a 'break' statement before 'case' no-fallthrough
          /* falls through */
          case BabylonFlowStep.SUMMARY:
            return (
              <BabylonStakingSummary
                amount={flowData.amount}
                usdAmount={BigNumber(flowData.amount || 0)
                  .times(price)
                  .toFormat(2)
                  .toString()}
                onBack={() => setStep(BabylonFlowStep.AMOUNT)}
                onConfirm={(values) => {
                  updateFlowData(values);
                  setStep(BabylonFlowStep.SUCCESS);
                }}
              />
            );
          case BabylonFlowStep.SUCCESS:
            return (
              <BabylonSuccess
                amount={flowData.amount}
                onDone={reset}
                transactionHash={flowData.transactionHash}
                buttonText="Stake More"
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};
