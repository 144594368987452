import React, { useCallback } from "react";
import BigNumber from "bignumber.js";
import { BaseUnstakingFlowData } from "../../../shared/types";

export const initialValues: BaseUnstakingFlowData = {
  amount: new BigNumber(32),
  errors: [],
};

type UpdateFlowData = (newFlowData: Partial<BaseUnstakingFlowData>) => void;

export const useFlowData = (initialFlowData = initialValues) => {
  const [flowData, setFlowData] =
    React.useState<BaseUnstakingFlowData>(initialFlowData);

  const updateFlowData: UpdateFlowData = useCallback(
    (newFlowData) => {
      setFlowData({ ...flowData, ...newFlowData });
    },
    [flowData]
  );

  return { flowData, updateFlowData };
};
