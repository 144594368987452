"use client";

import * as React from "react";
import { RestakingFlowStep } from "../flow/restaking-flow.types";
import { useRollbar } from "@figmentjs/rollbar-client";
import {
  useRestakingFlowQuery,
  MutationError,
  useEigenpodVerificationCreateMutation,
  useEigenlayerDelegateToMutation,
  EthereumNetworks,
  RestakingFlowEthereumWithdrawalAccountFragment,
} from "../../../../../../graphql/core/generated/gql";

type UseFinishRestaking = {
  ownerAddress: `0x${string}`;
  eigenPodAddress: `0x${string}`;
  eigenpodOwnerAddress: `0x${string}`;
  step: RestakingFlowStep;
  setRestakingFlowStep: (flowStep: RestakingFlowStep) => void;
  hasOperator: boolean;
  isEigenPodVerificationLoading: boolean;
  eigenPodVerificationPayload?: `0x${string}`;
  isEigenlayerDelegationLoading: boolean;
  eigenlayerDelegationPayload?: `0x${string}`;
  error?: MutationError;
};

const defaultValues: UseFinishRestaking = {
  step: RestakingFlowStep.INITIALIZING,
  setRestakingFlowStep: () => {
    throw new Error("setRestakingFlowStep not implemented");
  },
  ownerAddress: "" as `0x${string}`,
  eigenPodAddress: "" as `0x${string}`,
  eigenpodOwnerAddress: "" as `0x${string}`,
  hasOperator: false,
  isEigenPodVerificationLoading: false,
  isEigenlayerDelegationLoading: false,
};

const UseFinishRestakingContext =
  React.createContext<UseFinishRestaking>(defaultValues);

const hasUnverifiedValidators = (
  account: RestakingFlowEthereumWithdrawalAccountFragment
) =>
  (account?.totalVerifiedValidators || 0) <
  (account?.totalActiveValidators || 0);

const figmentOperator: { [key in EthereumNetworks]: `0x${string}` } = {
  [EthereumNetworks.Holesky]: "0x9d56343e24cd60fd60b55b75b20f992cfefa2e2e",
  [EthereumNetworks.Mainnet]: "0x39beca54f450fcc4b31729a85b3e9655ec0081d6",
};

export const FinishRestakingFlowProvider: React.FC<{
  ownerAddress: `0x${string}`;
  eigenPodAddress: `0x${string}`;
  network: keyof typeof EthereumNetworks;
  children: React.ReactNode;
}> = ({ ownerAddress, eigenPodAddress, network, children }) => {
  const [step, setRestakingFlowStep] = React.useState<RestakingFlowStep>(
    RestakingFlowStep.INITIALIZING
  );
  const [error, setError] = React.useState<MutationError>();
  const rollbar = useRollbar();
  const {
    mutateAsync: createEigenPodVerificationMutation,
    data: createEigenPodVerificationData,
    isLoading: createEigenPodVerificationLoading,
  } = useEigenpodVerificationCreateMutation();
  const {
    mutateAsync: createDelegateToMutation,
    data: createDelegateToData,
    isLoading: createDelegateToLoading,
  } = useEigenlayerDelegateToMutation();
  const { data: restakingFlowData } = useRestakingFlowQuery(
    {
      network: EthereumNetworks[network],
      address: eigenPodAddress,
    },
    {
      onSuccess: (data) => {
        const account = data.ethereumWithdrawalAccount;

        if (
          account?.eigenpodOwnerAddress?.toLowerCase() !==
          ownerAddress.toLowerCase()
        ) {
          setRestakingFlowStep(RestakingFlowStep.WALLET_IS_NOT_EIGENPOD_OWNER);
        } else if (hasUnverifiedValidators(account!)) {
          setRestakingFlowStep(RestakingFlowStep.VERIFY_STAKED_ETH);
        } else if (!account?.delegatedTo) {
          setRestakingFlowStep(RestakingFlowStep.DELEGATE_TO_OPERATOR);
        } else {
          setRestakingFlowStep(RestakingFlowStep.SUCCESS);
        }
      },
    }
  );

  React.useEffect(() => {
    if (step === RestakingFlowStep.VERIFY_STAKED_ETH) {
      if (
        createEigenPodVerificationLoading ||
        createEigenPodVerificationData?.eigenpodVerificationCreate
      )
        return;

      createEigenPodVerificationMutation(
        {
          ownerAddress,
          network: EthereumNetworks[network],
        },
        {
          onSuccess: (data) => {
            const errors = data.eigenpodVerificationCreate.userErrors?.length
              ? data.eigenpodVerificationCreate.userErrors
              : null;
            if (errors) {
              rollbar.error(JSON.stringify(errors));
              setError(errors[0]);
              setRestakingFlowStep(RestakingFlowStep.ERROR);
            }
          },
          onError: (error) => {
            rollbar.error((error as Error).toString());
            setError({ code: "UNKNOWN", message: "An error has occurred" });
            setRestakingFlowStep(RestakingFlowStep.ERROR);
          },
        }
      );
    } else if (step === RestakingFlowStep.DELEGATE_TO_OPERATOR) {
      if (createDelegateToLoading || createDelegateToData?.eigenlayerDelegateTo)
        return;
      createDelegateToMutation(
        {
          ownerAddress,
          operatorAddress: figmentOperator[EthereumNetworks[network]],
          network: EthereumNetworks[network],
        },
        {
          onSuccess: (data) => {
            const errors = data.eigenlayerDelegateTo.userErrors?.length
              ? data.eigenlayerDelegateTo.userErrors
              : null;
            if (errors) {
              rollbar.error(JSON.stringify(errors));
              setError(errors[0]);
              setRestakingFlowStep(RestakingFlowStep.ERROR);
            }
          },
          onError: (error) => {
            rollbar.error((error as Error).toString());
            setError({ code: "UNKNOWN", message: "An error has occurred" });
            setRestakingFlowStep(RestakingFlowStep.ERROR);
          },
        }
      );
    }
  }, [
    createDelegateToData?.eigenlayerDelegateTo,
    createDelegateToLoading,
    createDelegateToMutation,
    createEigenPodVerificationData?.eigenpodVerificationCreate,
    createEigenPodVerificationLoading,
    createEigenPodVerificationMutation,
    eigenPodAddress,
    network,
    ownerAddress,
    rollbar,
    step,
  ]);

  const account = restakingFlowData?.ethereumWithdrawalAccount;

  return (
    <UseFinishRestakingContext.Provider
      value={{
        step,
        setRestakingFlowStep,
        eigenPodAddress,
        ownerAddress,
        eigenpodOwnerAddress: account?.eigenpodOwnerAddress as `0x${string}`,
        hasOperator: !!account?.delegatedTo,
        isEigenPodVerificationLoading: createEigenPodVerificationLoading,
        eigenPodVerificationPayload: createEigenPodVerificationData
          ?.eigenpodVerificationCreate.data?.transactionPayload
          ? (createEigenPodVerificationData.eigenpodVerificationCreate.data
              .transactionPayload as `0x${string}`)
          : undefined,
        error,
        isEigenlayerDelegationLoading: createDelegateToLoading,
        eigenlayerDelegationPayload: createDelegateToData?.eigenlayerDelegateTo
          .data?.txSigningPayload
          ? (createDelegateToData.eigenlayerDelegateTo.data
              .unsignedRawTx as `0x${string}`)
          : undefined,
      }}
    >
      {children}
    </UseFinishRestakingContext.Provider>
  );
};

export const useFinishRestakingFlowData = () => {
  const context = React.useContext(UseFinishRestakingContext);

  return context;
};
