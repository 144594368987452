import React, { useState } from "react";
import { BodyText, Icon, Heading, Button } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";

type Props = {
  onClose: () => void;
  error: { code?: string; message: string; isWalletError?: boolean };
  buttonText?: string;
};

const transactionErrorMessages = {
  TX_TIMEOUT:
    "The wallet reported an error when signing the transaction, potentially due to a timeout.",
  TX_NOT_SUCCESSFUL: "There was an error broadcasting the transaction.",
};

export const ErrorStep: React.FC<Props> = ({ onClose, error, buttonText }) => {
  const [isErrorOpen, setIsErrorOpen] = useState(false);

  const transactionErrorMessage =
    transactionErrorMessages[
      error.message as keyof typeof transactionErrorMessages
    ];

  return (
    <>
      <div className="flex items-center space-x-2 justify-center text-center pb-4">
        <Icon icon="MdCancel" size="4xl" color="error" />
        <Heading
          level="h5"
          color="green"
          font={HeadingFont.space}
          weight="bold"
        >
          Something Went Wrong
        </Heading>
      </div>
      <div className="pt-4 flex justify-center">
        {error.isWalletError && !transactionErrorMessage ? (
          isErrorOpen ? (
            <div className="px-2 py-1 bg-error-200 rounded max-h-60 overflow-y-auto">
              <BodyText font="mono" color="error" size="sm">
                {error.message}
              </BodyText>
            </div>
          ) : (
            <Button
              palette="text"
              size="tiny"
              onClick={() => setIsErrorOpen(true)}
            >
              Error details
            </Button>
          )
        ) : (
          <div className="[&>span]:break-all">
            <BodyText font="mono" color="error" size="sm">
              {transactionErrorMessage || error.message}
            </BodyText>
          </div>
        )}
      </div>

      <div className="mt-4 py-4 border-t border-t-green-100">
        <BodyText weight="semibold">Need Help?</BodyText>
        <div className="pt-1">
          <BodyText size="sm">
            Please contact us at{" "}
            <a
              href="mailto:support@figment.io"
              className="text-green-800 no-underline hover:underline"
            >
              support@figment.io
            </a>
            .
          </BodyText>
        </div>
      </div>

      <div className="pt-4 w-full">
        <Button size="small" fullWidth onClick={onClose}>
          {buttonText || "Close"}
        </Button>
      </div>
    </>
  );
};
