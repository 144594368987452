import { graphql } from "msw";
import {
  LiquidCollectiveRedeemManagerProjectionQuery,
  LiquidCollectiveRedeemRequestCreateMutation,
} from "../../../graphql/core/generated/gql";
import { addDays } from "@figmentjs/utils/src/formatters/date";

export const LiquidCollectiveRedeemManagerProjectionQueryMock =
  graphql.query<LiquidCollectiveRedeemManagerProjectionQuery>(
    "LiquidCollectiveRedeemManagerProjection",
    (req, res, ctx) => {
      return res(
        ctx.data({
          liquidCollectiveRedeemManagerProjection: {
            projectedFulfilledAt: addDays(new Date(), 5).toISOString(),
          },
        })
      );
    }
  );

export const LiquidCollectiveRedeemRequestCreateMock =
  graphql.mutation<LiquidCollectiveRedeemRequestCreateMutation>(
    "LiquidCollectiveRedeemRequestCreate",
    (req, res, ctx) => {
      return res(
        ctx.data({
          liquidCollectiveRedeemRequestCreate: {
            data: {
              signingPayload:
                "0x3a4f4930bb7d744b967e1ac73127c402347258554566d7f43da4e35ffacd85b9",
              unsignedTransactionSerialized:
                "0x02f86f824268718459682f0084d7545eac830493e0941d8b30cc38dba8abce1ac29ea27d9cfd05379a0980b844107703ab00000000000000000000000000000000000000000000000000038d7ea4c680000000000000000000000000004351938cf093bd24bfc52c5d9ea9de020cca700ac0",
            },
          },
        })
      );
    }
  );
