import React, { useEffect, useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Network, Protocol, protocols } from "@figmentjs/protocols";
import {
  useEthereumStakingFlowQuery,
  useUnstakeEthereumMutation,
  useInfiniteEthereumActivityTableStakingActivityQuery,
  useStakingPositionQuery,
  EthereumNetworks,
} from "../../../../../../graphql/core/generated/gql";
import { Amount, Success } from "../../../../steps/ethereum";
import { Summary } from "../../../../steps/shared/summary";
import { useStepper } from "../../../../../hooks/use-stepper";
import { useFlowData, initialValues } from "../use-flow-data";
import { EthereumFlowStep } from "./flow.types";
import {
  UniversalWidgetActionEnum as Actions,
  UniversalFlowTrackEventProperties,
  UniversalWidgetObjectEnum,
} from "@figmentjs/analytics/client";
import { useSegment } from "../../../../../hooks/use-segment/use-segment";
import BigNumber from "bignumber.js";
import { DetailType, ErrorStep } from "../../../../../components";
import { friendlyDateFromHours } from "@figmentjs/utils/src/formatters/date";
import { getCoreAPINetwork } from "../../../../../utils";

type Props = {
  onCancel: () => void;
  onSuccessButtonClick: () => void;
  account: string;
  network: Network;
};

export const Flow: React.FC<Props> = ({
  account,
  network,
  onCancel,
  onSuccessButtonClick,
}) => {
  const { step, setStep } = useStepper<EthereumFlowStep>(
    EthereumFlowStep.AMOUNT
  );
  const { flowData, updateFlowData } = useFlowData();

  const queryClient = useQueryClient();
  const { data, isLoading } = useEthereumStakingFlowQuery();
  const { data: stakingPositionData, isLoading: isStakingPositionDataLoading } =
    useStakingPositionQuery(
      {
        ethAccount: account!,
        ethereumRewardsNetwork: network!,
        ethereumBalanceNetwork:
          network === Network.HOLESKY
            ? EthereumNetworks.Holesky
            : EthereumNetworks.Mainnet,
      },
      {
        enabled: !!(account && network),
      }
    );
  const { mutateAsync: unstakeEthereum, isLoading: isUnstakeLoading } =
    useUnstakeEthereumMutation({
      onSuccess(data) {
        if (
          data.ethereumExitRequestCreate?.userErrors &&
          data.ethereumExitRequestCreate.userErrors.length > 0
        ) {
          updateFlowData({ errors: data.ethereumExitRequestCreate.userErrors });
          setStep(EthereumFlowStep.ERROR);
        } else {
          queryClient.resetQueries({
            queryKey:
              useInfiniteEthereumActivityTableStakingActivityQuery.getKey(),
            exact: false,
          });
          queryClient.invalidateQueries(
            useStakingPositionQuery.getKey({
              ethAccount: account!,
              ethereumRewardsNetwork: network!,
              ethereumBalanceNetwork:
                network === Network.HOLESKY
                  ? EthereumNetworks.Holesky
                  : EthereumNetworks.Mainnet,
            })
          );

          setStep(EthereumFlowStep.SUCCESS);
        }
      },
    });

  const { trackEvent } = useSegment();
  const trackStakingFlowEvent = useCallback(
    (action: Actions, properties: UniversalFlowTrackEventProperties) => {
      trackEvent(
        { object: UniversalWidgetObjectEnum.UNSTAKING_FLOW, action },
        {
          amountToUnstake: flowData.amount.toNumber(),
          protocol: Protocol.ETHEREUM,
          network,
          ...properties,
        }
      );
    },
    [flowData.amount, network, trackEvent]
  );
  useEffect(() => {
    trackStakingFlowEvent(Actions.STEP_RENDERED, { step });
  }, [step, trackStakingFlowEvent]);

  const reset = () => {
    updateFlowData(initialValues);
    setStep(EthereumFlowStep.AMOUNT);
  };

  const maxUnstakeAmount = new BigNumber(
    stakingPositionData?.validatorCounts.exitable || 0
  ).times(32);

  const minExitTime = friendlyDateFromHours(
    data?.ethereumNetworkEstimates.estimatedWithdrawal?.hoursMin
  );
  const maxExitTime = friendlyDateFromHours(
    data?.ethereumNetworkEstimates.estimatedWithdrawal?.hoursMax
  );
  const isDifferingExitTimeUnits = !maxExitTime.unit.includes(minExitTime.unit);

  return (
    <div className="rounded-md bg-white overflow-hidden shadow-3xl">
      {((): React.ReactNode => {
        switch (step) {
          case EthereumFlowStep.AMOUNT:
            return (
              <Amount
                isLoading={isLoading || isStakingPositionDataLoading}
                price={data?.ethereumPrice.value?.toString() || "0"}
                onSubmit={(values) => {
                  updateFlowData(values);
                  setStep(EthereumFlowStep.SUMMARY);
                }}
                onCancel={onCancel}
                flowData={flowData}
                maxUnstakeAmount={maxUnstakeAmount}
                details={[
                  {
                    type: DetailType.TOKEN,
                    label: "Staked Balance",
                    value: {
                      tokenDetails: {
                        amount: maxUnstakeAmount.toString(),
                        ticker:
                          protocols[Protocol.ETHEREUM].networks[network!]
                            .ticker,
                        usdAmount: maxUnstakeAmount
                          .times(data?.ethereumPrice.value || "0")
                          .toFormat(2)
                          .toString(),
                      },
                    },
                  },
                  {
                    type: DetailType.DEFAULT,
                    label: "Unstaking Period",
                    value: {
                      text: `${minExitTime.time}${
                        isDifferingExitTimeUnits ? " " + minExitTime.unit : ""
                      } to ${maxExitTime.time}${
                        isDifferingExitTimeUnits ? " " + maxExitTime.unit : ""
                      }`,
                      appendedText: isDifferingExitTimeUnits
                        ? ""
                        : maxExitTime.unit,
                    },
                  },
                  {
                    type: DetailType.ADDRESS,
                    label: "Withdrawal Address",
                    value: {
                      text: account,
                    },
                  },
                ]}
              />
            );
          case EthereumFlowStep.SUMMARY:
            return (
              <Summary
                onConfirmUnstake={() =>
                  unstakeEthereum({
                    amountEth: flowData.amount.toNumber(),
                    withdrawalAddress: account!,
                    network: getCoreAPINetwork(network),
                  })
                }
                isConfirmButtonLoading={isUnstakeLoading}
                onCancel={onCancel}
                onBack={() => setStep(EthereumFlowStep.AMOUNT)}
                disclaimerText="Unstaking your ETH is an irreversible action. Once your ETH is unstaked, it will automatically be sent to the withdrawal address which cannot be changed."
                details={[
                  {
                    type: DetailType.TOKEN,
                    label: "Amount",
                    value: {
                      tokenDetails: {
                        amount: flowData.amount.toString(),
                        ticker:
                          protocols[Protocol.ETHEREUM].networks[network!]
                            .ticker,
                        usdAmount: flowData.amount
                          .times(data?.ethereumPrice.value || "0")
                          .toFormat(2)
                          .toString(),
                      },
                    },
                  },
                  {
                    type: DetailType.ADDRESS,
                    label: "Withdrawal Address",
                    value: {
                      text: account,
                    },
                  },
                ]}
              />
            );
          case EthereumFlowStep.SUCCESS:
            return (
              <Success
                onDone={() => {
                  reset();
                  onCancel();
                  onSuccessButtonClick();
                }}
                isSIWE
              />
            );
          case EthereumFlowStep.ERROR:
            return (
              <div className="p-4">
                <ErrorStep
                  onClose={() => setStep(EthereumFlowStep.AMOUNT)}
                  error={
                    flowData.errors?.[0] || { message: "An error occurred" }
                  }
                />
              </div>
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};
