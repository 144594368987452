import React, { useEffect, useState } from "react";
import { BigNumber } from "bignumber.js";
import { FigAppActionEnum } from "@figmentjs/analytics/apps";
import { Network, Protocol } from "@figmentjs/protocols";
import { BodyText, Heading, Button } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import Wallet, { useTransactionFee } from "@figmentjs/wallet";
import {
  Details,
  DetailType,
  SignTransaction,
  ConfirmTransaction,
  ErrorStep,
  CancelledStep,
  Link,
} from "../../../../components";

type Props = {
  eigenPodAddress: `0x${string}`;
  eigenPodVerificationPayload?: `0x${string}`;
  isEigenPodVerificationLoading: boolean;
  onCancel: () => void;
  onVerifySuccess: () => void;
  onSetIsCloseableStep: (isCloseableStep: boolean) => void;
  onTrackEvent: ({
    action,
    step,
  }: {
    action: FigAppActionEnum;
    step?: string;
  }) => void;
};

export const VerifyRestakedValidators: React.FC<Props> = ({
  eigenPodAddress,
  eigenPodVerificationPayload,
  isEigenPodVerificationLoading,
  onCancel,
  onVerifySuccess,
  onSetIsCloseableStep,
  onTrackEvent,
}) => {
  const [isBroadcastTriggered, setIsBroadcastTriggered] = useState(false);
  const {
    sendTransaction,
    isSendingTransaction,
    transactionHash,
    setTransactionHash,
    sendTransactionError,
    account,
    network,
  } = Wallet.useWallet<Protocol.ETHEREUM>();

  useEffect(() => {
    if (transactionHash && !isSendingTransaction && !sendTransactionError) {
      setTransactionHash();
      onVerifySuccess();
      onSetIsCloseableStep(true);
    }
  }, [
    isSendingTransaction,
    transactionHash,
    sendTransactionError,
    setTransactionHash,
    onVerifySuccess,
    onSetIsCloseableStep,
  ]);

  const { fee, isLoading } = useTransactionFee({
    account: account! as `0x${string}`,
    network: network as Network,
    payload: eigenPodVerificationPayload,
    amount: "0",
  });

  if (isBroadcastTriggered && sendTransactionError === "TX_CANCELED") {
    onTrackEvent({
      action: FigAppActionEnum.STEP_RENDERED,
      step: "VERIFY_RESTAKED_VALIDATORS_FAILED",
    });
    setTransactionHash();
    onSetIsCloseableStep(true);
    return (
      <CancelledStep
        onTryAgain={() => {
          onTrackEvent({
            action: FigAppActionEnum.TRY_AGAIN_BTN_CLICKED,
            step: "VERIFY_RESTAKED_VALIDATORS_FAILED",
          });
          setIsBroadcastTriggered(false);
        }}
      />
    );
  } else if (isBroadcastTriggered && sendTransactionError) {
    onTrackEvent({
      action: FigAppActionEnum.STEP_RENDERED,
      step: "VERIFY_RESTAKED_VALIDATORS_FAILED",
    });
    setTransactionHash();
    onSetIsCloseableStep(true);
    return (
      <ErrorStep
        error={{ message: sendTransactionError, isWalletError: true }}
        onClose={onCancel}
      />
    );
  } else if (isSendingTransaction && !transactionHash) {
    return <SignTransaction />;
  } else if (transactionHash) {
    return <ConfirmTransaction />;
  }

  return (
    <>
      <div className="text-center space-y-4">
        <Heading
          level="h5"
          weight="bold"
          color="green"
          font={HeadingFont.space}
        >
          Verify Restaked ETH
        </Heading>
        <BodyText as="p">
          To select an EigenLayer Operator and earn restaking rewards, first
          verify your restaked ETH.
        </BodyText>
      </div>
      <div className="my-4" />
      <Details
        details={[
          {
            type: DetailType.ADDRESS,
            label: "EigenPod Address",
            prependedIcon: "EigenLayerLogo",
            value: { text: eigenPodAddress, addressType: "EIGENPOD_ADDRESS" },
          },
          {
            type: DetailType.DEFAULT,
            label: "Transaction Fee",
            value: { text: fee ? BigNumber(fee).toFormat(5) : "-" },
          },
        ]}
        showTopBorder
        showBottomBorder
        containerClassName="gap-4"
        isLoading={isLoading || isEigenPodVerificationLoading}
      />
      <Link
        href="https://figment.io/insights/eigenlayer-faq/"
        text="EigenLayer restaking on Figment"
      />
      <div className="flex gap-2">
        <Button size="small" palette="tertiary" fullWidth onClick={onCancel}>
          Cancel
        </Button>
        <Button
          size="small"
          fullWidth
          testId="verify-restaked-validators-submit-button"
          loading={isSendingTransaction || isEigenPodVerificationLoading}
          onClick={async () => {
            sendTransaction({
              payload: eigenPodVerificationPayload!,
              amount: "0",
            });
            setIsBroadcastTriggered(true);
            onSetIsCloseableStep(false);
          }}
        >
          Verify
        </Button>
      </div>
    </>
  );
};
