import React, { useState, useEffect } from "react";
import { BigNumber } from "bignumber.js";
import { BodyText, Heading, Button } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { Network, Protocol, protocols } from "@figmentjs/protocols";
import Wallet, { useTransactionFee } from "@figmentjs/wallet";
import { useRollbar } from "@figmentjs/rollbar-client";
import {
  DetailType,
  Details,
  Link,
  WalletDetails,
  CancelledStep,
  ErrorStep,
  SignTransaction,
  ConfirmTransaction,
} from "../../../../components";
import {
  EthereumNetworks,
  useEigenPodCreateMutation,
} from "../../../../../graphql/core/generated/gql";
import { EthereumStakingFlowData } from "../../../flows/ethereum/siwe/use-flow-data/use-flow-data";

type Props = {
  flowData: EthereumStakingFlowData;
  onBack: () => void;
  onNext: (values: Partial<EthereumStakingFlowData>) => void;
};

export const DeployEigenPod: React.FC<Props> = ({
  flowData,
  onBack,
  onNext,
}) => {
  const [isBroadcastTriggered, setIsBroadcastTriggered] = useState(false);
  const {
    sendTransaction,
    isSendingTransaction,
    transactionHash,
    setTransactionHash,
    sendTransactionError,
    account,
    network,
  } = Wallet.useWallet();
  const { fee, isLoading: isTxFeeLoading } = useTransactionFee({
    account: account! as `0x${string}`,
    network: network as Network,
    payload: flowData.unsignedCreateEigenPodTx!,
  });
  const rollbar = useRollbar();
  const { mutateAsync } = useEigenPodCreateMutation({
    onSuccess: (data) => {
      if (data?.eigenpodCreate.userErrors?.length) {
        rollbar.warn(
          "EigenPodCreateMutation User Errors",
          JSON.stringify(data.eigenpodCreate.userErrors)
        );
      }
    },
  });

  useEffect(() => {
    if (
      isBroadcastTriggered &&
      transactionHash &&
      !isSendingTransaction &&
      !sendTransactionError
    ) {
      // At this point, we've confirmed the transaction, so we will poll until we've confirmed that the EigenPod is deployed.
      const interval = setInterval(async () => {
        const response = await mutateAsync({
          network:
            network === Network.HOLESKY
              ? EthereumNetworks.Holesky
              : EthereumNetworks.Mainnet,
          ownerAddress: account!,
        });

        if (response?.eigenpodCreate.userErrors?.length) {
          setTransactionHash();
          setIsBroadcastTriggered(false);
          clearInterval(interval);
          return;
        }

        if (response?.eigenpodCreate.data?.deployed) {
          setTransactionHash();
          setIsBroadcastTriggered(false);
          onNext({ eigenPodAddress: response?.eigenpodCreate.data?.address });

          clearInterval(interval);
        }
      }, 1000);
    }
  }, [
    isSendingTransaction,
    transactionHash,
    account,
    network,
    mutateAsync,
    setTransactionHash,
    sendTransactionError,
    isBroadcastTriggered,
    onNext,
  ]);

  if (isBroadcastTriggered && sendTransactionError === "TX_CANCELED") {
    setTransactionHash();
    return (
      <div className="p-4">
        <CancelledStep onTryAgain={() => setIsBroadcastTriggered(false)} />
      </div>
    );
  } else if (isBroadcastTriggered && sendTransactionError) {
    setTransactionHash();
    return (
      <div className="p-4">
        <ErrorStep
          error={{ message: sendTransactionError, isWalletError: true }}
          onClose={() => setIsBroadcastTriggered(false)}
        />
      </div>
    );
  } else if (isBroadcastTriggered && isSendingTransaction && !transactionHash) {
    return (
      <div className="p-4">
        <SignTransaction />
      </div>
    );
  } else if (isBroadcastTriggered && transactionHash) {
    return (
      <div className="p-4">
        <ConfirmTransaction />
      </div>
    );
  }

  return (
    <>
      <WalletDetails protocol={Protocol.ETHEREUM} />
      <div className="p-4 flex flex-col items-center">
        <div className="space-y-1 text-center mb-4">
          <Heading level="h5" font={HeadingFont.space} weight="semibold">
            Deploy EigenPod
          </Heading>
          <BodyText as="p">
            Restaking on EigenLayer requires an EigenPod, a smart contract
            associated with your wallet address.
          </BodyText>
        </div>
        <Button
          palette="secondary"
          size="small"
          rounded
          onClick={() => {
            sendTransaction({
              payload: flowData.unsignedCreateEigenPodTx!,
              amount: "0",
            });
            setIsBroadcastTriggered(true);
          }}
        >
          Deploy EigenPod to Continue
        </Button>
        <div className="w-full my-4">
          <Details
            details={[
              {
                type: DetailType.DEFAULT,
                label: "Transaction Fee",
                value: {
                  text: fee
                    ? `${BigNumber(fee).toFormat(5)} ${
                        protocols[Protocol.ETHEREUM].networks[
                          network as Network
                        ].ticker
                      }`
                    : "-",
                },
              },
            ]}
            isLoading={isTxFeeLoading}
          />
        </div>
        <Link
          href="https://figment.io/staking/protocols/eigenlayer/"
          text="EigenLayer restaking on Figment"
        />
        <div className="flex justify-evenly gap-2 w-full">
          <Button palette="tertiary" size="small" fullWidth onClick={onBack}>
            Back
          </Button>
          <Button size="small" fullWidth disabled>
            Next
          </Button>
        </div>
      </div>
    </>
  );
};
