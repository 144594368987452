import React from "react";
import { twMerge as tw } from "tailwind-merge";
import { BodyText, Icon, ShimmerItem, Tooltip } from "@figmentjs/web-core";
import { Address } from "..";
import { DetailProps, DetailsProps, DetailType } from "./details.types";
import { Protocol } from "@figmentjs/protocols";

const Detail: React.FC<DetailProps> = ({ detail }) => {
  switch (detail.type) {
    case DetailType.ADDRESS:
      return (
        <div className="min-w-fit">
          <Address
            address={detail.value.text!}
            type={detail.value.addressType}
            // TODO: make sure to pass the protocol into each detail
            protocol={detail.value.protocol || Protocol.ETHEREUM}
          />
        </div>
      );

    case DetailType.TOKEN:
      return (
        <div className="flex gap-1 justify-end flex-wrap">
          <div>
            <BodyText weight="semibold">
              {`${detail.value.tokenDetails?.amount}
              ${detail.value.tokenDetails?.ticker}`}
            </BodyText>
          </div>
          <div>
            <BodyText color="basic-800">
              (${detail.value.tokenDetails?.usdAmount})
            </BodyText>
          </div>
        </div>
      );

    case DetailType.HREF:
      return (
        <a
          href={detail.value.href}
          className="flex gap-2 items-center hover:text-basic-800"
          target="_blank"
          rel="noopener noreferrer"
        >
          {detail.value.prependedIcon && (
            <Icon size="xl" icon={detail.value.prependedIcon} />
          )}
          <div className="flex gap-1 items-center">
            <BodyText color="inherit">{detail.value.text}</BodyText>
          </div>
          {detail.value.appendedIcon && (
            <Icon
              size="sm"
              color="basic-800"
              icon={detail.value.appendedIcon}
            />
          )}
        </a>
      );

    default:
      return (
        <div>
          <BodyText
            color={detail.detailColor || "basic-1000"}
            weight="semibold"
          >
            {detail.value.text}
          </BodyText>{" "}
          {detail.value.appendedText && (
            <BodyText color="basic-800" size="sm">
              {detail.value.appendedText}
            </BodyText>
          )}
        </div>
      );
  }
};

export const Details: React.FC<DetailsProps> = ({
  details,
  isLoading,
  containerClassName,
  showTopBorder = false,
  showBottomBorder,
}) => {
  return (
    <div
      className={tw(
        "flex flex-col gap-2",
        showTopBorder && "border-t border-t-green-100",
        showBottomBorder && "border-b border-b-green-100 pb-4",
        containerClassName
      )}
    >
      {details.map((detail) => {
        return (
          <div key={detail.label} className="flex justify-between items-center">
            {isLoading ? (
              <>
                <ShimmerItem width={120} />
                <ShimmerItem width={80} />
              </>
            ) : (
              <>
                <div className="flex items-center gap-1">
                  {detail.prependedIcon && (
                    <Icon size="lg" icon={detail.prependedIcon} />
                  )}
                  <BodyText color="basic-1000">{detail.label}</BodyText>
                  {detail.tooltipContent && (
                    <Tooltip
                      overlayContent={detail.tooltipContent}
                      delayDuration={0}
                    >
                      <Icon size="md" icon="MdInfoOutline" color="basic-1000" />
                    </Tooltip>
                  )}
                </div>
                <Detail detail={detail} />
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
