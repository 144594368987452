import React, { useState } from "react";
import { SummaryProps } from "./summary.types";
import { BodyText, Button, Heading, InputCheckbox } from "@figmentjs/web-core";
import { Details } from "../../../../components/details";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";

export const Summary: React.FC<SummaryProps> = ({
  details,
  detailsContainerClassName = "gap-4",
  onConfirm,
  onBack,
  isConfirmButtonLoading,
}) => {
  const [confirmed, setConfirmed] = useState<boolean>(false);

  return (
    <div className="p-4">
      <div className="text-center pb-4">
        <Heading font={HeadingFont.space} level="h5" weight="semibold">
          Staking Summary
        </Heading>
      </div>
      <div>
        <div className="pb-4 border-b border-basic-100">
          <Details
            details={details}
            containerClassName={detailsContainerClassName}
          />
        </div>
        <div className="pt-4">
          <InputCheckbox
            name="confirm"
            onChange={({ value }) => {
              setConfirmed(value);
            }}
          >
            <BodyText size="sm">
              I agree to Figment&apos;s{" "}
              <a
                className="text-green-800"
                target="_blank"
                rel="noreferrer"
                href="https://figment.io/figment-app-terms-of-use/"
              >
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                className="text-green-800"
                target="_blank"
                rel="noreferrer"
                href="https://figment.io/privacy-policy/"
              >
                Privacy Policy
              </a>
            </BodyText>
          </InputCheckbox>
        </div>
        <div className="pt-4 flex justify-evenly gap-2">
          <Button
            palette="tertiary"
            size="small"
            type="submit"
            fullWidth
            onClick={onBack}
          >
            Back
          </Button>
          <Button
            disabled={!confirmed}
            palette="primary"
            size="small"
            fullWidth
            onClick={onConfirm}
            loading={isConfirmButtonLoading}
          >
            Confirm Stake
          </Button>
        </div>
      </div>
    </div>
  );
};
