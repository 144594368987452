import React from "react";
import { Button, Heading } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import {
  LsETHConversionDetails,
  LsETHFlow,
  LsETHWalletDetails,
} from "../shared";
import BigNumber from "bignumber.js";
import { useClaimEthFlowData } from "../../../flows/ls-eth/claim-eth/use-flow-data/use-flow-data";
import { FigAppActionEnum } from "@figmentjs/analytics/apps";
import {
  CancelledStep,
  ConfirmTransaction,
  ErrorStep,
  SignTransaction,
} from "../../../../components";
import { ClaimEthFlowStep } from "../../../flows/ls-eth/claim-eth/flow/claim-eth-flow.types";
import { fromWei } from "@figmentjs/utils";

type Props = {
  onCancel: () => void;
  onSetIsCloseableStep: (isCloseableStep: boolean) => void;
  onTrackEvent: ({
    action,
    step,
  }: {
    action: FigAppActionEnum;
    step?: string;
  }) => void;
  claimAmountInEth: string;
  claimAmountInLsEth: string;
  redeemRequestId: string;
};

export const ClaimSummary: React.FC<Props> = ({
  onCancel,
  onSetIsCloseableStep,
  onTrackEvent,
  claimAmountInEth,
  claimAmountInLsEth,
  redeemRequestId,
}) => {
  const {
    createLiquidCollectiveClaimTransaction,
    isCreateLiquidCollectiveClaimTransactionBroadcasting,
    isCreateLiquidCollectiveClaimTransactionLoading,
    createLiquidCollectiveClaimTransactionHash,
    isWalletConnecting,
    isWalletConnected,
    connectWallet,
    resetFlow,
    error,
  } = useClaimEthFlowData();

  if (error === "TX_CANCELED") {
    onTrackEvent({
      action: FigAppActionEnum.STEP_RENDERED,
      step: ClaimEthFlowStep.CANCELLED,
    });
    onSetIsCloseableStep(true);

    return (
      <div className="p-4 pt-6">
        <CancelledStep
          onTryAgain={() => {
            onTrackEvent({
              action: FigAppActionEnum.TRY_AGAIN_BTN_CLICKED,
              step: ClaimEthFlowStep.CANCELLED,
            });
            resetFlow({ step: "SUMMARY" });
          }}
        />
      </div>
    );
  } else if (error) {
    onTrackEvent({
      action: FigAppActionEnum.STEP_RENDERED,
      step: ClaimEthFlowStep.ERROR,
    });
    onSetIsCloseableStep(true);

    return (
      <div className="p-4 pt-6">
        <ErrorStep
          error={{
            message: error,
            isWalletError: true,
          }}
          onClose={() => {
            resetFlow({ step: "SUMMARY" });
            onCancel();
          }}
        />
      </div>
    );
  } else if (
    isCreateLiquidCollectiveClaimTransactionBroadcasting &&
    !createLiquidCollectiveClaimTransactionHash
  ) {
    return (
      <div className="p-4 pt-6">
        <SignTransaction />
      </div>
    );
  } else if (createLiquidCollectiveClaimTransactionHash) {
    onSetIsCloseableStep(false);
    return (
      <div className="p-4 pt-6">
        <ConfirmTransaction title="Claiming ETH" />
      </div>
    );
  }

  return (
    <>
      <LsETHWalletDetails action="Claim ETH" />
      <div className="p-4">
        <div className="text-center pb-4">
          <Heading
            font={HeadingFont.space}
            level="h5"
            color="green"
            weight="semibold"
          >
            Claim Summary
          </Heading>
        </div>
        <div>
          <LsETHConversionDetails
            flow={LsETHFlow.CLAIM}
            isLoading={false}
            amountInEth={BigNumber(fromWei(claimAmountInEth)).dp(5)}
            amountInLsEth={BigNumber(fromWei(claimAmountInLsEth)).dp(5)}
          />
          {!isWalletConnected ? (
            <Button
              fullWidth
              size="small"
              onClick={connectWallet}
              loading={isWalletConnecting}
            >
              Connect Wallet
            </Button>
          ) : (
            <>
              <div className="flex justify-evenly gap-2">
                <Button
                  palette="primary"
                  size="small"
                  type="submit"
                  fullWidth
                  onClick={async () => {
                    createLiquidCollectiveClaimTransaction({
                      redeemRequestId,
                    });
                  }}
                  loading={
                    isCreateLiquidCollectiveClaimTransactionLoading ||
                    isCreateLiquidCollectiveClaimTransactionBroadcasting
                  }
                >
                  Claim ETH
                </Button>
              </div>
              <div className="mt-4 flex justify-center">
                <Button
                  palette="text"
                  size="tiny"
                  textColor="text-basic-800"
                  onClick={onCancel}
                  autoFocus={true}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
