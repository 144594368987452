import React, { useCallback, memo, useEffect } from "react";
import { FigAppActionEnum } from "@figmentjs/analytics/client";
import { ClaimSummary, ClaimSuccess } from "../../../../steps/ls-eth";
import { ClaimEthFlowStep } from "./claim-eth-flow.types";
import { useClaimEthFlowData } from "../use-flow-data";
import BigNumber from "bignumber.js";

type Props = {
  onDone: () => void;
  onSetIsCloseableStep: (isCloseableStep: boolean) => void;
  onTrackEvent: ({
    action,
    step,
  }: {
    action: FigAppActionEnum;
    step?: string;
    amountToUnstake?: string;
  }) => void;
  claimAmount: {
    eth: string;
    lsEth: string;
  };
  redeemRequestId: string;
};

const LsETHClaimEthFlow: React.FC<Props> = ({
  onDone,
  onSetIsCloseableStep,
  onTrackEvent,
  claimAmount,
  redeemRequestId,
}) => {
  const { step, resetFlow } = useClaimEthFlowData();

  const handleOnClose = useCallback(() => {
    resetFlow({ step: "SUMMARY" });
    onDone();
  }, [onDone, resetFlow]);

  useEffect(() => {
    onTrackEvent({
      action: FigAppActionEnum.STEP_RENDERED,
      step: step,
      amountToUnstake: claimAmount.eth,
    });
  }, [claimAmount, onTrackEvent, step]);

  return (
    <div>
      {((): React.ReactNode => {
        switch (step) {
          case ClaimEthFlowStep.SUMMARY:
            return (
              <ClaimSummary
                onSetIsCloseableStep={onSetIsCloseableStep}
                onCancel={handleOnClose}
                onTrackEvent={onTrackEvent}
                claimAmountInEth={claimAmount.eth}
                claimAmountInLsEth={claimAmount.lsEth}
                redeemRequestId={redeemRequestId}
              />
            );

          case ClaimEthFlowStep.SUCCESS:
            onSetIsCloseableStep(true);
            return (
              <ClaimSuccess
                onClose={handleOnClose}
                amount={BigNumber(claimAmount.eth)}
              />
            );

          default:
            return null;
        }
      })()}
    </div>
  );
};

export default memo(LsETHClaimEthFlow);
