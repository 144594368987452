const defaultTheme = require("tailwindcss/defaultTheme");

const config = {
  important: true,
  content: [],
  theme: {
    screens: {
      sm: "576px",
      md: "768px",
      lg: "976px",
      xl: "1440px",
      "2x": "1536px",
    },
    colors: {
      green: {
        DEFAULT: "#004039",
        1100: "#092B28",
        1000: "#004039",
        800: "#0B703F",
        700: "#359B11",
        600: "#00C708",
        500: "#89E231",
        400: "#14F195",
        300: "#DAE7D4",
        100: "#F1F4F3",
      },
      teal: {
        DEFAULT: "#0D858B",
        primary: "#0D858B",
        600: "#0D858B",
        secondary: "#8FE2DD",
        400: "#8FE2DD",
      },
      blue: {
        DEFAULT: "#7ECEDF",
        primary: "#7ECEDF",
        500: "#7ECEDF",
        secondary: "#CEFCFF",
        300: "#CEFCFF",
        neutral: "#DAEFEB",
        200: "#DAEFEB",
      },
      yellow: {
        DEFAULT: "#FFE953",
        figment: "#FFE953",
        1000: "#FEC70D",
        500: "#FFE953",
        400: "#FDF4BB",
      },
      pending: {
        DEFAULT: "#FEC70D",
        1000: "#FEC70D",
        light: "#FDF4BB",
        400: "#FDF4BB",
      },
      black: {
        DEFAULT: "#111111",
      },
      basic: {
        DEFAULT: "#6F7471",
        800: "#6F7471",
        600: "#B3B3B3",
        300: "#D4D6D4",
        200: "#EFEFEF",
        100: "#F9F9F9",
        50: "#FBFBFB",
      },
      white: {
        DEFAULT: "#FFFFFF",
      },
      success: {
        DEFAULT: "#359B11",
      },
      error: {
        DEFAULT: "#C01005",
        700: "#C01005",
        light: "FFD8D6",
        200: "#FFD8D6",
      },
      ethereum: {
        DEFAULT: "#6093F7",
        1000: "#1A0C6D",
        600: "#6093F7",
        400: "#BBD2FF",
      },
      solana: {
        DEFAULT: "#7B3CCB",
        1000: "#591AA8",
        800: "#7B3CCB",
        400: "#B992EB",
      },

      /* --- BEGIN OLD COLORS */
      // These colors are legacy and will get phased out
      // as we transition to the new design system.
      // Do not use.
      //
      "basic-900": "#16161A",
      "regal-blue": "#0C3F5C",
      "regal-blue-ui-reco": "#034D77",
      horizon: "#6894AD",
      cloud: "#C7E0E7",
      "light-ice": "#F6F8FB",
      "figment-yellow": "#FFF850",
      slate400: "#94A3B8",
      alert: {
        DEFAULT: "#C01005",
        error: "#C01005",
        "error-light": "#FFD8D6",
        success: "#359B11",
        "success-light": "#E7F4E7",
        pending: "#FEC70D",
        "pending-light": "#FDF4BB",
      },
      /* --- END OLD COLORS */
    },
    fontSize: {
      "9xl": "8rem", // 128px
      "7xl": "4.5rem", // 72px
      "6xl": "3.5rem", // 56px
      "5xl": "3rem", // 48px
      "4xl": "2.5rem", // 40px
      "3xl": "2rem", // 32px
      "2xl": "1.5rem", // 24px
      xl: "1.313rem", // 21px
      lg: "1.125rem", // 18px
      md: "1rem", // 16px
      base: "0.875rem", // 14px
      sm: "0.75rem", // 12px
      xs: "0.625rem", // 10px
    },
    fontWeight: {
      light: 300,
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
    lineHeight: {
      3: "1.125rem", // 18px
      4: "1.25rem", // 20px
      5: "1.375rem", // 22px
      6: "1.625rem", // 26px
      7: "1.8125rem", // 29px
      8: "2rem", // 32px
      9: "2.5rem", // 40px
      10: "3rem", // 48px
      11: "3.5rem", // 56px
      12: "4rem", // 64px
      13: "5rem", // 80px
    },
    fontFamily: {
      sans: ["Inter", ...defaultTheme.fontFamily.sans],
      serif: ["Tiempos Headline", ...defaultTheme.fontFamily.serif],
      mono: [
        "SF Mono",
        "Roboto Mono",
        "Consolas",
        "DejaVu Sans Mono",
        ...defaultTheme.fontFamily.mono,
      ],
      space: ["Space Grotesk", "system-ui"],
    },
    extend: {
      keyframes: {
        spin: {
          from: { transform: "rotate(-45deg)" },
          to: { transform: "rotate(315deg)" },
        },
      },
      animation: {
        "spinner-fast-ease-in": "spin 0.6s ease-in infinite",
      },
      boxShadow: {
        "3xl": "0 4px 32px 0 rgba(0, 0, 0, 0.06)",
        header: "0 2px 18px 0 rgba(0, 0, 0, 0.1)",
      },
    },
    zIndex: {
      toast: "9999",
      banner: "998",
      modal: "999",
      tooltip: "1000",
      intercom: "99999",
      "close-button": "10",
      icon: "10",
      overlay: "1",
      "overlay+1": "2",
    },
  },
  plugins: [],
};

module.exports = config;
