import React, { memo } from "react";
import { twMerge as tw } from "tailwind-merge";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { TooltipProps } from "./Tooltip.types";

/**
 * Renders the Tooltip component.
 *
 * @param TooltipProps
 * @returns The Tooltip component
 */
const Tooltip = ({
  children,
  overlayText,
  overlayContent,
  placement,
  align,
  delayDuration,
  compact,
  ...props
}: TooltipProps) => {
  const settings = compact
    ? { arrowPadding: 10, width: 12, height: 5 }
    : { arrowPadding: 12, width: 20, height: 10 };

  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root delayDuration={delayDuration || 300} {...props}>
        <TooltipPrimitive.Trigger asChild>
          <span>{children}</span>
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            side={placement}
            sideOffset={4}
            align={align}
            className={tw(
              "inline-flex items-center rounded z-tooltip max-w-md",
              "bg-black drop-shadow-[0_2px_4px_rgba(0,0,0,0.15)]",
              compact ? "px-3 py-1" : "px-4 py-3"
            )}
            arrowPadding={settings.arrowPadding}
          >
            {overlayContent}
            {overlayText && (
              <p className="text-white text-sm leading-4">{overlayText}</p>
            )}
            <TooltipPrimitive.Arrow
              width={settings.width}
              height={settings.height}
              className="visible"
            />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};

export default memo(Tooltip);
