import React from "react";
import { BodyText, Heading, Button } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { Protocol } from "@figmentjs/protocols";
import {
  DetailType,
  Details,
  Link,
  WalletDetails,
} from "../../../../components";
import { EthereumStakingFlowData } from "../../../flows/ethereum/siwe/use-flow-data/use-flow-data";

type Props = {
  flowData: EthereumStakingFlowData;
  onBack: () => void;
  onNext: () => void;
};

export const EigenPodAddress: React.FC<Props> = ({
  flowData,
  onBack,
  onNext,
}) => {
  return (
    <>
      <WalletDetails protocol={Protocol.ETHEREUM} />
      <div className="p-4 flex flex-col items-center">
        <div className="space-y-1 text-center mb-4">
          <Heading level="h5" font={HeadingFont.space} weight="semibold">
            EigenLayer Restaking
          </Heading>
          <BodyText as="p">Your connected wallet has an EigenPod.</BodyText>
        </div>
        <div className="w-full mb-4">
          <Details
            details={[
              {
                type: DetailType.ADDRESS,
                label: "EigenPod Address",
                prependedIcon: "EigenLayerLogo",
                value: {
                  text: flowData.eigenPodAddress,
                  addressType: "EIGENPOD_ADDRESS",
                },
              },
            ]}
          />
        </div>

        <Link
          href="https://figment.io/staking/protocols/eigenlayer/"
          text="EigenLayer restaking on Figment"
        />
        <div className="flex justify-evenly gap-2 w-full">
          <Button palette="tertiary" size="small" fullWidth onClick={onBack}>
            Back
          </Button>
          <Button size="small" fullWidth onClick={onNext}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
};
