import * as RadixToast from "@radix-ui/react-toast";

import React, { memo } from "react";
import { DEFAULT_NOTIFICATION_DURATION } from "./Toast";
import { ToastProps } from "./Toast.types";

/**
 * Renders the Toast component.
 *
 * @param ToastProps
 * @returns The Toast component
 */
const LegacyToast: React.FC<ToastProps> = ({
  message,
  duration = DEFAULT_NOTIFICATION_DURATION,
}) => {
  return (
    <RadixToast.Provider>
      <RadixToast.Root
        duration={duration}
        className="z-10 h-16 absolute bottom-16 bg-basic-900 px-8 text-white flex items-center rounded-md right-[50%] translate-x-1/2 bg-none"
      >
        <RadixToast.Title />
        <RadixToast.Description className="text-sm font-normal">
          {message}
        </RadixToast.Description>
      </RadixToast.Root>
      <RadixToast.Viewport />
    </RadixToast.Provider>
  );
};

export default memo(LegacyToast);
