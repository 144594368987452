import { graphql } from "msw";
import { StakingPositionQuery } from "../../../graphql/core/generated/gql";

export const StakingPositionQueryMock = graphql.query<StakingPositionQuery>(
  "StakingPosition",
  (req, res, ctx) => {
    return res(
      ctx.data({
        rewardsSummary: {
          stakingRewards: "0.0173413432",
          totalRewards: "11.321000235",
          totalRewardsUsd: "4279995.325324423",
        },
        ethereumBalanceSummary: {
          balance: "320",
          balanceUsd: "1208726.4025325",
        },
        validatorCounts: {
          exitable: 10,
        },
      })
    );
  }
);

export const StakingPositionQueryEmptyMock =
  graphql.query<StakingPositionQuery>("StakingPosition", (req, res, ctx) => {
    return res(
      ctx.data({
        rewardsSummary: {
          stakingRewards: null,
          totalRewards: null,
          totalRewardsUsd: null,
        },
        ethereumBalanceSummary: {
          balance: null,
          balanceUsd: null,
        },
        validatorCounts: {
          exitable: 0,
        },
      })
    );
  });
