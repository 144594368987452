import React from "react";
import { BodyText, Button, Heading } from "@figmentjs/web-core";
import { Details } from "../../../../components/details";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import Wallet from "@figmentjs/wallet";
import { Protocol } from "@figmentjs/protocols";

export type Props = {
  details: React.ComponentProps<typeof Details>["details"];
  onConfirmUnstake: () => void;
  onCancel: () => void;
  isConfirmButtonLoading?: boolean;
  disclaimerText: string;
  onBack?: () => void;
  walletConnectionRequired?: boolean;
};

export const Summary: React.FC<Props> = ({
  details,
  onConfirmUnstake,
  onCancel,
  isConfirmButtonLoading,
  disclaimerText,
  onBack,
  walletConnectionRequired,
}) => {
  const { account, connect, isConnecting } =
    Wallet.useWallet<Protocol.SOLANA>();

  return (
    <div className="p-4">
      <div className="text-center">
        <Heading
          weight="bold"
          font={HeadingFont.space}
          level="h5"
          color="green"
        >
          Withdrawal Summary
        </Heading>
      </div>
      <div className="py-4">
        <Details details={details} />
      </div>
      <div className="pt-4 border-t border-basic-100">
        <span>
          <BodyText size="sm" weight="semibold">
            IMPORTANT:{" "}
          </BodyText>
          <BodyText size="sm">{disclaimerText}</BodyText>
        </span>
      </div>
      <div className="py-4 space-y-4">
        <div className="flex justify-evenly gap-2">
          {onBack && (
            <Button
              palette="tertiary"
              size="small"
              type="submit"
              fullWidth
              onClick={onBack}
            >
              Back
            </Button>
          )}
          {walletConnectionRequired && !account ? (
            <Button
              palette="primary"
              size="small"
              fullWidth
              onClick={connect}
              loading={isConnecting}
            >
              Connect Wallet
            </Button>
          ) : (
            <Button
              palette="primary"
              size="small"
              fullWidth
              onClick={onConfirmUnstake}
              loading={isConfirmButtonLoading}
            >
              Confirm Unstake
            </Button>
          )}
        </div>
        <div className="flex justify-center">
          <Button
            palette="text"
            size="tiny"
            textColor="text-basic-800"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
