import React, { memo } from "react";
import { CopyToClipboard } from "@figmentjs/web-core";
import { AddressType } from "../address-base";
import { FontColor } from "@figmentjs/web-core/src/theme/types";

type Props = {
  address: string;
  type: keyof typeof AddressType;
  size?: "base" | "lg";
  color?: keyof typeof FontColor;
};

export const addressTypeToTextMap: {
  [key in AddressType]: string;
} = {
  [AddressType.EIGENPOD_ADDRESS]: "EigenPod",
  [AddressType.EXECUTION_REWARDS_ADDRESS]: "Execution Rewards",
  [AddressType.WITHDRAWAL_ADDRESS]: "Withdrawal",
  [AddressType.VALIDATOR_ADDRESS]: "Validator",
  [AddressType.STAKE_ACCOUNT]: "Stake Account",
  [AddressType.WALLET]: "Wallet",
};

const CopyAddressToClipboard: React.FC<Props> = ({
  address,
  type,
  size = "base",
  color = "green",
}) => {
  const addressText = addressTypeToTextMap[type];

  return (
    <CopyToClipboard
      contentToSave={address}
      size={size}
      notificationMessage={`${addressText} Address copied`}
      withPadding={false}
      color={color}
    />
  );
};

export default memo(CopyAddressToClipboard);
