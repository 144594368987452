"use client";

import * as React from "react";
import Wallet, { formatEther } from "@figmentjs/wallet";
import { useRollbar } from "@figmentjs/rollbar-client";
import {
  EthereumNetworks,
  useLiquidCollectiveClaimTransactionCreateMutation,
  useLsEthRedemptionsQuery,
} from "../../../../../../graphql/core/generated/gql";
import { Network, Protocol } from "@figmentjs/protocols";
import { useEffect } from "react";
import { ClaimEthFlowStep } from "../flow/claim-eth-flow.types";
import { useQueryClient } from "@tanstack/react-query";

type UseClaimEth = {
  step: ClaimEthFlowStep;
  setClaimEthFlowStep: (flowStep: ClaimEthFlowStep) => void;
  isWalletConnecting: boolean;
  isWalletConnected: boolean;
  connectWallet: () => void;
  isCreateLiquidCollectiveClaimTransactionLoading: boolean;
  isCreateLiquidCollectiveClaimTransactionBroadcasting: boolean;
  createLiquidCollectiveClaimTransaction: ({
    redeemRequestId,
  }: {
    redeemRequestId: string;
  }) => void;
  createLiquidCollectiveClaimTransactionHash?: string;
  resetFlow: (args?: { step: keyof typeof ClaimEthFlowStep }) => void;
  lsEthConversionRate?: number;
  error?: string;
};

const defaultValues: UseClaimEth = {
  step: ClaimEthFlowStep.SUMMARY,
  setClaimEthFlowStep: () => {
    throw new Error("setClaimEthFlowStep not implemented");
  },
  isCreateLiquidCollectiveClaimTransactionLoading: false,
  isCreateLiquidCollectiveClaimTransactionBroadcasting: false,
  createLiquidCollectiveClaimTransaction: async () => {
    throw new Error("createLiquidCollectiveClaimTransaction not implemented");
  },
  isWalletConnecting: false,
  isWalletConnected: false,
  connectWallet: () => {
    throw new Error("connectWallet not implemented");
  },
  resetFlow: () => {
    throw new Error("resetFlow not implemented");
  },
};

const UseClaimEthContext = React.createContext<UseClaimEth>(defaultValues);

export const ClaimEthFlowProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [step, setClaimEthFlowStep] = React.useState<ClaimEthFlowStep>(
    ClaimEthFlowStep.SUMMARY
  );
  const rollbar = useRollbar();
  const {
    connect,
    isConnecting,
    account,
    network,
    isSendingTransaction: isCreateLiquidCollectiveClaimTransactionBroadcasting,
    sendTransaction,
    transactionHash,
    setTransactionHash,
    sendTransactionError,
    setSendTransactionError,
  } = Wallet.useWallet<Protocol.ETHEREUM>();
  const { lsEthConversionRate } = Wallet.useLiquidStaking();

  const queryClient = useQueryClient();

  const {
    mutate: createLiquidCollectiveClaimTransaction,
    isLoading: createLiquidCollectiveClaimTransactionLoading,
  } = useLiquidCollectiveClaimTransactionCreateMutation();

  useEffect(() => {
    if (
      transactionHash &&
      !isCreateLiquidCollectiveClaimTransactionBroadcasting &&
      !sendTransactionError
    ) {
      setClaimEthFlowStep(ClaimEthFlowStep.SUCCESS);
      queryClient.invalidateQueries({
        queryKey: useLsEthRedemptionsQuery.getKey({
          network:
            network === Network.MAINNET
              ? EthereumNetworks.Mainnet
              : EthereumNetworks.Holesky,
          address: account!,
        }),
      });
    }
  }, [
    isCreateLiquidCollectiveClaimTransactionBroadcasting,
    transactionHash,
    sendTransactionError,
  ]);

  return (
    <UseClaimEthContext.Provider
      value={{
        step,
        setClaimEthFlowStep: setClaimEthFlowStep,
        lsEthConversionRate: Number(formatEther(lsEthConversionRate)),
        isCreateLiquidCollectiveClaimTransactionLoading:
          createLiquidCollectiveClaimTransactionLoading,
        createLiquidCollectiveClaimTransaction: async ({ redeemRequestId }) => {
          createLiquidCollectiveClaimTransaction(
            {
              network:
                network === Network.MAINNET
                  ? EthereumNetworks.Mainnet
                  : EthereumNetworks.Holesky,
              redeemRequestId,
            },
            {
              onSuccess: (response) => {
                sendTransaction({
                  payload: response.liquidCollectiveClaimTransactionCreate.data!
                    .unsignedTransactionSerialized as `0x${string}`,
                  amount: "0",
                });
              },
              onError: (error) => {
                rollbar.error(error as Error);
                setSendTransactionError((error as Error).message);
              },
            }
          );
        },
        isCreateLiquidCollectiveClaimTransactionBroadcasting,
        createLiquidCollectiveClaimTransactionHash: transactionHash,
        resetFlow: (args) => {
          setTransactionHash();
          setSendTransactionError(undefined);
          if (args?.step) {
            setClaimEthFlowStep(ClaimEthFlowStep[args.step]);
          }
        },
        isWalletConnecting: !!isConnecting,
        isWalletConnected: !!account,
        connectWallet: connect,
        error: sendTransactionError,
      }}
    >
      {children}
    </UseClaimEthContext.Provider>
  );
};

export const useClaimEthFlowData = () => {
  const context = React.useContext(UseClaimEthContext);

  return context;
};
