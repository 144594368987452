import { SolanaFlowData } from "../default";
import { useWallet } from "@figmentjs/wallet";
import { Protocol } from "@figmentjs/protocols";
import {
  SolanaStakingDelegationCreateMutationVariables,
  useSolanaStakingDelegationCreateMutation,
} from "../../../../../graphql/core/generated/gql";

type Props = {
  input: SolanaStakingDelegationCreateMutationVariables;
  onError: (values: Partial<SolanaFlowData>) => void;
  onCreation: (values: Partial<SolanaFlowData>) => void;
};

export const useStakeSol = ({ input, onError, onCreation }: Props) => {
  const { mutateAsync: stakeSolana, isLoading } =
    useSolanaStakingDelegationCreateMutation();
  const { network, sendTransaction } = useWallet<Protocol.SOLANA>();

  const stake = async () => {
    if (!network) {
      onError({
        errors: [{ message: "Could not resolve the SOL network.", code: "" }],
      });
      throw new Error("No value for 'network'");
    }

    const { solanaStakingDelegateTransactionCreate } = await stakeSolana(input);

    if (
      solanaStakingDelegateTransactionCreate?.userErrors?.length ||
      !solanaStakingDelegateTransactionCreate?.data
    ) {
      onError({
        errors: solanaStakingDelegateTransactionCreate?.userErrors,
      });
      return;
    }

    sendTransaction({
      payload:
        solanaStakingDelegateTransactionCreate.data!
          .unsignedTransactionSerialized,
    });

    onCreation({
      unsignedRawTransaction:
        solanaStakingDelegateTransactionCreate.data
          .unsignedTransactionSerialized,
      stakeAccountPubkey:
        solanaStakingDelegateTransactionCreate.data.stakeAccountPubkey,
    });
  };

  return { stake, isLoading };
};
