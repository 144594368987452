import BigNumber from "bignumber.js";
import React from "react";
import { BodyText, Button, Heading, Icon } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { Link } from "../../../../components";
import { useEthereumStakingFlowQuery } from "../../../../../graphql/core/generated/gql";
import { friendlyDateFromDays } from "@figmentjs/utils/src/formatters/date";
import { UnstakingFeedback } from "../../shared/unstaking-feedback/unstaking-feedback";

type Props = {
  onDone: () => void;
  isSIWE?: boolean;
};

const getEstimatedTimeInDays = (hours?: number | null) => {
  return Math.ceil(
    Math.max(
      BigNumber(hours || "24")
        .div(24)
        .toNumber(),
      1
    )
  );
};

export const Success: React.FC<Props> = ({ onDone, isSIWE }) => {
  const { data, isLoading } = useEthereumStakingFlowQuery();
  const [isFeedbackActive, setIsFeedbackActive] = React.useState(false);

  const maxExitTime = friendlyDateFromDays(
    getEstimatedTimeInDays(data?.ethereumNetworkEstimates.estimatedExit?.hours)
  );

  const maxWithdrawalTime = friendlyDateFromDays(
    getEstimatedTimeInDays(
      data?.ethereumNetworkEstimates.estimatedWithdrawal?.hoursMax
    )
  );

  return (
    <div className="p-4">
      <div className="flex gap-2 items-center justify-center text-center border-b border-b-green-100 pb-4">
        <Icon icon="MdCheckCircle" size="4xl" color="green-800" />
        <Heading
          level="h5"
          color="green"
          font={HeadingFont.space}
          weight="bold"
        >
          Withdrawal Initiated
        </Heading>
      </div>
      <div className="py-4">
        <BodyText weight="semibold">What&apos;s Next?</BodyText>
        <div className="pt-1">
          {!isSIWE && (
            <div className="pb-4">
              <BodyText size="sm">
                You will immediately receive an email stating that the unstaking
                flow has started.
              </BodyText>
            </div>
          )}

          <BodyText size="sm">
            <>
              It will take around{" "}
              {isLoading ? (
                <div
                  className="animate-pulse bg-basic-200 rounded h-[12px] inline-block"
                  style={{ width: `50px` }}
                />
              ) : (
                <b>
                  {maxExitTime.time} {maxExitTime.unit}
                </b>
              )}{" "}
              for your validators to exit. After that it will take up to{" "}
              {isLoading ? (
                <div
                  className="animate-pulse bg-basic-200 rounded h-[12px] inline-block"
                  style={{ width: `50px` }}
                />
              ) : (
                <b>
                  {maxWithdrawalTime.time} {maxWithdrawalTime.unit}
                </b>
              )}{" "}
              for your funds to be withdrawn back to your wallet.
            </>
          </BodyText>
        </div>
      </div>
      <div className="border-t border-basic-100 pt-4">
        <UnstakingFeedback
          label="Reason for unstaking?"
          onActivate={() => setIsFeedbackActive(true)}
          onSubmit={onDone}
        />
      </div>
      <div className="w-full">
        {!isFeedbackActive && (
          <div className="mt-4">
            <Button size="small" fullWidth onClick={onDone}>
              Close
            </Button>
          </div>
        )}
        <Link
          href="https://figment.io/insights/ethereum-withdrawals-a-comprehensive-faq/"
          text="Ethereum Withdrawals: A Comprehensive FAQ"
        />
      </div>
    </div>
  );
};
