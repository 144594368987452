import React from "react";
import {
  Protocol,
  getAddressExplorerUrl,
  protocols,
  SolanaNetwork,
} from "@figmentjs/protocols";
import { BodyText, Button } from "@figmentjs/web-core";
import { Address, CurrencyToggle, TableLoading } from "..";
import { AddressType } from "../address-base";
import {
  useInfiniteSolanaStakingPositionsTableQuery,
  SolanaNetworks,
  StakeAccountStatus,
  StakeAccount,
} from "../../../graphql/core/generated/gql";
import { twMerge as tw } from "tailwind-merge";
import { hashQueryKey } from "@tanstack/react-query";
import BigNumber from "bignumber.js";
import { currencyFormatterCompact, tokenFormatter } from "@figmentjs/utils";
import { Currencies } from "../currency-toggle/currency-toggle.types";
import { useCurrency } from "../../hooks/use-currency";

const TABLE_PAGE_SIZE = 5;

type Props = {
  isTestnetMode: boolean;
  renderUndelegateButton: (address: StakeAccount) => React.ReactNode;
  renderWithdrawButton: (address: StakeAccount) => React.ReactNode;
  hasNoPositions: boolean;
};

const columnWidths = {
  address: "flex-1",
  wallet: "flex-1",
  stake: "flex-1",
  rewards: "flex-1 mr-8",
  status: "flex-1",
  actions: "flex-1",
};

const renderStatus = (status: StakeAccountStatus) => {
  switch (status) {
    case StakeAccountStatus.Activating:
      return (
        <>
          <div className="relative w-2 h-2">
            <div className="absolute w-2 h-2 rounded-full bg-yellow-1000 animate-ping" />
            <div className="absolute w-2 h-2 rounded-full bg-yellow-1000" />
          </div>
          <BodyText>Activating</BodyText>
        </>
      );
    case StakeAccountStatus.Active:
      return (
        <>
          <div className="w-2 h-2 rounded-full bg-green-500" />
          <BodyText>Active</BodyText>
        </>
      );
    case StakeAccountStatus.Deactivating:
      return (
        <>
          <div className="relative w-2 h-2">
            <div className="absolute w-2 h-2 rounded-full bg-yellow-1000 animate-ping" />
            <div className="absolute w-2 h-2 rounded-full bg-yellow-1000" />
          </div>
          <BodyText>Exiting</BodyText>
        </>
      );
    case StakeAccountStatus.Inactive:
      return (
        <>
          <div className="w-2 h-2 rounded-full bg-basic-600" />
          <BodyText>Exited</BodyText>
        </>
      );
    case StakeAccountStatus.Withdrawn:
      return (
        <>
          <div className="w-2 h-2 rounded-full bg-basic-600" />
          <BodyText>Withdrawn</BodyText>
        </>
      );
  }
};

const TableHeader = ({
  ticker,
  currency,
  setCurrency,
}: {
  ticker?: string;
  currency?: string;
  setCurrency?: (currency: string) => void;
}) => {
  const renderCurrencyToggle = ticker && currency && setCurrency;
  return (
    <div className="p-4 flex justify-between items-center">
      <BodyText weight="semibold">Positions</BodyText>
      {renderCurrencyToggle && (
        <CurrencyToggle
          ticker={ticker}
          currency={currency}
          onValueChange={setCurrency}
        />
      )}
    </div>
  );
};

const TableEmpty = () => {
  return (
    <div className="rounded-md bg-white">
      <TableHeader />
      <div className="grid place-items-center p-12 pb-20">
        <BodyText size="sm">No positions yet</BodyText>
      </div>
    </div>
  );
};

export const SolanaStakingPositionsTable: React.FC<Props> = ({
  isTestnetMode,
  renderUndelegateButton,
  renderWithdrawButton,
  hasNoPositions,
}) => {
  const network = isTestnetMode ? SolanaNetwork.DEVNET : SolanaNetwork.MAINNET;
  const ticker = protocols[Protocol.SOLANA].networks[network].ticker;
  const { isUsd, setIsUsd } = useCurrency();
  const apiNetwork = isTestnetMode
    ? SolanaNetworks.Devnet
    : SolanaNetworks.Mainnet;

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteSolanaStakingPositionsTableQuery(
      "after",
      {
        network: apiNetwork,
        first: TABLE_PAGE_SIZE,
      },
      {
        enabled: !hasNoPositions,
        cacheTime: 0,
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage.solanaStakeAccounts.pageInfo.hasNextPage &&
          lastPage.solanaStakeAccounts.pageInfo.endCursor
            ? { after: lastPage.solanaStakeAccounts.pageInfo.endCursor }
            : null,
        queryKeyHashFn: () =>
          hashQueryKey(["SolanaStakingPositionsTable.infinite", apiNetwork]),
      }
    );

  if (hasNoPositions) {
    return <TableEmpty />;
  }

  if (isLoading) {
    return (
      <div className="rounded-md bg-white">
        <TableHeader />
        <TableLoading />
      </div>
    );
  }

  if (!data?.pages[0].solanaStakeAccounts.nodes.length) {
    return <TableEmpty />;
  }

  const addresses = data.pages.flatMap((p) => p.solanaStakeAccounts.nodes);

  return (
    <div className="rounded-md bg-white" data-testid="staking-positions-table">
      <TableHeader
        ticker={ticker}
        currency={isUsd ? Currencies.USD : ticker}
        setCurrency={setIsUsd}
      />
      <div className="flex flex-col w-full">
        <div className="w-full">
          <div className="flex flex-row border-t border-b border-green-100 py-3 px-4">
            <div className={tw(columnWidths.address)}>
              <BodyText color="basic-800">Stake Account</BodyText>
            </div>
            <div className={tw(columnWidths.wallet)}>
              <BodyText color="basic-800">Wallet</BodyText>
            </div>
            <div className={tw("text-right", columnWidths.stake)}>
              <BodyText color="basic-800">Stake</BodyText>
            </div>
            <div className={tw("text-right", columnWidths.rewards)}>
              <BodyText color="basic-800">All Time Rewards</BodyText>
            </div>
            <div className={tw(columnWidths.status)}>
              <BodyText color="basic-800">Status</BodyText>
            </div>
            <div className={tw("text-right", columnWidths.actions)} />
          </div>
          <div className="p-2">
            {addresses.map((address) => {
              const stakedBalance = isUsd
                ? currencyFormatterCompact.format(
                    new BigNumber(address.balanceUsd || 0).toNumber()
                  )
                : tokenFormatter.format(
                    new BigNumber(address.balance || 0).toNumber(),
                    Protocol.SOLANA
                  );

              const allTimeRewards = isUsd
                ? currencyFormatterCompact.format(
                    new BigNumber(address.totalRewardsUsd || 0).toNumber()
                  )
                : tokenFormatter.format(
                    new BigNumber(address.totalRewards || 0).toNumber(),
                    Protocol.SOLANA
                  );

              return (
                <div
                  className="flex flex-row p-2 items-center hover:bg-basic-100"
                  key={address.id}
                >
                  {/* Stake Account */}
                  <div className={tw(columnWidths.address)}>
                    <Address
                      address={address.address}
                      protocol={Protocol.SOLANA}
                      type={AddressType.STAKE_ACCOUNT}
                      overrideTheme={true}
                      href={getAddressExplorerUrl(
                        Protocol.SOLANA,
                        address.address,
                        network
                      )}
                    />
                  </div>

                  {/* Wallet */}
                  <div className={tw(columnWidths.address)}>
                    {address.stakeAuthorityAddress && (
                      <Address
                        address={address.stakeAuthorityAddress}
                        protocol={Protocol.SOLANA}
                        type={AddressType.WALLET}
                        overrideTheme={true}
                        href={getAddressExplorerUrl(
                          Protocol.SOLANA,
                          address.stakeAuthorityAddress,
                          network
                        )}
                      />
                    )}
                  </div>

                  {/* Stake */}
                  <div
                    className={tw("flex gap-1 justify-end", columnWidths.stake)}
                    data-testid="staking-positions-table-stake"
                    title={isUsd ? "" : address.balance || ""}
                  >
                    <BodyText font="mono" weight="semibold">
                      {stakedBalance}
                    </BodyText>
                    {!isUsd && <BodyText color="basic-800">{ticker}</BodyText>}
                  </div>

                  {/* All Time Rewards */}
                  <div
                    className={tw(
                      "flex gap-1 justify-end",
                      columnWidths.rewards
                    )}
                    data-testid="staking-positions-table-rewards"
                    title={isUsd ? "" : address.totalRewards || ""}
                  >
                    <BodyText font="mono" weight="semibold">
                      {allTimeRewards}
                    </BodyText>
                    {!isUsd && <BodyText color="basic-800">{ticker}</BodyText>}
                  </div>

                  {/* Status */}
                  <div
                    className={tw(
                      "flex items-center gap-2 flex-nowrap",
                      tw(columnWidths.status)
                    )}
                  >
                    {renderStatus(address.status!)}
                  </div>

                  {/* Actions */}
                  <div className={tw("flex justify-end", columnWidths.actions)}>
                    {address.status === StakeAccountStatus.Active &&
                      renderUndelegateButton(address as StakeAccount)}
                    {address.status === StakeAccountStatus.Inactive &&
                      renderWithdrawButton(address as StakeAccount)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {hasNextPage && (
        <div className="pb-4 flex justify-center">
          <Button
            palette="text"
            size="tiny"
            disabled={isFetchingNextPage}
            onClick={fetchNextPage}
          >
            More Positions
          </Button>
        </div>
      )}
    </div>
  );
};
