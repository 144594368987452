import { graphql } from "msw";
import { EthereumStakingFlowQuery } from "../../../graphql/core/generated/gql";

export const EthereumStakingFlowQueryMock =
  graphql.query<EthereumStakingFlowQuery>(
    "EthereumStakingFlow",
    (req, res, ctx) => {
      return res(
        ctx.data({
          ethereumNetworkEstimates: {
            estimatedActivation: {
              hours: 35,
            },
            estimatedWithdrawal: {
              hoursMin: 28,
              hoursMax: 248,
            },
            estimatedExit: {
              hours: 0,
            },
          },
          ethereumRewardsRate: {
            value: 4.0,
          },
          ethereumPrice: {
            value: 1777.72925403491,
          },
        })
      );
    }
  );

export const EthereumStakingFlowQueryLoadingMock =
  graphql.query<EthereumStakingFlowQuery>(
    "EthereumStakingFlow",
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.delay(10000));
    }
  );
