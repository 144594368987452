"use client";

import React from "react";
import { SolanaNetwork, protocols } from "@figmentjs/protocols";
import { Spinner } from "@figmentjs/web-core";
import tailwindConfig from "@figmentjs/web-core/src/theme/tailwind.config";
import { useSolanaRewardsEarnedChartQuery } from "../../../graphql/core/generated/gql";
import { RewardsEarnedChart } from "./components";

type Props = {
  isTestnetMode: boolean;
  showLinkToDownloadsPage?: boolean;
};

export const SolanaRewardsEarnedChart: React.FC<Props> = ({
  isTestnetMode,
  showLinkToDownloadsPage = false,
}) => {
  const { data, isLoading } = useSolanaRewardsEarnedChartQuery({
    network: isTestnetMode ? SolanaNetwork.DEVNET : SolanaNetwork.MAINNET,
  });

  if (isLoading) {
    return (
      <div className="py-40 w-100 flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="bg-white">
      <RewardsEarnedChart
        rewardsEarned={data!}
        isTestnetMode={isTestnetMode}
        showLinkToDownloadsPage={showLinkToDownloadsPage}
        labels={{
          consensusLayer: "Protocol Rewards",
          executionLayer: "MEV Rewards",
        }}
        ticker={
          isTestnetMode
            ? protocols.SOLANA.networks.devnet.ticker
            : protocols.SOLANA.ticker
        }
        colors={{
          consensusLayer: tailwindConfig.theme.colors.solana[1000],
          executionLayer: tailwindConfig.theme.colors.solana[400],
        }}
        legendLabels={{
          consensusLayer: "Protocol (inflationary)",
          executionLayer: "MEV",
        }}
      />
    </div>
  );
};
