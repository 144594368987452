import React from "react";
import { BodyText, Button, InputRadio, InputText } from "@figmentjs/web-core";
import { useSegment } from "../../../../hooks/use-segment/use-segment";
import {
  UniversalWidgetActionEnum,
  UniversalWidgetObjectEnum,
} from "@figmentjs/analytics/apps";

export type Props = {
  label: string;
  onActivate: () => void;
  onSubmit: () => void;
};

export const UnstakingFeedback: React.FC<Props> = ({
  label,
  onActivate,
  onSubmit,
}) => {
  const { trackEvent } = useSegment();
  const [feedback, setFeedback] = React.useState<{
    reason?: string;
    message?: string;
  } | null>();

  return (
    <div className="flex flex-col">
      <BodyText weight="semibold">{label}</BodyText>
      <div className="mt-2 flex flex-col gap-4">
        <InputRadio
          layout="column"
          value={feedback?.reason}
          onChange={(value: string) => {
            const updatedFeedback = {
              reason: value,
              message: feedback?.message,
            };
            setFeedback(updatedFeedback);
            onActivate();
          }}
          options={[
            {
              value: "Liquidity / Reallocation",
              name: "Liquidity / Reallocation",
            },
            { value: "Performance Concerns", name: "Performance Concerns" },
            { value: "Other", name: "Other" },
          ]}
        />
        {feedback?.reason && (
          <>
            <InputText
              multiline
              borderClassName="border-basic-600"
              placeholder="We’d love your feedback (optional)"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const updatedFeedback = {
                  reason: feedback!.reason,
                  message: event.currentTarget.value,
                };
                setFeedback(updatedFeedback);
              }}
            />
            <Button
              size="small"
              onClick={() => {
                trackEvent(
                  {
                    object: UniversalWidgetObjectEnum.UNSTAKING_FLOW,
                    action:
                      UniversalWidgetActionEnum.UNSTAKING_FEEDBACK_SUBMITTED,
                  },
                  {
                    message: feedback!.message,
                    reason: feedback!.reason,
                  }
                );
                onSubmit();
              }}
            >
              Submit & Close
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
