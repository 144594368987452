import * as React from "react";

export const TableLoading: React.FC = () => {
  return (
    <div className="flex gap-4 w-full p-4">
      <div className="w-full h-6 bg-basic-200 rounded-md animate-pulse"></div>
      <div className="w-full h-6 bg-basic-200 rounded-md animate-pulse"></div>
      <div className="w-full h-6 bg-basic-200 rounded-md animate-pulse"></div>
    </div>
  );
};
