import React, { memo } from "react";
import { twMerge as tw } from "tailwind-merge";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { PopoverProps } from "./Popover.types";

/**
 * Renders the Popover component.
 *
 * @param PopoverProps
 * @returns Popover component with children as the popover content
 */
const Popover = ({
  className,
  children,
  placement,
  trigger,
  dark,
  ...props
}: PopoverProps) => {
  const bgStyles = dark ? "bg-basic-900" : "bg-white";
  const svgStyles = dark ? "fill-basic-900" : "fill-white";
  const textStyles = dark ? "text-white" : "text-basic-900";
  const roundedStyles = "rounded-lg";

  return (
    <span className="w-auto">
      <PopoverPrimitive.Root {...props}>
        <PopoverPrimitive.Trigger asChild>
          <span>{trigger}</span>
        </PopoverPrimitive.Trigger>
        <PopoverPrimitive.Anchor />
        <PopoverPrimitive.Portal>
          <PopoverPrimitive.Content
            side={placement}
            align="center"
            sideOffset={4}
            className={tw(
              "inline-flex items-center shadow z-10",
              // TODO: [FIGAPP-735] Popover: use props instead of className override
              className,
              // override required styles from className prop
              bgStyles,
              textStyles,
              roundedStyles
            )}
          >
            <PopoverPrimitive.Close
              className={tw("fill-current", textStyles)}
            />
            <PopoverPrimitive.Arrow className={svgStyles} />
            <div className={tw("overflow-hidden", roundedStyles)}>
              {children}
            </div>
          </PopoverPrimitive.Content>
        </PopoverPrimitive.Portal>
      </PopoverPrimitive.Root>
    </span>
  );
};

export default memo(Popover);
