import BigNumber from "bignumber.js";

import { dateFormatter } from "@figmentjs/utils";
import {
  AggregateRewards,
  Currencies,
  SupportedDurations,
} from "./rewards-earned-chart.types";

const SMALL_CHART_WIDTH = 560;

export type XTickFormatterFunction = (params: {
  date: string;
  index: number;
  width: number;
  dateLabelFormat: string;
  duration: SupportedDurations;
}) => string;

export const xTickFormatter: XTickFormatterFunction = ({
  date,
  index,
  width,
  dateLabelFormat,
  duration,
}) => {
  if (
    width > SMALL_CHART_WIDTH ||
    index % 2 === 0 ||
    duration !== SupportedDurations.OneMonth
  ) {
    return dateFormatter({
      inputDate: date,
      format: dateLabelFormat,
      timeZone: "UTC",
    });
  }

  return "";
};

export const getDate = ({ timestamp }: AggregateRewards): string => timestamp!;

export const yDomainFunc = (
  data: AggregateRewards[] | null | undefined,
  currency: string
): number[] => {
  const usdTotals = data?.map(
    (rewardsPerDay) => new BigNumber(rewardsPerDay.totalUsd || "0")
  );

  const tokenTotals = data?.map(
    (rewardsPerDay) => new BigNumber(rewardsPerDay.total || "0")
  );

  const totals = currency === Currencies.USD ? usdTotals : tokenTotals;

  return [
    BigNumber.min(0, ...(totals || [])).toNumber(),
    BigNumber.max(...(totals || [])).toNumber(),
  ];
};
