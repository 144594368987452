import React from "react";
import BigNumber from "bignumber.js";
import { Network, Protocol, protocols } from "@figmentjs/protocols";
import { currencyFormatter } from "@figmentjs/utils";
import { Icon } from "@figmentjs/web-core";
import tailwindConfig from "@figmentjs/web-core/src/theme/tailwind.config";
import {
  EthereumNetworks,
  SolanaNetworks,
  useBalanceAndRewardsCardsQuery,
} from "../../../graphql/core/generated/gql";
import { StakingPositionCard } from "../staking-position/staking-position-card";
import { ProtocolDonutChart } from "../protocol-donut-chart";

type Props = {
  isTestnetMode: boolean;
};

export const BalanceAndRewardsCards: React.FC<Props> = ({ isTestnetMode }) => {
  const { data, isLoading } = useBalanceAndRewardsCardsQuery({
    ethereumRewardsNetwork: isTestnetMode ? Network.HOLESKY : Network.MAINNET,
    ethereumBalanceNetwork: isTestnetMode
      ? EthereumNetworks.Holesky
      : EthereumNetworks.Mainnet,
    solanaNetwork: isTestnetMode
      ? SolanaNetworks.Devnet
      : SolanaNetworks.Mainnet,
  });

  const stakedBalanceUsd = currencyFormatter.format(
    BigNumber.sum(
      data?.solanaBalanceSummary.balanceUsd || 0,
      data?.ethereumBalanceSummary.balanceUsd || 0
    )
  );
  const allTimeRewardsUsd = currencyFormatter.format(
    BigNumber.sum(
      data?.rewardsSummary.totalRewardsUsd || 0,
      data?.solanaRewardsSummary.totalRewardsUsd || 0
    )
  );

  return (
    <div className="flex flex-col gap-4 sm:flex-row w-full">
      <StakingPositionCard
        title="Total Staked"
        amount={stakedBalanceUsd}
        isLoading={isLoading}
        aside={
          <div className="ml-4 w-[75px] h-[75px]">
            <ProtocolDonutChart
              data={[
                {
                  label: isTestnetMode
                    ? protocols.ETHEREUM.networks.holesky.displayName
                    : protocols.ETHEREUM.displayName,
                  value: BigNumber(
                    data?.ethereumBalanceSummary.balanceUsd || 0
                  ).toNumber(),
                  tokens: data?.ethereumBalanceSummary.balance || "0",
                  color: tailwindConfig.theme.colors.ethereum.DEFAULT,
                  ticker: isTestnetMode
                    ? protocols.ETHEREUM.networks.holesky.ticker
                    : protocols.ETHEREUM.ticker,
                  protocol: Protocol.ETHEREUM,
                  icon: <Icon icon="Ethereum" size="md" />,
                },
                {
                  label: isTestnetMode
                    ? protocols.SOLANA.networks.devnet.displayName
                    : protocols.SOLANA.displayName,
                  value: BigNumber(
                    data?.solanaBalanceSummary.balanceUsd || 0
                  ).toNumber(),
                  tokens: data?.solanaBalanceSummary.balance || "0",
                  color: tailwindConfig.theme.colors.solana.DEFAULT,
                  ticker: isTestnetMode
                    ? protocols.SOLANA.networks.devnet.ticker
                    : protocols.SOLANA.ticker,
                  protocol: Protocol.SOLANA,
                  icon: <Icon icon="Solana" size="md" />,
                },
              ]}
            />
          </div>
        }
      />
      <StakingPositionCard
        title="All Time Rewards"
        amount={allTimeRewardsUsd}
        isLoading={isLoading}
        aside={
          <div
            className="ml-4 w-[75px] h-[75px]"
            data-testid="rewards-donut-chart"
          >
            <ProtocolDonutChart
              data={[
                {
                  label: protocols.ETHEREUM.displayName,
                  value: BigNumber(
                    data?.rewardsSummary.totalRewardsUsd || 0
                  ).toNumber(),
                  tokens: data?.rewardsSummary.totalRewards || "0",
                  color: tailwindConfig.theme.colors.ethereum.DEFAULT,
                  ticker: protocols.ETHEREUM.ticker,
                  protocol: Protocol.ETHEREUM,
                  icon: <Icon icon="Ethereum" size="md" />,
                },
                {
                  label: protocols.SOLANA.displayName,
                  value: BigNumber(
                    data?.solanaRewardsSummary.totalRewardsUsd || 0
                  ).toNumber(),
                  tokens: data?.solanaRewardsSummary.totalRewards || "0",
                  color: tailwindConfig.theme.colors.solana.DEFAULT,
                  ticker: protocols.SOLANA.ticker,
                  protocol: Protocol.SOLANA,
                  icon: <Icon icon="Solana" size="md" />,
                },
              ]}
            />
          </div>
        }
      />
    </div>
  );
};
