import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
} from "@tanstack/react-query";
import { coreGQLFetcher } from "../fetcher";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ISO8601Date: any;
  ISO8601DateTime: any;
  JSON: any;
};

/** A wallet address or withdrawal account address tracked for rewards and balances. */
export type Account = {
  __typename?: "Account";
  /** The account address (e.g. 0xabc123) */
  address: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  /** The same as address, but checksummed or formatted for the specific protocol. */
  formattedAddress: Scalars["String"];
  id: Scalars["ID"];
  /** Whether the account has active delegation (manually managed) */
  isActive: Scalars["Boolean"];
  /** A free-form note about the account */
  label?: Maybe<Scalars["String"]>;
  /** The protocol network name (e.g. mainnet) */
  network: Scalars["String"];
  /** The Salesforce Opportunity Token. */
  opToken?: Maybe<OpToken>;
  /** Options that are set per account type. This is generic JSON because all account types can have different options. */
  options: Scalars["JSON"];
  /** The protocol name (e.g. Ethereum) */
  protocol: Scalars["String"];
  /** Signals the need to report for a white label validator */
  reportForWhiteLabelValidator: Scalars["Boolean"];
  /** Where this account was first added from */
  source?: Maybe<Scalars["String"]>;
  /** The type of account */
  type?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for Account. */
export type AccountConnection = {
  __typename?: "AccountConnection";
  /** A list of edges. */
  edges: Array<AccountEdge>;
  /** A list of nodes. */
  nodes: Array<Account>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type AccountEdge = {
  __typename?: "AccountEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Account;
};

export enum AccountOrderByFields {
  /** The account address */
  Address = "address",
  /** Date the account was added */
  CreatedAt = "createdAt",
  /** The network of the protocol (e.g. mainnet) */
  Network = "network",
  /** The protocol the address is for (e.g. ethereum) */
  Protocol = "protocol",
}

export type Accounts = {
  field: AccountOrderByFields;
  sortDirection: SortDirection;
};

/** Attributes for filtering tracked accounts. */
export type AccountsFilter = {
  address_cont?: InputMaybe<Scalars["String"]>;
  address_eq?: InputMaybe<Scalars["String"]>;
  address_i_cont?: InputMaybe<Scalars["String"]>;
  network_eq?: InputMaybe<Scalars["String"]>;
  organization_id_eq?: InputMaybe<Scalars["String"]>;
  protocol_eq?: InputMaybe<Scalars["String"]>;
  source_eq?: InputMaybe<Scalars["String"]>;
  source_id_eq?: InputMaybe<Scalars["String"]>;
};

export type AccountsInvitation = {
  __typename?: "AccountsInvitation";
  invitation?: Maybe<Invitation>;
};

export type AccountsInvitationResendInput = {
  email: Scalars["String"];
  organizationId: Scalars["ID"];
};

export type AccountsSignUp = {
  __typename?: "AccountsSignUp";
  acceptedTos?: Maybe<Scalars["Boolean"]>;
  email?: Maybe<Scalars["String"]>;
  isIndividual?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  organization?: Maybe<Organization>;
  organizationName?: Maybe<Scalars["String"]>;
};

export type AccountsSignUpInput = {
  acceptedTos: Scalars["Boolean"];
  email: Scalars["String"];
  isIndividual: Scalars["Boolean"];
  name: Scalars["String"];
  organizationName?: InputMaybe<Scalars["String"]>;
  recaptchaToken: Scalars["String"];
};

export enum ActivityTypes {
  /** Ethereum batch staking */
  Deposit = "deposit",
  /** Ethereum batch unstaking */
  ExitRequest = "exit_request",
}

export type Address = {
  __typename?: "Address";
  additionalDetails?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  streetAddress?: Maybe<Scalars["String"]>;
  zipCode?: Maybe<Scalars["String"]>;
};

/** Attributes for address */
export type AddressInput = {
  additionalDetails?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  streetAddress?: InputMaybe<Scalars["String"]>;
  zipCode?: InputMaybe<Scalars["String"]>;
};

export type AggregatedRewards = {
  __typename?: "AggregatedRewards";
  rewards?: Maybe<Array<AggregatedRewardsTuple>>;
};

export type AggregatedRewardsTuple = {
  __typename?: "AggregatedRewardsTuple";
  consensusTotal?: Maybe<Scalars["String"]>;
  consensusTotalUsd?: Maybe<Scalars["String"]>;
  executionTotal?: Maybe<Scalars["String"]>;
  executionTotalReceived?: Maybe<Scalars["String"]>;
  executionTotalReceivedUsd?: Maybe<Scalars["String"]>;
  executionTotalUsd?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["ISO8601DateTime"]>;
  total?: Maybe<Scalars["String"]>;
  totalReceived?: Maybe<Scalars["String"]>;
  totalReceivedUsd?: Maybe<Scalars["String"]>;
  totalUsd?: Maybe<Scalars["String"]>;
};

export type ApiKey = {
  __typename?: "ApiKey";
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  projectId: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
  value: Scalars["String"];
};

/** The connection type for ApiKey. */
export type ApiKeyConnection = {
  __typename?: "ApiKeyConnection";
  /** A list of edges. */
  edges: Array<ApiKeyEdge>;
  /** A list of nodes. */
  nodes: Array<ApiKey>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** Attributes for creating an Api Key */
export type ApiKeyCreateInput = {
  name: Scalars["String"];
  projectId: Scalars["String"];
};

/** An edge in a connection. */
export type ApiKeyEdge = {
  __typename?: "ApiKeyEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: ApiKey;
};

export type ApiKeyMutationPayload = {
  __typename?: "ApiKeyMutationPayload";
  /** ApiKey object */
  data?: Maybe<ApiKey>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum ApiKeyOrderByFields {
  /** Date the Api Key was created */
  CreatedAt = "createdAt",
  /** The name of the Api Key */
  Name = "name",
  /** The Project ID that Api Key belongs to */
  ProjectId = "projectId",
}

/** Attributes for updating an Api Key */
export type ApiKeyUpdateInput = {
  name: Scalars["String"];
};

export type ApiKeys = {
  field: ApiKeyOrderByFields;
  sortDirection: SortDirection;
};

export type BabylonNetworkOverview = {
  __typename?: "BabylonNetworkOverview";
  finalityProviderAddress: Scalars["String"];
  maxStakingAmount: Scalars["Int"];
  maxStakingTime: Scalars["Int"];
  minStakingAmount: Scalars["Int"];
  minStakingTime: Scalars["Int"];
};

export type BabylonStake = {
  __typename?: "BabylonStake";
  address?: Maybe<Scalars["String"]>;
  /** Amount staked in BTC */
  amount?: Maybe<Scalars["String"]>;
  amountSatoshi?: Maybe<Scalars["String"]>;
  covenantPubkeys?: Maybe<Scalars["String"]>;
  covenantThreshold?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  minUnbondingTime?: Maybe<Scalars["Int"]>;
  network?: Maybe<Scalars["String"]>;
  organizationId: Scalars["String"];
  stakerPubkey?: Maybe<Scalars["String"]>;
  stakingDuration?: Maybe<Scalars["Int"]>;
  state?: Maybe<BabylonStates>;
  txHash?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Inputs adding tx hash to Babylon stake */
export type BabylonStakeBroadcastedInput = {
  stakeId: Scalars["String"];
  txHash: Scalars["String"];
};

/** The connection type for BabylonStake. */
export type BabylonStakeConnection = {
  __typename?: "BabylonStakeConnection";
  /** A list of edges. */
  edges: Array<BabylonStakeEdge>;
  /** A list of nodes. */
  nodes: Array<BabylonStake>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type BabylonStakeEdge = {
  __typename?: "BabylonStakeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: BabylonStake;
};

/** Inputs for stake id and doing Babylon mutations */
export type BabylonStakeIdInput = {
  stakeId: Scalars["String"];
};

/** Inputs for creating a BTC staking tx on Babylon */
export type BabylonStakingTxInput = {
  address: Scalars["String"];
  changeAddress: Scalars["String"];
  network: BitcoinNetworks;
  stakerPk: Scalars["String"];
  stakingAmount: Scalars["Int"];
  stakingDuration: Scalars["Int"];
  taprootPubkey?: InputMaybe<Scalars["String"]>;
  utxos: Array<BitcoinUtxo>;
};

export enum BabylonStates {
  Active = "active",
  Broadcasted = "broadcasted",
  Init = "init",
  Unbonded = "unbonded",
  UnbondingBroadcasted = "unbonding_broadcasted",
  UnbondingRequested = "unbonding_requested",
  Withdrawal = "withdrawal",
  WithdrawalBroadcasted = "withdrawal_broadcasted",
}

export type BabylonTx = {
  __typename?: "BabylonTx";
  signingPayload?: Maybe<Scalars["String"]>;
  stakeId: Scalars["String"];
  unsignedTransactionSerialized: Scalars["String"];
};

/** Inputs for getting an unsigned withdrawal tx */
export type BabylonWithdrawalInput = {
  stakeId: Scalars["String"];
  withdrawalAddress: Scalars["String"];
};

export enum BitcoinNetworks {
  Mainnet = "mainnet",
  Signet = "signet",
}

export type BitcoinUtxo = {
  /** script which provides the conditions that must be fulfilled for this UTXO to be spent */
  scriptPubKey: Scalars["String"];
  /** transaction ID */
  txid: Scalars["String"];
  /** value of the UTXO, in Satoshis */
  value: Scalars["Int"];
  /** output index */
  vout: Scalars["Int"];
};

export type CommssionRate = {
  __typename?: "CommssionRate";
  /** The commission rate. */
  rate?: Maybe<Scalars["Float"]>;
};

export enum CurrencyTickers {
  Btc = "BTC",
  Eth = "ETH",
  Sol = "SOL",
}

/** Attributes for scheduling a daily report */
export type DailyReportScheduleInput = {
  chainId?: InputMaybe<Scalars["String"]>;
  end: Scalars["String"];
  network: Scalars["String"];
  start: Scalars["String"];
  timeRollup: DailyReportTimeRollup;
};

export enum DailyReportStatus {
  /** Error */
  Error = "error",
  /** Finished */
  Finished = "finished",
  /** Running */
  Running = "running",
  /** Submitted */
  Submitted = "submitted",
  /** Unknown */
  Unknown = "unknown",
}

export enum DailyReportTimeRollup {
  /** Daily */
  Daily = "daily",
  /** Epoch */
  Epoch = "epoch",
  /** Era */
  Era = "era",
}

export type DailyReports = {
  __typename?: "DailyReports";
  chainId?: Maybe<Scalars["String"]>;
  end: Scalars["String"];
  expiresOn?: Maybe<Scalars["ISO8601DateTime"]>;
  file?: Maybe<Scalars["String"]>;
  fileExpiresOn?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  network: Scalars["String"];
  start: Scalars["String"];
  status: DailyReportStatus;
  timeRollup: DailyReportTimeRollup;
};

/** The connection type for DailyReports. */
export type DailyReportsConnection = {
  __typename?: "DailyReportsConnection";
  /** A list of edges. */
  edges: Array<DailyReportsEdge>;
  /** A list of nodes. */
  nodes: Array<DailyReports>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type DailyReportsEdge = {
  __typename?: "DailyReportsEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: DailyReports;
};

export type DailyReportsMutationPayload = {
  __typename?: "DailyReportsMutationPayload";
  /** DailyReports object */
  data?: Maybe<DailyReports>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of DeactivateTransactionCreateMutation. */
export type DeactivateTransactionCreateMutationPayload = {
  __typename?: "DeactivateTransactionCreateMutationPayload";
  data?: Maybe<SolanaStakingTransactionPayload>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of DelegateTransactionCreateMutation. */
export type DelegateTransactionCreateMutationPayload = {
  __typename?: "DelegateTransactionCreateMutationPayload";
  data?: Maybe<SolanaStakingTransactionPayload>;
  userErrors?: Maybe<Array<MutationError>>;
};

export type Deposit = {
  __typename?: "Deposit";
  address: Scalars["String"];
  amountEth: Scalars["Float"];
  network: Scalars["String"];
  unsignedTransactionHash: Scalars["String"];
  unsignedTransactionPayload: Scalars["String"];
};

export type DepositMutationPayload = {
  __typename?: "DepositMutationPayload";
  /** Deposit object */
  data?: Maybe<Deposit>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum DepositStatuses {
  Activated = "activated",
  Activating = "activating",
  Deposited = "deposited",
  Failed = "failed",
  Initialized = "initialized",
  Unfinalized = "unfinalized",
}

export type EigenlayerDelegateTo = {
  __typename?: "EigenlayerDelegateTo";
  network: Networks;
  operatorAddress: Scalars["String"];
  ownerAddress: Scalars["String"];
  txSigningPayload?: Maybe<Scalars["String"]>;
  unsignedRawTx?: Maybe<Scalars["String"]>;
};

/** Parameters to build a tx to delegate an Eigenpod to an Operator */
export type EigenlayerDelegateToInput = {
  network: EthereumNetworks;
  operatorAddress: Scalars["String"];
  ownerAddress: Scalars["String"];
};

export type EigenlayerDelegateToMutationPayload = {
  __typename?: "EigenlayerDelegateToMutationPayload";
  /** EigenlayerDelegateTo object */
  data?: Maybe<EigenlayerDelegateTo>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export type EigenlayerOperators = {
  __typename?: "EigenlayerOperators";
  address: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  network: Scalars["String"];
  operatedBy: OperatedBy;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for EigenlayerOperators. */
export type EigenlayerOperatorsConnection = {
  __typename?: "EigenlayerOperatorsConnection";
  /** A list of edges. */
  edges: Array<EigenlayerOperatorsEdge>;
  /** A list of nodes. */
  nodes: Array<EigenlayerOperators>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type EigenlayerOperatorsEdge = {
  __typename?: "EigenlayerOperatorsEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: EigenlayerOperators;
};

export type Eigenpod = {
  __typename?: "Eigenpod";
  address: Scalars["String"];
  confirmedDeployedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  createdAt: Scalars["ISO8601DateTime"];
  createpodTxSigningPayload?: Maybe<Scalars["String"]>;
  deployed: Scalars["Boolean"];
  id: Scalars["ID"];
  network: Networks;
  ownerAddress: Scalars["String"];
  unsignedRawCreatepodTx?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Parameters to create an Eigenpod tracking record in our database, and possibly return a tx to create one onchain. */
export type EigenpodCreateInput = {
  network: EthereumNetworks;
  ownerAddress: Scalars["String"];
};

export type EigenpodMutationPayload = {
  __typename?: "EigenpodMutationPayload";
  /** Eigenpod object */
  data?: Maybe<Eigenpod>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export type EigenpodVerification = {
  __typename?: "EigenpodVerification";
  eigenpodAddress: Scalars["String"];
  network: Networks;
  proofs: Scalars["JSON"];
  transactionInputs: Scalars["JSON"];
  transactionPayload: Scalars["String"];
};

/** Parameters to build a tx to validate validators in the Eigenpod */
export type EigenpodVerificationInput = {
  network: EthereumNetworks;
  ownerAddress: Scalars["String"];
};

export type EigenpodVerificationMutationPayload = {
  __typename?: "EigenpodVerificationMutationPayload";
  /** EigenpodVerification object */
  data?: Maybe<EigenpodVerification>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

/** A wallet address or withdrawal account address tracked for rewards and balances. */
export type EthereumAccount = {
  __typename?: "EthereumAccount";
  /** The account address (e.g. 0xabc123) */
  address: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  /** Address of Operator this account's Eigenpod is delegated to */
  delegatedTo?: Maybe<Scalars["String"]>;
  /** Address of the wallet that owns the Eigenpod */
  eigenpodOwnerAddress?: Maybe<Scalars["String"]>;
  /** The same as address, but checksummed or formatted for the specific protocol. */
  formattedAddress: Scalars["String"];
  id: Scalars["ID"];
  /** Whether the account has active delegation (manually managed) */
  isActive: Scalars["Boolean"];
  /** Is this address an Eigenpod? */
  isEigenpod: Scalars["Boolean"];
  /** Is this address a Vault? */
  isVault: Scalars["Boolean"];
  /** A free-form note about the account */
  label?: Maybe<Scalars["String"]>;
  /** The protocol network name (e.g. mainnet) */
  network: Scalars["String"];
  /** The Salesforce Opportunity Token. */
  opToken?: Maybe<OpToken>;
  /** The opportunity token for this account */
  opTokenId?: Maybe<Scalars["String"]>;
  /** Options that are set per account type. This is generic JSON because all account types can have different options. */
  options: Scalars["JSON"];
  /** The protocol name (e.g. Ethereum) */
  protocol: Scalars["String"];
  /** Signals the need to report for a white label validator */
  reportForWhiteLabelValidator: Scalars["Boolean"];
  /** Where this account was first added from */
  source?: Maybe<Scalars["String"]>;
  /** Sum of all staked balance */
  stakedBalance?: Maybe<Scalars["String"]>;
  /** Sum of all staked balance in USD */
  stakedBalanceUsd?: Maybe<Scalars["String"]>;
  /** total number of active validators */
  totalActiveValidators: Scalars["Int"];
  /** The number of validators eligible to exit */
  totalExitableValidators: Scalars["Int"];
  /** Sum of all gross/earned rewards */
  totalRewards?: Maybe<Scalars["String"]>;
  /** Sum of all rewards received */
  totalRewardsReceived?: Maybe<Scalars["String"]>;
  /** Sum of all rewards received in USD */
  totalRewardsReceivedUsd?: Maybe<Scalars["String"]>;
  /** Sum of all gross/earned rewards in USD */
  totalRewardsUsd?: Maybe<Scalars["String"]>;
  /** The number of eigenlayer unverified validators within this pod */
  totalUnverifiedValidators: Scalars["Int"];
  /** The number of validators with a withdrawal address matching this account */
  totalValidators: Scalars["Int"];
  /** The number of eigenlayer verified validators within this pod */
  totalVerifiedValidators: Scalars["Int"];
  /** The type of account */
  type?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  /** Totals by validator status */
  validatorCountsByStatus?: Maybe<ValidatorCountsByStatus>;
};

/** A wallet address or withdrawal account address tracked for rewards and balances. */
export type EthereumAccountTotalActiveValidatorsArgs = {
  operatedBy?: InputMaybe<OperatedBy>;
};

/** A wallet address or withdrawal account address tracked for rewards and balances. */
export type EthereumAccountTotalExitableValidatorsArgs = {
  operatedBy?: InputMaybe<OperatedBy>;
};

/** A wallet address or withdrawal account address tracked for rewards and balances. */
export type EthereumAccountTotalValidatorsArgs = {
  operatedBy?: InputMaybe<OperatedBy>;
};

/** Autogenerated return type of EthereumAccountAddMutation. */
export type EthereumAccountAddMutationPayload = {
  __typename?: "EthereumAccountAddMutationPayload";
  data?: Maybe<Account>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** The connection type for EthereumAccount. */
export type EthereumAccountConnection = {
  __typename?: "EthereumAccountConnection";
  /** A list of edges. */
  edges: Array<EthereumAccountEdge>;
  /** A list of nodes. */
  nodes: Array<EthereumAccount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type EthereumAccountEdge = {
  __typename?: "EthereumAccountEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: EthereumAccount;
};

export type EthereumBalanceSummary = {
  __typename?: "EthereumBalanceSummary";
  balance?: Maybe<Scalars["String"]>;
  balanceUsd?: Maybe<Scalars["String"]>;
};

export type EthereumExitRequest = {
  __typename?: "EthereumExitRequest";
  activity: Scalars["String"];
  activityAt: Scalars["ISO8601DateTime"];
  amountEth: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  estimatedPrincipalReturnAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedWithdrawableAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  network: Networks;
  pubkeys: Array<Scalars["String"]>;
  region?: Maybe<Regions>;
  status: ExitRequestStatuses;
  updatedAt: Scalars["ISO8601DateTime"];
  user?: Maybe<User>;
  validators: Array<EthereumStakingValidator>;
  withdrawalAddress: Scalars["String"];
};

/** Parameters to exit a set of validators. */
export type EthereumExitRequestCreateInput = {
  amountEth: Scalars["Int"];
  network: Networks;
  region?: InputMaybe<Regions>;
  withdrawalAddress: Scalars["String"];
};

export type EthereumExitRequestMutationPayload = {
  __typename?: "EthereumExitRequestMutationPayload";
  /** EthereumExitRequest object */
  data?: Maybe<EthereumExitRequest>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum EthereumNetworks {
  /** Ethereum holesky testnet */
  Holesky = "holesky",
  /** Ethereum mainnet */
  Mainnet = "mainnet",
}

export type EthereumStakingDeposit = {
  __typename?: "EthereumStakingDeposit";
  activity: Scalars["String"];
  activityAt: Scalars["ISO8601DateTime"];
  amountEth: Scalars["String"];
  blockNumber?: Maybe<Scalars["Int"]>;
  blockTime?: Maybe<Scalars["ISO8601DateTime"]>;
  createdAt: Scalars["ISO8601DateTime"];
  estimatedActiveAt?: Maybe<Scalars["ISO8601DateTime"]>;
  fundingAddress?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  network: Networks;
  provisioners?: Maybe<Array<User>>;
  status: DepositStatuses;
  trackingTransaction?: Maybe<TrackingTransaction>;
  txHash: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
  validators: Array<EthereumStakingValidator>;
};

export type EthereumStakingRequest = {
  __typename?: "EthereumStakingRequest";
  amountEth: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  feeRecipientAddress?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  maxGasWei: Scalars["String"];
  network: Networks;
  region: Regions;
  unsignedTransactionHashed: Scalars["String"];
  unsignedTransactionSerialized: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
  validators: Array<EthereumStakingValidator>;
  withdrawalAddress: Scalars["String"];
};

/** Parameters to create a set of validators and staking transaction. */
export type EthereumStakingRequestCreateInput = {
  amountEth: Scalars["Int"];
  feeRecipientAddress?: InputMaybe<Scalars["String"]>;
  fundingAddress?: InputMaybe<Scalars["String"]>;
  network: Networks;
  region?: InputMaybe<Regions>;
  withdrawalAddress: Scalars["String"];
};

export type EthereumStakingRequestMutationPayload = {
  __typename?: "EthereumStakingRequestMutationPayload";
  /** EthereumStakingRequest object */
  data?: Maybe<EthereumStakingRequest>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

/** Parameters to set the transaction hash for a Staking Request. */
export type EthereumStakingRequestSetTxHashInput = {
  amountEth?: InputMaybe<Scalars["Int"]>;
  blockNumber?: InputMaybe<Scalars["Int"]>;
  blockTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
  fundingAddress?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  txHash: Scalars["String"];
};

export type EthereumStakingValidator = {
  __typename?: "EthereumStakingValidator";
  amountEth: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  deposit?: Maybe<EthereumStakingDeposit>;
  estimatedActiveAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedExitAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedWithdrawalAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  network: Networks;
  pubkey: Scalars["String"];
  region: Regions;
  status: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
  withdrawalAddress: Scalars["String"];
};

export type EthereumTransactionPayload = {
  __typename?: "EthereumTransactionPayload";
  signingPayload: Scalars["String"];
  unsignedTransactionSerialized: Scalars["String"];
};

export type EthereumTransactionPayloadMutationPayload = {
  __typename?: "EthereumTransactionPayloadMutationPayload";
  /** EthereumTransactionPayload object */
  data?: Maybe<EthereumTransactionPayload>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum ExitRequestStatuses {
  Completed = "completed",
  ExitRequested = "exit_requested",
  Exiting = "exiting",
  PendingWithdrawal = "pending_withdrawal",
}

export type FigmentValidatorCounts = {
  __typename?: "FigmentValidatorCounts";
  /** Totals by validator status */
  byStatus?: Maybe<ValidatorCountsByStatus>;
  /** Active validators which have already been requested to exit */
  exitPending?: Maybe<Scalars["Int"]>;
  /** Active validators which have not yet been requested to exit */
  exitable?: Maybe<Scalars["Int"]>;
  /** The total number of validators across all statuses */
  total?: Maybe<Scalars["Int"]>;
};

export type Invitation = {
  __typename?: "Invitation";
  createdAt: Scalars["ISO8601DateTime"];
  email: Scalars["String"];
  expiresAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  organization: Organization;
  role: Role;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for Invitation. */
export type InvitationConnection = {
  __typename?: "InvitationConnection";
  /** A list of edges. */
  edges: Array<InvitationEdge>;
  /** A list of nodes. */
  nodes: Array<Invitation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** Attributes for creating an invitation */
export type InvitationCreateInput = {
  clientId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  name: Scalars["String"];
  roleId: Scalars["ID"];
};

/** An edge in a connection. */
export type InvitationEdge = {
  __typename?: "InvitationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Invitation;
};

export type InvitationMutationPayload = {
  __typename?: "InvitationMutationPayload";
  /** Invitation object */
  data?: Maybe<Invitation>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum InvitationOrderByFields {
  /** Date the invitation was created */
  CreatedAt = "createdAt",
  /** Date the invitation was updated */
  UpdatedAt = "updatedAt",
}

export type Invitations = {
  field: InvitationOrderByFields;
  sortDirection: SortDirection;
};

/** Attributes for creating a Liquid Collective Claim Transaction */
export type LiquidCollectiveClaimTransactionCreateInput = {
  gasLimit?: InputMaybe<Scalars["Float"]>;
  gasPrice?: InputMaybe<Scalars["Float"]>;
  network: EthereumNetworks;
  redeemRequestId: Scalars["String"];
};

/** Attributes for creating a Liquid Collective Deposit */
export type LiquidCollectiveDepositCreateInput = {
  address: Scalars["String"];
  amountEth: Scalars["Float"];
  network: Scalars["String"];
};

/** Attributes for creating a Liquid Collective Redeem Request */
export type LiquidCollectiveRedeemRequestCreateInput = {
  address: Scalars["String"];
  amountLseth: Scalars["String"];
  gasLimit?: InputMaybe<Scalars["Float"]>;
  gasPrice?: InputMaybe<Scalars["Float"]>;
  network: EthereumNetworks;
};

/** Parameters to delegate SOL. */
export type MaxDelegationEstimateInput = {
  fundingAccountPubkey: Scalars["String"];
  network: SolanaNetworks;
  voteAccountPubkey: Scalars["String"];
};

export type Me = {
  __typename?: "Me";
  id: Scalars["ID"];
  organization: Organization;
  otherMemberships: MemberConnection;
  role: Role;
  user: User;
};

export type MeOtherMembershipsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type Member = {
  __typename?: "Member";
  id: Scalars["ID"];
  organization: Organization;
  role: Role;
  user: User;
};

/** The connection type for Member. */
export type MemberConnection = {
  __typename?: "MemberConnection";
  /** A list of edges. */
  edges: Array<MemberEdge>;
  /** A list of nodes. */
  nodes: Array<Member>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type MemberEdge = {
  __typename?: "MemberEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Member;
};

export type MemberMutationPayload = {
  __typename?: "MemberMutationPayload";
  /** Member object */
  data?: Maybe<Member>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum MemberOrderByFields {
  /** Date the member was created */
  CreatedAt = "createdAt",
  /** The email of the member */
  Email = "email",
  /** The name of the member */
  Name = "name",
  /** Date the member was updated last time */
  UpdatedAt = "updatedAt",
}

export type Members = {
  field: MemberOrderByFields;
  sortDirection: SortDirection;
};

export type MfaTicket = {
  __typename?: "MfaTicket";
  sendMail?: Maybe<Scalars["Boolean"]>;
  ticketUrl?: Maybe<Scalars["String"]>;
};

export type MfaTicketMutationPayload = {
  __typename?: "MfaTicketMutationPayload";
  /** MfaTicket object */
  data?: Maybe<MfaTicket>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export type MonthlyStatement = {
  __typename?: "MonthlyStatement";
  downloadUrl?: Maybe<Scalars["String"]>;
  filename?: Maybe<Scalars["String"]>;
  generatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  month?: Maybe<Scalars["ISO8601Date"]>;
  statementType?: Maybe<Statement>;
};

/** The connection type for MonthlyStatement. */
export type MonthlyStatementConnection = {
  __typename?: "MonthlyStatementConnection";
  /** A list of edges. */
  edges: Array<MonthlyStatementEdge>;
  /** A list of nodes. */
  nodes: Array<MonthlyStatement>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type MonthlyStatementEdge = {
  __typename?: "MonthlyStatementEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: MonthlyStatement;
};

export enum MonthlyStatementOrderByFields {
  /** Date the report was created */
  GeneratedAt = "generatedAt",
  /** The month for which the report was generated */
  Month = "month",
  /** The type of monthly statement */
  StatementType = "statementType",
}

export type MonthlyStatements = {
  field: MonthlyStatementOrderByFields;
  sortDirection: SortDirection;
};

export type Mutation = {
  __typename?: "Mutation";
  /** Create api key */
  apiKeyCreate: ApiKeyMutationPayload;
  /** Delete api key */
  apiKeyDelete: ApiKeyMutationPayload;
  /** Update Api Key */
  apiKeyUpdate: ApiKeyMutationPayload;
  /** After a Babylon stake tx is broadcasted, add the tx_hash to the stake record. */
  babylonStakeBroadcasted?: Maybe<StakeBroadcastedMutationPayload>;
  /** Get a tx to stake BTC to Babylon */
  babylonStakeBtc?: Maybe<StakeTransactionCreatePayload>;
  /** Get a tx to stake BTC to Babylon */
  babylonStakeTransactionCreate?: Maybe<StakeTransactionCreatePayload>;
  /** Get a transaction to unbond BTC from Babylon */
  babylonUnbondTransactionCreate?: Maybe<UnbondTransactionCreatePayload>;
  /** Get a transaction to withdraw BTC from Babylon */
  babylonWithdrawalTransactionCreate?: Maybe<WithdrawalTransactionCreatePayload>;
  /** Schedule a daily report */
  dailyReportSchedule: DailyReportsMutationPayload;
  /** Create an Eigenpod record and retrieve a createPod transaction if it has not yet been deployed onchain. */
  eigenlayerDelegateTo: EigenlayerDelegateToMutationPayload;
  /** A transaction payload to verify validator withdrawal credentials for an EigenPod */
  eigenlayerVerifyWithdrawalCredentialsTransactionCreate?: Maybe<VerifyWithdrawalCredentialsTransactionCreatePayload>;
  /** Create an Eigenpod record and retrieve a createPod transaction if it has not yet been deployed onchain. */
  eigenpodCreate: EigenpodMutationPayload;
  /** Create an Eigenpod Verification record and retrieve a verifyWithdrawalCredentials transaction. */
  eigenpodVerificationCreate: EigenpodVerificationMutationPayload;
  /** Request to exit Ethereum Validators. */
  ethereumExitRequestCreate: EthereumExitRequestMutationPayload;
  /** Create validators and retrieve a staking transaction. */
  ethereumStakingRequestCreate: EthereumStakingRequestMutationPayload;
  /** Set the transaction hash for a Staking Request. */
  ethereumStakingRequestSetTxHash: EthereumStakingRequestMutationPayload;
  /** Create Invitation */
  invitationCreate: InvitationMutationPayload;
  /** Delete Invitation */
  invitationDelete: InvitationMutationPayload;
  /** Resend an invitation to a user */
  invitationResend?: Maybe<MutationsAccountsInvitationResendPayload>;
  /** Create liquid collective claim transaction */
  liquidCollectiveClaimTransactionCreate: EthereumTransactionPayloadMutationPayload;
  /** Create liquid collective deposit */
  liquidCollectiveDepositCreate: DepositMutationPayload;
  /** Create liquid collective redeem request */
  liquidCollectiveRedeemRequestCreate: EthereumTransactionPayloadMutationPayload;
  /** Delete Member */
  memberDelete: MemberMutationPayload;
  /** Change Member Role */
  memberRoleChange: MemberMutationPayload;
  /** Update an organization */
  organizationUpdate: OrganizationMutationPayload;
  /** Create project */
  projectCreate: ProjectMutationPayload;
  /** Delete project */
  projectDelete: ProjectMutationPayload;
  /** Update project */
  projectUpdate: ProjectMutationPayload;
  /** Sign up a new user */
  signUpCreate?: Maybe<MutationsAccountsSignUpCreatePayload>;
  /** A transaction payload to deactivate a Stake Account */
  solanaStakingDeactivateTransactionCreate?: Maybe<DeactivateTransactionCreateMutationPayload>;
  /** A transaction payload to create and delegate to a stake account */
  solanaStakingDelegateTransactionCreate?: Maybe<DelegateTransactionCreateMutationPayload>;
  /** Save a transaction hash to the staking activity log. */
  solanaStakingTxSave?: Maybe<TxSaveMutationPayload>;
  /** A transaction payload to withdraw funds from an inactive Stake Account */
  solanaStakingWithdrawTransactionCreate?: Maybe<WithdrawTransactionCreateMutationPayload>;
  /** Tracks an Ethereum funding account */
  trackedEthereumAccountAdd?: Maybe<EthereumAccountAddMutationPayload>;
  /** Creates a url for the user to setup MFA */
  userCreateMfaTicket: MfaTicketMutationPayload;
  /** Resets the users MFA in case they are locked out. */
  userResetMfa: UserMutationPayload;
  /** Sends reset password email */
  userSendResetPasswordEmail: UserMutationPayload;
  /** Update a user's name or email. Also updates the user in Auth0. */
  userUpdate: UserMutationPayload;
};

export type MutationApiKeyCreateArgs = {
  input: ApiKeyCreateInput;
};

export type MutationApiKeyDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationApiKeyUpdateArgs = {
  id: Scalars["ID"];
  input: ApiKeyUpdateInput;
};

export type MutationBabylonStakeBroadcastedArgs = {
  input: BabylonStakeBroadcastedInput;
};

export type MutationBabylonStakeBtcArgs = {
  input: BabylonStakingTxInput;
};

export type MutationBabylonStakeTransactionCreateArgs = {
  input: BabylonStakingTxInput;
};

export type MutationBabylonUnbondTransactionCreateArgs = {
  input: BabylonStakeIdInput;
};

export type MutationBabylonWithdrawalTransactionCreateArgs = {
  input: BabylonWithdrawalInput;
};

export type MutationDailyReportScheduleArgs = {
  input: DailyReportScheduleInput;
};

export type MutationEigenlayerDelegateToArgs = {
  input: EigenlayerDelegateToInput;
};

export type MutationEigenlayerVerifyWithdrawalCredentialsTransactionCreateArgs =
  {
    input: EigenpodVerificationInput;
  };

export type MutationEigenpodCreateArgs = {
  input: EigenpodCreateInput;
};

export type MutationEigenpodVerificationCreateArgs = {
  input: EigenpodVerificationInput;
};

export type MutationEthereumExitRequestCreateArgs = {
  input: EthereumExitRequestCreateInput;
};

export type MutationEthereumStakingRequestCreateArgs = {
  input: EthereumStakingRequestCreateInput;
};

export type MutationEthereumStakingRequestSetTxHashArgs = {
  input: EthereumStakingRequestSetTxHashInput;
};

export type MutationInvitationCreateArgs = {
  input: InvitationCreateInput;
};

export type MutationInvitationDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationInvitationResendArgs = {
  input: AccountsInvitationResendInput;
};

export type MutationLiquidCollectiveClaimTransactionCreateArgs = {
  input: LiquidCollectiveClaimTransactionCreateInput;
};

export type MutationLiquidCollectiveDepositCreateArgs = {
  input: LiquidCollectiveDepositCreateInput;
};

export type MutationLiquidCollectiveRedeemRequestCreateArgs = {
  input: LiquidCollectiveRedeemRequestCreateInput;
};

export type MutationMemberDeleteArgs = {
  organizationId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationMemberRoleChangeArgs = {
  organizationId: Scalars["ID"];
  roleId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationOrganizationUpdateArgs = {
  input: OrganizationUpdateInput;
};

export type MutationProjectCreateArgs = {
  input: ProjectCreateInput;
};

export type MutationProjectDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationProjectUpdateArgs = {
  id: Scalars["ID"];
  input: ProjectUpdateInput;
};

export type MutationSignUpCreateArgs = {
  input: AccountsSignUpInput;
};

export type MutationSolanaStakingDeactivateTransactionCreateArgs = {
  input: SolanaStakingDeactivateTransactionCreateInput;
};

export type MutationSolanaStakingDelegateTransactionCreateArgs = {
  input: SolanaStakingDelegateTransactionCreateInput;
};

export type MutationSolanaStakingTxSaveArgs = {
  input: SolanaStakingTxSaveInput;
};

export type MutationSolanaStakingWithdrawTransactionCreateArgs = {
  input: SolanaStakingWithdrawTransactionCreateInput;
};

export type MutationTrackedEthereumAccountAddArgs = {
  input: TrackedAccountAddInput;
};

export type MutationUserCreateMfaTicketArgs = {
  sendMail: Scalars["Boolean"];
};

export type MutationUserResetMfaArgs = {
  userId: Scalars["ID"];
};

export type MutationUserSendResetPasswordEmailArgs = {
  userId: Scalars["ID"];
};

export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type MutationError = {
  __typename?: "MutationError";
  code: Scalars["String"];
  extras?: Maybe<Scalars["JSON"]>;
  field?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  /** Which input value this error came from */
  path?: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated return type of MutationsAccountsInvitationResend. */
export type MutationsAccountsInvitationResendPayload = {
  __typename?: "MutationsAccountsInvitationResendPayload";
  data?: Maybe<AccountsInvitation>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of MutationsAccountsSignUpCreate. */
export type MutationsAccountsSignUpCreatePayload = {
  __typename?: "MutationsAccountsSignUpCreatePayload";
  data?: Maybe<AccountsSignUp>;
  userErrors?: Maybe<Array<MutationError>>;
};

export type NetworkEstimate = {
  __typename?: "NetworkEstimate";
  hours?: Maybe<Scalars["Int"]>;
  hoursMax?: Maybe<Scalars["Int"]>;
  hoursMin?: Maybe<Scalars["Int"]>;
};

export type NetworkEstimates = {
  __typename?: "NetworkEstimates";
  estimatedActivation?: Maybe<NetworkEstimate>;
  estimatedExit?: Maybe<NetworkEstimate>;
  estimatedWithdrawal?: Maybe<NetworkEstimate>;
};

export type NetworkOverview = {
  __typename?: "NetworkOverview";
  estimatedActivation?: Maybe<NetworkEstimate>;
  estimatedExit?: Maybe<NetworkEstimate>;
  estimatedWithdrawal?: Maybe<NetworkEstimate>;
  price?: Maybe<Price>;
  rewardsRate?: Maybe<RewardsRate>;
};

export enum Networks {
  /** Ethereum goerli testnet */
  Goerli = "goerli",
  /** Ethereum holesky testnet */
  Holesky = "holesky",
  /** Ethereum mainnet */
  Mainnet = "mainnet",
  /** Ethereum prater testnet */
  Prater = "prater",
}

export type OnDemandExit = {
  __typename?: "OnDemandExit";
  /** On demand exit approved at */
  approvedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** On demand exit request at */
  requestedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** On demand exit submitted at */
  submittedAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** A Salesforce opportunity token */
export type OpToken = {
  __typename?: "OpToken";
  /** The Salesforce ID for the op token */
  id: Scalars["String"];
  /** The name displayed for the token in Salesforce */
  name: Scalars["String"];
  /** The full URL to the Op Token page in Salesforce */
  url: Scalars["String"];
};

export enum OperatedBy {
  Figment = "figment",
  Other = "other",
}

export enum Operations {
  /** Ethereum batch staking */
  AggregatedStaking = "aggregated_staking",
  /** Ethereum batch unstaking */
  AggregatedUnstaking = "aggregated_unstaking",
  /** Staking operations */
  Staking = "staking",
  /** Unstaking operations */
  Unstaking = "unstaking",
}

export type Organization = {
  __typename?: "Organization";
  address?: Maybe<Address>;
  createdAt: Scalars["ISO8601DateTime"];
  devmodeEnabled: Scalars["Boolean"];
  displayName: Scalars["String"];
  id: Scalars["ID"];
  isIndividual: Scalars["Boolean"];
  members?: Maybe<MemberConnection>;
  name: Scalars["String"];
  riskAssessmentPassed?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  usesOnchainBilling: Scalars["Boolean"];
};

export type OrganizationMembersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type OrganizationMutationPayload = {
  __typename?: "OrganizationMutationPayload";
  /** Organization object */
  data?: Maybe<Organization>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

/** Attributes for updating an organization */
export type OrganizationUpdateInput = {
  address?: InputMaybe<AddressInput>;
  displayName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type Price = {
  __typename?: "Price";
  date?: Maybe<Scalars["ISO8601DateTime"]>;
  value?: Maybe<Scalars["Float"]>;
};

export type Project = {
  __typename?: "Project";
  apiKeys: ApiKeyConnection;
  createdAt: Scalars["ISO8601DateTime"];
  description?: Maybe<Scalars["String"]>;
  /** One of production, test */
  environment?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  organization: Organization;
  readOnly?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

export type ProjectApiKeysArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ApiKeys>>;
};

/** The connection type for Project. */
export type ProjectConnection = {
  __typename?: "ProjectConnection";
  /** A list of edges. */
  edges: Array<ProjectEdge>;
  /** A list of nodes. */
  nodes: Array<Project>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** Attributes for creating a project */
export type ProjectCreateInput = {
  description?: InputMaybe<Scalars["String"]>;
  environment?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  readOnly?: InputMaybe<Scalars["Boolean"]>;
};

/** An edge in a connection. */
export type ProjectEdge = {
  __typename?: "ProjectEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Project;
};

export type ProjectMutationPayload = {
  __typename?: "ProjectMutationPayload";
  /** Project object */
  data?: Maybe<Project>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum ProjectOrderByFields {
  /** Date the Project was created */
  CreatedAt = "createdAt",
  /** The description of the Project */
  Description = "description",
  /** The name of the Project */
  Name = "name",
  /** Date the Project was updated last time */
  UpdatedAt = "updatedAt",
}

/** Attributes for updating a project */
export type ProjectUpdateInput = {
  description?: InputMaybe<Scalars["String"]>;
  environment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type Projects = {
  field: ProjectOrderByFields;
  sortDirection: SortDirection;
};

export type Query = {
  __typename?: "Query";
  /** Aggregated rewards by periods */
  aggregatedRewards: AggregatedRewards;
  /** Babylon Network Overview */
  babylonNetworkOverview: BabylonNetworkOverview;
  /** Babylon Stakes */
  babylonStakes: BabylonStakeConnection;
  /** All daily reports for an organization */
  dailyReports: DailyReportsConnection;
  /** Eigenlayer Operators */
  eigenlayerOperators?: Maybe<EigenlayerOperatorsConnection>;
  /** ETH balances aggregated across all tracked addresses for the org */
  ethereumBalanceSummary: EthereumBalanceSummary;
  /** Figment-calculated Ethereum network estimates */
  ethereumNetworkEstimates: NetworkEstimates;
  /** The latest Ethereum price */
  ethereumPrice: Price;
  /** Ethereum network rewards rate */
  ethereumRewardsRate: RewardsRate;
  /** Withdrawal addresses and other network wallet addresses tracked for rewards and balances. Protocol is always Ethereum. */
  ethereumWithdrawalAccount?: Maybe<EthereumAccount>;
  /** Withdrawal addresses and other network wallet addresses tracked for rewards and balances. Protocol is always Ethereum. */
  ethereumWithdrawalAccounts: EthereumAccountConnection;
  /** Number of Figment-operated validators for an organization (by status) */
  figmentValidatorCounts: FigmentValidatorCounts;
  /** All invitations for organization */
  invitations: InvitationConnection;
  /** The projected time for the Redeem Request to be fulfilled */
  liquidCollectiveRedeemManagerProjection: RedeemManagerProjection;
  /** A list of Liquid Collective Redeem Requests for a wallet address */
  liquidCollectiveRedeemRequests: Array<RedeemRequest>;
  me: Me;
  /** Organization members */
  members: MemberConnection;
  /** Monthly statements for all protocols supported for the org */
  monthlyStatements: MonthlyStatementConnection;
  /**
   * Figment-calculated Ethereum network estimates
   * @deprecated Use ethereumRewardsRate, ethereumNetworkEstimates, and ethereumPrice instead
   */
  networkOverview: NetworkOverview;
  /** Current organization */
  organization: Organization;
  /** All projects for the users organization. */
  projects: ProjectConnection;
  /** Protocol price */
  protocolPrice: Price;
  /** Balance and rewards */
  rewardsSummary: RewardsSummary;
  /** All roles used for access control. */
  roles: RoleConnection;
  /** Aggregated SOL rewards by periods */
  solanaAggregatedRewards: AggregatedRewards;
  /** SOL balances aggregated across all tracked addresses for the org */
  solanaBalanceSummary: SolanaBalanceSummary;
  /** Estimated time to activate stake (next epoch). */
  solanaEstimatedActivationTime: StakingTimeEstimate;
  /** Estimated time to deactivate stake (next epoch). */
  solanaEstimatedDeactivationTime: StakingTimeEstimate;
  /** Figment's public validator commission rate. */
  solanaFigmentPublicCommissionRate: CommssionRate;
  /** SOL price */
  solanaPrice: Price;
  /** Solana network rewards rate */
  solanaRewardsRate: RewardsRate;
  /** SOL total rewards for organization */
  solanaRewardsSummary: SolanaRewardsSummary;
  /** Solana stake accounts */
  solanaStakeAccounts: StakeAccountConnection;
  /** Details about the max value that can be delegated in a delegation transaction. */
  solanaStakingMaxDelegationEstimate: SolanaStakingMaxDelegationEstimate;
  /** Staking API activity */
  stakingActivity: StakingActivityConnection;
  /**
   * Staking API activity (Slate Flows)
   * @deprecated This is old Slate flows. Use stakingActivity (ETH) or stakingActivityLogs instead.
   */
  stakingActivityFlows: StakingActivityFlowConnection;
  /** Staking activity logs */
  stakingActivityLogs: StakingActivityLogConnection;
  /** Withdrawal addresses and other network wallet addresses tracked for rewards and balances. */
  trackedAccounts: AccountConnection;
  /** Validators tracked for rewards and balances. */
  trackedValidators: ValidatorConnection;
  /** Tracking Transactions */
  trackingTransaction?: Maybe<TrackingTransactionConnection>;
  /** Number of validators for an organization (by status) */
  validatorCounts: ValidatorCounts;
};

export type QueryAggregatedRewardsArgs = {
  ethAccount?: InputMaybe<Scalars["String"]>;
  network?: InputMaybe<Scalars["String"]>;
  timePeriod?: InputMaybe<TimePeriod>;
};

export type QueryBabylonNetworkOverviewArgs = {
  network: BitcoinNetworks;
};

export type QueryBabylonStakesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  network: BitcoinNetworks;
  state?: InputMaybe<BabylonStates>;
};

export type QueryDailyReportsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryEigenlayerOperatorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  operatedBy?: InputMaybe<Array<OperatedBy>>;
};

export type QueryEthereumBalanceSummaryArgs = {
  network?: EthereumNetworks;
};

export type QueryEthereumWithdrawalAccountArgs = {
  address: Scalars["String"];
  network: EthereumNetworks;
};

export type QueryEthereumWithdrawalAccountsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<AccountsFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Accounts>>;
};

export type QueryFigmentValidatorCountsArgs = {
  ethAccount?: InputMaybe<Scalars["String"]>;
  network?: InputMaybe<Scalars["String"]>;
};

export type QueryInvitationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  excludeInternalMembers?: InputMaybe<Scalars["Boolean"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Invitations>>;
};

export type QueryLiquidCollectiveRedeemManagerProjectionArgs = {
  network?: EthereumNetworks;
};

export type QueryLiquidCollectiveRedeemRequestsArgs = {
  address: Scalars["String"];
  network?: EthereumNetworks;
};

export type QueryMembersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  excludeInternalMembers?: InputMaybe<Scalars["Boolean"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Members>>;
};

export type QueryMonthlyStatementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MonthlyStatements>>;
};

export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Projects>>;
};

export type QueryProtocolPriceArgs = {
  ticker: CurrencyTickers;
};

export type QueryRewardsSummaryArgs = {
  network?: InputMaybe<Scalars["String"]>;
};

export type QueryRolesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QuerySolanaAggregatedRewardsArgs = {
  network?: InputMaybe<Scalars["String"]>;
  timePeriod: TimePeriod;
};

export type QuerySolanaBalanceSummaryArgs = {
  network?: InputMaybe<SolanaNetworks>;
};

export type QuerySolanaEstimatedActivationTimeArgs = {
  network?: SolanaNetworks;
};

export type QuerySolanaEstimatedDeactivationTimeArgs = {
  network?: SolanaNetworks;
};

export type QuerySolanaRewardsSummaryArgs = {
  network?: InputMaybe<SolanaNetworks>;
};

export type QuerySolanaStakeAccountsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  network?: SolanaNetworks;
  orderBy?: InputMaybe<Array<SolanaAccounts>>;
};

export type QuerySolanaStakingMaxDelegationEstimateArgs = {
  input: MaxDelegationEstimateInput;
};

export type QueryStakingActivityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  fundingAddress?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  minDepositStatus?: InputMaybe<DepositStatuses>;
  minExitRequestStatus?: InputMaybe<ExitRequestStatuses>;
  network?: InputMaybe<Networks>;
  operation?: InputMaybe<Array<ActivityTypes>>;
  page?: InputMaybe<Scalars["Int"]>;
  protocol?: InputMaybe<Scalars["String"]>;
  withdrawalAddress?: InputMaybe<Scalars["String"]>;
};

export type QueryStakingActivityFlowsArgs = {
  network?: InputMaybe<Networks>;
  operation?: InputMaybe<Array<Operations>>;
  page?: InputMaybe<Scalars["Int"]>;
  protocol?: InputMaybe<Scalars["String"]>;
  states?: InputMaybe<Array<States>>;
};

export type QueryStakingActivityLogsArgs = {
  activityType?: InputMaybe<StakingActivityActivityTypes>;
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  network?: InputMaybe<Scalars["String"]>;
  protocol: StakingActivityProtocols;
  status?: InputMaybe<StakingActivityStatuses>;
};

export type QueryTrackedAccountsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<AccountsFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Accounts>>;
};

export type QueryTrackedValidatorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<ValidatorsFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Validators>>;
};

export type QueryTrackingTransactionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  network: Networks;
  protocol: Scalars["String"];
  status?: InputMaybe<TransactionStatuses>;
  txHash?: InputMaybe<Scalars["String"]>;
};

export type QueryValidatorCountsArgs = {
  ethAccount?: InputMaybe<Scalars["String"]>;
  network?: InputMaybe<Scalars["String"]>;
  operatedBy?: InputMaybe<Array<OperatedBy>>;
  provisionedByMyOrganization?: InputMaybe<Scalars["Boolean"]>;
};

export type RedeemManagerProjection = {
  __typename?: "RedeemManagerProjection";
  projectedFulfilledAt: Scalars["String"];
};

export type RedeemRequest = {
  __typename?: "RedeemRequest";
  claimableAmountLseth: Scalars["String"];
  claimedAmountLseth: Scalars["String"];
  height: Scalars["String"];
  id: Scalars["String"];
  maxRedeemableAmountEth: Scalars["String"];
  network: Scalars["String"];
  owner: Scalars["String"];
  projectedRedeemableAt?: Maybe<Scalars["String"]>;
  requestedAt: Scalars["Float"];
  requestedAtTimestamp: Scalars["String"];
  statusClaim: Scalars["String"];
  statusSatisfaction: Scalars["String"];
  totalAmountLseth: Scalars["String"];
  withdrawalEventId: Scalars["Float"];
};

export enum Regions {
  /** Canada Central 1 */
  CaCentral_1 = "ca_central_1",
  /** Europe West 1 */
  EuWest_1 = "eu_west_1",
}

export type RewardsRate = {
  __typename?: "RewardsRate";
  date?: Maybe<Scalars["ISO8601Date"]>;
  staking?: Maybe<Scalars["Float"]>;
  tips?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** ETH rewards summary for an organization */
export type RewardsSummary = {
  __typename?: "RewardsSummary";
  balance?: Maybe<Scalars["String"]>;
  balanceUsd?: Maybe<Scalars["String"]>;
  consensusEarned?: Maybe<Scalars["String"]>;
  consensusEarnedUsd?: Maybe<Scalars["String"]>;
  executionEarned?: Maybe<Scalars["String"]>;
  executionEarnedUsd?: Maybe<Scalars["String"]>;
  executionReceived?: Maybe<Scalars["String"]>;
  executionReceivedUsd?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use consensusRewards instead */
  stakingRewards?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use consensusRewardsUsd instead */
  stakingRewardsUsd?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use executionRewards instead */
  tipsRewards?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use executionRewardsUsd instead */
  tipsRewardsUsd?: Maybe<Scalars["String"]>;
  totalEarned?: Maybe<Scalars["String"]>;
  totalEarnedUsd?: Maybe<Scalars["String"]>;
  totalReceived?: Maybe<Scalars["String"]>;
  totalReceivedUsd?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use totalReceived instead */
  totalRewards?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use totalReceivedUsd instead */
  totalRewardsUsd?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

export type Role = {
  __typename?: "Role";
  createdAt: Scalars["ISO8601DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for Role. */
export type RoleConnection = {
  __typename?: "RoleConnection";
  /** A list of edges. */
  edges: Array<RoleEdge>;
  /** A list of nodes. */
  nodes: Array<Role>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type RoleEdge = {
  __typename?: "RoleEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Role;
};

export enum SolanaAccountOrderByFields {
  /** Balance of the account in Lamports */
  ActiveBalanceLamports = "activeBalanceLamports",
  /** Date the Account was created */
  CreatedAt = "createdAt",
  /** Status of the account */
  Status = "status",
}

export type SolanaAccounts = {
  field: SolanaAccountOrderByFields;
  sortDirection: SortDirection;
};

export type SolanaBalanceSummary = {
  __typename?: "SolanaBalanceSummary";
  balance?: Maybe<Scalars["String"]>;
  balanceUsd?: Maybe<Scalars["String"]>;
};

export enum SolanaNetworks {
  /** Solana devnet */
  Devnet = "devnet",
  /** Solana mainnet */
  Mainnet = "mainnet",
  /** Solana testnet */
  Testnet = "testnet",
}

export type SolanaRewardsSummary = {
  __typename?: "SolanaRewardsSummary";
  balance?: Maybe<Scalars["String"]>;
  balanceUsd?: Maybe<Scalars["String"]>;
  mevRewards?: Maybe<Scalars["String"]>;
  mevRewardsUsd?: Maybe<Scalars["String"]>;
  protocolRewards?: Maybe<Scalars["String"]>;
  protocolRewardsUsd?: Maybe<Scalars["String"]>;
  totalRewards?: Maybe<Scalars["String"]>;
  totalRewardsUsd?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** Parameters to generate a SOL deactivate transaction. */
export type SolanaStakingDeactivateTransactionCreateInput = {
  network: SolanaNetworks;
  stakeAccountPubkey: Scalars["String"];
};

/** Parameters to generate a SOL delegate transaction. */
export type SolanaStakingDelegateTransactionCreateInput = {
  amountSol: Scalars["Float"];
  fundingAccountPubkey: Scalars["String"];
  network: SolanaNetworks;
  voteAccountPubkey: Scalars["String"];
};

export type SolanaStakingMaxDelegationEstimate = {
  __typename?: "SolanaStakingMaxDelegationEstimate";
  /** in lamports */
  accountBalance: Scalars["String"];
  /** in lamports */
  estimatedFee: Scalars["String"];
  /** in lamports */
  maxDelegation: Scalars["String"];
  /** in lamports */
  minBalanceForRentExemption: Scalars["String"];
};

export type SolanaStakingTransactionPayload = {
  __typename?: "SolanaStakingTransactionPayload";
  lastValidBlockHeight: Scalars["Int"];
  network: SolanaNetworks;
  signingPayload: Scalars["String"];
  stakeAccountPubkey: Scalars["String"];
  unsignedTransactionSerialized: Scalars["String"];
};

/** Save a transaction hash for a Solana staking activity */
export type SolanaStakingTxSaveInput = {
  activityType: StakingActivityActivityTypes;
  amountSol: Scalars["Float"];
  network: SolanaNetworks;
  stakeAccountPubkey: Scalars["String"];
  txHash: Scalars["String"];
};

/** Parameters to generate a SOL withdraw transaction. */
export type SolanaStakingWithdrawTransactionCreateInput = {
  amountSol?: InputMaybe<Scalars["Float"]>;
  network: SolanaNetworks;
  recipientPubkey: Scalars["String"];
  stakeAccountPubkey: Scalars["String"];
};

/** Column ordering options */
export enum SortDirection {
  /** in the ascending order */
  Asc = "asc",
  /** in the descending order */
  Desc = "desc",
}

/** A tracked Solana stake account. */
export type StakeAccount = {
  __typename?: "StakeAccount";
  /** Total balance that is actively delegated to the validator in SOL */
  activeBalance?: Maybe<Scalars["String"]>;
  /** Total balance that is actively delegated to the validator in USD */
  activeBalanceUsd?: Maybe<Scalars["String"]>;
  /** The account address (e.g. BF2z...) */
  address: Scalars["String"];
  /** Total stake account balance in SOL (active, inactive, and any additional) */
  balance?: Maybe<Scalars["String"]>;
  /** Total stake account balance in USD (active, inactive, and any additional) */
  balanceUsd?: Maybe<Scalars["String"]>;
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  /** Total balance that is not delegated to the validator (activating / deactivating) in SOL */
  inactiveBalance?: Maybe<Scalars["String"]>;
  /** Total balance that is not delegated to the validator in USD */
  inactiveBalanceUsd?: Maybe<Scalars["String"]>;
  /** Whether the account has active delegation (manually managed) */
  isActive: Scalars["Boolean"];
  /** A free-form note about the account */
  label?: Maybe<Scalars["String"]>;
  /** The protocol network name (e.g. mainnet) */
  network: Scalars["String"];
  /** Stake authority for the stake account */
  stakeAuthorityAddress?: Maybe<Scalars["String"]>;
  /** The current status of the stake account */
  status?: Maybe<StakeAccountStatus>;
  /** Total rewards earned for the stake account */
  totalRewards?: Maybe<Scalars["String"]>;
  /** Total rewards for the stake account in USD */
  totalRewardsUsd?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  /** The user that created the stake account (only applies when staking through Fig app) */
  user?: Maybe<User>;
  /** Withdraw authority for the stake account */
  withdrawAuthorityAddress?: Maybe<Scalars["String"]>;
};

/** The connection type for StakeAccount. */
export type StakeAccountConnection = {
  __typename?: "StakeAccountConnection";
  /** A list of edges. */
  edges: Array<StakeAccountEdge>;
  /** A list of nodes. */
  nodes: Array<StakeAccount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type StakeAccountEdge = {
  __typename?: "StakeAccountEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: StakeAccount;
};

export enum StakeAccountStatus {
  Activating = "activating",
  Active = "active",
  Deactivating = "deactivating",
  Inactive = "inactive",
  Withdrawn = "withdrawn",
}

/** Autogenerated return type of StakeBroadcastedMutation. */
export type StakeBroadcastedMutationPayload = {
  __typename?: "StakeBroadcastedMutationPayload";
  data?: Maybe<BabylonStake>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of StakeTransactionCreate. */
export type StakeTransactionCreatePayload = {
  __typename?: "StakeTransactionCreatePayload";
  data?: Maybe<BabylonTx>;
  userErrors?: Maybe<Array<MutationError>>;
};

export type StakingActivity = EthereumExitRequest | EthereumStakingDeposit;

export enum StakingActivityActivityTypes {
  Delegation = "delegation",
  Stake = "stake",
  Unbond = "unbond",
  Undelegation = "undelegation",
  Withdrawal = "withdrawal",
}

/** The connection type for StakingActivity. */
export type StakingActivityConnection = {
  __typename?: "StakingActivityConnection";
  /** A list of edges. */
  edges: Array<StakingActivityEdge>;
  /** A list of nodes. */
  nodes: Array<StakingActivity>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type StakingActivityEdge = {
  __typename?: "StakingActivityEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: StakingActivity;
};

export type StakingActivityFlow = {
  __typename?: "StakingActivityFlow";
  aggregatedStakingContractAddress?: Maybe<Scalars["String"]>;
  amount?: Maybe<Scalars["Float"]>;
  createdAt: Scalars["ISO8601DateTime"];
  estimatedActiveAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedExitAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedPrincipalReturnAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedWithdrawalAt?: Maybe<Scalars["ISO8601DateTime"]>;
  feeRecipientAddress?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  network?: Maybe<Networks>;
  operation?: Maybe<Operations>;
  region?: Maybe<Regions>;
  state?: Maybe<States>;
  updatedAt: Scalars["ISO8601DateTime"];
  withdrawalAddress?: Maybe<Scalars["String"]>;
};

/** The connection type for StakingActivityFlow. */
export type StakingActivityFlowConnection = {
  __typename?: "StakingActivityFlowConnection";
  /** A list of edges. */
  edges: Array<StakingActivityFlowEdge>;
  /** A list of nodes. */
  nodes: Array<StakingActivityFlow>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type StakingActivityFlowEdge = {
  __typename?: "StakingActivityFlowEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: StakingActivityFlow;
};

export type StakingActivityLog = {
  __typename?: "StakingActivityLog";
  activityAt: Scalars["ISO8601DateTime"];
  activityType: StakingActivityActivityTypes;
  amount: Scalars["String"];
  /** The currency for the amount (e.g. SOL or ETH) */
  amountCurrency: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  estimatedCompletedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  network: Scalars["String"];
  protocol: StakingActivityProtocols;
  source?: Maybe<Scalars["String"]>;
  status: StakingActivityStatuses;
  txHash?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  user?: Maybe<User>;
};

/** The connection type for StakingActivityLog. */
export type StakingActivityLogConnection = {
  __typename?: "StakingActivityLogConnection";
  /** A list of edges. */
  edges: Array<StakingActivityLogEdge>;
  /** A list of nodes. */
  nodes: Array<StakingActivityLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type StakingActivityLogEdge = {
  __typename?: "StakingActivityLogEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: StakingActivityLog;
};

export enum StakingActivityProtocols {
  Babylon = "babylon",
  Solana = "solana",
}

export enum StakingActivityStatuses {
  Complete = "complete",
  Failed = "failed",
  Pending = "pending",
}

export type StakingTimeEstimate = {
  __typename?: "StakingTimeEstimate";
  /** The date/time of the estimate. */
  date?: Maybe<Scalars["ISO8601DateTime"]>;
  /** The number of seconds until the date occurs. */
  numSeconds?: Maybe<Scalars["Int"]>;
};

export enum Statement {
  /** Revenue Share Report */
  RevenueShare = "revenue_share",
  /** Staking Rewards Report */
  StakingRewards = "staking_rewards",
  /** White Label Validator Report */
  WhiteLabelValidator = "white_label_validator",
}

export enum States {
  Activating = "activating",
  Active = "active",
  AggregatedDepositTxSignature = "aggregated_deposit_tx_signature",
  AwaitingOcbDeployment = "awaiting_ocb_deployment",
  AwaitingProvision = "awaiting_provision",
  Broadcasting = "broadcasting",
  Deposited = "deposited",
  Exiting = "exiting",
  Failed = "failed",
  Initialized = "initialized",
  Unstaked = "unstaked",
  Withdrawing = "withdrawing",
}

/** timestamps of when validator transitioned into a given status */
export type StatusHistory = {
  __typename?: "StatusHistory";
  activeExitingAt?: Maybe<Scalars["ISO8601DateTime"]>;
  activeOngoingAt?: Maybe<Scalars["ISO8601DateTime"]>;
  activeSlashedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  depositedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  depositedNotFinalizedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  exitedSlashedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  exitedUnslashedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  fundingRequestedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  pendingInitializedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  pendingQueuedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  provisionedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  withdrawalDoneAt?: Maybe<Scalars["ISO8601DateTime"]>;
  withdrawalPossibleAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

export enum TimePeriod {
  /** All time */
  AllTime = "all_time",
  /** One month */
  OneMonth = "one_month",
  /** Year */
  OneYear = "one_year",
  /** Six months */
  SixMonths = "six_months",
}

/** Input attributes for tracking an account */
export type TrackedAccountAddInput = {
  address: Scalars["String"];
  network: Scalars["String"];
};

export type TrackingTransaction = {
  __typename?: "TrackingTransaction";
  actionId: Scalars["String"];
  actionType: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  error?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  network: Scalars["String"];
  organizationId: Scalars["String"];
  protocol: Scalars["String"];
  signedPayload?: Maybe<Scalars["String"]>;
  signingPayload?: Maybe<Scalars["String"]>;
  status?: Maybe<TransactionStatuses>;
  transactionableId: Scalars["String"];
  transactionableType: Scalars["String"];
  txHash: Scalars["String"];
  unsignedRawTx?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for TrackingTransaction. */
export type TrackingTransactionConnection = {
  __typename?: "TrackingTransactionConnection";
  /** A list of edges. */
  edges: Array<TrackingTransactionEdge>;
  /** A list of nodes. */
  nodes: Array<TrackingTransaction>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type TrackingTransactionEdge = {
  __typename?: "TrackingTransactionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: TrackingTransaction;
};

export enum TransactionStatuses {
  Confirmed = "confirmed",
  Expired = "expired",
  Failed = "failed",
  InProgress = "in_progress",
  NotFound = "not_found",
}

/** Autogenerated return type of TxSaveMutation. */
export type TxSaveMutationPayload = {
  __typename?: "TxSaveMutationPayload";
  data?: Maybe<StakingActivityLog>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of UnbondTransactionCreate. */
export type UnbondTransactionCreatePayload = {
  __typename?: "UnbondTransactionCreatePayload";
  data?: Maybe<BabylonTx>;
  userErrors?: Maybe<Array<MutationError>>;
};

export type User = {
  __typename?: "User";
  acceptedTosAt?: Maybe<Scalars["ISO8601DateTime"]>;
  auth0Id: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  email: Scalars["String"];
  id: Scalars["ID"];
  mfaMethods?: Maybe<Array<Scalars["String"]>>;
  name: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  riskAssessmentPassed?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

export type UserMutationPayload = {
  __typename?: "UserMutationPayload";
  /** User object */
  data?: Maybe<User>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

/** Attributes for updating an user */
export type UserUpdateInput = {
  acceptedTosAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

/** An Ethereum validator tracked for rewards and balances. */
export type Validator = {
  __typename?: "Validator";
  /** The validator pubkey (prefixed with 0x) */
  address: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  /** A free-form note about the account */
  label?: Maybe<Scalars["String"]>;
  /** The Ethereum network name (e.g. mainnet, holesky, goerli) */
  network: Scalars["String"];
  /** On demand exit validator data */
  onDemandExit?: Maybe<OnDemandExit>;
  /** The region a Figment Validator is hosted in */
  region?: Maybe<Scalars["String"]>;
  /** Staked balance for the validator */
  stakedBalance?: Maybe<Scalars["String"]>;
  /** Staked balance in USD for the validator */
  stakedBalanceUsd?: Maybe<Scalars["String"]>;
  /** status of validator */
  status?: Maybe<ValidatorStatus>;
  /** timestamps of status transitions */
  statusHistory: StatusHistory;
  /** Total gross (earned) rewards this validator has received */
  totalRewards?: Maybe<Scalars["String"]>;
  /** Sum of all rewards received */
  totalRewardsReceived?: Maybe<Scalars["String"]>;
  /** Sum of all rewards received in USD */
  totalRewardsReceivedUsd?: Maybe<Scalars["String"]>;
  /** Total gross (earned) rewards in USD this validator has received */
  totalRewardsUsd?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  /** Withdrawal account for the validator */
  withdrawalAccount?: Maybe<Account>;
};

/** The connection type for Validator. */
export type ValidatorConnection = {
  __typename?: "ValidatorConnection";
  /** A list of edges. */
  edges: Array<ValidatorEdge>;
  /** A list of nodes. */
  nodes: Array<Validator>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type ValidatorCounts = {
  __typename?: "ValidatorCounts";
  /** Totals by validator status */
  byStatus?: Maybe<ValidatorCountsByStatus>;
  /** Active validators which have already been requested to exit */
  exitPending?: Maybe<Scalars["Int"]>;
  /** Active validators which have not yet been requested to exit */
  exitable?: Maybe<Scalars["Int"]>;
  /** The total number of validators across all statuses */
  total?: Maybe<Scalars["Int"]>;
  /** Total number of validators verified in Eigenlayer. */
  verified?: Maybe<Scalars["Int"]>;
};

export type ValidatorCountsByStatus = {
  __typename?: "ValidatorCountsByStatus";
  activeExiting?: Maybe<Scalars["Int"]>;
  activeOngoing?: Maybe<Scalars["Int"]>;
  activeSlashed?: Maybe<Scalars["Int"]>;
  deposited?: Maybe<Scalars["Int"]>;
  depositedNotFinalized?: Maybe<Scalars["Int"]>;
  exitedSlashed?: Maybe<Scalars["Int"]>;
  exitedUnslashed?: Maybe<Scalars["Int"]>;
  fundingRequested?: Maybe<Scalars["Int"]>;
  pendingInitialized?: Maybe<Scalars["Int"]>;
  pendingQueued?: Maybe<Scalars["Int"]>;
  provisioned?: Maybe<Scalars["Int"]>;
  withdrawalDone?: Maybe<Scalars["Int"]>;
  withdrawalPossible?: Maybe<Scalars["Int"]>;
};

/** An edge in a connection. */
export type ValidatorEdge = {
  __typename?: "ValidatorEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Validator;
};

export enum ValidatorOrderByFields {
  /** Date the validator was activated */
  ActivatedAt = "activatedAt",
  /** The validator pubkey */
  Address = "address",
  /** Date the validator was added */
  CreatedAt = "createdAt",
  /** The network of the protocol (e.g. mainnet) */
  Network = "network",
  /** The status of the validator */
  Status = "status",
}

export enum ValidatorStatus {
  Active = "active",
  ActiveExiting = "active_exiting",
  ActiveOngoing = "active_ongoing",
  ActiveSlashed = "active_slashed",
  Deposited = "deposited",
  DepositedNotFinalized = "deposited_not_finalized",
  Exited = "exited",
  ExitedSlashed = "exited_slashed",
  ExitedUnslashed = "exited_unslashed",
  FundingRequested = "funding_requested",
  Pending = "pending",
  PendingInitialized = "pending_initialized",
  PendingQueued = "pending_queued",
  Provisioned = "provisioned",
  Unfunded = "unfunded",
  Unknown = "unknown",
  WithdrawalDone = "withdrawal_done",
  WithdrawalPossible = "withdrawal_possible",
}

export type Validators = {
  field: ValidatorOrderByFields;
  sortDirection: SortDirection;
};

/** Attributes for filtering tracked validators. */
export type ValidatorsFilter = {
  address_cont?: InputMaybe<Scalars["String"]>;
  address_eq?: InputMaybe<Scalars["String"]>;
  address_i_cont?: InputMaybe<Scalars["String"]>;
  network_eq?: InputMaybe<Scalars["String"]>;
  organization_id_eq?: InputMaybe<Scalars["String"]>;
  source_eq?: InputMaybe<Scalars["String"]>;
  source_id_eq?: InputMaybe<Scalars["String"]>;
  status_in?: InputMaybe<Array<ValidatorStatus>>;
  status_not_in?: InputMaybe<Array<ValidatorStatus>>;
  withdrawal_account_address_eq?: InputMaybe<Scalars["String"]>;
  withdrawal_account_address_i_eq?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of VerifyWithdrawalCredentialsTransactionCreate. */
export type VerifyWithdrawalCredentialsTransactionCreatePayload = {
  __typename?: "VerifyWithdrawalCredentialsTransactionCreatePayload";
  data?: Maybe<EthereumTransactionPayload>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of WithdrawTransactionCreateMutation. */
export type WithdrawTransactionCreateMutationPayload = {
  __typename?: "WithdrawTransactionCreateMutationPayload";
  data?: Maybe<SolanaStakingTransactionPayload>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of WithdrawalTransactionCreate. */
export type WithdrawalTransactionCreatePayload = {
  __typename?: "WithdrawalTransactionCreatePayload";
  data?: Maybe<BabylonTx>;
  userErrors?: Maybe<Array<MutationError>>;
};

export type BabylonActivityTableStakingActivityQueryVariables = Exact<{
  network?: InputMaybe<Scalars["String"]>;
  protocol: StakingActivityProtocols;
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type BabylonActivityTableStakingActivityQuery = {
  __typename?: "Query";
  stakingActivityLogs: {
    __typename?: "StakingActivityLogConnection";
    nodes: Array<{
      __typename?: "StakingActivityLog";
      id: string;
      activityType: StakingActivityActivityTypes;
      amount: string;
      createdAt: any;
      status: StakingActivityStatuses;
      estimatedCompletedAt?: any | null;
      txHash?: string | null;
      protocol: StakingActivityProtocols;
      network: string;
      user?: { __typename?: "User"; email: string } | null;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
  };
};

export type StakingActivityNodeFragment = {
  __typename?: "StakingActivityLog";
  id: string;
  activityType: StakingActivityActivityTypes;
  amount: string;
  createdAt: any;
  status: StakingActivityStatuses;
  estimatedCompletedAt?: any | null;
  txHash?: string | null;
  protocol: StakingActivityProtocols;
  network: string;
  user?: { __typename?: "User"; email: string } | null;
};

export type EthereumActivityTableStakingActivityQueryVariables = Exact<{
  protocol?: InputMaybe<Scalars["String"]>;
  network?: InputMaybe<Networks>;
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type EthereumActivityTableStakingActivityQuery = {
  __typename?: "Query";
  stakingActivity: {
    __typename?: "StakingActivityConnection";
    nodes: Array<
      | {
          __typename: "EthereumExitRequest";
          id: string;
          network: Networks;
          amountEth: string;
          createdAt: any;
          activityAt: any;
          estimatedWithdrawableAt?: any | null;
          estimatedPrincipalReturnAt?: any | null;
          exitStatus: ExitRequestStatuses;
          user?: { __typename?: "User"; email: string } | null;
        }
      | {
          __typename: "EthereumStakingDeposit";
          id: string;
          network: Networks;
          amountEth: string;
          createdAt: any;
          activityAt: any;
          estimatedActiveAt?: any | null;
          txHash: string;
          depositStatus: DepositStatuses;
          provisioners?: Array<{ __typename?: "User"; email: string }> | null;
        }
    >;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
  };
};

type EthereumStakingActivityConnectionNode_EthereumExitRequest_Fragment = {
  __typename: "EthereumExitRequest";
  id: string;
  network: Networks;
  amountEth: string;
  createdAt: any;
  activityAt: any;
  estimatedWithdrawableAt?: any | null;
  estimatedPrincipalReturnAt?: any | null;
  exitStatus: ExitRequestStatuses;
  user?: { __typename?: "User"; email: string } | null;
};

type EthereumStakingActivityConnectionNode_EthereumStakingDeposit_Fragment = {
  __typename: "EthereumStakingDeposit";
  id: string;
  network: Networks;
  amountEth: string;
  createdAt: any;
  activityAt: any;
  estimatedActiveAt?: any | null;
  txHash: string;
  depositStatus: DepositStatuses;
  provisioners?: Array<{ __typename?: "User"; email: string }> | null;
};

export type EthereumStakingActivityConnectionNodeFragment =
  | EthereumStakingActivityConnectionNode_EthereumExitRequest_Fragment
  | EthereumStakingActivityConnectionNode_EthereumStakingDeposit_Fragment;

export type SolanaActivityTableStakingActivityQueryVariables = Exact<{
  network?: InputMaybe<Scalars["String"]>;
  protocol: StakingActivityProtocols;
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type SolanaActivityTableStakingActivityQuery = {
  __typename?: "Query";
  stakingActivityLogs: {
    __typename?: "StakingActivityLogConnection";
    nodes: Array<{
      __typename?: "StakingActivityLog";
      id: string;
      activityType: StakingActivityActivityTypes;
      amount: string;
      createdAt: any;
      status: StakingActivityStatuses;
      estimatedCompletedAt?: any | null;
      txHash?: string | null;
      protocol: StakingActivityProtocols;
      network: string;
      user?: { __typename?: "User"; email: string } | null;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
  };
};

export type BalanceAndRewardsCardsQueryVariables = Exact<{
  ethereumRewardsNetwork?: InputMaybe<Scalars["String"]>;
  ethereumBalanceNetwork: EthereumNetworks;
  solanaNetwork: SolanaNetworks;
}>;

export type BalanceAndRewardsCardsQuery = {
  __typename?: "Query";
  rewardsSummary: {
    __typename?: "RewardsSummary";
    totalRewards?: string | null;
    totalRewardsUsd?: string | null;
  };
  ethereumBalanceSummary: {
    __typename?: "EthereumBalanceSummary";
    balance?: string | null;
    balanceUsd?: string | null;
  };
  solanaRewardsSummary: {
    __typename?: "SolanaRewardsSummary";
    totalRewards?: string | null;
    totalRewardsUsd?: string | null;
  };
  solanaBalanceSummary: {
    __typename?: "SolanaBalanceSummary";
    balance?: string | null;
    balanceUsd?: string | null;
  };
};

export type LsEthRedemptionsQueryVariables = Exact<{
  network: EthereumNetworks;
  address: Scalars["String"];
}>;

export type LsEthRedemptionsQuery = {
  __typename?: "Query";
  liquidCollectiveRedeemRequests: Array<{
    __typename?: "RedeemRequest";
    claimableAmountLseth: string;
    claimedAmountLseth: string;
    height: string;
    id: string;
    maxRedeemableAmountEth: string;
    network: string;
    owner: string;
    projectedRedeemableAt?: string | null;
    requestedAt: number;
    statusClaim: string;
    statusSatisfaction: string;
    totalAmountLseth: string;
    withdrawalEventId: number;
    requestedAtTimestamp: string;
  }>;
};

export type EthereumRewardsEarnedChartQueryVariables = Exact<{
  network: Scalars["String"];
}>;

export type EthereumRewardsEarnedChartQuery = {
  __typename?: "Query";
  rewardsEarnedOneMonth: {
    __typename?: "AggregatedRewards";
    rewards?: Array<{
      __typename?: "AggregatedRewardsTuple";
      timestamp?: any | null;
      total?: string | null;
      totalUsd?: string | null;
      consensusTotal?: string | null;
      consensusTotalUsd?: string | null;
      executionTotal?: string | null;
      executionTotalUsd?: string | null;
    }> | null;
  };
  rewardsEarnedSixMonths: {
    __typename?: "AggregatedRewards";
    rewards?: Array<{
      __typename?: "AggregatedRewardsTuple";
      timestamp?: any | null;
      total?: string | null;
      totalUsd?: string | null;
      consensusTotal?: string | null;
      consensusTotalUsd?: string | null;
      executionTotal?: string | null;
      executionTotalUsd?: string | null;
    }> | null;
  };
  rewardsEarnedOneYear: {
    __typename?: "AggregatedRewards";
    rewards?: Array<{
      __typename?: "AggregatedRewardsTuple";
      timestamp?: any | null;
      total?: string | null;
      totalUsd?: string | null;
      consensusTotal?: string | null;
      consensusTotalUsd?: string | null;
      executionTotal?: string | null;
      executionTotalUsd?: string | null;
    }> | null;
  };
};

export type EthereumRewardsEarnedChartAggregatedRewardsFragment = {
  __typename?: "AggregatedRewards";
  rewards?: Array<{
    __typename?: "AggregatedRewardsTuple";
    timestamp?: any | null;
    total?: string | null;
    totalUsd?: string | null;
    consensusTotal?: string | null;
    consensusTotalUsd?: string | null;
    executionTotal?: string | null;
    executionTotalUsd?: string | null;
  }> | null;
};

export type SolanaRewardsEarnedChartQueryVariables = Exact<{
  network: Scalars["String"];
}>;

export type SolanaRewardsEarnedChartQuery = {
  __typename?: "Query";
  rewardsEarnedOneMonth: {
    __typename?: "AggregatedRewards";
    rewards?: Array<{
      __typename?: "AggregatedRewardsTuple";
      timestamp?: any | null;
      total?: string | null;
      totalUsd?: string | null;
      consensusTotal?: string | null;
      consensusTotalUsd?: string | null;
      executionTotal?: string | null;
      executionTotalUsd?: string | null;
    }> | null;
  };
  rewardsEarnedSixMonths: {
    __typename?: "AggregatedRewards";
    rewards?: Array<{
      __typename?: "AggregatedRewardsTuple";
      timestamp?: any | null;
      total?: string | null;
      totalUsd?: string | null;
      consensusTotal?: string | null;
      consensusTotalUsd?: string | null;
      executionTotal?: string | null;
      executionTotalUsd?: string | null;
    }> | null;
  };
  rewardsEarnedOneYear: {
    __typename?: "AggregatedRewards";
    rewards?: Array<{
      __typename?: "AggregatedRewardsTuple";
      timestamp?: any | null;
      total?: string | null;
      totalUsd?: string | null;
      consensusTotal?: string | null;
      consensusTotalUsd?: string | null;
      executionTotal?: string | null;
      executionTotalUsd?: string | null;
    }> | null;
  };
};

export type SolanaRewardsEarnedChartAggregatedRewardsFragment = {
  __typename?: "AggregatedRewards";
  rewards?: Array<{
    __typename?: "AggregatedRewardsTuple";
    timestamp?: any | null;
    total?: string | null;
    totalUsd?: string | null;
    consensusTotal?: string | null;
    consensusTotalUsd?: string | null;
    executionTotal?: string | null;
    executionTotalUsd?: string | null;
  }> | null;
};

export type StakingPositionQueryVariables = Exact<{
  ethAccount: Scalars["String"];
  ethereumRewardsNetwork: Scalars["String"];
  ethereumBalanceNetwork: EthereumNetworks;
}>;

export type StakingPositionQuery = {
  __typename?: "Query";
  rewardsSummary: {
    __typename?: "RewardsSummary";
    stakingRewards?: string | null;
    totalRewards?: string | null;
    totalRewardsUsd?: string | null;
  };
  ethereumBalanceSummary: {
    __typename?: "EthereumBalanceSummary";
    balance?: string | null;
    balanceUsd?: string | null;
  };
  validatorCounts: { __typename?: "ValidatorCounts"; exitable?: number | null };
};

export type EthereumStakingPositionsTableQueryVariables = Exact<{
  network: Scalars["String"];
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
}>;

export type EthereumStakingPositionsTableQuery = {
  __typename?: "Query";
  ethereumWithdrawalAccounts: {
    __typename?: "EthereumAccountConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      startCursor?: string | null;
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    nodes: Array<{
      __typename?: "EthereumAccount";
      id: string;
      network: string;
      protocol: string;
      address: string;
      totalRewards?: string | null;
      totalRewardsUsd?: string | null;
      stakedBalance?: string | null;
      stakedBalanceUsd?: string | null;
      eigenpodOwnerAddress?: string | null;
      isEigenpod: boolean;
      delegatedTo?: string | null;
      totalActiveValidators: number;
      totalVerifiedValidators: number;
      totalValidators: number;
      totalExitableValidators: number;
      validatorCountsByStatus?: {
        __typename?: "ValidatorCountsByStatus";
        activeExiting?: number | null;
        activeOngoing?: number | null;
        activeSlashed?: number | null;
        deposited?: number | null;
        depositedNotFinalized?: number | null;
        exitedSlashed?: number | null;
        exitedUnslashed?: number | null;
        fundingRequested?: number | null;
        pendingInitialized?: number | null;
        pendingQueued?: number | null;
        provisioned?: number | null;
        withdrawalDone?: number | null;
        withdrawalPossible?: number | null;
      } | null;
    }>;
  };
};

export type SolanaStakingPositionsTableQueryVariables = Exact<{
  network: SolanaNetworks;
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
}>;

export type SolanaStakingPositionsTableQuery = {
  __typename?: "Query";
  solanaStakeAccounts: {
    __typename?: "StakeAccountConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      startCursor?: string | null;
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    nodes: Array<{
      __typename?: "StakeAccount";
      id: string;
      network: string;
      address: string;
      totalRewards?: string | null;
      totalRewardsUsd?: string | null;
      balance?: string | null;
      balanceUsd?: string | null;
      status?: StakeAccountStatus | null;
      stakeAuthorityAddress?: string | null;
      withdrawAuthorityAddress?: string | null;
      user?: { __typename?: "User"; email: string } | null;
    }>;
  };
};

export type ValidatorsTableQueryVariables = Exact<{
  network: Scalars["String"];
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  address?: InputMaybe<Scalars["String"]>;
}>;

export type ValidatorsTableQuery = {
  __typename?: "Query";
  trackedValidators: {
    __typename?: "ValidatorConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Validator";
      id: string;
      network: string;
      address: string;
      status?: ValidatorStatus | null;
      stakedBalance?: string | null;
      stakedBalanceUsd?: string | null;
      totalRewards?: string | null;
      totalRewardsUsd?: string | null;
      onDemandExit?: {
        __typename?: "OnDemandExit";
        requestedAt?: any | null;
        submittedAt?: any | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      endCursor?: string | null;
      startCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type ValidatorsTableStakingPositionQueryVariables = Exact<{
  address: Scalars["String"];
  network: EthereumNetworks;
}>;

export type ValidatorsTableStakingPositionQuery = {
  __typename?: "Query";
  ethereumWithdrawalAccount?: {
    __typename?: "EthereumAccount";
    isEigenpod: boolean;
    totalVerifiedValidators: number;
    totalActiveValidators: number;
    delegatedTo?: string | null;
  } | null;
};

export type RestakingFlowQueryVariables = Exact<{
  address: Scalars["String"];
  network: EthereumNetworks;
}>;

export type RestakingFlowQuery = {
  __typename?: "Query";
  ethereumWithdrawalAccount?: {
    __typename?: "EthereumAccount";
    id: string;
    eigenpodOwnerAddress?: string | null;
    isEigenpod: boolean;
    delegatedTo?: string | null;
    totalActiveValidators: number;
    totalVerifiedValidators: number;
  } | null;
};

export type RestakingFlowEthereumWithdrawalAccountFragment = {
  __typename?: "EthereumAccount";
  id: string;
  eigenpodOwnerAddress?: string | null;
  isEigenpod: boolean;
  delegatedTo?: string | null;
  totalActiveValidators: number;
  totalVerifiedValidators: number;
};

export type EigenpodVerificationCreateMutationVariables = Exact<{
  network: EthereumNetworks;
  ownerAddress: Scalars["String"];
}>;

export type EigenpodVerificationCreateMutation = {
  __typename?: "Mutation";
  eigenpodVerificationCreate: {
    __typename?: "EigenpodVerificationMutationPayload";
    data?: {
      __typename?: "EigenpodVerification";
      eigenpodAddress: string;
      network: Networks;
      proofs: any;
      transactionPayload: string;
      transactionInputs: any;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      field?: string | null;
      message: string;
      path?: Array<string> | null;
      extras?: any | null;
    }> | null;
  };
};

export type EigenlayerDelegateToMutationVariables = Exact<{
  operatorAddress: Scalars["String"];
  ownerAddress: Scalars["String"];
  network: EthereumNetworks;
}>;

export type EigenlayerDelegateToMutation = {
  __typename?: "Mutation";
  eigenlayerDelegateTo: {
    __typename?: "EigenlayerDelegateToMutationPayload";
    data?: {
      __typename?: "EigenlayerDelegateTo";
      network: Networks;
      operatorAddress: string;
      ownerAddress: string;
      txSigningPayload?: string | null;
      unsignedRawTx?: string | null;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      field?: string | null;
      message: string;
      path?: Array<string> | null;
      extras?: any | null;
    }> | null;
  };
};

export type BabylonNetworkOverviewQueryVariables = Exact<{
  network: BitcoinNetworks;
}>;

export type BabylonNetworkOverviewQuery = {
  __typename?: "Query";
  babylonNetworkOverview: {
    __typename?: "BabylonNetworkOverview";
    finalityProviderAddress: string;
    maxStakingAmount: number;
    maxStakingTime: number;
    minStakingAmount: number;
    minStakingTime: number;
  };
};

export type EthereumStakingFlowQueryVariables = Exact<{ [key: string]: never }>;

export type EthereumStakingFlowQuery = {
  __typename?: "Query";
  ethereumNetworkEstimates: {
    __typename?: "NetworkEstimates";
    estimatedActivation?: {
      __typename?: "NetworkEstimate";
      hours?: number | null;
    } | null;
    estimatedWithdrawal?: {
      __typename?: "NetworkEstimate";
      hoursMin?: number | null;
      hoursMax?: number | null;
    } | null;
    estimatedExit?: {
      __typename?: "NetworkEstimate";
      hours?: number | null;
    } | null;
  };
  ethereumRewardsRate: { __typename?: "RewardsRate"; value?: number | null };
  ethereumPrice: { __typename?: "Price"; value?: number | null };
};

export type SolanaStakingFlowQueryVariables = Exact<{
  network: SolanaNetworks;
  fundingAccountPubkey: Scalars["String"];
  voteAccountPubkey: Scalars["String"];
}>;

export type SolanaStakingFlowQuery = {
  __typename?: "Query";
  solanaStakingMaxDelegationEstimate: {
    __typename?: "SolanaStakingMaxDelegationEstimate";
    estimatedFee: string;
    minBalanceForRentExemption: string;
  };
  solanaRewardsRate: {
    __typename?: "RewardsRate";
    date?: any | null;
    value?: number | null;
  };
  solanaFigmentPublicCommissionRate: {
    __typename?: "CommssionRate";
    rate?: number | null;
  };
  solanaEstimatedDeactivationTime: {
    __typename?: "StakingTimeEstimate";
    date?: any | null;
    numSeconds?: number | null;
  };
  solanaEstimatedActivationTime: {
    __typename?: "StakingTimeEstimate";
    date?: any | null;
    numSeconds?: number | null;
  };
  solanaPrice: { __typename?: "Price"; value?: number | null };
};

export type ProtocolPriceQueryVariables = Exact<{
  ticker: CurrencyTickers;
}>;

export type ProtocolPriceQuery = {
  __typename?: "Query";
  protocolPrice: { __typename?: "Price"; value?: number | null };
};

export type StakeBtcMutationVariables = Exact<{
  address: Scalars["String"];
  changeAddress: Scalars["String"];
  network: BitcoinNetworks;
  stakerPk: Scalars["String"];
  stakingAmount: Scalars["Int"];
  stakingDuration: Scalars["Int"];
  taprootPubkey?: InputMaybe<Scalars["String"]>;
  utxos: Array<BitcoinUtxo> | BitcoinUtxo;
}>;

export type StakeBtcMutation = {
  __typename?: "Mutation";
  babylonStakeBtc?: {
    __typename?: "StakeTransactionCreatePayload";
    data?: {
      __typename?: "BabylonTx";
      signingPayload?: string | null;
      stakeId: string;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      message: string;
    }> | null;
  } | null;
};

export type ConfirmStakedBtcMutationVariables = Exact<{
  stakeId: Scalars["String"];
  txHash: Scalars["String"];
}>;

export type ConfirmStakedBtcMutation = {
  __typename?: "Mutation";
  babylonStakeBroadcasted?: {
    __typename?: "StakeBroadcastedMutationPayload";
    userErrors?: Array<{
      __typename?: "MutationError";
      message: string;
      code: string;
    }> | null;
  } | null;
};

export type EigenPodCreateMutationVariables = Exact<{
  network: EthereumNetworks;
  ownerAddress: Scalars["String"];
}>;

export type EigenPodCreateMutation = {
  __typename?: "Mutation";
  eigenpodCreate: {
    __typename?: "EigenpodMutationPayload";
    data?: {
      __typename?: "Eigenpod";
      address: string;
      deployed: boolean;
      unsignedRawCreatepodTx?: string | null;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      message: string;
    }> | null;
  };
};

export type StakeEthereumMutationVariables = Exact<{
  network: Networks;
  amountEth: Scalars["Int"];
  withdrawalAddress: Scalars["String"];
  fundingAddress?: InputMaybe<Scalars["String"]>;
  feeRecipientAddress?: InputMaybe<Scalars["String"]>;
  region?: InputMaybe<Regions>;
}>;

export type StakeEthereumMutation = {
  __typename?: "Mutation";
  ethereumStakingRequestCreate: {
    __typename?: "EthereumStakingRequestMutationPayload";
    data?: {
      __typename?: "EthereumStakingRequest";
      id: string;
      amountEth: string;
      feeRecipientAddress?: string | null;
      maxGasWei: string;
      network: Networks;
      region: Regions;
      unsignedTransactionHashed: string;
      unsignedTransactionSerialized: string;
      withdrawalAddress: string;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      extras?: any | null;
      field?: string | null;
      message: string;
      path?: Array<string> | null;
    }> | null;
  };
};

export type StakeEthereumSetTxHashMutationVariables = Exact<{
  id: Scalars["String"];
  txHash: Scalars["String"];
  amountEth: Scalars["Int"];
  fundingAddress: Scalars["String"];
}>;

export type StakeEthereumSetTxHashMutation = {
  __typename?: "Mutation";
  ethereumStakingRequestSetTxHash: {
    __typename?: "EthereumStakingRequestMutationPayload";
    data?: { __typename?: "EthereumStakingRequest"; id: string } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      field?: string | null;
      message: string;
      path?: Array<string> | null;
    }> | null;
  };
};

export type SolanaStakingDelegationCreateMutationVariables = Exact<{
  network: SolanaNetworks;
  amountSol: Scalars["Float"];
  fundingAccountPubkey: Scalars["String"];
  voteAccountPubkey: Scalars["String"];
}>;

export type SolanaStakingDelegationCreateMutation = {
  __typename?: "Mutation";
  solanaStakingDelegateTransactionCreate?: {
    __typename?: "DelegateTransactionCreateMutationPayload";
    data?: {
      __typename?: "SolanaStakingTransactionPayload";
      stakeAccountPubkey: string;
      signingPayload: string;
      unsignedTransactionSerialized: string;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      message: string;
    }> | null;
  } | null;
};

export type SolanaStakingTxSaveMutationVariables = Exact<{
  network: SolanaNetworks;
  amountSol: Scalars["Float"];
  stakeAccountPubkey: Scalars["String"];
  activityType: StakingActivityActivityTypes;
  txHash: Scalars["String"];
}>;

export type SolanaStakingTxSaveMutation = {
  __typename?: "Mutation";
  solanaStakingTxSave?: {
    __typename?: "TxSaveMutationPayload";
    data?: {
      __typename?: "StakingActivityLog";
      activityAt: any;
      activityType: StakingActivityActivityTypes;
      amount: string;
      amountCurrency: string;
      createdAt: any;
      estimatedCompletedAt?: any | null;
      id: string;
      network: string;
      protocol: StakingActivityProtocols;
      source?: string | null;
      status: StakingActivityStatuses;
      txHash?: string | null;
      updatedAt: any;
      user?: { __typename?: "User"; email: string } | null;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      message: string;
    }> | null;
  } | null;
};

export type UnstakeEthereumMutationVariables = Exact<{
  network: Networks;
  amountEth: Scalars["Int"];
  withdrawalAddress: Scalars["String"];
}>;

export type UnstakeEthereumMutation = {
  __typename?: "Mutation";
  ethereumExitRequestCreate: {
    __typename?: "EthereumExitRequestMutationPayload";
    data?: {
      __typename?: "EthereumExitRequest";
      amountEth: string;
      network: Networks;
      withdrawalAddress: string;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      extras?: any | null;
      field?: string | null;
      message: string;
      path?: Array<string> | null;
    }> | null;
  };
};

export type LiquidCollectiveClaimTransactionCreateMutationVariables = Exact<{
  network: EthereumNetworks;
  redeemRequestId: Scalars["String"];
}>;

export type LiquidCollectiveClaimTransactionCreateMutation = {
  __typename?: "Mutation";
  liquidCollectiveClaimTransactionCreate: {
    __typename?: "EthereumTransactionPayloadMutationPayload";
    data?: {
      __typename?: "EthereumTransactionPayload";
      signingPayload: string;
      unsignedTransactionSerialized: string;
    } | null;
  };
};

export type LiquidCollectiveRedeemManagerProjectionQueryVariables = Exact<{
  network: EthereumNetworks;
}>;

export type LiquidCollectiveRedeemManagerProjectionQuery = {
  __typename?: "Query";
  liquidCollectiveRedeemManagerProjection: {
    __typename?: "RedeemManagerProjection";
    projectedFulfilledAt: string;
  };
};

export type LiquidCollectiveRedeemRequestCreateMutationVariables = Exact<{
  network: EthereumNetworks;
  address: Scalars["String"];
  amount: Scalars["String"];
}>;

export type LiquidCollectiveRedeemRequestCreateMutation = {
  __typename?: "Mutation";
  liquidCollectiveRedeemRequestCreate: {
    __typename?: "EthereumTransactionPayloadMutationPayload";
    data?: {
      __typename?: "EthereumTransactionPayload";
      signingPayload: string;
      unsignedTransactionSerialized: string;
    } | null;
  };
};

export type UndelegateSolanaMutationVariables = Exact<{
  network: SolanaNetworks;
  stakeAccountPubkey: Scalars["String"];
}>;

export type UndelegateSolanaMutation = {
  __typename?: "Mutation";
  solanaStakingDeactivateTransactionCreate?: {
    __typename?: "DeactivateTransactionCreateMutationPayload";
    data?: {
      __typename?: "SolanaStakingTransactionPayload";
      unsignedTransactionSerialized: string;
      signingPayload: string;
      lastValidBlockHeight: number;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      message: string;
      field?: string | null;
      extras?: any | null;
    }> | null;
  } | null;
};

export type SolanaStakingWithdrawTransactionCreateMutationVariables = Exact<{
  network: SolanaNetworks;
  stakeAccountPubkey: Scalars["String"];
  recipientPubkey: Scalars["String"];
  amountSol?: InputMaybe<Scalars["Float"]>;
}>;

export type SolanaStakingWithdrawTransactionCreateMutation = {
  __typename?: "Mutation";
  solanaStakingWithdrawTransactionCreate?: {
    __typename?: "WithdrawTransactionCreateMutationPayload";
    data?: {
      __typename?: "SolanaStakingTransactionPayload";
      lastValidBlockHeight: number;
      network: SolanaNetworks;
      signingPayload: string;
      stakeAccountPubkey: string;
      unsignedTransactionSerialized: string;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      message: string;
      field?: string | null;
      path?: Array<string> | null;
      extras?: any | null;
    }> | null;
  } | null;
};

export const StakingActivityNodeFragmentDoc = `
    fragment StakingActivityNode on StakingActivityLog {
  id
  activityType
  amount
  createdAt
  status
  estimatedCompletedAt
  txHash
  protocol
  network
  user {
    email
  }
}
    `;
export const EthereumStakingActivityConnectionNodeFragmentDoc = `
    fragment EthereumStakingActivityConnectionNode on StakingActivity {
  __typename
  ... on EthereumStakingDeposit {
    id
    network
    amountEth
    createdAt
    activityAt
    depositStatus: status
    estimatedActiveAt
    txHash
    provisioners {
      email
    }
  }
  ... on EthereumExitRequest {
    id
    network
    amountEth
    createdAt
    activityAt
    exitStatus: status
    estimatedWithdrawableAt
    estimatedPrincipalReturnAt
    user {
      email
    }
  }
}
    `;
export const EthereumRewardsEarnedChartAggregatedRewardsFragmentDoc = `
    fragment EthereumRewardsEarnedChartAggregatedRewards on AggregatedRewards {
  rewards {
    timestamp
    total
    totalUsd
    consensusTotal
    consensusTotalUsd
    executionTotal
    executionTotalUsd
  }
}
    `;
export const SolanaRewardsEarnedChartAggregatedRewardsFragmentDoc = `
    fragment SolanaRewardsEarnedChartAggregatedRewards on AggregatedRewards {
  rewards {
    timestamp
    total
    totalUsd
    consensusTotal
    consensusTotalUsd
    executionTotal
    executionTotalUsd
  }
}
    `;
export const RestakingFlowEthereumWithdrawalAccountFragmentDoc = `
    fragment RestakingFlowEthereumWithdrawalAccount on EthereumAccount {
  id
  eigenpodOwnerAddress
  isEigenpod
  delegatedTo
  totalActiveValidators
  totalVerifiedValidators
}
    `;
export const BabylonActivityTableStakingActivityDocument = `
    query BabylonActivityTableStakingActivity($network: String, $protocol: StakingActivityProtocols!, $first: Int, $after: String) {
  stakingActivityLogs(
    network: $network
    protocol: $protocol
    first: $first
    after: $after
  ) {
    nodes {
      ...StakingActivityNode
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${StakingActivityNodeFragmentDoc}`;
export const useBabylonActivityTableStakingActivityQuery = <
  TData = BabylonActivityTableStakingActivityQuery,
  TError = unknown
>(
  variables: BabylonActivityTableStakingActivityQueryVariables,
  options?: UseQueryOptions<
    BabylonActivityTableStakingActivityQuery,
    TError,
    TData
  >
) =>
  useQuery<BabylonActivityTableStakingActivityQuery, TError, TData>(
    ["BabylonActivityTableStakingActivity", variables],
    coreGQLFetcher<
      BabylonActivityTableStakingActivityQuery,
      BabylonActivityTableStakingActivityQueryVariables
    >(BabylonActivityTableStakingActivityDocument, variables),
    options
  );

useBabylonActivityTableStakingActivityQuery.getKey = (
  variables: BabylonActivityTableStakingActivityQueryVariables
) => ["BabylonActivityTableStakingActivity", variables];
export const useInfiniteBabylonActivityTableStakingActivityQuery = <
  TData = BabylonActivityTableStakingActivityQuery,
  TError = unknown
>(
  pageParamKey: keyof BabylonActivityTableStakingActivityQueryVariables,
  variables: BabylonActivityTableStakingActivityQueryVariables,
  options?: UseInfiniteQueryOptions<
    BabylonActivityTableStakingActivityQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<
    BabylonActivityTableStakingActivityQuery,
    TError,
    TData
  >(
    ["BabylonActivityTableStakingActivity.infinite", variables],
    (metaData) =>
      coreGQLFetcher<
        BabylonActivityTableStakingActivityQuery,
        BabylonActivityTableStakingActivityQueryVariables
      >(BabylonActivityTableStakingActivityDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteBabylonActivityTableStakingActivityQuery.getKey = (
  variables: BabylonActivityTableStakingActivityQueryVariables
) => ["BabylonActivityTableStakingActivity.infinite", variables];
export const EthereumActivityTableStakingActivityDocument = `
    query EthereumActivityTableStakingActivity($protocol: String, $network: Networks, $first: Int, $after: String) {
  stakingActivity(
    protocol: $protocol
    network: $network
    first: $first
    after: $after
    minDepositStatus: unfinalized
  ) {
    nodes {
      ...EthereumStakingActivityConnectionNode
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${EthereumStakingActivityConnectionNodeFragmentDoc}`;
export const useEthereumActivityTableStakingActivityQuery = <
  TData = EthereumActivityTableStakingActivityQuery,
  TError = unknown
>(
  variables?: EthereumActivityTableStakingActivityQueryVariables,
  options?: UseQueryOptions<
    EthereumActivityTableStakingActivityQuery,
    TError,
    TData
  >
) =>
  useQuery<EthereumActivityTableStakingActivityQuery, TError, TData>(
    variables === undefined
      ? ["EthereumActivityTableStakingActivity"]
      : ["EthereumActivityTableStakingActivity", variables],
    coreGQLFetcher<
      EthereumActivityTableStakingActivityQuery,
      EthereumActivityTableStakingActivityQueryVariables
    >(EthereumActivityTableStakingActivityDocument, variables),
    options
  );

useEthereumActivityTableStakingActivityQuery.getKey = (
  variables?: EthereumActivityTableStakingActivityQueryVariables
) =>
  variables === undefined
    ? ["EthereumActivityTableStakingActivity"]
    : ["EthereumActivityTableStakingActivity", variables];
export const useInfiniteEthereumActivityTableStakingActivityQuery = <
  TData = EthereumActivityTableStakingActivityQuery,
  TError = unknown
>(
  pageParamKey: keyof EthereumActivityTableStakingActivityQueryVariables,
  variables?: EthereumActivityTableStakingActivityQueryVariables,
  options?: UseInfiniteQueryOptions<
    EthereumActivityTableStakingActivityQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<
    EthereumActivityTableStakingActivityQuery,
    TError,
    TData
  >(
    variables === undefined
      ? ["EthereumActivityTableStakingActivity.infinite"]
      : ["EthereumActivityTableStakingActivity.infinite", variables],
    (metaData) =>
      coreGQLFetcher<
        EthereumActivityTableStakingActivityQuery,
        EthereumActivityTableStakingActivityQueryVariables
      >(EthereumActivityTableStakingActivityDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteEthereumActivityTableStakingActivityQuery.getKey = (
  variables?: EthereumActivityTableStakingActivityQueryVariables
) =>
  variables === undefined
    ? ["EthereumActivityTableStakingActivity.infinite"]
    : ["EthereumActivityTableStakingActivity.infinite", variables];
export const SolanaActivityTableStakingActivityDocument = `
    query SolanaActivityTableStakingActivity($network: String, $protocol: StakingActivityProtocols!, $first: Int, $after: String) {
  stakingActivityLogs(
    network: $network
    protocol: $protocol
    first: $first
    after: $after
  ) {
    nodes {
      ...StakingActivityNode
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${StakingActivityNodeFragmentDoc}`;
export const useSolanaActivityTableStakingActivityQuery = <
  TData = SolanaActivityTableStakingActivityQuery,
  TError = unknown
>(
  variables: SolanaActivityTableStakingActivityQueryVariables,
  options?: UseQueryOptions<
    SolanaActivityTableStakingActivityQuery,
    TError,
    TData
  >
) =>
  useQuery<SolanaActivityTableStakingActivityQuery, TError, TData>(
    ["SolanaActivityTableStakingActivity", variables],
    coreGQLFetcher<
      SolanaActivityTableStakingActivityQuery,
      SolanaActivityTableStakingActivityQueryVariables
    >(SolanaActivityTableStakingActivityDocument, variables),
    options
  );

useSolanaActivityTableStakingActivityQuery.getKey = (
  variables: SolanaActivityTableStakingActivityQueryVariables
) => ["SolanaActivityTableStakingActivity", variables];
export const useInfiniteSolanaActivityTableStakingActivityQuery = <
  TData = SolanaActivityTableStakingActivityQuery,
  TError = unknown
>(
  pageParamKey: keyof SolanaActivityTableStakingActivityQueryVariables,
  variables: SolanaActivityTableStakingActivityQueryVariables,
  options?: UseInfiniteQueryOptions<
    SolanaActivityTableStakingActivityQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<
    SolanaActivityTableStakingActivityQuery,
    TError,
    TData
  >(
    ["SolanaActivityTableStakingActivity.infinite", variables],
    (metaData) =>
      coreGQLFetcher<
        SolanaActivityTableStakingActivityQuery,
        SolanaActivityTableStakingActivityQueryVariables
      >(SolanaActivityTableStakingActivityDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteSolanaActivityTableStakingActivityQuery.getKey = (
  variables: SolanaActivityTableStakingActivityQueryVariables
) => ["SolanaActivityTableStakingActivity.infinite", variables];
export const BalanceAndRewardsCardsDocument = `
    query BalanceAndRewardsCards($ethereumRewardsNetwork: String, $ethereumBalanceNetwork: EthereumNetworks!, $solanaNetwork: SolanaNetworks!) {
  rewardsSummary(network: $ethereumRewardsNetwork) {
    totalRewards
    totalRewardsUsd
  }
  ethereumBalanceSummary(network: $ethereumBalanceNetwork) {
    balance
    balanceUsd
  }
  solanaRewardsSummary(network: $solanaNetwork) {
    totalRewards
    totalRewardsUsd
  }
  solanaBalanceSummary(network: $solanaNetwork) {
    balance
    balanceUsd
  }
}
    `;
export const useBalanceAndRewardsCardsQuery = <
  TData = BalanceAndRewardsCardsQuery,
  TError = unknown
>(
  variables: BalanceAndRewardsCardsQueryVariables,
  options?: UseQueryOptions<BalanceAndRewardsCardsQuery, TError, TData>
) =>
  useQuery<BalanceAndRewardsCardsQuery, TError, TData>(
    ["BalanceAndRewardsCards", variables],
    coreGQLFetcher<
      BalanceAndRewardsCardsQuery,
      BalanceAndRewardsCardsQueryVariables
    >(BalanceAndRewardsCardsDocument, variables),
    options
  );

useBalanceAndRewardsCardsQuery.getKey = (
  variables: BalanceAndRewardsCardsQueryVariables
) => ["BalanceAndRewardsCards", variables];
export const useInfiniteBalanceAndRewardsCardsQuery = <
  TData = BalanceAndRewardsCardsQuery,
  TError = unknown
>(
  pageParamKey: keyof BalanceAndRewardsCardsQueryVariables,
  variables: BalanceAndRewardsCardsQueryVariables,
  options?: UseInfiniteQueryOptions<BalanceAndRewardsCardsQuery, TError, TData>
) => {
  return useInfiniteQuery<BalanceAndRewardsCardsQuery, TError, TData>(
    ["BalanceAndRewardsCards.infinite", variables],
    (metaData) =>
      coreGQLFetcher<
        BalanceAndRewardsCardsQuery,
        BalanceAndRewardsCardsQueryVariables
      >(BalanceAndRewardsCardsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteBalanceAndRewardsCardsQuery.getKey = (
  variables: BalanceAndRewardsCardsQueryVariables
) => ["BalanceAndRewardsCards.infinite", variables];
export const LsEthRedemptionsDocument = `
    query LsETHRedemptions($network: EthereumNetworks!, $address: String!) {
  liquidCollectiveRedeemRequests(network: $network, address: $address) {
    claimableAmountLseth
    claimedAmountLseth
    height
    id
    maxRedeemableAmountEth
    network
    owner
    projectedRedeemableAt
    requestedAt
    statusClaim
    statusSatisfaction
    totalAmountLseth
    withdrawalEventId
    requestedAtTimestamp
  }
}
    `;
export const useLsEthRedemptionsQuery = <
  TData = LsEthRedemptionsQuery,
  TError = unknown
>(
  variables: LsEthRedemptionsQueryVariables,
  options?: UseQueryOptions<LsEthRedemptionsQuery, TError, TData>
) =>
  useQuery<LsEthRedemptionsQuery, TError, TData>(
    ["LsETHRedemptions", variables],
    coreGQLFetcher<LsEthRedemptionsQuery, LsEthRedemptionsQueryVariables>(
      LsEthRedemptionsDocument,
      variables
    ),
    options
  );

useLsEthRedemptionsQuery.getKey = (
  variables: LsEthRedemptionsQueryVariables
) => ["LsETHRedemptions", variables];
export const useInfiniteLsEthRedemptionsQuery = <
  TData = LsEthRedemptionsQuery,
  TError = unknown
>(
  pageParamKey: keyof LsEthRedemptionsQueryVariables,
  variables: LsEthRedemptionsQueryVariables,
  options?: UseInfiniteQueryOptions<LsEthRedemptionsQuery, TError, TData>
) => {
  return useInfiniteQuery<LsEthRedemptionsQuery, TError, TData>(
    ["LsETHRedemptions.infinite", variables],
    (metaData) =>
      coreGQLFetcher<LsEthRedemptionsQuery, LsEthRedemptionsQueryVariables>(
        LsEthRedemptionsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteLsEthRedemptionsQuery.getKey = (
  variables: LsEthRedemptionsQueryVariables
) => ["LsETHRedemptions.infinite", variables];
export const EthereumRewardsEarnedChartDocument = `
    query EthereumRewardsEarnedChart($network: String!) {
  rewardsEarnedOneMonth: aggregatedRewards(
    network: $network
    timePeriod: one_month
  ) {
    ...EthereumRewardsEarnedChartAggregatedRewards
  }
  rewardsEarnedSixMonths: aggregatedRewards(
    network: $network
    timePeriod: six_months
  ) {
    ...EthereumRewardsEarnedChartAggregatedRewards
  }
  rewardsEarnedOneYear: aggregatedRewards(network: $network, timePeriod: one_year) {
    ...EthereumRewardsEarnedChartAggregatedRewards
  }
}
    ${EthereumRewardsEarnedChartAggregatedRewardsFragmentDoc}`;
export const useEthereumRewardsEarnedChartQuery = <
  TData = EthereumRewardsEarnedChartQuery,
  TError = unknown
>(
  variables: EthereumRewardsEarnedChartQueryVariables,
  options?: UseQueryOptions<EthereumRewardsEarnedChartQuery, TError, TData>
) =>
  useQuery<EthereumRewardsEarnedChartQuery, TError, TData>(
    ["EthereumRewardsEarnedChart", variables],
    coreGQLFetcher<
      EthereumRewardsEarnedChartQuery,
      EthereumRewardsEarnedChartQueryVariables
    >(EthereumRewardsEarnedChartDocument, variables),
    options
  );

useEthereumRewardsEarnedChartQuery.getKey = (
  variables: EthereumRewardsEarnedChartQueryVariables
) => ["EthereumRewardsEarnedChart", variables];
export const useInfiniteEthereumRewardsEarnedChartQuery = <
  TData = EthereumRewardsEarnedChartQuery,
  TError = unknown
>(
  pageParamKey: keyof EthereumRewardsEarnedChartQueryVariables,
  variables: EthereumRewardsEarnedChartQueryVariables,
  options?: UseInfiniteQueryOptions<
    EthereumRewardsEarnedChartQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<EthereumRewardsEarnedChartQuery, TError, TData>(
    ["EthereumRewardsEarnedChart.infinite", variables],
    (metaData) =>
      coreGQLFetcher<
        EthereumRewardsEarnedChartQuery,
        EthereumRewardsEarnedChartQueryVariables
      >(EthereumRewardsEarnedChartDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteEthereumRewardsEarnedChartQuery.getKey = (
  variables: EthereumRewardsEarnedChartQueryVariables
) => ["EthereumRewardsEarnedChart.infinite", variables];
export const SolanaRewardsEarnedChartDocument = `
    query SolanaRewardsEarnedChart($network: String!) {
  rewardsEarnedOneMonth: solanaAggregatedRewards(
    network: $network
    timePeriod: one_month
  ) {
    ...SolanaRewardsEarnedChartAggregatedRewards
  }
  rewardsEarnedSixMonths: solanaAggregatedRewards(
    network: $network
    timePeriod: six_months
  ) {
    ...SolanaRewardsEarnedChartAggregatedRewards
  }
  rewardsEarnedOneYear: solanaAggregatedRewards(
    network: $network
    timePeriod: one_year
  ) {
    ...SolanaRewardsEarnedChartAggregatedRewards
  }
}
    ${SolanaRewardsEarnedChartAggregatedRewardsFragmentDoc}`;
export const useSolanaRewardsEarnedChartQuery = <
  TData = SolanaRewardsEarnedChartQuery,
  TError = unknown
>(
  variables: SolanaRewardsEarnedChartQueryVariables,
  options?: UseQueryOptions<SolanaRewardsEarnedChartQuery, TError, TData>
) =>
  useQuery<SolanaRewardsEarnedChartQuery, TError, TData>(
    ["SolanaRewardsEarnedChart", variables],
    coreGQLFetcher<
      SolanaRewardsEarnedChartQuery,
      SolanaRewardsEarnedChartQueryVariables
    >(SolanaRewardsEarnedChartDocument, variables),
    options
  );

useSolanaRewardsEarnedChartQuery.getKey = (
  variables: SolanaRewardsEarnedChartQueryVariables
) => ["SolanaRewardsEarnedChart", variables];
export const useInfiniteSolanaRewardsEarnedChartQuery = <
  TData = SolanaRewardsEarnedChartQuery,
  TError = unknown
>(
  pageParamKey: keyof SolanaRewardsEarnedChartQueryVariables,
  variables: SolanaRewardsEarnedChartQueryVariables,
  options?: UseInfiniteQueryOptions<
    SolanaRewardsEarnedChartQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<SolanaRewardsEarnedChartQuery, TError, TData>(
    ["SolanaRewardsEarnedChart.infinite", variables],
    (metaData) =>
      coreGQLFetcher<
        SolanaRewardsEarnedChartQuery,
        SolanaRewardsEarnedChartQueryVariables
      >(SolanaRewardsEarnedChartDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteSolanaRewardsEarnedChartQuery.getKey = (
  variables: SolanaRewardsEarnedChartQueryVariables
) => ["SolanaRewardsEarnedChart.infinite", variables];
export const StakingPositionDocument = `
    query StakingPosition($ethAccount: String!, $ethereumRewardsNetwork: String!, $ethereumBalanceNetwork: EthereumNetworks!) {
  rewardsSummary(network: $ethereumRewardsNetwork) {
    stakingRewards
    totalRewards
    totalRewardsUsd
  }
  ethereumBalanceSummary(network: $ethereumBalanceNetwork) {
    balance
    balanceUsd
  }
  validatorCounts(ethAccount: $ethAccount, network: $ethereumRewardsNetwork) {
    exitable
  }
}
    `;
export const useStakingPositionQuery = <
  TData = StakingPositionQuery,
  TError = unknown
>(
  variables: StakingPositionQueryVariables,
  options?: UseQueryOptions<StakingPositionQuery, TError, TData>
) =>
  useQuery<StakingPositionQuery, TError, TData>(
    ["StakingPosition", variables],
    coreGQLFetcher<StakingPositionQuery, StakingPositionQueryVariables>(
      StakingPositionDocument,
      variables
    ),
    options
  );

useStakingPositionQuery.getKey = (variables: StakingPositionQueryVariables) => [
  "StakingPosition",
  variables,
];
export const useInfiniteStakingPositionQuery = <
  TData = StakingPositionQuery,
  TError = unknown
>(
  pageParamKey: keyof StakingPositionQueryVariables,
  variables: StakingPositionQueryVariables,
  options?: UseInfiniteQueryOptions<StakingPositionQuery, TError, TData>
) => {
  return useInfiniteQuery<StakingPositionQuery, TError, TData>(
    ["StakingPosition.infinite", variables],
    (metaData) =>
      coreGQLFetcher<StakingPositionQuery, StakingPositionQueryVariables>(
        StakingPositionDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteStakingPositionQuery.getKey = (
  variables: StakingPositionQueryVariables
) => ["StakingPosition.infinite", variables];
export const EthereumStakingPositionsTableDocument = `
    query EthereumStakingPositionsTable($network: String!, $after: String, $before: String, $first: Int, $last: Int) {
  ethereumWithdrawalAccounts(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: {protocol_eq: "ethereum", network_eq: $network}
    orderBy: {field: createdAt, sortDirection: desc}
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      id
      network
      protocol
      address
      totalRewards
      totalRewardsUsd
      stakedBalance
      stakedBalanceUsd
      eigenpodOwnerAddress
      isEigenpod
      delegatedTo
      totalActiveValidators
      totalVerifiedValidators
      totalValidators
      totalExitableValidators
      validatorCountsByStatus {
        activeExiting
        activeOngoing
        activeSlashed
        deposited
        depositedNotFinalized
        exitedSlashed
        exitedUnslashed
        fundingRequested
        pendingInitialized
        pendingQueued
        provisioned
        withdrawalDone
        withdrawalPossible
      }
    }
  }
}
    `;
export const useEthereumStakingPositionsTableQuery = <
  TData = EthereumStakingPositionsTableQuery,
  TError = unknown
>(
  variables: EthereumStakingPositionsTableQueryVariables,
  options?: UseQueryOptions<EthereumStakingPositionsTableQuery, TError, TData>
) =>
  useQuery<EthereumStakingPositionsTableQuery, TError, TData>(
    ["EthereumStakingPositionsTable", variables],
    coreGQLFetcher<
      EthereumStakingPositionsTableQuery,
      EthereumStakingPositionsTableQueryVariables
    >(EthereumStakingPositionsTableDocument, variables),
    options
  );

useEthereumStakingPositionsTableQuery.getKey = (
  variables: EthereumStakingPositionsTableQueryVariables
) => ["EthereumStakingPositionsTable", variables];
export const useInfiniteEthereumStakingPositionsTableQuery = <
  TData = EthereumStakingPositionsTableQuery,
  TError = unknown
>(
  pageParamKey: keyof EthereumStakingPositionsTableQueryVariables,
  variables: EthereumStakingPositionsTableQueryVariables,
  options?: UseInfiniteQueryOptions<
    EthereumStakingPositionsTableQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<EthereumStakingPositionsTableQuery, TError, TData>(
    ["EthereumStakingPositionsTable.infinite", variables],
    (metaData) =>
      coreGQLFetcher<
        EthereumStakingPositionsTableQuery,
        EthereumStakingPositionsTableQueryVariables
      >(EthereumStakingPositionsTableDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteEthereumStakingPositionsTableQuery.getKey = (
  variables: EthereumStakingPositionsTableQueryVariables
) => ["EthereumStakingPositionsTable.infinite", variables];
export const SolanaStakingPositionsTableDocument = `
    query SolanaStakingPositionsTable($network: SolanaNetworks!, $after: String, $before: String, $first: Int, $last: Int) {
  solanaStakeAccounts(
    first: $first
    last: $last
    after: $after
    before: $before
    network: $network
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      id
      network
      address
      totalRewards
      totalRewardsUsd
      balance
      balanceUsd
      status
      stakeAuthorityAddress
      withdrawAuthorityAddress
      user {
        email
      }
    }
  }
}
    `;
export const useSolanaStakingPositionsTableQuery = <
  TData = SolanaStakingPositionsTableQuery,
  TError = unknown
>(
  variables: SolanaStakingPositionsTableQueryVariables,
  options?: UseQueryOptions<SolanaStakingPositionsTableQuery, TError, TData>
) =>
  useQuery<SolanaStakingPositionsTableQuery, TError, TData>(
    ["SolanaStakingPositionsTable", variables],
    coreGQLFetcher<
      SolanaStakingPositionsTableQuery,
      SolanaStakingPositionsTableQueryVariables
    >(SolanaStakingPositionsTableDocument, variables),
    options
  );

useSolanaStakingPositionsTableQuery.getKey = (
  variables: SolanaStakingPositionsTableQueryVariables
) => ["SolanaStakingPositionsTable", variables];
export const useInfiniteSolanaStakingPositionsTableQuery = <
  TData = SolanaStakingPositionsTableQuery,
  TError = unknown
>(
  pageParamKey: keyof SolanaStakingPositionsTableQueryVariables,
  variables: SolanaStakingPositionsTableQueryVariables,
  options?: UseInfiniteQueryOptions<
    SolanaStakingPositionsTableQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<SolanaStakingPositionsTableQuery, TError, TData>(
    ["SolanaStakingPositionsTable.infinite", variables],
    (metaData) =>
      coreGQLFetcher<
        SolanaStakingPositionsTableQuery,
        SolanaStakingPositionsTableQueryVariables
      >(SolanaStakingPositionsTableDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteSolanaStakingPositionsTableQuery.getKey = (
  variables: SolanaStakingPositionsTableQueryVariables
) => ["SolanaStakingPositionsTable.infinite", variables];
export const ValidatorsTableDocument = `
    query ValidatorsTable($network: String!, $after: String, $first: Int, $address: String) {
  trackedValidators(
    filter: {network_eq: $network, status_not_in: [provisioned, funding_requested], withdrawal_account_address_i_eq: $address}
    after: $after
    first: $first
  ) {
    nodes {
      id
      network
      address
      status
      stakedBalance
      stakedBalanceUsd
      totalRewards
      totalRewardsUsd
      onDemandExit {
        requestedAt
        submittedAt
      }
    }
    totalCount
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;
export const useValidatorsTableQuery = <
  TData = ValidatorsTableQuery,
  TError = unknown
>(
  variables: ValidatorsTableQueryVariables,
  options?: UseQueryOptions<ValidatorsTableQuery, TError, TData>
) =>
  useQuery<ValidatorsTableQuery, TError, TData>(
    ["ValidatorsTable", variables],
    coreGQLFetcher<ValidatorsTableQuery, ValidatorsTableQueryVariables>(
      ValidatorsTableDocument,
      variables
    ),
    options
  );

useValidatorsTableQuery.getKey = (variables: ValidatorsTableQueryVariables) => [
  "ValidatorsTable",
  variables,
];
export const useInfiniteValidatorsTableQuery = <
  TData = ValidatorsTableQuery,
  TError = unknown
>(
  pageParamKey: keyof ValidatorsTableQueryVariables,
  variables: ValidatorsTableQueryVariables,
  options?: UseInfiniteQueryOptions<ValidatorsTableQuery, TError, TData>
) => {
  return useInfiniteQuery<ValidatorsTableQuery, TError, TData>(
    ["ValidatorsTable.infinite", variables],
    (metaData) =>
      coreGQLFetcher<ValidatorsTableQuery, ValidatorsTableQueryVariables>(
        ValidatorsTableDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteValidatorsTableQuery.getKey = (
  variables: ValidatorsTableQueryVariables
) => ["ValidatorsTable.infinite", variables];
export const ValidatorsTableStakingPositionDocument = `
    query ValidatorsTableStakingPosition($address: String!, $network: EthereumNetworks!) {
  ethereumWithdrawalAccount(address: $address, network: $network) {
    isEigenpod
    totalVerifiedValidators
    totalActiveValidators
    delegatedTo
  }
}
    `;
export const useValidatorsTableStakingPositionQuery = <
  TData = ValidatorsTableStakingPositionQuery,
  TError = unknown
>(
  variables: ValidatorsTableStakingPositionQueryVariables,
  options?: UseQueryOptions<ValidatorsTableStakingPositionQuery, TError, TData>
) =>
  useQuery<ValidatorsTableStakingPositionQuery, TError, TData>(
    ["ValidatorsTableStakingPosition", variables],
    coreGQLFetcher<
      ValidatorsTableStakingPositionQuery,
      ValidatorsTableStakingPositionQueryVariables
    >(ValidatorsTableStakingPositionDocument, variables),
    options
  );

useValidatorsTableStakingPositionQuery.getKey = (
  variables: ValidatorsTableStakingPositionQueryVariables
) => ["ValidatorsTableStakingPosition", variables];
export const useInfiniteValidatorsTableStakingPositionQuery = <
  TData = ValidatorsTableStakingPositionQuery,
  TError = unknown
>(
  pageParamKey: keyof ValidatorsTableStakingPositionQueryVariables,
  variables: ValidatorsTableStakingPositionQueryVariables,
  options?: UseInfiniteQueryOptions<
    ValidatorsTableStakingPositionQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<ValidatorsTableStakingPositionQuery, TError, TData>(
    ["ValidatorsTableStakingPosition.infinite", variables],
    (metaData) =>
      coreGQLFetcher<
        ValidatorsTableStakingPositionQuery,
        ValidatorsTableStakingPositionQueryVariables
      >(ValidatorsTableStakingPositionDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteValidatorsTableStakingPositionQuery.getKey = (
  variables: ValidatorsTableStakingPositionQueryVariables
) => ["ValidatorsTableStakingPosition.infinite", variables];
export const RestakingFlowDocument = `
    query RestakingFlow($address: String!, $network: EthereumNetworks!) {
  ethereumWithdrawalAccount(address: $address, network: $network) {
    ...RestakingFlowEthereumWithdrawalAccount
  }
}
    ${RestakingFlowEthereumWithdrawalAccountFragmentDoc}`;
export const useRestakingFlowQuery = <
  TData = RestakingFlowQuery,
  TError = unknown
>(
  variables: RestakingFlowQueryVariables,
  options?: UseQueryOptions<RestakingFlowQuery, TError, TData>
) =>
  useQuery<RestakingFlowQuery, TError, TData>(
    ["RestakingFlow", variables],
    coreGQLFetcher<RestakingFlowQuery, RestakingFlowQueryVariables>(
      RestakingFlowDocument,
      variables
    ),
    options
  );

useRestakingFlowQuery.getKey = (variables: RestakingFlowQueryVariables) => [
  "RestakingFlow",
  variables,
];
export const useInfiniteRestakingFlowQuery = <
  TData = RestakingFlowQuery,
  TError = unknown
>(
  pageParamKey: keyof RestakingFlowQueryVariables,
  variables: RestakingFlowQueryVariables,
  options?: UseInfiniteQueryOptions<RestakingFlowQuery, TError, TData>
) => {
  return useInfiniteQuery<RestakingFlowQuery, TError, TData>(
    ["RestakingFlow.infinite", variables],
    (metaData) =>
      coreGQLFetcher<RestakingFlowQuery, RestakingFlowQueryVariables>(
        RestakingFlowDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteRestakingFlowQuery.getKey = (
  variables: RestakingFlowQueryVariables
) => ["RestakingFlow.infinite", variables];
export const EigenpodVerificationCreateDocument = `
    mutation EigenpodVerificationCreate($network: EthereumNetworks!, $ownerAddress: String!) {
  eigenpodVerificationCreate(
    input: {network: $network, ownerAddress: $ownerAddress}
  ) {
    data {
      eigenpodAddress
      network
      proofs
      transactionPayload
      transactionInputs
    }
    userErrors {
      code
      field
      message
      path
      extras
    }
  }
}
    `;
export const useEigenpodVerificationCreateMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    EigenpodVerificationCreateMutation,
    TError,
    EigenpodVerificationCreateMutationVariables,
    TContext
  >
) =>
  useMutation<
    EigenpodVerificationCreateMutation,
    TError,
    EigenpodVerificationCreateMutationVariables,
    TContext
  >(
    ["EigenpodVerificationCreate"],
    (variables?: EigenpodVerificationCreateMutationVariables) =>
      coreGQLFetcher<
        EigenpodVerificationCreateMutation,
        EigenpodVerificationCreateMutationVariables
      >(EigenpodVerificationCreateDocument, variables)(),
    options
  );
useEigenpodVerificationCreateMutation.getKey = () => [
  "EigenpodVerificationCreate",
];

export const EigenlayerDelegateToDocument = `
    mutation eigenlayerDelegateTo($operatorAddress: String!, $ownerAddress: String!, $network: EthereumNetworks!) {
  eigenlayerDelegateTo(
    input: {operatorAddress: $operatorAddress, ownerAddress: $ownerAddress, network: $network}
  ) {
    data {
      network
      operatorAddress
      ownerAddress
      txSigningPayload
      unsignedRawTx
    }
    userErrors {
      code
      field
      message
      path
      extras
    }
  }
}
    `;
export const useEigenlayerDelegateToMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    EigenlayerDelegateToMutation,
    TError,
    EigenlayerDelegateToMutationVariables,
    TContext
  >
) =>
  useMutation<
    EigenlayerDelegateToMutation,
    TError,
    EigenlayerDelegateToMutationVariables,
    TContext
  >(
    ["eigenlayerDelegateTo"],
    (variables?: EigenlayerDelegateToMutationVariables) =>
      coreGQLFetcher<
        EigenlayerDelegateToMutation,
        EigenlayerDelegateToMutationVariables
      >(EigenlayerDelegateToDocument, variables)(),
    options
  );
useEigenlayerDelegateToMutation.getKey = () => ["eigenlayerDelegateTo"];

export const BabylonNetworkOverviewDocument = `
    query BabylonNetworkOverview($network: BitcoinNetworks!) {
  babylonNetworkOverview(network: $network) {
    finalityProviderAddress
    maxStakingAmount
    maxStakingTime
    minStakingAmount
    minStakingTime
  }
}
    `;
export const useBabylonNetworkOverviewQuery = <
  TData = BabylonNetworkOverviewQuery,
  TError = unknown
>(
  variables: BabylonNetworkOverviewQueryVariables,
  options?: UseQueryOptions<BabylonNetworkOverviewQuery, TError, TData>
) =>
  useQuery<BabylonNetworkOverviewQuery, TError, TData>(
    ["BabylonNetworkOverview", variables],
    coreGQLFetcher<
      BabylonNetworkOverviewQuery,
      BabylonNetworkOverviewQueryVariables
    >(BabylonNetworkOverviewDocument, variables),
    options
  );

useBabylonNetworkOverviewQuery.getKey = (
  variables: BabylonNetworkOverviewQueryVariables
) => ["BabylonNetworkOverview", variables];
export const useInfiniteBabylonNetworkOverviewQuery = <
  TData = BabylonNetworkOverviewQuery,
  TError = unknown
>(
  pageParamKey: keyof BabylonNetworkOverviewQueryVariables,
  variables: BabylonNetworkOverviewQueryVariables,
  options?: UseInfiniteQueryOptions<BabylonNetworkOverviewQuery, TError, TData>
) => {
  return useInfiniteQuery<BabylonNetworkOverviewQuery, TError, TData>(
    ["BabylonNetworkOverview.infinite", variables],
    (metaData) =>
      coreGQLFetcher<
        BabylonNetworkOverviewQuery,
        BabylonNetworkOverviewQueryVariables
      >(BabylonNetworkOverviewDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteBabylonNetworkOverviewQuery.getKey = (
  variables: BabylonNetworkOverviewQueryVariables
) => ["BabylonNetworkOverview.infinite", variables];
export const EthereumStakingFlowDocument = `
    query EthereumStakingFlow {
  ethereumNetworkEstimates {
    estimatedActivation {
      hours
    }
    estimatedWithdrawal {
      hoursMin
      hoursMax
    }
    estimatedExit {
      hours
    }
  }
  ethereumRewardsRate {
    value
  }
  ethereumPrice {
    value
  }
}
    `;
export const useEthereumStakingFlowQuery = <
  TData = EthereumStakingFlowQuery,
  TError = unknown
>(
  variables?: EthereumStakingFlowQueryVariables,
  options?: UseQueryOptions<EthereumStakingFlowQuery, TError, TData>
) =>
  useQuery<EthereumStakingFlowQuery, TError, TData>(
    variables === undefined
      ? ["EthereumStakingFlow"]
      : ["EthereumStakingFlow", variables],
    coreGQLFetcher<EthereumStakingFlowQuery, EthereumStakingFlowQueryVariables>(
      EthereumStakingFlowDocument,
      variables
    ),
    options
  );

useEthereumStakingFlowQuery.getKey = (
  variables?: EthereumStakingFlowQueryVariables
) =>
  variables === undefined
    ? ["EthereumStakingFlow"]
    : ["EthereumStakingFlow", variables];
export const useInfiniteEthereumStakingFlowQuery = <
  TData = EthereumStakingFlowQuery,
  TError = unknown
>(
  pageParamKey: keyof EthereumStakingFlowQueryVariables,
  variables?: EthereumStakingFlowQueryVariables,
  options?: UseInfiniteQueryOptions<EthereumStakingFlowQuery, TError, TData>
) => {
  return useInfiniteQuery<EthereumStakingFlowQuery, TError, TData>(
    variables === undefined
      ? ["EthereumStakingFlow.infinite"]
      : ["EthereumStakingFlow.infinite", variables],
    (metaData) =>
      coreGQLFetcher<
        EthereumStakingFlowQuery,
        EthereumStakingFlowQueryVariables
      >(EthereumStakingFlowDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteEthereumStakingFlowQuery.getKey = (
  variables?: EthereumStakingFlowQueryVariables
) =>
  variables === undefined
    ? ["EthereumStakingFlow.infinite"]
    : ["EthereumStakingFlow.infinite", variables];
export const SolanaStakingFlowDocument = `
    query SolanaStakingFlow($network: SolanaNetworks!, $fundingAccountPubkey: String!, $voteAccountPubkey: String!) {
  solanaStakingMaxDelegationEstimate(
    input: {network: $network, fundingAccountPubkey: $fundingAccountPubkey, voteAccountPubkey: $voteAccountPubkey}
  ) {
    estimatedFee
    minBalanceForRentExemption
  }
  solanaRewardsRate {
    date
    value
  }
  solanaFigmentPublicCommissionRate {
    rate
  }
  solanaEstimatedDeactivationTime(network: mainnet) {
    date
    numSeconds
  }
  solanaEstimatedActivationTime(network: mainnet) {
    date
    numSeconds
  }
  solanaPrice {
    value
  }
}
    `;
export const useSolanaStakingFlowQuery = <
  TData = SolanaStakingFlowQuery,
  TError = unknown
>(
  variables: SolanaStakingFlowQueryVariables,
  options?: UseQueryOptions<SolanaStakingFlowQuery, TError, TData>
) =>
  useQuery<SolanaStakingFlowQuery, TError, TData>(
    ["SolanaStakingFlow", variables],
    coreGQLFetcher<SolanaStakingFlowQuery, SolanaStakingFlowQueryVariables>(
      SolanaStakingFlowDocument,
      variables
    ),
    options
  );

useSolanaStakingFlowQuery.getKey = (
  variables: SolanaStakingFlowQueryVariables
) => ["SolanaStakingFlow", variables];
export const useInfiniteSolanaStakingFlowQuery = <
  TData = SolanaStakingFlowQuery,
  TError = unknown
>(
  pageParamKey: keyof SolanaStakingFlowQueryVariables,
  variables: SolanaStakingFlowQueryVariables,
  options?: UseInfiniteQueryOptions<SolanaStakingFlowQuery, TError, TData>
) => {
  return useInfiniteQuery<SolanaStakingFlowQuery, TError, TData>(
    ["SolanaStakingFlow.infinite", variables],
    (metaData) =>
      coreGQLFetcher<SolanaStakingFlowQuery, SolanaStakingFlowQueryVariables>(
        SolanaStakingFlowDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteSolanaStakingFlowQuery.getKey = (
  variables: SolanaStakingFlowQueryVariables
) => ["SolanaStakingFlow.infinite", variables];
export const ProtocolPriceDocument = `
    query ProtocolPrice($ticker: CurrencyTickers!) {
  protocolPrice(ticker: $ticker) {
    value
  }
}
    `;
export const useProtocolPriceQuery = <
  TData = ProtocolPriceQuery,
  TError = unknown
>(
  variables: ProtocolPriceQueryVariables,
  options?: UseQueryOptions<ProtocolPriceQuery, TError, TData>
) =>
  useQuery<ProtocolPriceQuery, TError, TData>(
    ["ProtocolPrice", variables],
    coreGQLFetcher<ProtocolPriceQuery, ProtocolPriceQueryVariables>(
      ProtocolPriceDocument,
      variables
    ),
    options
  );

useProtocolPriceQuery.getKey = (variables: ProtocolPriceQueryVariables) => [
  "ProtocolPrice",
  variables,
];
export const useInfiniteProtocolPriceQuery = <
  TData = ProtocolPriceQuery,
  TError = unknown
>(
  pageParamKey: keyof ProtocolPriceQueryVariables,
  variables: ProtocolPriceQueryVariables,
  options?: UseInfiniteQueryOptions<ProtocolPriceQuery, TError, TData>
) => {
  return useInfiniteQuery<ProtocolPriceQuery, TError, TData>(
    ["ProtocolPrice.infinite", variables],
    (metaData) =>
      coreGQLFetcher<ProtocolPriceQuery, ProtocolPriceQueryVariables>(
        ProtocolPriceDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteProtocolPriceQuery.getKey = (
  variables: ProtocolPriceQueryVariables
) => ["ProtocolPrice.infinite", variables];
export const StakeBtcDocument = `
    mutation StakeBtc($address: String!, $changeAddress: String!, $network: BitcoinNetworks!, $stakerPk: String!, $stakingAmount: Int!, $stakingDuration: Int!, $taprootPubkey: String, $utxos: [BitcoinUTXO!]!) {
  babylonStakeBtc(
    input: {stakerPk: $stakerPk, stakingDuration: $stakingDuration, stakingAmount: $stakingAmount, changeAddress: $changeAddress, network: $network, utxos: $utxos, address: $address, taprootPubkey: $taprootPubkey}
  ) {
    data {
      signingPayload
      stakeId
    }
    userErrors {
      code
      message
    }
  }
}
    `;
export const useStakeBtcMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    StakeBtcMutation,
    TError,
    StakeBtcMutationVariables,
    TContext
  >
) =>
  useMutation<StakeBtcMutation, TError, StakeBtcMutationVariables, TContext>(
    ["StakeBtc"],
    (variables?: StakeBtcMutationVariables) =>
      coreGQLFetcher<StakeBtcMutation, StakeBtcMutationVariables>(
        StakeBtcDocument,
        variables
      )(),
    options
  );
useStakeBtcMutation.getKey = () => ["StakeBtc"];

export const ConfirmStakedBtcDocument = `
    mutation ConfirmStakedBtc($stakeId: String!, $txHash: String!) {
  babylonStakeBroadcasted(input: {stakeId: $stakeId, txHash: $txHash}) {
    userErrors {
      message
      code
    }
  }
}
    `;
export const useConfirmStakedBtcMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ConfirmStakedBtcMutation,
    TError,
    ConfirmStakedBtcMutationVariables,
    TContext
  >
) =>
  useMutation<
    ConfirmStakedBtcMutation,
    TError,
    ConfirmStakedBtcMutationVariables,
    TContext
  >(
    ["ConfirmStakedBtc"],
    (variables?: ConfirmStakedBtcMutationVariables) =>
      coreGQLFetcher<
        ConfirmStakedBtcMutation,
        ConfirmStakedBtcMutationVariables
      >(ConfirmStakedBtcDocument, variables)(),
    options
  );
useConfirmStakedBtcMutation.getKey = () => ["ConfirmStakedBtc"];

export const EigenPodCreateDocument = `
    mutation EigenPodCreate($network: EthereumNetworks!, $ownerAddress: String!) {
  eigenpodCreate(input: {network: $network, ownerAddress: $ownerAddress}) {
    data {
      address
      deployed
      unsignedRawCreatepodTx
    }
    userErrors {
      code
      message
    }
  }
}
    `;
export const useEigenPodCreateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    EigenPodCreateMutation,
    TError,
    EigenPodCreateMutationVariables,
    TContext
  >
) =>
  useMutation<
    EigenPodCreateMutation,
    TError,
    EigenPodCreateMutationVariables,
    TContext
  >(
    ["EigenPodCreate"],
    (variables?: EigenPodCreateMutationVariables) =>
      coreGQLFetcher<EigenPodCreateMutation, EigenPodCreateMutationVariables>(
        EigenPodCreateDocument,
        variables
      )(),
    options
  );
useEigenPodCreateMutation.getKey = () => ["EigenPodCreate"];

export const StakeEthereumDocument = `
    mutation StakeEthereum($network: Networks!, $amountEth: Int!, $withdrawalAddress: String!, $fundingAddress: String, $feeRecipientAddress: String, $region: Regions) {
  ethereumStakingRequestCreate(
    input: {network: $network, amountEth: $amountEth, withdrawalAddress: $withdrawalAddress, fundingAddress: $fundingAddress, feeRecipientAddress: $feeRecipientAddress, region: $region}
  ) {
    data {
      id
      amountEth
      feeRecipientAddress
      maxGasWei
      network
      region
      unsignedTransactionHashed
      unsignedTransactionSerialized
      withdrawalAddress
    }
    userErrors {
      code
      extras
      field
      message
      path
    }
  }
}
    `;
export const useStakeEthereumMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    StakeEthereumMutation,
    TError,
    StakeEthereumMutationVariables,
    TContext
  >
) =>
  useMutation<
    StakeEthereumMutation,
    TError,
    StakeEthereumMutationVariables,
    TContext
  >(
    ["StakeEthereum"],
    (variables?: StakeEthereumMutationVariables) =>
      coreGQLFetcher<StakeEthereumMutation, StakeEthereumMutationVariables>(
        StakeEthereumDocument,
        variables
      )(),
    options
  );
useStakeEthereumMutation.getKey = () => ["StakeEthereum"];

export const StakeEthereumSetTxHashDocument = `
    mutation StakeEthereumSetTxHash($id: String!, $txHash: String!, $amountEth: Int!, $fundingAddress: String!) {
  ethereumStakingRequestSetTxHash(
    input: {id: $id, txHash: $txHash, amountEth: $amountEth, fundingAddress: $fundingAddress}
  ) {
    data {
      id
    }
    userErrors {
      code
      field
      message
      path
    }
  }
}
    `;
export const useStakeEthereumSetTxHashMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    StakeEthereumSetTxHashMutation,
    TError,
    StakeEthereumSetTxHashMutationVariables,
    TContext
  >
) =>
  useMutation<
    StakeEthereumSetTxHashMutation,
    TError,
    StakeEthereumSetTxHashMutationVariables,
    TContext
  >(
    ["StakeEthereumSetTxHash"],
    (variables?: StakeEthereumSetTxHashMutationVariables) =>
      coreGQLFetcher<
        StakeEthereumSetTxHashMutation,
        StakeEthereumSetTxHashMutationVariables
      >(StakeEthereumSetTxHashDocument, variables)(),
    options
  );
useStakeEthereumSetTxHashMutation.getKey = () => ["StakeEthereumSetTxHash"];

export const SolanaStakingDelegationCreateDocument = `
    mutation solanaStakingDelegationCreate($network: SolanaNetworks!, $amountSol: Float!, $fundingAccountPubkey: String!, $voteAccountPubkey: String!) {
  solanaStakingDelegateTransactionCreate(
    input: {network: $network, amountSol: $amountSol, fundingAccountPubkey: $fundingAccountPubkey, voteAccountPubkey: $voteAccountPubkey}
  ) {
    data {
      stakeAccountPubkey
      signingPayload
      unsignedTransactionSerialized
    }
    userErrors {
      code
      message
    }
  }
}
    `;
export const useSolanaStakingDelegationCreateMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    SolanaStakingDelegationCreateMutation,
    TError,
    SolanaStakingDelegationCreateMutationVariables,
    TContext
  >
) =>
  useMutation<
    SolanaStakingDelegationCreateMutation,
    TError,
    SolanaStakingDelegationCreateMutationVariables,
    TContext
  >(
    ["solanaStakingDelegationCreate"],
    (variables?: SolanaStakingDelegationCreateMutationVariables) =>
      coreGQLFetcher<
        SolanaStakingDelegationCreateMutation,
        SolanaStakingDelegationCreateMutationVariables
      >(SolanaStakingDelegationCreateDocument, variables)(),
    options
  );
useSolanaStakingDelegationCreateMutation.getKey = () => [
  "solanaStakingDelegationCreate",
];

export const SolanaStakingTxSaveDocument = `
    mutation solanaStakingTxSave($network: SolanaNetworks!, $amountSol: Float!, $stakeAccountPubkey: String!, $activityType: StakingActivityActivityTypes!, $txHash: String!) {
  solanaStakingTxSave(
    input: {network: $network, amountSol: $amountSol, stakeAccountPubkey: $stakeAccountPubkey, activityType: $activityType, txHash: $txHash}
  ) {
    data {
      activityAt
      activityType
      amount
      amountCurrency
      createdAt
      estimatedCompletedAt
      id
      network
      protocol
      source
      status
      txHash
      updatedAt
      user {
        email
      }
    }
    userErrors {
      code
      message
    }
  }
}
    `;
export const useSolanaStakingTxSaveMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    SolanaStakingTxSaveMutation,
    TError,
    SolanaStakingTxSaveMutationVariables,
    TContext
  >
) =>
  useMutation<
    SolanaStakingTxSaveMutation,
    TError,
    SolanaStakingTxSaveMutationVariables,
    TContext
  >(
    ["solanaStakingTxSave"],
    (variables?: SolanaStakingTxSaveMutationVariables) =>
      coreGQLFetcher<
        SolanaStakingTxSaveMutation,
        SolanaStakingTxSaveMutationVariables
      >(SolanaStakingTxSaveDocument, variables)(),
    options
  );
useSolanaStakingTxSaveMutation.getKey = () => ["solanaStakingTxSave"];

export const UnstakeEthereumDocument = `
    mutation UnstakeEthereum($network: Networks!, $amountEth: Int!, $withdrawalAddress: String!) {
  ethereumExitRequestCreate(
    input: {network: $network, amountEth: $amountEth, withdrawalAddress: $withdrawalAddress}
  ) {
    data {
      amountEth
      network
      withdrawalAddress
    }
    userErrors {
      code
      extras
      field
      message
      path
    }
  }
}
    `;
export const useUnstakeEthereumMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UnstakeEthereumMutation,
    TError,
    UnstakeEthereumMutationVariables,
    TContext
  >
) =>
  useMutation<
    UnstakeEthereumMutation,
    TError,
    UnstakeEthereumMutationVariables,
    TContext
  >(
    ["UnstakeEthereum"],
    (variables?: UnstakeEthereumMutationVariables) =>
      coreGQLFetcher<UnstakeEthereumMutation, UnstakeEthereumMutationVariables>(
        UnstakeEthereumDocument,
        variables
      )(),
    options
  );
useUnstakeEthereumMutation.getKey = () => ["UnstakeEthereum"];

export const LiquidCollectiveClaimTransactionCreateDocument = `
    mutation LiquidCollectiveClaimTransactionCreate($network: EthereumNetworks!, $redeemRequestId: String!) {
  liquidCollectiveClaimTransactionCreate(
    input: {network: $network, redeemRequestId: $redeemRequestId}
  ) {
    data {
      signingPayload
      unsignedTransactionSerialized
    }
  }
}
    `;
export const useLiquidCollectiveClaimTransactionCreateMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    LiquidCollectiveClaimTransactionCreateMutation,
    TError,
    LiquidCollectiveClaimTransactionCreateMutationVariables,
    TContext
  >
) =>
  useMutation<
    LiquidCollectiveClaimTransactionCreateMutation,
    TError,
    LiquidCollectiveClaimTransactionCreateMutationVariables,
    TContext
  >(
    ["LiquidCollectiveClaimTransactionCreate"],
    (variables?: LiquidCollectiveClaimTransactionCreateMutationVariables) =>
      coreGQLFetcher<
        LiquidCollectiveClaimTransactionCreateMutation,
        LiquidCollectiveClaimTransactionCreateMutationVariables
      >(LiquidCollectiveClaimTransactionCreateDocument, variables)(),
    options
  );
useLiquidCollectiveClaimTransactionCreateMutation.getKey = () => [
  "LiquidCollectiveClaimTransactionCreate",
];

export const LiquidCollectiveRedeemManagerProjectionDocument = `
    query LiquidCollectiveRedeemManagerProjection($network: EthereumNetworks!) {
  liquidCollectiveRedeemManagerProjection(network: $network) {
    projectedFulfilledAt
  }
}
    `;
export const useLiquidCollectiveRedeemManagerProjectionQuery = <
  TData = LiquidCollectiveRedeemManagerProjectionQuery,
  TError = unknown
>(
  variables: LiquidCollectiveRedeemManagerProjectionQueryVariables,
  options?: UseQueryOptions<
    LiquidCollectiveRedeemManagerProjectionQuery,
    TError,
    TData
  >
) =>
  useQuery<LiquidCollectiveRedeemManagerProjectionQuery, TError, TData>(
    ["LiquidCollectiveRedeemManagerProjection", variables],
    coreGQLFetcher<
      LiquidCollectiveRedeemManagerProjectionQuery,
      LiquidCollectiveRedeemManagerProjectionQueryVariables
    >(LiquidCollectiveRedeemManagerProjectionDocument, variables),
    options
  );

useLiquidCollectiveRedeemManagerProjectionQuery.getKey = (
  variables: LiquidCollectiveRedeemManagerProjectionQueryVariables
) => ["LiquidCollectiveRedeemManagerProjection", variables];
export const useInfiniteLiquidCollectiveRedeemManagerProjectionQuery = <
  TData = LiquidCollectiveRedeemManagerProjectionQuery,
  TError = unknown
>(
  pageParamKey: keyof LiquidCollectiveRedeemManagerProjectionQueryVariables,
  variables: LiquidCollectiveRedeemManagerProjectionQueryVariables,
  options?: UseInfiniteQueryOptions<
    LiquidCollectiveRedeemManagerProjectionQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<
    LiquidCollectiveRedeemManagerProjectionQuery,
    TError,
    TData
  >(
    ["LiquidCollectiveRedeemManagerProjection.infinite", variables],
    (metaData) =>
      coreGQLFetcher<
        LiquidCollectiveRedeemManagerProjectionQuery,
        LiquidCollectiveRedeemManagerProjectionQueryVariables
      >(LiquidCollectiveRedeemManagerProjectionDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteLiquidCollectiveRedeemManagerProjectionQuery.getKey = (
  variables: LiquidCollectiveRedeemManagerProjectionQueryVariables
) => ["LiquidCollectiveRedeemManagerProjection.infinite", variables];
export const LiquidCollectiveRedeemRequestCreateDocument = `
    mutation LiquidCollectiveRedeemRequestCreate($network: EthereumNetworks!, $address: String!, $amount: String!) {
  liquidCollectiveRedeemRequestCreate(
    input: {network: $network, address: $address, amountLseth: $amount}
  ) {
    data {
      signingPayload
      unsignedTransactionSerialized
    }
  }
}
    `;
export const useLiquidCollectiveRedeemRequestCreateMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    LiquidCollectiveRedeemRequestCreateMutation,
    TError,
    LiquidCollectiveRedeemRequestCreateMutationVariables,
    TContext
  >
) =>
  useMutation<
    LiquidCollectiveRedeemRequestCreateMutation,
    TError,
    LiquidCollectiveRedeemRequestCreateMutationVariables,
    TContext
  >(
    ["LiquidCollectiveRedeemRequestCreate"],
    (variables?: LiquidCollectiveRedeemRequestCreateMutationVariables) =>
      coreGQLFetcher<
        LiquidCollectiveRedeemRequestCreateMutation,
        LiquidCollectiveRedeemRequestCreateMutationVariables
      >(LiquidCollectiveRedeemRequestCreateDocument, variables)(),
    options
  );
useLiquidCollectiveRedeemRequestCreateMutation.getKey = () => [
  "LiquidCollectiveRedeemRequestCreate",
];

export const UndelegateSolanaDocument = `
    mutation UndelegateSolana($network: SolanaNetworks!, $stakeAccountPubkey: String!) {
  solanaStakingDeactivateTransactionCreate(
    input: {network: $network, stakeAccountPubkey: $stakeAccountPubkey}
  ) {
    data {
      unsignedTransactionSerialized
      signingPayload
      lastValidBlockHeight
    }
    userErrors {
      code
      message
      field
      extras
    }
  }
}
    `;
export const useUndelegateSolanaMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UndelegateSolanaMutation,
    TError,
    UndelegateSolanaMutationVariables,
    TContext
  >
) =>
  useMutation<
    UndelegateSolanaMutation,
    TError,
    UndelegateSolanaMutationVariables,
    TContext
  >(
    ["UndelegateSolana"],
    (variables?: UndelegateSolanaMutationVariables) =>
      coreGQLFetcher<
        UndelegateSolanaMutation,
        UndelegateSolanaMutationVariables
      >(UndelegateSolanaDocument, variables)(),
    options
  );
useUndelegateSolanaMutation.getKey = () => ["UndelegateSolana"];

export const SolanaStakingWithdrawTransactionCreateDocument = `
    mutation solanaStakingWithdrawTransactionCreate($network: SolanaNetworks!, $stakeAccountPubkey: String!, $recipientPubkey: String!, $amountSol: Float) {
  solanaStakingWithdrawTransactionCreate(
    input: {network: $network, stakeAccountPubkey: $stakeAccountPubkey, recipientPubkey: $recipientPubkey, amountSol: $amountSol}
  ) {
    data {
      lastValidBlockHeight
      network
      signingPayload
      stakeAccountPubkey
      unsignedTransactionSerialized
    }
    userErrors {
      code
      message
      field
      path
      extras
    }
  }
}
    `;
export const useSolanaStakingWithdrawTransactionCreateMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    SolanaStakingWithdrawTransactionCreateMutation,
    TError,
    SolanaStakingWithdrawTransactionCreateMutationVariables,
    TContext
  >
) =>
  useMutation<
    SolanaStakingWithdrawTransactionCreateMutation,
    TError,
    SolanaStakingWithdrawTransactionCreateMutationVariables,
    TContext
  >(
    ["solanaStakingWithdrawTransactionCreate"],
    (variables?: SolanaStakingWithdrawTransactionCreateMutationVariables) =>
      coreGQLFetcher<
        SolanaStakingWithdrawTransactionCreateMutation,
        SolanaStakingWithdrawTransactionCreateMutationVariables
      >(SolanaStakingWithdrawTransactionCreateDocument, variables)(),
    options
  );
useSolanaStakingWithdrawTransactionCreateMutation.getKey = () => [
  "solanaStakingWithdrawTransactionCreate",
];
