import { EigenPodCreateMutation } from "../../../graphql/core/generated/gql";
import { graphql } from "msw";

export const EigenPodCreateMutationDeployedMock =
  graphql.mutation<EigenPodCreateMutation>(
    "EigenPodCreate",
    (req, res, ctx) => {
      return res(
        ctx.data({
          eigenpodCreate: {
            data: {
              address: "0x7d35B47C58B313DbBdfFa2d02e1F1fF02D1C6F94",
              deployed: true,
              unsignedRawCreatepodTx: undefined,
            },
            userErrors: [],
          },
        })
      );
    }
  );

export const EigenPodCreateMutationMock =
  graphql.mutation<EigenPodCreateMutation>(
    "EigenPodCreate",
    (req, res, ctx) => {
      return res(
        ctx.data({
          eigenpodCreate: {
            data: {
              address: "0x7d35B47C58B313DbBdfFa2d02e1F1fF02D1C6F94",
              deployed: false,
              unsignedRawCreatepodTx: "0x12345",
            },
            userErrors: [],
          },
        })
      );
    }
  );
