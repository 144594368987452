import React from "react";
import { BodyText, Button, Heading, Icon } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { Link } from "../../../../components";
import BigNumber from "bignumber.js";
import { Protocol, getTransactionExplorerUrl } from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import { useNetworkConfig } from "../../../../hooks";
import { fromWei } from "@figmentjs/utils";

type Props = {
  amount: BigNumber;
  onClose: () => void;
};

export const ClaimSuccess: React.FC<Props> = ({ amount, onClose }) => {
  const { network, transactionHash } = Wallet.useWallet();
  const { ticker } = useNetworkConfig({ protocol: Protocol.ETHEREUM });

  return (
    <div className="p-4">
      <div className="flex gap-2 items-center justify-center text-center border-b border-b-green-100 pb-4">
        <Icon icon="MdCheckCircle" size="4xl" color="green-800" />
        <Heading
          level="h5"
          color="green"
          font={HeadingFont.space}
          weight="bold"
        >
          Claimed {BigNumber(fromWei(amount.toString())).dp(5).toString()}{" "}
          {ticker}
        </Heading>
      </div>
      <div className="py-4">
        <BodyText size="sm">Your</BodyText>
        <div className="inline mx-1">
          <Button
            palette="text"
            size="tiny"
            openInNewTab
            href={getTransactionExplorerUrl(
              Protocol.ETHEREUM,
              transactionHash!,
              network
            )}
          >
            transaction
          </Button>
        </div>
        <BodyText size="sm">
          has been confirmed on chain. Your position has been withdrawn.
        </BodyText>
        <Link
          href="https://docs.liquidcollective.io/eth/tokenomics/redemptions#three-stage-redemption-process/"
          text="Liquid Collective Redemptions Deep Dive"
        />
      </div>
      <div className="flex justify-center">
        <Button
          palette="text"
          size="tiny"
          textColor="text-basic-800"
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </div>
  );
};
