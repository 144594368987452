import React from "react";
import { BodyText, Heading, Spinner } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";

type Props = {
  title?: string;
  subtitle?: string;
};

export const ConfirmTransaction: React.FC<Props> = ({
  title = "Confirming Transaction",
  subtitle = "This can take up to a minute. Do not close or refresh the page.",
}) => {
  return (
    <div className="flex flex-col items-center space-y-4">
      <Spinner size={40} />
      <Heading
        color="green-1000"
        level="h5"
        font={HeadingFont.space}
        weight="semibold"
      >
        {title}
      </Heading>
      <div className="text-center">
        <BodyText color="green-1000">{subtitle}</BodyText>
      </div>
    </div>
  );
};
