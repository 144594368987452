import React, { memo } from "react";
import { BodyText, Icon, Heading, Button } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { DetailType, Details } from "../../../../components";

type Props = {
  eigenpodOwnerAddress: `0x${string}`;
  onClose?: () => void;
};

export const WalletIsNotEigenPodOwner: React.FC<Props> = ({
  eigenpodOwnerAddress,
  onClose,
}) => {
  return (
    <>
      <div className="flex flex-col items-center space-y-2 text-center pb-4">
        <Icon icon="CheckYourWallet" size="4xl" color="pending" />
        <Heading
          level="h5"
          color="green"
          font={HeadingFont.space}
          weight="bold"
        >
          Check Your Wallet
        </Heading>
      </div>
      <div className="py-4 border-y border-y-green-100">
        <div className="pt-1 text-center">
          <BodyText as="p" size="sm">
            The connected wallet is not the owner of the EigenPod.
          </BodyText>
          <BodyText as="p" size="sm">
            Connect the wallet below to finish restaking.
          </BodyText>
        </div>
      </div>
      <Details
        details={[
          {
            type: DetailType.ADDRESS,
            label: "EigenPod Owner Wallet Address",
            value: { text: eigenpodOwnerAddress },
          },
        ]}
        showTopBorder={false}
        containerClassName="gap-4"
      />
      <div className="pt-4 w-full">
        <Button size="small" fullWidth onClick={onClose}>
          Close
        </Button>
      </div>
    </>
  );
};

export default memo(WalletIsNotEigenPodOwner);
