import { graphql } from "msw";
import {
  SolanaNetworks,
  SolanaStakingWithdrawTransactionCreateMutation,
  UndelegateSolanaMutation,
} from "../../../graphql/core/generated/gql";

export const SolanaStakingWithdrawTransactionCreateMutationMock =
  graphql.mutation<SolanaStakingWithdrawTransactionCreateMutation>(
    "solanaStakingWithdrawTransactionCreate",
    (req, res, ctx) => {
      return res(
        ctx.data({
          solanaStakingWithdrawTransactionCreate: {
            data: {
              unsignedTransactionSerialized: "unsignedTransactionSerialized",
              signingPayload: "xyz",
              lastValidBlockHeight: 1,
              network: SolanaNetworks.Devnet,
              stakeAccountPubkey: "stake-account-pub-key",
            },
            userErrors: [],
          },
        })
      );
    }
  );

export const SolanaStakingWithdrawTransactionCreateMutationMockWithErrors =
  graphql.mutation<SolanaStakingWithdrawTransactionCreateMutation>(
    "solanaStakingWithdrawTransactionCreate",
    (req, res, ctx) => {
      return res(
        ctx.data({
          solanaStakingWithdrawTransactionCreate: {
            data: undefined,
            userErrors: [{ message: "SOL undelegate error", code: "501" }],
          },
        })
      );
    }
  );

export const UndelegateSolanaMutationMock =
  graphql.mutation<UndelegateSolanaMutation>(
    "UndelegateSolana",
    (req, res, ctx) => {
      return res(
        ctx.data({
          solanaStakingDeactivateTransactionCreate: {
            data: {
              unsignedTransactionSerialized: "unsignedTransactionSerialized",
              signingPayload: "xyz",
              lastValidBlockHeight: 1,
            },
            userErrors: [],
          },
        })
      );
    }
  );

export const UndelegateSolanaMutationMockWithErrors =
  graphql.mutation<UndelegateSolanaMutation>(
    "UndelegateSolana",
    (req, res, ctx) => {
      return res(
        ctx.data({
          solanaStakingDeactivateTransactionCreate: {
            data: undefined,
            userErrors: [{ message: "SOL undelegate error", code: "501" }],
          },
        })
      );
    }
  );
