export const validatorStatusConfig = {
  activating: {
    text: "Activating",
    color: "bg-yellow-1000",
  },
  active: {
    text: "Active",
    color: "bg-green-500",
  },
  active_exit_requested: {
    text: "Exit Requested",
    color: "bg-yellow-1000",
  },
  active_exiting: {
    text: "Exiting",
    color: "bg-yellow-1000",
  },
  withdrawal_pending: {
    text: "Withdrawal Pending",
    color: "bg-yellow-1000",
  },
  withdrawn: {
    text: "Withdrawn",
    color: "bg-basic-800",
  },
  active_slashed: {
    text: "Slashed - Exiting",
    color: "bg-error",
  },
  exited_slashed: {
    text: "Slashed - Withdrawal Pending",
    color: "bg-error",
  },
};

export const validatorStatuses: {
  [key in string]: {
    text: string;
    color: string;
    pulse?: boolean;
  };
} = {
  active: {
    text: validatorStatusConfig.active.text,
    color: validatorStatusConfig.active.color,
  },
  active_exit_requested: {
    text: validatorStatusConfig.active_exit_requested.text,
    color: validatorStatusConfig.active_exiting.color,
  },
  active_exiting: {
    text: validatorStatusConfig.active_exiting.text,
    color: validatorStatusConfig.active_exiting.color,
    pulse: true,
  },
  active_ongoing: {
    text: validatorStatusConfig.active.text,
    color: validatorStatusConfig.active.color,
  },
  active_slashed: {
    text: validatorStatusConfig.active_slashed.text,
    color: validatorStatusConfig.active_slashed.color,
  },
  deposited: {
    text: validatorStatusConfig.activating.text,
    color: validatorStatusConfig.activating.color,
    pulse: true,
  },
  deposited_not_finalized: {
    text: validatorStatusConfig.activating.text,
    color: validatorStatusConfig.activating.color,
    pulse: true,
  },
  exited: {
    text: validatorStatusConfig.withdrawal_pending.text,
    color: validatorStatusConfig.withdrawal_pending.color,
  },
  exited_slashed: {
    text: validatorStatusConfig.exited_slashed.text,
    color: validatorStatusConfig.exited_slashed.color,
  },
  exited_unslashed: {
    text: validatorStatusConfig.withdrawal_pending.text,
    color: validatorStatusConfig.withdrawal_pending.color,
  },
  pending: {
    text: validatorStatusConfig.activating.text,
    color: validatorStatusConfig.activating.color,
    pulse: true,
  },
  pending_initialized: {
    text: validatorStatusConfig.activating.text,
    color: validatorStatusConfig.activating.color,
    pulse: true,
  },
  pending_queued: {
    text: validatorStatusConfig.activating.text,
    color: validatorStatusConfig.activating.color,
    pulse: true,
  },
  withdrawal_done: {
    text: validatorStatusConfig.withdrawn.text,
    color: validatorStatusConfig.withdrawn.color,
  },
  withdrawal_possible: {
    text: validatorStatusConfig.withdrawal_pending.text,
    color: validatorStatusConfig.withdrawal_pending.color,
  },
};
