import { Button, Tooltip } from "@figmentjs/web-core";
import React from "react";

type Props = {
  onUnstake: () => void;
  disabled: boolean;
};

const StakingPositionButton: React.FC<Props> = ({ onUnstake, disabled }) => {
  const tooltipText =
    "Unstaking will be possible once your validator(s) are exitable";

  return disabled ? (
    <Tooltip overlayText={tooltipText}>
      <Button
        palette="tertiary"
        size="xtiny"
        disabled={disabled}
        iconAfter="MdArrowDownward"
        onClick={onUnstake}
      >
        Unstake
      </Button>
    </Tooltip>
  ) : (
    <Button
      palette="tertiary"
      size="xtiny"
      disabled={disabled}
      iconAfter="MdArrowDownward"
      onClick={onUnstake}
    >
      Unstake
    </Button>
  );
};

export default StakingPositionButton;
