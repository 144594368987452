import React, { useEffect } from "react";
import { Icon, Heading, BodyText } from "@figmentjs/web-core";
import Wallet from "@figmentjs/wallet";

type Props = {
  onNetworkChange: () => void;
};

export const UnsupportedNetwork: React.FC<Props> = ({ onNetworkChange }) => {
  const { network } = Wallet.useWallet();

  useEffect(() => {
    if (network) {
      onNetworkChange();
    }
  }, [network, onNetworkChange]);

  return (
    <div className="flex flex-col items-center p-14 px-20">
      <Icon icon="MdOutlineError" size="7xl" color="pending" />
      <div className="mt-8 space-y-3 text-center">
        <Heading level="h5">You&apos;re on an unsupported network</Heading>
        <div>
          <BodyText>Please switch to a supported network.</BodyText>
        </div>
      </div>
      <div className="flex justify-center mt-10">
        <w3m-network-button />
      </div>
    </div>
  );
};
