import React, { useCallback } from "react";
import { BaseStakingFlowData } from "../../../shared/types";
import BigNumber from "bignumber.js";
import { Location } from "../../../../steps/ethereum/validator-location/validator-location";

export type EthereumStakingFlowData = BaseStakingFlowData & {
  location: Location;
  isRestakingEnabled: boolean;
  unsignedCreateEigenPodTx?: `0x${string}` | null;
  eigenPodAddress: string;
  withdrawalAddress: string;
  executionRewardsAddress: string;
};

export const initialValues: EthereumStakingFlowData = {
  amount: new BigNumber(32),
  location: Location.Canada,
  isRestakingEnabled: false,
  unsignedCreateEigenPodTx: undefined,
  eigenPodAddress: "",
  withdrawalAddress: "",
  executionRewardsAddress: "",
  transactionHash: "",
  errors: [],
};

type UpdateFlowData = (newFlowData: Partial<EthereumStakingFlowData>) => void;

export const useFlowData = (initialFlowData = initialValues) => {
  const [flowData, setFlowData] =
    React.useState<EthereumStakingFlowData>(initialFlowData);

  const updateFlowData: UpdateFlowData = useCallback(
    (newFlowData) => {
      setFlowData({ ...flowData, ...newFlowData });
    },
    [flowData]
  );

  return { flowData, updateFlowData };
};
