import { graphql } from "msw";
import { BabylonNetworkOverviewQuery } from "../../../graphql/core/generated/gql";

export const BabylonNetworkOverviewQueryMock =
  graphql.query<BabylonNetworkOverviewQuery>(
    "BabylonNetworkOverview",
    (req, res, ctx) => {
      return res(
        ctx.data({
          babylonNetworkOverview: {
            finalityProviderAddress:
              "710104ee21393df7b209e7f1202b60425075160aea52b60a9bcb0e996771f069",
            maxStakingAmount: 100000,
            maxStakingTime: 150,
            minStakingAmount: 50000,
            minStakingTime: 150,
          },
        })
      );
    }
  );
