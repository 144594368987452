import React from "react";
import { Protocol } from "@figmentjs/protocols";
import { currencyFormatter, tokenFormatter } from "@figmentjs/utils";
import { BodyText, DonutChart } from "@figmentjs/web-core";
import BigNumber from "bignumber.js";

type ProtocolDonutChartData = {
  label: string;
  value: number;
  tokens: string;
  color: string;
  ticker: string;
  protocol: Protocol;
  icon: React.ReactElement;
};

type Props = {
  data: ProtocolDonutChartData[];
};

export const ProtocolDonutChart: React.FC<Props> = ({ data }) => {
  if (data.every((d) => d.value === 0)) {
    return null;
  }

  const total = data.reduce((acc, d) => acc + d.value, 0);

  return (
    <DonutChart<ProtocolDonutChartData>
      animate
      padAngle={data.filter((d) => d.value > 0).length > 1 ? 0.04 : 0}
      cornerRadius={2}
      data={data}
      thickness={10}
      renderTooltipChildren={({ tooltipData }) => (
        <div className="flex flex-col py-2 px-3">
          <div>
            <BodyText color="basic-600">$</BodyText>
            <BodyText color="white">
              {currencyFormatter.format(tooltipData.value, { prefix: "" })}
            </BodyText>
          </div>
          <div className="flex gap-1 items-center">
            <div>{tooltipData.icon}</div>
            <BodyText
              color="basic-100"
              weight="semibold"
              testId="donut-chart-tooltip-token-amount"
            >
              {tokenFormatter.format(
                BigNumber(tooltipData.tokens).toNumber(),
                tooltipData.protocol
              )}
            </BodyText>{" "}
            <BodyText color="basic-600">{tooltipData.ticker}</BodyText>
          </div>
          <div>
            <BodyText color="basic-100" weight="semibold">
              {tokenFormatter.format(
                BigNumber(tooltipData.value)
                  .dividedBy(total)
                  .multipliedBy(100)
                  .toNumber()
              )}
              %
            </BodyText>
          </div>
        </div>
      )}
    />
  );
};
