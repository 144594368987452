import * as React from "react";

type ShimmerItemProps = {
  width: number;
  height?: number;
};

const ShimmerItem: React.FC<ShimmerItemProps> = ({ width, height = "22" }) => {
  return (
    <div
      className="animate-pulse bg-basic-200 rounded"
      style={{ width: `${width}px`, height: `${height}px` }}
    />
  );
};

export default ShimmerItem;
