import React, { useCallback } from "react";
import { BaseStakingFlowData } from "../../../shared/types";
import BigNumber from "bignumber.js";

export type BabylonFlowData = BaseStakingFlowData & {
  acceptedTerms: boolean;
  signingPayload: string;
};

export const babylonInitialValues: BabylonFlowData = {
  amount: BigNumber(0),
  acceptedTerms: false,
  signingPayload: "",
  transactionHash: "",
  errors: [],
};

type UpdateFlowData = (newFlowData: Partial<BabylonFlowData>) => void;

export const useBabylonFlowData = (initialFlowData = babylonInitialValues) => {
  const [flowData, setFlowData] =
    React.useState<BabylonFlowData>(initialFlowData);

  const updateFlowData: UpdateFlowData = useCallback(
    (newFlowData) => {
      setFlowData({ ...flowData, ...newFlowData });
    },
    [flowData]
  );

  return { flowData, updateFlowData };
};
