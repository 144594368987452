import React from "react";
import { localPoint } from "@visx/event";
import { BarStack } from "@visx/shape/lib";
import { StackKey } from "@visx/shape/lib/types/stack";
import { theme } from "../../../theme/tailwind.core.config";
import { startBucketingIndex, bucketKey } from "../shared/utils/constants";
import { Container } from "../shared/components";
import { DisplayedKey } from "../shared/types";
import { BarChartProps } from "./bar-chart.types";

/**
 * The bar chart component.
 *
 * @param BarChartProps
 * @returns The BarChart component
 */
const BarChart = <DataPoint, Key extends StackKey>({
  data,
  keys,
  xTickFormatter,
  getXValue,
  yTickFormatter,
  renderTooltipChildren,
  xPadding = 0.5,
  getValue,
  filterFunc,
  yDomainFunc,
  xDomainFunc,
  showLegend = true,
  customColors,
  customLegendLabels,
  enableFiltering,
  numXTicks,
  numYTicks,
}: BarChartProps<DataPoint, Key>): React.ReactElement => {
  const displayedKeys: DisplayedKey<Key>[] = [
    ...keys.slice(0, startBucketingIndex),
  ];
  const bucketedKeys = keys.slice(startBucketingIndex);

  if (keys.length > startBucketingIndex) {
    displayedKeys.push(bucketKey);
  }

  return (
    <Container<DataPoint, Key>
      keys={displayedKeys}
      data={data}
      bucketedKeys={bucketedKeys}
      filterFunc={filterFunc}
      xTickFormatter={xTickFormatter}
      yTickFormatter={yTickFormatter}
      renderTooltipChildren={renderTooltipChildren}
      yDomainFunc={yDomainFunc}
      xDomainFunc={xDomainFunc}
      xPadding={xPadding}
      showLegend={showLegend}
      numXTicks={numXTicks}
      numYTicks={numYTicks}
      customColors={customColors}
      customLegendLabels={customLegendLabels}
      enableFiltering={enableFiltering}
    >
      {({
        showTooltip,
        hideTooltip,
        colorScale,
        marginLeft,
        data,
        xScale,
        yScale,
        activeKeys,
      }) => (
        <BarStack<DataPoint, DisplayedKey<Key>>
          data={data}
          keys={activeKeys}
          x={getXValue}
          xScale={xScale}
          yScale={yScale}
          color={colorScale}
          offset="diverging"
          value={(dataPoint, key) => {
            if (key === bucketKey) {
              return bucketedKeys.reduce(
                (totalValue, currentKey) =>
                  (totalValue += getValue(dataPoint, currentKey)),
                0
              );
            }

            return getValue(dataPoint, key);
          }}
        >
          {(barStacks) =>
            barStacks.map((barStack) =>
              barStack.bars.map((bar) => {
                const value = getValue(bar.bar.data, bar.key as Key);
                const isNegative = value < 0;

                return (
                  <rect
                    key={`bar-stack-${barStack.index}-${bar.index}`}
                    className="transition-all"
                    x={bar.x}
                    y={bar.y}
                    height={bar.height}
                    width={bar.width}
                    fill={isNegative ? theme.colors.error.DEFAULT : bar.color}
                    onMouseLeave={() => hideTooltip()}
                    onMouseMove={(event) => {
                      const eventSvgCoords = localPoint(event);
                      const left = bar.x + bar.width + marginLeft;

                      showTooltip({
                        tooltipData: bar.bar.data,
                        tooltipTop: eventSvgCoords?.y || bar.y,
                        tooltipLeft: left,
                      });
                    }}
                  />
                );
              })
            )
          }
        </BarStack>
      )}
    </Container>
  );
};

export default BarChart;
