import React from "react";
import {
  BabylonNetwork,
  Protocol,
  getTransactionExplorerUrl,
  protocols,
} from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import {
  useInfiniteBabylonActivityTableStakingActivityQuery,
  StakingActivityProtocols,
  StakingActivityActivityTypes,
  StakingActivityStatuses,
} from "../../../../graphql/core/generated/gql";
import {
  ACTIVITY_TABLE_PAGE_SIZE,
  ActivityTableBase as ActivityTable,
} from "../activity-table-base/activity-table-base";
import { ActivityTableEmpty } from "../activity-table-empty/activity-table-empty";
import { ActivityTableLoading } from "../activity-table-loading/activity-table-loading";
import BigNumber from "bignumber.js";

const networkMap: {
  [key in BabylonNetwork]?: string;
} = {
  [BabylonNetwork.MAINNET]: "mainnet",
  [BabylonNetwork.SIGNET]: "signet",
};

type Props = {
  networkOverride?: BabylonNetwork;
  transparent?: boolean;
};

export const BabylonActivityTable: React.FC<Props> = ({
  networkOverride,
  transparent,
}) => {
  const { network } = Wallet.useWallet<Protocol.BABYLON>();

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteBabylonActivityTableStakingActivityQuery(
      "after",
      {
        network: networkMap[networkOverride || network!],
        protocol: StakingActivityProtocols.Babylon,
        first: ACTIVITY_TABLE_PAGE_SIZE,
      },
      {
        // cacheTime: 0 ensures cached data is cleared when the component unmounts,
        // which prevents duplicate data from being displayed when the component is re-mounted.
        cacheTime: 0,
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage.stakingActivityLogs.pageInfo.hasNextPage &&
          lastPage.stakingActivityLogs.pageInfo.endCursor
            ? { after: lastPage.stakingActivityLogs.pageInfo.endCursor }
            : null,
      }
    );

  if (isLoading) {
    return <ActivityTableLoading />;
  }

  if (!data?.pages?.[0]?.stakingActivityLogs?.nodes?.length) {
    return <ActivityTableEmpty />;
  }

  const activities = data.pages
    .flatMap((group) => group.stakingActivityLogs.nodes)
    .map((activity) => {
      const isStaking =
        activity.activityType === StakingActivityActivityTypes.Stake;
      const isComplete = activity.status === StakingActivityStatuses.Complete;

      const txUrl = activity.txHash
        ? getTransactionExplorerUrl(
            Protocol.BABYLON,
            activity.txHash,
            activity.network as BabylonNetwork
          )
        : null;

      return {
        id: activity.id,
        amount: BigNumber(activity.amount).dp(5).toString(),
        createdAt: activity.createdAt,
        isStaking: isStaking,
        isComplete: isComplete,
        user: activity.user?.email,
        txUrl,
      };
    });

  return (
    <ActivityTable
      ticker={
        protocols[Protocol.BABYLON].networks[
          data.pages[0].stakingActivityLogs.nodes[0].network as BabylonNetwork
        ].ticker
      }
      transparent={transparent}
      activities={activities}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      onFetchNextPage={fetchNextPage}
    />
  );
};
