import React from "react";
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { StringLike } from "@visx/scale";
import { LegendProps, CustomLegendProps } from "./legend.types";
import { twMerge as tw } from "tailwind-merge";

const legendGlyphSize = 15;
const Legend = <Key extends StringLike>({
  colorScale,
  onClick,
  filterKeys,
  customLabels,
}: LegendProps<Key>): React.ReactElement => {
  return (
    <div className="w-full flex justify-center">
      <LegendOrdinal scale={colorScale()} direction="row">
        {(labels) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {labels.map((label) => (
              <CustomLegendItem<Key>
                filterKeys={filterKeys}
                label={label}
                key={label.datum.toString()}
                onClick={onClick}
                customLabels={customLabels}
              />
            ))}
          </div>
        )}
      </LegendOrdinal>
    </div>
  );
};

const CustomLegendItem = <Key extends StringLike>({
  label,
  onClick,
  filterKeys,
  customLabels,
}: CustomLegendProps<Key>) => {
  const isActive = filterKeys[label.datum as string];
  return (
    <LegendItem
      key={`legend-quantile-${label.index}`}
      margin="0 5px"
      className={tw("group ml-4", onClick && "cursor-pointer")}
      onClick={() => {
        onClick?.(label);
      }}
    >
      <svg width={legendGlyphSize} height={legendGlyphSize}>
        <circle
          fill={label.value}
          className={tw(
            "transition-all duration-300 flex self-center rounded-full",
            onClick && "group-hover:opacity-40"
          )}
          opacity={isActive ? 1 : 0.5}
          cx={8}
          cy={8}
          r={4}
        />
      </svg>
      <LegendLabel
        className="capitalize flex-wrap justify-center text-base ml-1  "
        align="left"
        style={{ opacity: isActive ? 1 : 0.5 }}
      >
        {(customLabels && customLabels[label.datum as string]) ||
          label.text.toLocaleLowerCase()}
      </LegendLabel>
    </LegendItem>
  );
};

export default Legend;
