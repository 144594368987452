import * as React from "react";
import dynamic from "next/dynamic";
import BigNumber from "bignumber.js";
import { Button } from "@figmentjs/web-core";
import Wallet from "@figmentjs/wallet";

type Props = {
  insufficientBalance: boolean;
  isLessThanMinimum: boolean;
  isGreaterThanMaximum: boolean;
  onSubmit: (values: any) => void;
  isStakeDisabled: boolean | undefined;
  onConnectWalletClick?: () => void;
  amount: BigNumber;
  ticker: string;
  minimumAmount: number;
  maximumAmount?: number;
};

export const ContinueButtonComponent: React.FC<Props> = ({
  insufficientBalance,
  isLessThanMinimum,
  isGreaterThanMaximum,
  onSubmit,
  isStakeDisabled,
  onConnectWalletClick,
  amount,
  ticker,
  minimumAmount,
  maximumAmount,
}) => {
  const { account, isSendingTransaction, connect, isConnecting } =
    Wallet.useWallet();

  return (
    <div className="mt-4 space-y-4">
      {!account ? (
        <Button
          fullWidth
          size="small"
          onClick={onConnectWalletClick || connect}
          loading={isConnecting}
        >
          Connect Wallet
        </Button>
      ) : (
        <Button
          disabled={
            insufficientBalance ||
            !amount.toNumber() ||
            isStakeDisabled ||
            isLessThanMinimum ||
            isGreaterThanMaximum
          }
          onClick={() => onSubmit({ amount })}
          fullWidth
          size="small"
          loading={isSendingTransaction}
        >
          {insufficientBalance
            ? `Insufficient ${ticker}`
            : isLessThanMinimum
            ? `Can't stake less than ${minimumAmount} ${ticker}`
            : isGreaterThanMaximum
            ? `Can't stake more than ${maximumAmount} ${ticker}`
            : "Stake"}
        </Button>
      )}
    </div>
  );
};

export const ContinueButton = dynamic(
  () => Promise.resolve(ContinueButtonComponent),
  {
    ssr: false,
    loading: () => (
      <div className="mt-4 space-y-4">
        <Button fullWidth size="small" disabled>
          Connect Wallet
        </Button>
      </div>
    ),
  }
);
