import { graphql } from "msw";
import {
  ValidatorsTableQuery,
  ValidatorStatus,
  ValidatorsTableStakingPositionQuery,
} from "../../../graphql/core/generated/gql";

export const ValidatorsTableQueryMock = graphql.query<ValidatorsTableQuery>(
  "ValidatorsTable",
  (req, res, ctx) => {
    return res(
      ctx.data({
        trackedValidators: {
          totalCount: 5,
          nodes: [
            {
              address:
                "0x957dc4f4cf8886f4b092f5db569eecd724d060f1aaa90b9b3d8d9e2c5f272519a5dcb7a89ec2f4fc87f30ec4512eb616",
              id: "98cd2ae0-18cc-49dd-a616-b7cda33c2680",
              network: "holesky",
              status: ValidatorStatus.Deposited,
              stakedBalance: "32",
              totalRewards: "0.281720302",
            },
            {
              address:
                "0x957dc4f4cf8886f4b092f5db569eecd724d060f1aaa90b9b3d8d9e2c5f272519a5dcb7a89ec2f4fc87f30ec4512eb616",
              id: "98cd2ae0-18cc-49dd-a616-b7cda33c2680",
              network: "holesky",
              status: ValidatorStatus.DepositedNotFinalized,
              stakedBalance: "32",
              totalRewards: "0.32029492991",
            },
            {
              address:
                "0x957dc4f4cf8886f4b092f5db569eecd724d060f1aaa90b9b3d8d9e2c5f272519a5dcb7a89ec2f4fc87f30ec4512eb616",
              id: "98cd2ae0-18cc-49dd-a616-b7cda33c2680",
              network: "holesky",
              status: ValidatorStatus.Pending,
              stakedBalance: "32",
              totalRewards: "0.28",
            },
            {
              address:
                "0x957dc4f4cf8886f4b092f5db569eecd724d060f1aaa90b9b3d8d9e2c5f272519a5dcb7a89ec2f4fc87f30ec4512eb616",
              id: "98cd2ae0-18cc-49dd-a616-b7cda33c2680",
              network: "holesky",
              status: ValidatorStatus.PendingInitialized,
              stakedBalance: "32",
              totalRewards: "0.28",
            },
            {
              address:
                "0x957dc4f4cf8886f4b092f5db569eecd724d060f1aaa90b9b3d8d9e2c5f272519a5dcb7a89ec2f4fc87f30ec4512eb616",
              id: "98cd2ae0-18cc-49dd-a616-b7cda33c2680",
              network: "holesky",
              status: ValidatorStatus.PendingQueued,
              stakedBalance: "32",
              totalRewards: "0.28",
            },
            {
              address:
                "0x82c11ea0687aec4b29ee8bd6a62fc817bdad43ec9aaa57d7c40bdcf573f99206ff034f1fb95dd78bddafea9339a9a4ac",
              id: "83b50ec4-69e7-46ab-9317-7b1bb92d8f1e",
              network: "holesky",
              status: ValidatorStatus.Active,
              stakedBalance: "32",
              totalRewards: "0.24",
            },
            {
              address:
                "0x8ebeead62707991ea218e725541b5ac656e6e1f958415b8965475f1627ce56b5f9fb44b1f55aec620ac3fbdffc59a705",
              id: "36e472fe-9140-4660-b34f-1ba603e2acc3",
              network: "holesky",
              status: ValidatorStatus.ActiveOngoing,
              stakedBalance: "32",
              totalRewards: "0.47",
            },
            {
              address:
                "0x8dfb089fd90338b22945a0debc8658d3343fe71b9532f33e4a8f491343d5853ba0c816828684f3965ee15922bb1a25e4",
              id: "b9e29f29-3ea3-456c-9f74-52edec177bf3",
              network: "holesky",
              status: ValidatorStatus.ActiveExiting,
              stakedBalance: "32",
              totalRewards: "0.09",
            },
            {
              address:
                "0x906e6c24ab84e77638bfb56d6939c23258fca3de8ce26e1a06cc602062c4aeb0caa284122232c5695c7fd77bb7713e82",
              id: "602fd6f0-973e-4cf3-877e-4c135cd6b5ec",
              network: "holesky",
              status: ValidatorStatus.ActiveSlashed,
              stakedBalance: "32",
              totalRewards: "0.04",
            },
            {
              address:
                "0x957dc4f4cf8886f4b092f5db569eecd724d060f1aaa90b9b3d8d9e2c5f272519a5dcb7a89ec2f4fc87f30ec4512eb616",
              id: "98cd2ae0-18cc-49dd-a616-b7cda33c2680",
              network: "holesky",
              status: ValidatorStatus.Exited,
              stakedBalance: "32",
              totalRewards: "0.28",
            },
            {
              address:
                "0x957dc4f4cf8886f4b092f5db569eecd724d060f1aaa90b9b3d8d9e2c5f272519a5dcb7a89ec2f4fc87f30ec4512eb616",
              id: "98cd2ae0-18cc-49dd-a616-b7cda33c2680",
              network: "holesky",
              status: ValidatorStatus.ExitedSlashed,
              stakedBalance: "32",
              totalRewards: "0.28",
            },
            {
              address:
                "0x957dc4f4cf8886f4b092f5db569eecd724d060f1aaa90b9b3d8d9e2c5f272519a5dcb7a89ec2f4fc87f30ec4512eb616",
              id: "98cd2ae0-18cc-49dd-a616-b7cda33c2680",
              network: "holesky",
              status: ValidatorStatus.ExitedUnslashed,
              stakedBalance: "32",
              totalRewards: "0.28",
            },
            {
              address:
                "0x957dc4f4cf8886f4b092f5db569eecd724d060f1aaa90b9b3d8d9e2c5f272519a5dcb7a89ec2f4fc87f30ec4512eb616",
              id: "98cd2ae0-18cc-49dd-a616-b7cda33c2680",
              network: "holesky",
              status: ValidatorStatus.WithdrawalPossible,
              stakedBalance: "32",
              totalRewards: "0.28",
            },
            {
              address:
                "0x957dc4f4cf8886f4b092f5db569eecd724d060f1aaa90b9b3d8d9e2c5f272519a5dcb7a89ec2f4fc87f30ec4512eb616",
              id: "98cd2ae0-18cc-49dd-a616-b7cda33c2680",
              network: "holesky",
              status: ValidatorStatus.WithdrawalDone,
              stakedBalance: "32",
              totalRewards: "0.28",
            },
          ],
          pageInfo: {
            endCursor: "OQ",
            hasNextPage: false,
            hasPreviousPage: false,
            startCursor: "MQ",
          },
        },
      })
    );
  }
);

export const ValidatorsTableStakingPositionQueryMock =
  graphql.query<ValidatorsTableStakingPositionQuery>(
    "ValidatorsTableStakingPosition",
    (req, res, ctx) => {
      return res(
        ctx.data({
          ethereumWithdrawalAccount: {
            isEigenpod: true,
            totalActiveValidators: 2,
            totalVerifiedValidators: 0,
            delegatedTo: "0xF264465a0A14Cc121d2cC528F4bbaEBBFd157BB6",
          },
        })
      );
    }
  );
