import { Protocol } from "@figmentjs/protocols";
import { FontColor } from "@figmentjs/web-core/src/theme/types";

export const protocolAddressTheme = (protocol: Protocol) => {
  switch (protocol) {
    case Protocol.ETHEREUM:
      return {
        border: `border-ethereum-1000 border-opacity-20`,
        background: `bg-ethereum bg-opacity-5`,
        text: "ethereum-1000" as keyof typeof FontColor,
      };
    default:
      return {
        border: "border-basic-200",
        background: "bg-basic-100",
        text: "green" as keyof typeof FontColor,
      };
  }
};
