import React, { forwardRef } from "react";
import { twMerge as tw } from "tailwind-merge";
import { ErrorText, HelperText, InputAdornment } from "../shared/components";
import {
  InputTextProps,
  MultiLineInputProps,
  SingleLineInputProps,
} from "./InputText.types";
import { InputSize } from "../shared/types/Input.types";

const inputTextSizeMap: { [key in InputSize]: string } = {
  small: "h-10",
  base: "h-12",
  large: "h-14",
};

/**
 * The text input field.
 *
 * @param InputTextProps
 * @returns The InputText component
 */
const InputText = (
  {
    startAdornment,
    endAdornment,
    renderEndAdornment,
    size = "base",
    error,
    helperText,
    multiline,
    borderClassName,
    ...props
  }: InputTextProps<SingleLineInputProps | MultiLineInputProps>,
  ref: React.LegacyRef<HTMLInputElement>
) => {
  const inputHeight = inputTextSizeMap[size];
  const inputClassName = tw(
    `
      w-full appearance-none bg-white border rounded text-black font-normal text-base leading-5 font-sans placeholder-basic-800 placeholder-opacity-60
      focus:shadow-md focus:outline-none focus:border-green focus:ring-1 focus:ring-green
      disabled:border-basic-300 disabled:shadow-none
      invalid:border-error
      focus:invalid:border-error focus:invalid:ring-error
    `,
    borderClassName ? borderClassName : "border-black",
    !multiline ? inputHeight : "",
    error && "border-error focus:border-error focus:ring-error",
    size === "small" ? "py-2 px-4" : "p-4",
    props.disabled && "cursor-not-allowed",
    startAdornment && "pl-10",
    endAdornment && "pr-10"
  );
  const input = multiline ? (
    <textarea
      {...(props as InputTextProps<MultiLineInputProps>)}
      className={inputClassName}
      {...(props.testId ? { "data-testid": props.testId } : {})}
    />
  ) : (
    <input
      {...(props as InputTextProps<SingleLineInputProps>)}
      className={inputClassName}
      {...(props.testId ? { "data-testid": props.testId } : {})}
      ref={ref}
    />
  );

  return (
    <div className="w-full">
      <div className="flex flex-row relative">
        {startAdornment && (
          <div className={`absolute left-4 flex items-center ${inputHeight}`}>
            <InputAdornment placement="start" adornment={startAdornment} />
          </div>
        )}
        {input}
        {(endAdornment || renderEndAdornment) && (
          <div className={`absolute right-4 flex items-center ${inputHeight}`}>
            {endAdornment && (
              <InputAdornment placement="end" adornment={endAdornment} />
            )}
            {renderEndAdornment ? renderEndAdornment() : null}
          </div>
        )}
      </div>
      {helperText && (
        <div className="mx-2 my-0">
          <HelperText>{helperText}</HelperText>
        </div>
      )}
      {error && (
        <div className="mx-2 my-0">
          <ErrorText>{error}</ErrorText>
        </div>
      )}
    </div>
  );
};

export default forwardRef(InputText);
