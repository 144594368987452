import React from "react";
import { ToggleGroup } from "@figmentjs/web-core";
import { Currencies, CurrencyOptions } from "./currency-toggle.types";

type Props = {
  ticker: string;
  onValueChange: (currency: string) => void;
  currency: string;
};

export const CurrencyToggle: React.FC<Props> = ({
  ticker,
  onValueChange,
  currency,
}) => {
  const currencyOptions: CurrencyOptions = {
    [ticker]: {
      content: ticker,
      value: ticker,
    },
    [Currencies.USD]: {
      content: Currencies.USD,
      value: Currencies.USD,
    },
  };

  if (!Object.keys(currencyOptions).includes(currency)) {
    onValueChange(ticker);
  }

  return (
    <ToggleGroup
      palette="secondary"
      items={Object.values(currencyOptions)}
      size="small"
      value={currency}
      onValueChange={onValueChange}
    />
  );
};
