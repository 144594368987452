import BigNumber from "bignumber.js";
import React, { useEffect } from "react";
import {
  getValidatorExplorerUrl,
  Network,
  Protocol,
} from "@figmentjs/protocols";
import Wallet, { useCurrentWalletAddress } from "@figmentjs/wallet";
import { BodyText, Button } from "@figmentjs/web-core";
import { Address, TableLoading } from "..";
import { AddressType } from "../address-base";
import { twMerge as tw } from "tailwind-merge";
import {
  useInfiniteValidatorsTableQuery,
  ValidatorStatus,
} from "../../../graphql/core/generated/gql";
import { useNetworkConfig } from "../../hooks";
import { validatorStatuses } from "./validators-table.config";
import { hashQueryKey } from "@tanstack/react-query";
import { currencyFormatterCompact, tokenFormatter } from "@figmentjs/utils";
import { ReadonlyURLSearchParams } from "next/navigation";
import { Currencies } from "../currency-toggle/currency-toggle.types";
import { useCurrency } from "../../hooks/use-currency";
import { TableHeader } from "./table-header";

const VALIDATOR_TABLE_PAGE_SIZE = 5;

const columnWidths = {
  publicKey: "flex-1 sm:flex-[1.5_1.5_0%]",
  stake: "flex-[.5_.5_0%] sm:flex-1",
  rewards: "flex-[.5_.5_0%] mr-4 sm:flex-1 sm:mr-8 text-right",
  status: "flex-1 sm:flex-[1.5_1.5_0%]",
};

export type Props = {
  // Address is passed in the FigApp, while in the dapp address comes from useCurrentWalletAddress.
  // This is used as an indicator to display all of the "FigApp-specific" pieces.
  address?: string;
  network?: Network;
  onBack?: (queryParams: ReadonlyURLSearchParams | null) => void;
  renderUnstakingButton?: (address: string) => React.ReactNode;
  renderRestakingButton?: (address: string) => React.ReactNode;
};

export const ValidatorsTable: React.FC<Props> = ({
  address,
  network: networkOverride,
  onBack,
  renderUnstakingButton,
  renderRestakingButton,
}) => {
  const { network: walletNetwork } = Wallet.useWallet<Protocol.ETHEREUM>();
  const { ticker } = useNetworkConfig({ protocol: Protocol.ETHEREUM });
  const { currentWalletAddress } = useCurrentWalletAddress();
  const { isUsd, setIsUsd } = useCurrency();

  const network = networkOverride || walletNetwork;

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteValidatorsTableQuery(
      "after",
      {
        first: VALIDATOR_TABLE_PAGE_SIZE,
        network: network!,
        address: address,
      },
      {
        enabled: !!network,
        cacheTime: 0,
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage.trackedValidators.pageInfo.hasNextPage &&
          lastPage.trackedValidators.pageInfo.endCursor
            ? { after: lastPage.trackedValidators.pageInfo.endCursor }
            : null,
        queryKeyHashFn: () =>
          hashQueryKey([
            "ValidatorsTable.infinite",
            address || currentWalletAddress,
            network,
          ]),
      }
    );

  useEffect(() => {
    const { hash } = window.location;

    if (hash && !isLoading) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ block: "start", behavior: "instant" });
      }
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className="rounded-md bg-white">
        <TableHeader
          address={address}
          onBack={onBack}
          network={network}
          renderUnstakingButton={renderUnstakingButton}
          renderRestakingButton={renderRestakingButton}
          currency={isUsd ? Currencies.USD : ticker}
          setCurrency={setIsUsd}
          ticker={ticker}
        />
        <TableLoading />
      </div>
    );
  }

  if (!data?.pages[0].trackedValidators.nodes.length) {
    return (
      <div className="rounded-md bg-white">
        <TableHeader
          address={address}
          onBack={onBack}
          network={network}
          renderUnstakingButton={renderUnstakingButton}
          renderRestakingButton={renderRestakingButton}
          currency={isUsd ? Currencies.USD : ticker}
          setCurrency={setIsUsd}
          ticker={ticker}
          count={0}
        />
        <div className="grid place-items-center p-12">
          <BodyText size="sm">No validators found</BodyText>
        </div>
      </div>
    );
  }

  const validators = data.pages.flatMap((p) => p.trackedValidators.nodes);

  return (
    <div>
      <div className="rounded-md bg-white">
        <TableHeader
          address={address}
          onBack={onBack}
          network={network}
          renderUnstakingButton={renderUnstakingButton}
          renderRestakingButton={renderRestakingButton}
          currency={isUsd ? Currencies.USD : ticker}
          setCurrency={setIsUsd}
          ticker={ticker}
          count={data?.pages[0].trackedValidators.totalCount}
        />
        <div className="flex flex-col w-full overflow-x-scroll sm:overflow-x-hidden">
          <div className="w-[160%] sm:w-full">
            <div className="flex flex-row border-t border-b border-green-100 px-2 py-3 sm:px-4">
              <div className={columnWidths.publicKey}>
                <BodyText color="basic-800">Public Key</BodyText>
              </div>
              <div className={columnWidths.stake}>
                <BodyText color="basic-800">Stake</BodyText>
              </div>
              <div className={columnWidths.rewards}>
                <BodyText color="basic-800">All Time Rewards</BodyText>
              </div>
              <div className={columnWidths.status}>
                <BodyText color="basic-800">Status</BodyText>
              </div>
            </div>
            <div className="p-2">
              {validators.map((validator) => {
                const hasExitRequest =
                  !!validator.onDemandExit?.requestedAt ||
                  !!validator.onDemandExit?.submittedAt;

                const status =
                  hasExitRequest &&
                  [
                    ValidatorStatus.Active,
                    ValidatorStatus.ActiveOngoing,
                    ValidatorStatus.ActiveSlashed,
                  ].includes(validator.status!)
                    ? "active_exit_requested"
                    : validator.status!;

                const stakedBalance = isUsd
                  ? currencyFormatterCompact.format(
                      new BigNumber(validator.stakedBalanceUsd || 0).toNumber()
                    )
                  : tokenFormatter.format(
                      new BigNumber(validator.stakedBalance || 0).toNumber()
                    );

                const allTimeRewards = isUsd
                  ? currencyFormatterCompact.format(
                      new BigNumber(validator.totalRewardsUsd || 0).toNumber()
                    )
                  : tokenFormatter.format(
                      new BigNumber(validator.totalRewards || 0).toNumber()
                    );

                return (
                  <div
                    className="flex flex-row px-0 py-2 sm:p-2"
                    key={validator.id}
                  >
                    <React.Fragment key={validator.id}>
                      {/* Public Key */}
                      <div className={columnWidths.publicKey}>
                        <Address
                          address={validator.address}
                          protocol={Protocol.ETHEREUM}
                          type={AddressType.VALIDATOR_ADDRESS}
                          overrideTheme={true}
                          href={getValidatorExplorerUrl(
                            Protocol.ETHEREUM,
                            validator.address,
                            network
                          )}
                        />
                      </div>

                      {/* Staked Balance */}
                      <div className={columnWidths.stake}>
                        <BodyText font="mono" weight="semibold">
                          {stakedBalance}
                        </BodyText>
                        {!isUsd && (
                          <BodyText color="basic-800"> {ticker}</BodyText>
                        )}
                      </div>

                      {/* Rewards */}
                      <div className={columnWidths.rewards}>
                        <BodyText font="mono" weight="semibold">
                          {allTimeRewards}
                        </BodyText>
                        {!isUsd && (
                          <BodyText color="basic-800"> {ticker}</BodyText>
                        )}{" "}
                      </div>

                      {/* Status */}
                      <div
                        className={tw(
                          "flex items-center gap-2 flex-nowrap",
                          columnWidths.status
                        )}
                      >
                        <div className="relative w-1.5 h-1.5">
                          <div
                            className={tw(
                              "absolute w-1.5 h-1.5 rounded-full",
                              validatorStatuses[status].color
                            )}
                          >
                            {validatorStatuses[status].pulse && (
                              <div className="absolute w-1.5 h-1.5 bg-yellow-1000 rounded-full animate-ping" />
                            )}
                          </div>
                        </div>
                        <BodyText>{validatorStatuses[status].text}</BodyText>
                      </div>
                    </React.Fragment>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {hasNextPage && (
          <div className="pb-2 sm:pb-4 flex justify-center">
            <Button
              palette="text"
              size="tiny"
              disabled={isFetchingNextPage}
              onClick={fetchNextPage}
            >
              More validators
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
