import React from "react";

export const ActivitySubtext = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <div className="flex flex-1 items-center gap-x-1">
    <div className="relative w-[3px] h-[3px]">
      <div className="absolute w-[3px] h-[3px] bg-basic-300 rounded-full" />
    </div>
    <div>{children}</div>
  </div>
);
