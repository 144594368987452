import * as React from "react";
import { Icon } from "@figmentjs/web-core";

type Props = {
  href: string;
  text: string;
};

export const Link: React.FC<Props> = ({ href, text }) => {
  return (
    <div className="my-4 flex items-center gap-1 justify-center">
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className="text-basic-800 text-sm hover:underline"
      >
        {text}
      </a>
      <Icon icon="MdOpenInNew" color="basic-800" />
    </div>
  );
};
