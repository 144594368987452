import { graphql } from "msw";
import {
  Networks,
  Regions,
  StakeEthereumMutation,
  StakeEthereumSetTxHashMutation,
} from "../../../graphql/core/generated/gql";

export const StakeEthereumMutationMock =
  graphql.mutation<StakeEthereumMutation>("StakeEthereum", (req, res, ctx) => {
    return res(
      ctx.data({
        ethereumStakingRequestCreate: {
          data: {
            id: "12345",
            unsignedTransactionSerialized: "0x123456",
            amountEth: "32",
            maxGasWei: "1",
            network: Networks.Holesky,
            region: Regions.CaCentral_1,
            unsignedTransactionHashed: "0x123456",
            withdrawalAddress: "0x0001",
          },
          userErrors: [],
        },
      })
    );
  });

export const StakeEthereumSetTxHashMutationMock =
  graphql.mutation<StakeEthereumSetTxHashMutation>(
    "StakeEthereumSetTxHash",
    (req, res, ctx) => {
      return res(
        ctx.data({
          ethereumStakingRequestSetTxHash: {
            data: {
              id: "12345",
            },
            userErrors: [],
          },
        })
      );
    }
  );
