import { Icons } from "@figmentjs/web-core";
import { AddressType } from "../address-base";
import { Protocol } from "@figmentjs/protocols";
import { FontColor } from "@figmentjs/web-core/src/theme/types";

export enum DetailType {
  DEFAULT = "DEFAULT",
  ADDRESS = "ADDRESS",
  TOKEN = "TOKEN",
  HREF = "HREF",
}

export type Value = {
  text?: string;
  appendedText?: string;
  tokenDetails?: {
    ticker: string;
    amount: string;
    usdAmount: string;
  };
  prependedIcon?: Icons;
  appendedIcon?: Icons;
  href?: string;
  addressType?: keyof typeof AddressType;
  protocol?: Protocol;
};

export type Detail = {
  prependedIcon?: Icons;
  label: string;
  type: DetailType;
  value: Value;
  tooltipContent?: React.ReactNode;
  detailColor?: keyof typeof FontColor;
};

export type DetailProps = {
  detail: Detail;
};

export type DetailsProps = {
  details: Detail[];
  isLoading?: boolean;
  containerClassName?: string;
  showTopBorder?: boolean;
  showBottomBorder?: boolean;
};
