import { graphql } from "msw";
import {
  Networks,
  UnstakeEthereumMutation,
} from "../../../graphql/core/generated/gql";

export const UnstakeEthereumMutationMock =
  graphql.mutation<UnstakeEthereumMutation>(
    "UnstakeEthereum",
    (req, res, ctx) => {
      return res(
        ctx.data({
          ethereumExitRequestCreate: {
            data: {
              amountEth: "32",
              network: Networks.Holesky,
              withdrawalAddress: "0x0001",
            },
            userErrors: [],
          },
        })
      );
    }
  );

export const UnstakeEthereumErrorMutationMock =
  graphql.mutation<UnstakeEthereumMutation>(
    "UnstakeEthereum",
    (req, res, ctx) => {
      return res(
        ctx.data({
          ethereumExitRequestCreate: {
            data: undefined,
            userErrors: [
              {
                message: "You have no more validators to unstake.",
                code: "NO_MORE_VALIDATORS",
              },
            ],
          },
        })
      );
    }
  );
