import React, { memo } from "react";

import { Popover } from "..";
import DropdownItem from "./DropdownItem";
import { DropdownProps } from "./Dropdown.types";

const Dropdown: React.FC<DropdownProps> = ({
  children,
  items = [],
  size = "lg",
}) => {
  if (!items.length) {
    return children;
  }

  return (
    <div className="w-fit">
      <Popover
        trigger={children}
        className="w-fit flex flex-col drop-shadow-lg"
      >
        {items.map((item, index) => {
          return (
            <DropdownItem
              key={`dropdown-${index}`}
              item={item}
              small={size === "sm"}
            />
          );
        })}
      </Popover>
    </div>
  );
};

export default memo(Dropdown);
