import React, { useState } from "react";
import BigNumber from "bignumber.js";
import Wallet, { useTransactionFee } from "@figmentjs/wallet";
import { friendlyDateFromHours } from "@figmentjs/utils/src/formatters/date";
import { Protocol } from "@figmentjs/protocols";
import { EthereumStakingFlowQuery } from "../../../../../graphql/core/generated/gql";
import { EthereumStakingFlowData } from "../../../flows/ethereum/siwe/use-flow-data/use-flow-data";
import { Amount } from "../../shared";
import { OnSubmit } from "../../shared/amount/amount";
import { Detail, DetailType } from "../../../../components";
import { BodyText } from "@figmentjs/web-core";
import { currencyFormatter } from "@figmentjs/utils";

type Props = {
  data: EthereumStakingFlowQuery | undefined;
  isLoading: boolean;
  flowData: EthereumStakingFlowData;
  commission?: number;
  onSubmit: OnSubmit;
  isStakeDisabled?: boolean;
};

export const EthereumAmount: React.FC<Props> = ({
  data,
  isLoading,
  commission,
  flowData,
  onSubmit,
  isStakeDisabled,
}) => {
  const [amount, setAmount] = useState<BigNumber>();
  const { account, network } = Wallet.useWallet<Protocol.ETHEREUM>();
  // TODO: include a self-constructed (without calling the API) payload in this call once we know it works
  const { fee } = useTransactionFee({
    account: (account as `0x${string}`) || "",
    network,
    amount: amount?.toString(),
  });

  const activationTime = friendlyDateFromHours(
    data?.ethereumNetworkEstimates.estimatedActivation?.hours
  );

  const details: Detail[] = React.useMemo(
    () => [
      {
        type: DetailType.DEFAULT,
        label: "Estimated Yearly Rewards",
        value: {
          text:
            account &&
            amount &&
            !amount?.isZero() &&
            data?.ethereumPrice.value &&
            data?.ethereumRewardsRate.value
              ? currencyFormatter.format(
                  new BigNumber(amount)
                    .times(data?.ethereumPrice.value)
                    .times(data?.ethereumRewardsRate.value * 0.01)
                )
              : "-",
        },
        detailColor: "green-700",
      },
      {
        type: DetailType.DEFAULT,
        label: "Gross Rewards Rate",
        value: { text: `${data?.ethereumRewardsRate.value || 0}%` },
        tooltipContent: (
          <BodyText color="basic-100" size="sm">
            Average performance of Figment validators over the last 30 days.
          </BodyText>
        ),
      },
      ...(commission
        ? [
            {
              type: DetailType.DEFAULT,
              label: "Commission",
              value: {
                text: `${commission}% of rewards`,
              },
            },
          ]
        : []),
      {
        type: DetailType.DEFAULT,
        label: "Activation Time",
        value: {
          text: `~${activationTime.time} ${activationTime.unit}`,
        },
        tooltipContent: (
          <BodyText color="basic-100" size="sm">
            The time between staking your ETH and earning the first reward
          </BodyText>
        ),
      },
    ],
    [
      data?.ethereumRewardsRate.value,
      activationTime,
      amount,
      commission,
      data?.ethereumPrice.value,
    ]
  );

  return (
    <Amount
      protocol={Protocol.ETHEREUM}
      useSlider
      details={details}
      flowData={flowData}
      isLoading={isLoading}
      onSubmit={onSubmit}
      estimatedFee={fee}
      onChange={(amount) => setAmount(amount)}
      price={data?.ethereumPrice.value?.toString() || "0"}
      isStakeDisabled={isStakeDisabled}
    />
  );
};
