import React, { useEffect } from "react";
import {
  Protocol,
  Network,
  getAddressExplorerUrl,
  protocols,
} from "@figmentjs/protocols";
import { BodyText, Button, Tooltip } from "@figmentjs/web-core";
import { Address, CurrencyToggle, TableLoading } from "..";
import { AddressType } from "../address-base";
import {
  useInfiniteEthereumStakingPositionsTableQuery,
  EthereumAccount,
  ValidatorCountsByStatus,
} from "../../../graphql/core/generated/gql";
import { twMerge as tw } from "tailwind-merge";
import { hashQueryKey } from "@tanstack/react-query";
import BigNumber from "bignumber.js";
import { currencyFormatterCompact, tokenFormatter } from "@figmentjs/utils";
import { Currencies } from "../currency-toggle/currency-toggle.types";
import { useCurrency } from "../../hooks/use-currency";
import { useRouter } from "next/navigation";

const TABLE_PAGE_SIZE = 5;

const ETHEREUM_STAKING_POSITIONS_SESSION_STORAGE_KEY =
  "FigmentDashboardScrollPosition";

const columnWidths = {
  address: "w-[190px]",
  validators: "w-[120px]",
  stake: "flex-[.3_.3_0%] lg:flex-[.35_.35_0%] mr-2",
  rewards: "flex-[.3_.3_0%] lg:flex-[.35_.35_0%] mr-2",
  actions: "flex-1",
};

export const hasRestakingTasks = (address: EthereumAccount) => {
  return (
    address.isEigenpod &&
    (address.totalVerifiedValidators < address.totalActiveValidators ||
      !address.delegatedTo) &&
    address.totalActiveValidators > 0
  );
};

const TableHeader = ({
  importPositionButton,
  ticker,
  currency,
  setCurrency,
  count = null,
}: {
  importPositionButton: React.ReactNode;
  ticker?: string;
  currency?: string;
  setCurrency?: (currency: string) => void;
  count?: number | null;
}) => {
  const renderCurrencyToggle = ticker && currency && setCurrency;
  return (
    <div className="p-4 flex justify-between items-center gap-2">
      <div className="flex flex-row items-center mr-auto">
        <BodyText weight="semibold">
          <span className="mr-2">Positions</span>
        </BodyText>
        {count !== null && (
          <div className="text-base bg-basic-800 text-white px-2 rounded-full">
            {count}
          </div>
        )}
      </div>
      {renderCurrencyToggle && (
        <CurrencyToggle
          ticker={ticker}
          currency={currency}
          onValueChange={setCurrency}
        />
      )}
      {importPositionButton}
    </div>
  );
};

const TableEmpty = ({
  importPositionButton,
}: {
  importPositionButton: React.ReactNode;
}) => {
  return (
    <div className="rounded-md bg-white">
      <TableHeader importPositionButton={importPositionButton} count={0} />
      <div className="grid place-items-center p-12 pb-20">
        <BodyText size="sm">No positions yet</BodyText>
      </div>
    </div>
  );
};

type Props = {
  isTestnetMode: boolean;
  renderUnstakingButton: (address: string) => React.ReactNode;
  renderRestakingButton: (address: string) => React.ReactNode;
  importPositionButton: React.ReactNode;
  hasNoPositions: boolean;
};

export const EthereumStakingPositionsTable: React.FC<Props> = ({
  isTestnetMode,
  renderUnstakingButton,
  renderRestakingButton,
  importPositionButton,
  hasNoPositions,
}) => {
  const network = isTestnetMode ? Network.HOLESKY : Network.MAINNET;
  const ticker = protocols[Protocol.ETHEREUM].networks[network].ticker;
  const { isUsd, setIsUsd } = useCurrency();
  const router = useRouter();

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteEthereumStakingPositionsTableQuery(
      "after",
      {
        network,
        first: TABLE_PAGE_SIZE,
      },
      {
        enabled: !hasNoPositions,
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage.ethereumWithdrawalAccounts.pageInfo.hasNextPage &&
          lastPage.ethereumWithdrawalAccounts.pageInfo.endCursor
            ? { after: lastPage.ethereumWithdrawalAccounts.pageInfo.endCursor }
            : null,
        queryKeyHashFn: () =>
          hashQueryKey(["EthereumStakingPositionsTable.infinite", network]),
      }
    );

  useEffect(() => {
    const scrollPosition = window.sessionStorage.getItem(
      ETHEREUM_STAKING_POSITIONS_SESSION_STORAGE_KEY
    );
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      window.sessionStorage.removeItem("FigmentDashboardScrollPosition");
    }
  }, []);

  if (hasNoPositions) {
    return <TableEmpty importPositionButton={importPositionButton} />;
  }

  if (isLoading) {
    return (
      <div className="rounded-md bg-white">
        <TableHeader importPositionButton={importPositionButton} />
        <TableLoading />
      </div>
    );
  }

  if (!data?.pages[0].ethereumWithdrawalAccounts.nodes.length) {
    return <TableEmpty importPositionButton={importPositionButton} />;
  }

  const withdrawalAddresses = data.pages.flatMap(
    (p) => p.ethereumWithdrawalAccounts.nodes
  );

  return (
    <div className="rounded-md bg-white" data-testid="staking-positions-table">
      <TableHeader
        importPositionButton={importPositionButton}
        ticker={ticker}
        currency={isUsd ? Currencies.USD : ticker}
        setCurrency={setIsUsd}
        count={data.pages[0].ethereumWithdrawalAccounts.totalCount}
      />
      <div className="flex flex-col w-full">
        <div className="w-full">
          <div className="flex flex-row border-t border-b border-green-100 py-3 px-4">
            <div className={tw(columnWidths.address)}>
              <BodyText color="basic-800">Withdrawal Address</BodyText>
            </div>
            <div className={tw(columnWidths.validators)}>
              <BodyText color="basic-800">Validators</BodyText>
            </div>
            <div className={tw("text-right", columnWidths.stake)}>
              <BodyText color="basic-800">Stake</BodyText>
            </div>
            <div className={tw("text-right", columnWidths.rewards)}>
              <BodyText color="basic-800">Rewards</BodyText>
            </div>
            <div className={tw("text-right", columnWidths.actions)} />
          </div>
          <div className="p-2">
            {withdrawalAddresses.map((address) => {
              const phaseMap = buildPhaseMap(address.validatorCountsByStatus);

              const stakedBalance = isUsd
                ? currencyFormatterCompact.format(
                    new BigNumber(address.stakedBalanceUsd || 0).toNumber()
                  )
                : tokenFormatter.format(
                    new BigNumber(address.stakedBalance || 0).toNumber(),
                    Protocol.ETHEREUM
                  );

              const allTimeRewards = isUsd
                ? currencyFormatterCompact.format(
                    new BigNumber(address.totalRewardsUsd || 0).toNumber()
                  )
                : tokenFormatter.format(
                    new BigNumber(address.totalRewards || 0).toNumber(),
                    Protocol.ETHEREUM
                  );

              return (
                <div
                  className="group/staking-position flex flex-row p-2 items-center hover:bg-basic-100 cursor-pointer"
                  key={address.id}
                  onClick={() => {
                    window.sessionStorage.setItem(
                      "FigmentDashboardScrollPosition",
                      window.scrollY.toString()
                    );
                    router.push(
                      `/ethereum/validators/${address.address}#validators-table`
                    );
                  }}
                >
                  {/* Withdrawal Address */}
                  <div className={tw(columnWidths.address)}>
                    <Address
                      address={address.address}
                      protocol={Protocol.ETHEREUM}
                      type={
                        address.isEigenpod
                          ? AddressType.EIGENPOD_ADDRESS
                          : AddressType.WITHDRAWAL_ADDRESS
                      }
                      overrideTheme={true}
                      href={getAddressExplorerUrl(
                        Protocol.ETHEREUM,
                        address.address,
                        network
                      )}
                    />
                  </div>

                  {/* Validators */}
                  <div
                    className={tw(
                      "flex items-center gap-2 flex-nowrap",
                      tw(columnWidths.validators)
                    )}
                  >
                    <div className="w-fit flex gap-1 items-center justify-center rounded bg-green-100 py-0.5 px-2">
                      <BodyText font="mono" weight="semibold">
                        {phaseMap.total}
                      </BodyText>

                      {/* circles for validators statuses */}
                      <Tooltip
                        compact
                        delayDuration={0}
                        overlayContent={
                          <div className="flex flex-col">
                            {phaseMap.activating > 0 && (
                              <div className="flex flex-row items-center">
                                <div className="w-2 h-2 rounded-full bg-pending mr-1" />
                                <BodyText
                                  color="basic-100"
                                  size="sm"
                                >{`${phaseMap.activating} activating`}</BodyText>
                              </div>
                            )}
                            {phaseMap.active > 0 && (
                              <div className="flex flex-row items-center">
                                <div className="w-2 h-2 rounded-full bg-green-500 mr-1" />
                                <BodyText
                                  color="basic-100"
                                  size="sm"
                                >{`${phaseMap.active} active`}</BodyText>
                              </div>
                            )}
                            {phaseMap.exiting > 0 && (
                              <div className="flex flex-row items-center">
                                <div className="w-2 h-2 rounded-full bg-pending mr-1" />
                                <BodyText
                                  color="basic-100"
                                  size="sm"
                                >{`${phaseMap.exiting} exiting`}</BodyText>
                              </div>
                            )}
                            {phaseMap.exited > 0 && (
                              <div className="flex flex-row items-center">
                                <div className="w-2 h-2 rounded-full bg-basic-600 mr-1" />
                                <BodyText
                                  color="basic-100"
                                  size="sm"
                                >{`${phaseMap.exited} exited`}</BodyText>
                              </div>
                            )}
                          </div>
                        }
                      >
                        <div
                          className={tw(
                            "flex flex-row",
                            phaseMap.total > 0 ? "ml-1" : ""
                          )}
                        >
                          {phaseMap.activating > 0 && (
                            <div className="w-2 h-2 rounded-full bg-pending mr-1 animate-pulse" />
                          )}
                          {phaseMap.active > 0 && (
                            <div className="w-2 h-2 rounded-full bg-green-500 mr-1" />
                          )}
                          {phaseMap.exiting > 0 && (
                            <div className="w-2 h-2 rounded-full bg-pending mr-1 animate-pulse" />
                          )}
                          {phaseMap.exited > 0 && (
                            <div className="w-2 h-2 rounded-full bg-basic-600 mr-1" />
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  </div>

                  {/* Stake */}
                  <div
                    className={tw(
                      "flex gap-1 justify-end items-baseline",
                      columnWidths.stake
                    )}
                    data-testid="staking-positions-table-stake"
                    title={isUsd ? "" : address.stakedBalance || ""}
                  >
                    <BodyText font="mono" weight="semibold">
                      {stakedBalance}
                    </BodyText>
                    {!isUsd && <BodyText color="basic-800">{ticker}</BodyText>}
                  </div>

                  {/* All Time Rewards */}
                  <div
                    className={tw(
                      "flex gap-1 justify-end",
                      columnWidths.rewards
                    )}
                    data-testid="staking-positions-table-rewards"
                    title={isUsd ? "" : address.totalRewards || ""}
                  >
                    <BodyText font="mono" weight="semibold">
                      {allTimeRewards}
                    </BodyText>
                    {!isUsd && <BodyText color="basic-800">{ticker}</BodyText>}
                  </div>

                  {/* Actions */}
                  <div
                    className={tw(
                      "flex justify-end gap-2 z-close-button",
                      columnWidths.actions
                    )}
                  >
                    <span className="hidden group-hover/staking-position:block">
                      {address.totalExitableValidators > 0 &&
                        renderUnstakingButton(address.address)}
                    </span>

                    {hasRestakingTasks(address as EthereumAccount) ? (
                      renderRestakingButton(address.address)
                    ) : (
                      <div className="w-[81px]" />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {hasNextPage && (
        <div className="pb-4 flex justify-center">
          <Button
            palette="text"
            size="tiny"
            disabled={isFetchingNextPage}
            onClick={fetchNextPage}
          >
            More Positions
          </Button>
        </div>
      )}
    </div>
  );
};

const countValidatorsByStatuses = (
  validatorCountsByStatus: ValidatorCountsByStatus,
  statusesToSum: Array<keyof ValidatorCountsByStatus>
): number => {
  let count = 0;
  statusesToSum.forEach((statusToSum: keyof ValidatorCountsByStatus) => {
    count += validatorCountsByStatus[statusToSum] as number;
  });

  return count;
};

const buildPhaseMap = (
  validatorCountsByStatus?: ValidatorCountsByStatus | null
) => {
  return {
    total: validatorCountsByStatus
      ? countValidatorsByStatuses(validatorCountsByStatus, [
          "deposited",
          "depositedNotFinalized",
          "pendingInitialized",
          "pendingQueued",
          "activeExiting",
          "activeOngoing",
          "exitedSlashed",
          "exitedUnslashed",
          "withdrawalPossible",
          "withdrawalDone",
        ])
      : 0,
    activating: validatorCountsByStatus
      ? countValidatorsByStatuses(validatorCountsByStatus, [
          "deposited",
          "depositedNotFinalized",
          "pendingInitialized",
          "pendingQueued",
        ])
      : 0,
    active: validatorCountsByStatus
      ? countValidatorsByStatuses(validatorCountsByStatus, ["activeOngoing"])
      : 0,
    exiting: validatorCountsByStatus
      ? countValidatorsByStatuses(validatorCountsByStatus, ["activeExiting"])
      : 0,
    exited: validatorCountsByStatus
      ? countValidatorsByStatuses(validatorCountsByStatus, [
          "exitedSlashed",
          "exitedUnslashed",
          "withdrawalPossible",
          "withdrawalDone",
        ])
      : 0,
  };
};
