import React from "react";
import { AxisLeft as VxAxisLeft, AxisBottom as VxAxisBottom } from "@visx/axis";
import { TextProps } from "@visx/text/lib/Text";
import { theme } from "../../../../../theme/tailwind.config";
import { AxisLeftProps, AxisBottomProps } from "./axis.types";

const {
  colors: { basic },
} = theme;

const sharedTickLabelProps: TextProps = {
  fill: basic["800"],
  fontSize: 12,
};

export const LEFT_LABEL_OFFSET = 12;

export const AxisLeft: React.FC<AxisLeftProps> = ({
  scale,
  tickFormat,
  numTicks,
}) => (
  <VxAxisLeft
    hideAxisLine
    hideTicks
    scale={scale}
    numTicks={numTicks}
    tickFormat={tickFormat}
    tickLabelProps={() => ({
      ...sharedTickLabelProps,
      verticalAnchor: "middle",
      textAnchor: "end",
      dx: LEFT_LABEL_OFFSET * -1,
    })}
    axisClassName="axis-left"
  />
);

export const AxisBottom: React.FC<AxisBottomProps> = ({
  top,
  scale,
  tickFormat,
  numTicks,
}) => (
  <VxAxisBottom
    hideAxisLine
    top={top}
    scale={scale}
    numTicks={numTicks}
    tickStroke={basic["300"]}
    tickFormat={tickFormat}
    tickLabelProps={() => ({
      ...sharedTickLabelProps,
      textAnchor: "middle",
      dy: 8,
    })}
  />
);
