import { graphql } from "msw";
import { ProtocolPriceQuery } from "../../../graphql/core/generated/gql";

export const ProtocolPriceQueryMock = graphql.query<ProtocolPriceQuery>(
  "ProtocolPrice",
  (req, res, ctx) => {
    return res(
      ctx.data({
        protocolPrice: {
          value: 65752.3138022093,
        },
      })
    );
  }
);
