import React from "react";
import { friendlyDate } from "@figmentjs/utils/src/formatters/date";
import { BodyText, Button, Heading, Icon } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { Link } from "../../../../components";
import { useRedeemEthFlowData } from "../../../flows/ls-eth/redeem-eth/use-flow-data";

type Props = {
  onClose: () => void;
};

export const RedeemSuccess: React.FC<Props> = ({ onClose }) => {
  const { redemptionProjection } = useRedeemEthFlowData();
  const friendlyRedemptionProjection = redemptionProjection
    ? friendlyDate(
        redemptionProjection.liquidCollectiveRedeemManagerProjection
          .projectedFulfilledAt
      )
    : null;

  return (
    <div className="p-4">
      <div className="flex gap-2 items-center justify-center text-center border-b border-b-green-100 pb-4">
        <Icon icon="MdCheckCircle" size="4xl" color="green-800" />
        <Heading
          level="h5"
          color="green"
          font={HeadingFont.space}
          weight="bold"
        >
          Processing Redemption
        </Heading>
      </div>
      <div className="py-4">
        <BodyText weight="semibold">What&apos;s Next?</BodyText>
        <div className="pt-1">
          <BodyText size="sm">
            Your redemption request has been received. The exit queue time is
            estimated to be
          </BodyText>
          <BodyText size="sm" weight="semibold">
            {" "}
            {friendlyRedemptionProjection?.time}{" "}
            {friendlyRedemptionProjection?.unit}.{" "}
          </BodyText>
          <BodyText size="sm">Please check back for regular updates.</BodyText>
        </div>
        <Link
          href="https://docs.liquidcollective.io/eth/tokenomics/redemptions#three-stage-redemption-process/"
          text="Liquid Collective Redemptions Deep Dive"
        />
      </div>
      <div className="flex justify-center">
        <Button
          palette="text"
          size="tiny"
          textColor="text-basic-800"
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </div>
  );
};
