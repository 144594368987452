import React from "react";
import { twMerge as tw } from "tailwind-merge";
import { BodyText, Heading, Popover, ShimmerItem } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";

type Props = {
  title: string;
  ticker?: string;
  amount: string;
  secondaryAmount?: string;
  isLoading: boolean;
  aside?: React.ReactNode;
  withBorder?: boolean;
  info?: React.ReactElement;
};

const StakingPositionCard: React.FC<Props> = ({
  title,
  ticker,
  amount,
  secondaryAmount,
  isLoading,
  aside,
  withBorder,
  info,
}) => {
  return (
    <div
      className={tw(
        "p-2 sm:p-4 flex-col flex-1 bg-white rounded-md ",
        withBorder && "border border-basic-300"
      )}
    >
      <div className="flex justify-between">
        <div className="w-full">
          <div className="flex gap-2 w-full pb-1 justify-between items-center">
            <BodyText weight="semibold">{title}</BodyText>
            {info && (
              <Popover
                dark
                placement="bottom"
                className="max-w-sm p-3" // flex flex-col drop-shadow-lg
                trigger={
                  <BodyText italic color="green-800" size="base">
                    <span className="cursor-pointer">Not in your wallet?</span>
                  </BodyText>
                }
              >
                {info}
              </Popover>
            )}
          </div>
          {isLoading ? (
            <ShimmerItem width={100} height={32} />
          ) : (
            <div className="flex justify-between">
              <span className="flex items-baseline gap-1">
                <Heading level="h4" font={HeadingFont.space} weight="semibold">
                  {amount}
                </Heading>
                {ticker && <BodyText color="basic-800">{ticker}</BodyText>}
              </span>
            </div>
          )}
        </div>
        <div>
          <div>{aside}</div>
        </div>
      </div>
      {isLoading ? (
        <div className="h-[32px]" />
      ) : (
        <BodyText color="basic-800">{secondaryAmount}</BodyText>
      )}
    </div>
  );
};

export default StakingPositionCard;
