import BigNumber from "bignumber.js";
import React, { Fragment } from "react";
import { twMerge as tw } from "tailwind-merge";
import { Network, Protocol } from "@figmentjs/protocols";
import { fromWei } from "@figmentjs/utils";
import { friendlyDate } from "@figmentjs/utils/src/formatters/date";
import {
  BodyText,
  Button,
  Icon,
  Spinner,
  Timestamp,
  Tooltip,
} from "@figmentjs/web-core";
import Wallet from "@figmentjs/wallet";
import {
  useLsEthRedemptionsQuery,
  EthereumNetworks,
} from "../../../graphql/core/generated/gql";

const networkMap: {
  [key in EthereumNetworks]: EthereumNetworks;
} = {
  [Network.MAINNET]: EthereumNetworks.Mainnet,
  [Network.HOLESKY]: EthereumNetworks.Holesky,
};

// Possible status combinations are
// documented at https://docs.liquidcollective.io/eth/tokenomics/redemptions#redeem-status-matrix
enum StatusSatisfaction {
  PENDING_SATISFACTION = "PENDING_SATISFACTION",
  FULLY_SATISFIED = "FULLY_SATISFIED",
}

enum StatusClaim {
  NOT_CLAIMED = "NOT_CLAIMED",
  FULLY_CLAIMED = "FULLY_CLAIMED",
}

type CombinedStatus = `${StatusSatisfaction}-${StatusClaim}`;

enum RedeemStatus {
  PENDING = "Pending",
  CLAIMABLE = "Claimable",
  CLAIMED = "Claimed",
}

const getStatus = (
  statusSatisfaction: StatusSatisfaction,
  statusClaim: StatusClaim
): RedeemStatus => {
  const status = `${statusSatisfaction}-${statusClaim}` as CombinedStatus;

  switch (status) {
    case `${StatusSatisfaction.PENDING_SATISFACTION}-${StatusClaim.NOT_CLAIMED}`:
      return RedeemStatus.PENDING;
    case `${StatusSatisfaction.FULLY_SATISFIED}-${StatusClaim.NOT_CLAIMED}`:
      return RedeemStatus.CLAIMABLE;
    case `${StatusSatisfaction.FULLY_SATISFIED}-${StatusClaim.FULLY_CLAIMED}`:
      return RedeemStatus.CLAIMED;
    default:
      return RedeemStatus.PENDING;
  }
};

type Props = {
  onClickRedeem: () => void;
  onClickClaim: (
    amountEth: string,
    amountLsEth: string,
    redeemRequestId: string
  ) => void;
};

export const LsETHRedemptionsTable: React.FC<Props> = ({
  onClickRedeem,
  onClickClaim,
}) => {
  const { network, account } = Wallet.useWallet<Protocol.ETHEREUM>();
  const { lsEthBalance } = Wallet.useLiquidStaking();
  const { data, isLoading } = useLsEthRedemptionsQuery(
    {
      network: networkMap[network! as Network.MAINNET | Network.HOLESKY],
      address: account!,
    },
    {
      enabled: !!(account && network),
      // Redemptions may not always appear immediately after they're
      // created, so we'll poll every 30 seconds to check for new ones.
      refetchInterval: 1000 * 30,
    }
  );

  return (
    <div className="flex flex-col w-full gap-4 p-8 bg-white rounded-2xl">
      <div className="flex justify-between items-center border-b border-b-green-100 pb-4">
        <div className="flex items-center justify-center">
          <div className="mr-1">
            <BodyText weight="semibold">Redemptions</BodyText>
          </div>
          <Tooltip
            overlayContent={
              <div>
                <BodyText color="white" as="p">
                  Your Liquid Collective redemptions.
                </BodyText>
                <BodyText color="white" as="p">
                  Recent redemption requests can take a few minutes to appear.
                </BodyText>
              </div>
            }
          >
            <Icon icon="MdInfoOutline" />
          </Tooltip>
        </div>
        <Button
          size="tiny"
          palette="tertiary"
          onClick={onClickRedeem}
          disabled={lsEthBalance === "0"}
        >
          Redeem ETH
        </Button>
      </div>
      {isLoading && (
        <div className="flex justify-center">
          <Spinner size={26} />
        </div>
      )}
      {!isLoading && !data?.liquidCollectiveRedeemRequests.length && (
        <div className="flex flex-col justify-center items-center">
          <BodyText weight="bold" as="p">
            No Redemptions
          </BodyText>
          <BodyText color="basic-800" as="p">
            Recent redemptions can take a few minutes.
          </BodyText>
        </div>
      )}
      {!isLoading &&
        data?.liquidCollectiveRedeemRequests.map((redeemRequest) => {
          const status = getStatus(
            redeemRequest.statusSatisfaction as StatusSatisfaction,
            redeemRequest.statusClaim as StatusClaim
          );

          const { time, unit } = friendlyDate(
            redeemRequest.projectedRedeemableAt!
          );

          const claimAmountLsEth = BigNumber(
            fromWei(redeemRequest.totalAmountLseth)
          )
            .dp(5)
            .toString();

          return (
            <Fragment key={redeemRequest.id}>
              <div className="flex items-center w-full">
                <div className="flex flex-1 items-center">
                  <div className="whitespace-nowrap">
                    <BodyText>
                      <Timestamp
                        dateTime={redeemRequest.requestedAtTimestamp}
                        format="MMMM dd"
                      />
                    </BodyText>
                  </div>
                </div>
                <div className="flex-1">
                  <BodyText weight="semibold">{claimAmountLsEth}</BodyText>{" "}
                  <BodyText color="basic-800">LsETH</BodyText>
                </div>
                <div className="flex flex-1 items-center gap-x-2 flex-wrap">
                  {status !== RedeemStatus.CLAIMABLE ? (
                    <>
                      <div
                        className={tw(
                          "w-1.5 h-1.5 rounded-full",
                          status === RedeemStatus.PENDING
                            ? "bg-yellow-1000"
                            : status === RedeemStatus.CLAIMED
                            ? "bg-green-500"
                            : ""
                        )}
                      />
                      <BodyText>{status}</BodyText>
                      {status === RedeemStatus.PENDING && (
                        <BodyText
                          color="basic-800"
                          size="sm"
                        >{`~${time} ${unit}`}</BodyText>
                      )}
                    </>
                  ) : (
                    <Button
                      size="tiny"
                      palette="tertiary"
                      onClick={() => {
                        onClickClaim(
                          redeemRequest.maxRedeemableAmountEth,
                          redeemRequest.totalAmountLseth,
                          redeemRequest.id
                        );
                      }}
                    >
                      Claim ETH
                    </Button>
                  )}
                </div>
              </div>
            </Fragment>
          );
        })}
    </div>
  );
};
