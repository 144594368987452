import React, { useEffect, useState } from "react";
import { BigNumber } from "bignumber.js";
import { Protocol, protocols } from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import {
  WalletDetails,
  SliderInput,
  Details,
  Detail,
} from "../../../../components";
import { BaseStakingFlowData } from "../../../flows/shared/types";
import AmountInput from "../../../../components/amount-input/amount-input";
import { useNetworkConfig } from "../../../../hooks";
import { ContinueButton } from "./components";

export type OnSubmit = (values: Partial<BaseStakingFlowData>) => void;

type Props = {
  protocol: Protocol;
  isLoading: boolean;
  price?: string;
  onSubmit: OnSubmit;
  flowData: BaseStakingFlowData;
  estimatedFee: string;
  onChange?: (amount: BigNumber) => void;
  showWalletDetails?: boolean;
  useSlider?: boolean;
  details: Detail[];
  isStakeDisabled?: boolean;
  onConnectWalletClick?: () => void;
  hideMaxButton?: boolean;
  minimumAmount?: number;
  maximumAmount?: number;
};

export const Amount: React.FC<Props> = ({
  protocol,
  isLoading,
  price,
  onSubmit,
  flowData,
  onChange,
  estimatedFee,
  useSlider = false,
  showWalletDetails = true,
  details,
  isStakeDisabled,
  onConnectWalletClick,
  hideMaxButton = false,
  minimumAmount = 0,
  maximumAmount,
}) => {
  const [amount, setAmount] = useState<BigNumber>(flowData.amount);
  const { account, balance } = Wallet.useWallet();

  const { ticker } = useNetworkConfig({ protocol });

  useEffect(() => {
    if (onChange) {
      onChange(amount);
    }
  }, [amount, onChange]);

  const walletBalance = new BigNumber(balance || "0");
  const availableBalance = walletBalance.minus(new BigNumber(estimatedFee));
  const insufficientBalance =
    availableBalance.lt(amount) || availableBalance.lte(minimumAmount);
  const isAmountInputDisabled = !account || !balance || insufficientBalance;
  const isLessThanMinimum = amount.gt(0) && amount.lt(minimumAmount);
  const isGreaterThanMaximum = maximumAmount ? amount.gt(maximumAmount) : false;

  return (
    <>
      {showWalletDetails && <WalletDetails protocol={protocol} />}
      <div className="p-4">
        {useSlider ? (
          <SliderInput
            amount={amount}
            disabled={isAmountInputDisabled}
            protocol={protocol}
            isLoading={isLoading}
            tokenPrice={price || "0"}
            onValueChange={(value) => setAmount(new BigNumber(value))}
            balance={availableBalance}
            initialValue={flowData.amount}
          />
        ) : (
          <div className="pb-4">
            <AmountInput
              ticker={ticker}
              tokenPrice={parseFloat(price || "0.0")}
              balance={availableBalance}
              decimalScale={protocols[protocol].units[0].magnitude!}
              hideMaxButton={hideMaxButton}
              onChange={(amount) => {
                setAmount(new BigNumber(amount || 0));
              }}
              disabled={isAmountInputDisabled}
            />
          </div>
        )}
        <Details
          details={details}
          isLoading={isLoading}
          showTopBorder={false}
        />
        <ContinueButton
          insufficientBalance={insufficientBalance}
          isLessThanMinimum={isLessThanMinimum}
          isGreaterThanMaximum={isGreaterThanMaximum}
          onSubmit={onSubmit}
          isStakeDisabled={isStakeDisabled}
          onConnectWalletClick={onConnectWalletClick}
          amount={amount}
          ticker={ticker}
          minimumAmount={minimumAmount}
          maximumAmount={maximumAmount}
        />
      </div>
    </>
  );
};
