import React, { useEffect } from "react";
import { SignTransaction, ConfirmTransaction } from "../../../../components";
import { SolanaFlowData } from "../../../../staking/flows/solana/default/use-flow-data/use-flow-data";
import Wallet from "@figmentjs/wallet";
import { Protocol, SolanaNetwork } from "@figmentjs/protocols";
import { Spinner } from "@figmentjs/web-core";
import {
  SolanaNetworks,
  StakingActivityActivityTypes,
  useSolanaStakingTxSaveMutation,
} from "../../../../../graphql/core/generated/gql";
import { FigAppActionEnum } from "@figmentjs/analytics/apps";
import BigNumber from "bignumber.js";

type Props = {
  unsignedRawTransaction: string | undefined;
  stakeAccountPubkey: string | undefined;
  amount: BigNumber;
  onSuccess: (values: Partial<SolanaFlowData>) => void;
  onError: (values: Partial<SolanaFlowData>) => void;
  onCancelled: () => void;
  onTrackEvent: ({
    action,
    step,
    amount,
  }: {
    action: FigAppActionEnum;
    step?: string;
    amount?: string;
  }) => void;
};

const networkMap: {
  [key in SolanaNetwork]?: SolanaNetworks;
} = {
  [SolanaNetwork.DEVNET]: SolanaNetworks.Devnet,
  [SolanaNetwork.MAINNET]: SolanaNetworks.Mainnet,
  [SolanaNetwork.TESTNET]: SolanaNetworks.Testnet,
};

export const WithdrawSolanaAccount: React.FC<Props> = ({
  onSuccess,
  onError,
  onCancelled,
  stakeAccountPubkey,
  amount,
  onTrackEvent,
}) => {
  const {
    isSendingTransaction,
    transactionHash,
    setTransactionHash,
    sendTransactionError,
    network,
  } = Wallet.useWallet<Protocol.SOLANA>();

  const { mutateAsync: saveTx } = useSolanaStakingTxSaveMutation();

  useEffect(() => {
    const main = async () => {
      if (transactionHash && !isSendingTransaction && !sendTransactionError) {
        const { solanaStakingTxSave } = await saveTx({
          activityType: StakingActivityActivityTypes.Withdrawal,
          network: networkMap[network!]!,
          stakeAccountPubkey: stakeAccountPubkey!,
          amountSol: amount.toNumber(),
          txHash: transactionHash,
        });
        onSuccess({
          errors: solanaStakingTxSave?.userErrors,
          transactionHash,
        });
      }
    };

    main();
  }, [
    isSendingTransaction,
    transactionHash,
    sendTransactionError,
    setTransactionHash,
    onSuccess,
    saveTx,
  ]);

  if (sendTransactionError === "TX_CANCELED") {
    onTrackEvent({
      action: FigAppActionEnum.WITHDRAW_TRANSACTION_CANCELLED,
      step: "WITHDRAW_SOLANA_ACCOUNT",
      amount: amount.toString(),
    });
    onCancelled();
    return null;
  } else if (sendTransactionError) {
    onError({
      errors: [
        { message: sendTransactionError, code: "", isWalletError: true },
      ],
    });
    return null;
  } else if (isSendingTransaction && !transactionHash) {
    return (
      <div className="p-4">
        <SignTransaction />
      </div>
    );
  } else if (transactionHash) {
    return (
      <div className="p-4">
        <ConfirmTransaction
          title="Withdrawing SOL"
          subtitle="Do not close or refresh the page."
        />
      </div>
    );
  }

  return (
    <div className="py-24 flex justify-center items-center">
      <Spinner />
    </div>
  );
};
