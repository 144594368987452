import * as React from "react";
import * as RadixToast from "@radix-ui/react-toast";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { ToastPlacement, ToastProps } from "./Toast.types";
import Toast, { DEFAULT_NOTIFICATION_DURATION } from "./Toast";
import { twMerge as tw } from "tailwind-merge";

type ToastContext = {
  toasts: Array<ToastProps>;
  addToast: Dispatch<SetStateAction<ToastProps[]>>;
};

const toastPlacementMap: { [key in ToastPlacement]: string } = {
  TOP_LEFT: "top-0 left-0",
  TOP_RIGHT: "top-0 right-0",
  BOTTOM_RIGHT: "bottom-0 right-0",
  BOTTOM_LEFT: "bottom-0 left-0",
  BOTTOM_CENTER: "bottom-0 right-[50%] translate-x-1/2",
  TOP_CENTER: "top-0 right-[50%] translate-x-1/2",
};

export const ToastContext = React.createContext<ToastContext>({
  toasts: [],
  addToast: () => {
    throw new Error("addToast not implemented");
  },
});

export const ToastProvider: React.FC<{
  placement?: keyof typeof ToastPlacement;
  children: React.ReactNode;
}> = ({ placement = ToastPlacement.TOP_LEFT, children }) => {
  const [toasts, addToast] = useState<Array<ToastProps>>([]);

  return (
    <ToastContext.Provider value={{ toasts, addToast }}>
      <RadixToast.Provider>
        <div
          className={tw("fixed z-toast sm:p-8", toastPlacementMap[placement])}
        >
          {toasts.map((toast) => {
            return (
              <Toast
                key={toast.id}
                palette={toast.palette || undefined}
                size={toast.size || undefined}
                message={toast.message}
                duration={toast.duration || DEFAULT_NOTIFICATION_DURATION}
                noIcon={toast.noIcon || undefined}
                action={toast.action || undefined}
                hideDismiss={toast.hideDismiss || undefined}
                open={toast.open || undefined}
                onOpenChange={() =>
                  addToast([...toasts.filter((t) => t.id !== toast.id)])
                }
              />
            );
          })}
        </div>
      </RadixToast.Provider>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const { toasts, addToast } = useContext(ToastContext);

  return {
    toasts,
    addToast: (newToast: Omit<ToastProps, "id">) => {
      const uuid = self.crypto.randomUUID();
      addToast([...toasts, { id: uuid, ...newToast }]);
    },
  };
};
