import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { BodyText, Heading, Button, Spinner } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { Protocol } from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import { WalletDetails } from "../../../../components";
import {
  useNonce,
  useSignatureAccessToken,
  useValidateAccessToken,
  validateAccessTokenQueryKey,
  useGenerateNonce,
} from "../../../../hooks";
import { useRollbar } from "@figmentjs/rollbar-client";

type Props = {
  protocol: Protocol;
  onBack: () => void;
  onNext: () => void;
};

export const VerifyOwnership: React.FC<Props> = ({
  protocol,
  onBack,
  onNext,
}) => {
  const rollbar = useRollbar();
  const { account, signMessage, isSendingTransaction, network } =
    Wallet.useWallet<Protocol.BABYLON>();
  const [verificationFailed, setVerificationFailed] = useState(false);
  const [isSignInWithBitcoinLoading, setIsSignInWithBitcoinLoading] =
    useState(false);
  const { setNonce } = useNonce();
  const { setSignatureAccessToken } = useSignatureAccessToken({
    account,
  });

  const queryClient = useQueryClient();

  const { isLoading: isGenerateNonceLoading, mutateAsync: generateNonce } =
    useGenerateNonce();

  const {
    isLoading: isValidateAccessTokenLoading,
    data: validateAccessTokenData,
  } = useValidateAccessToken();

  useEffect(() => {
    if (validateAccessTokenData?.isValid) {
      onNext();
    }
  }, [validateAccessTokenData, onNext]);

  const isLoading =
    isSignInWithBitcoinLoading ||
    isGenerateNonceLoading ||
    isSendingTransaction;

  const verifyOwnership = async () => {
    setVerificationFailed(false);

    const nonceData = await generateNonce();
    const { encryptedNonce, nonce: unencryptedNonce } = nonceData;

    setNonce(encryptedNonce);

    const message = `${process.env.NEXT_PUBLIC_SIGN_IN_WITH_ETHEREUM_DOMAIN} wants to sign in with your Bitcoin address: ${account}. Nonce: ${unencryptedNonce}.`;

    const signature = await signMessage({
      message,
    });

    const urlParams = new URLSearchParams(window.location.search);
    const rawDappToken = urlParams.get("dappToken");
    const dappToken = rawDappToken === "undefined" ? undefined : rawDappToken;

    setIsSignInWithBitcoinLoading(true);

    const response = await fetch("/api/sign-in-with-bitcoin", {
      method: "POST",
      body: JSON.stringify({
        message,
        signature,
        address: account,
        network,
      }),
      headers: {
        "content-type": "application/json",
        "X-Figment-Nonce": encryptedNonce,
        ...(dappToken ? { "X-Figment-Dapp-Token": dappToken } : {}),
      },
    });
    const data = await response.json();

    setIsSignInWithBitcoinLoading(false);

    const accessToken = data?.accessToken;

    setSignatureAccessToken(accessToken);

    if (!accessToken) {
      rollbar.error(
        "Failed to create SIWB access token",
        JSON.stringify({ message, signature, account, network })
      );
      setVerificationFailed(true);
      return;
    }

    queryClient.invalidateQueries(validateAccessTokenQueryKey);

    onNext();
  };

  if (isValidateAccessTokenLoading) {
    return (
      <div className="py-24 flex justify-center items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <WalletDetails protocol={protocol} />
      <div className="p-4 flex flex-col items-center">
        <div className="space-y-1 text-center mb-4">
          <Heading level="h5" font={HeadingFont.space} weight="semibold">
            Verify Ownership
          </Heading>
          <BodyText as="p">
            To avoid malicious use, Figment needs to confirm you&apos;re the
            owner of this address. Sign the message using your wallet.
          </BodyText>
        </div>
        {verificationFailed && (
          <div className="mt-4">
            <BodyText color="error">
              Verification failed. If you&apos;re the owner of{" "}
              <BodyText weight="bold">{account}</BodyText> and you believe
              something isn&apos;t working properly, please contact us at{" "}
              <a
                href="mailto:support@figment.io"
                className="text-green-800 underline hover:no-underline"
              >
                support@figment.io
              </a>
              .
            </BodyText>
          </div>
        )}
        <div className="flex justify-evenly gap-2 w-full mt-8">
          <Button palette="tertiary" size="small" fullWidth onClick={onBack}>
            Back
          </Button>
          <Button
            size="small"
            onClick={verifyOwnership}
            loading={isLoading}
            fullWidth
          >
            Sign Message
          </Button>
        </div>
      </div>
    </>
  );
};
