import { useState } from "react";

type Props<T extends string> = {
  step: T;
  setStep: (step: T) => void;
};

export const useStepper = <T extends string>(initialStep: T): Props<T> => {
  const [step, setStep] = useState<T>(initialStep);

  return { step, setStep };
};
