import React, { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Protocol } from "@figmentjs/protocols";
import Wallet from "@figmentjs/wallet";
import {
  WalletDetails,
  SignTransaction,
  ConfirmTransaction,
  ErrorStep,
  CancelledStep,
} from "../../../../components";
import { Summary } from "../../shared/summary";
import { DetailType } from "../../../../components/details/details.types";
import { Location } from "../validator-location/validator-location";
import {
  useStakeEthereumMutation,
  useStakeEthereumSetTxHashMutation,
  Regions,
  MutationError,
  useInfiniteEthereumActivityTableStakingActivityQuery,
} from "../../../../../graphql/core/generated/gql";
import { getCoreAPINetwork } from "../../../../utils";
import { EthereumStakingFlowData } from "../../../flows/ethereum/siwe/use-flow-data/use-flow-data";
import { useNetworkConfig } from "../../../../hooks";
import { useSegment } from "../../../../hooks";
import {
  UniversalWidgetActionEnum,
  UniversalWidgetObjectEnum,
} from "@figmentjs/analytics/apps";
type Props = {
  flowData: EthereumStakingFlowData;
  price: number;
  showValidatorLocation?: boolean;
  onSuccess: (values: Partial<EthereumStakingFlowData>) => void;
  onBack: () => void;
};

export const EthereumStakingSummary: React.FC<Props> = ({
  flowData,
  price,
  showValidatorLocation = true,
  onSuccess,
  onBack,
}) => {
  const [isBroadcastTriggered, setIsBroadcastTriggered] = useState(false);
  const [userErrors, setUserErrors] = useState<MutationError[] | null>();
  const { trackEvent } = useSegment();
  const {
    sendTransaction,
    isSendingTransaction,
    transactionHash,
    setTransactionHash,
    sendTransactionError,
    network,
    account,
  } = Wallet.useWallet<Protocol.ETHEREUM>();
  const {
    mutateAsync: stakeEthereum,
    data,
    isLoading,
  } = useStakeEthereumMutation({
    onSuccess: ({ ethereumStakingRequestCreate: { userErrors } }) =>
      setUserErrors(userErrors),
  });
  const { mutateAsync: confirmTransaction } =
    useStakeEthereumSetTxHashMutation();
  const queryClient = useQueryClient();

  const { ticker } = useNetworkConfig({ protocol: Protocol.ETHEREUM });
  const {
    amount,
    eigenPodAddress,
    withdrawalAddress,
    isRestakingEnabled,
    executionRewardsAddress,
    location,
  } = flowData;

  useEffect(() => {
    const main = async () => {
      if (
        transactionHash &&
        !isSendingTransaction &&
        !sendTransactionError &&
        data?.ethereumStakingRequestCreate.data?.id
      ) {
        const {
          ethereumStakingRequestSetTxHash: { userErrors },
        } = await confirmTransaction({
          id: data?.ethereumStakingRequestCreate.data?.id,
          txHash: transactionHash,
          amountEth: amount.toNumber(),
          fundingAddress: account!,
        });

        queryClient.resetQueries({
          queryKey:
            useInfiniteEthereumActivityTableStakingActivityQuery.getKey(),
          exact: false,
        });

        onSuccess({
          errors: userErrors,
          transactionHash,
        });
        setIsBroadcastTriggered(false);
      }
    };

    main();
  }, [
    isSendingTransaction,
    transactionHash,
    sendTransactionError,
    setTransactionHash,
    onSuccess,
    amount,
    confirmTransaction,
    data?.ethereumStakingRequestCreate.data?.id,
    account,
    queryClient,
  ]);

  if (userErrors?.length) {
    return (
      <div className="p-4">
        <ErrorStep
          error={{
            message: userErrors[0].message,
          }}
          onClose={() => setUserErrors(null)}
        />
      </div>
    );
  } else if (isBroadcastTriggered && sendTransactionError === "TX_CANCELED") {
    return (
      <div className="p-4">
        <CancelledStep
          onTryAgain={() => {
            setIsBroadcastTriggered(false);
            setTransactionHash();
          }}
        />
      </div>
    );
  } else if (isBroadcastTriggered && sendTransactionError) {
    return (
      <div className="p-4">
        <ErrorStep
          error={{ message: sendTransactionError, isWalletError: true }}
          onClose={() => {
            setIsBroadcastTriggered(false);
            setTransactionHash();
          }}
        />
      </div>
    );
  } else if (isBroadcastTriggered && isSendingTransaction && !transactionHash) {
    return (
      <div className="p-4">
        <SignTransaction />
      </div>
    );
  } else if (isBroadcastTriggered && transactionHash) {
    return (
      <div className="p-4">
        <ConfirmTransaction
          title="Confirming Transaction"
          subtitle="This can take up to a minute. Do not close or refresh the page."
        />
      </div>
    );
  }

  return (
    <>
      <WalletDetails protocol={Protocol.ETHEREUM} />
      <Summary
        onBack={onBack}
        isConfirmButtonLoading={isLoading || isSendingTransaction}
        onConfirm={async () => {
          trackEvent(
            {
              object: UniversalWidgetObjectEnum.STAKING_FLOW,
              action: UniversalWidgetActionEnum.CONFIRM_STAKE_BTN_CLICKED,
            },
            {
              protocol: Protocol.ETHEREUM,
              network: network,
              amountToStake: amount.toNumber(),
            }
          );
          const {
            ethereumStakingRequestCreate: { data, userErrors },
          } = await stakeEthereum({
            fundingAddress: account,
            amountEth: amount.toNumber(),
            network: getCoreAPINetwork(network),
            withdrawalAddress: isRestakingEnabled
              ? eigenPodAddress
              : withdrawalAddress,
            feeRecipientAddress: executionRewardsAddress,
            region:
              location === Location.Ireland
                ? Regions.EuWest_1
                : Regions.CaCentral_1,
          });

          if (!userErrors?.length && data?.unsignedTransactionSerialized) {
            sendTransaction({
              payload: data.unsignedTransactionSerialized as `0x${string}`,
              amount: amount.toString(),
            });
            setIsBroadcastTriggered(true);
          }
        }}
        detailsContainerClassName="gap-2"
        details={[
          {
            type: DetailType.TOKEN,
            label: "Amount",
            value: {
              tokenDetails: {
                amount: amount.toString(),
                ticker,
                usdAmount: amount.times(price).toFormat(2).toString(),
              },
            },
          },
          ...(showValidatorLocation
            ? [
                {
                  type: DetailType.DEFAULT,
                  label: "Validator Location",
                  value: {
                    text: location,
                  },
                },
              ]
            : []),
          flowData.isRestakingEnabled
            ? {
                type: DetailType.ADDRESS,
                label: "EigenPod Address",
                value: {
                  text: eigenPodAddress,
                  addressType: "EIGENPOD_ADDRESS",
                },
              }
            : {
                type: DetailType.ADDRESS,
                label: "Withdrawal Address",
                value: {
                  text: withdrawalAddress,
                  addressType: "WITHDRAWAL_ADDRESS",
                },
              },
          {
            type: DetailType.ADDRESS,
            label: "Execution Layer Rewards Address",
            value: {
              text: executionRewardsAddress,
              addressType: "EXECUTION_REWARDS_ADDRESS",
            },
          },
        ]}
      />
    </>
  );
};
