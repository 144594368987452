"use client";

import React from "react";
import { json2csv } from "json-2-csv";

import {
  FigAppActionEnum,
  FigAppObjectsEnum,
} from "@figmentjs/analytics/client";
import { BodyText, Dropdown, Icon, ToggleGroup } from "@figmentjs/web-core";
import Chart from "./chart";
import {
  RewardsEarned,
  SupportedDurations,
  DurationToggle,
} from "./rewards-earned-chart.types";
import { useSegment } from "../../../hooks/use-segment/use-segment";
import { CurrencyToggle } from "../../";
import { useRouter } from "next/navigation";

type Props = {
  showLinkToDownloadsPage?: boolean;
  rewardsEarned: RewardsEarned;
  labels?: {
    consensusLayer?: string;
    executionLayer?: string;
  };
  ticker: string;
  isTestnetMode: boolean;
  colors: {
    consensusLayer: string;
    executionLayer: string;
  };
  legendLabels: {
    consensusLayer: string;
    executionLayer: string;
  };
};

const RewardsEarnedChart: React.FC<Props> = ({
  rewardsEarned,
  labels = {
    consensusLayer: "Consensus Layer",
    executionLayer: "Execution Layer",
  },
  ticker,
  isTestnetMode,
  colors,
  legendLabels,
  showLinkToDownloadsPage = false,
}) => {
  const [currency, setCurrency] = React.useState<string>(ticker);
  const [duration, setDuration] = React.useState<SupportedDurations>(
    SupportedDurations.OneMonth
  );

  const durationToggle: DurationToggle = {
    [SupportedDurations.OneMonth]: {
      content: "30D",
      value: SupportedDurations.OneMonth,
      dateLabelFormat: "MMM dd",
    },
    [SupportedDurations.SixMonths]: {
      content: "6M",
      value: SupportedDurations.SixMonths,
      dateLabelFormat: "MMM",
    },
    [SupportedDurations.OneYear]: {
      content: "1Y",
      value: SupportedDurations.OneYear,
      dateLabelFormat: "MMM",
    },
  };

  const { trackEvent } = useSegment();

  return (
    <>
      <div className="flex justify-between items-center whitespace-nowrap">
        <div className="mr-auto">
          <BodyText weight="semibold">Rewards</BodyText>
        </div>
        <div className="flex gap-2">
          <ToggleGroup
            palette="secondary"
            size="small"
            value={duration}
            onValueChange={(e) => {
              setDuration(e as SupportedDurations);
              trackEvent(
                {
                  object: FigAppObjectsEnum.REWARDS_EARNED_CHART,
                  action: FigAppActionEnum.DURATION_TOGGLED,
                },
                {
                  duration: e,
                }
              );
            }}
            items={Object.values(durationToggle)}
          />
          <CurrencyToggle
            ticker={ticker}
            currency={currency}
            onValueChange={setCurrency}
          />
          <Export
            data={rewardsEarned}
            duration={duration}
            showLinkToDownloadsPage={showLinkToDownloadsPage}
          />
        </div>
      </div>
      <Chart
        duration={duration}
        labels={labels}
        dateLabelFormat={durationToggle[duration].dateLabelFormat}
        aggregatedRewards={rewardsEarned}
        ticker={ticker}
        isTestnetMode={isTestnetMode}
        colors={colors}
        legendLabels={legendLabels}
        currency={currency}
      />
    </>
  );
};

function Export({
  data,
  duration,
  showLinkToDownloadsPage,
}: {
  data: RewardsEarned;
  duration: SupportedDurations;
  showLinkToDownloadsPage: boolean;
}) {
  const router = useRouter();
  const { trackEvent } = useSegment();

  const { rewardsJSON, rewardsCSV } = convertRewardsJSONtoCSV(data, duration);

  const exportData = (type: "csv" | "json") => {
    const string = `data:text/${type};chatset=utf-8,${encodeURIComponent(
      type === "json" ? JSON.stringify(rewardsJSON) : rewardsCSV
    )}`;
    const link = document.createElement("a");
    link.href = string;
    link.download = `figment_rewards.${type}`;

    link.click();
  };

  return (
    <div className="hidden sm:block">
      <Dropdown
        size="sm"
        items={[
          {
            content: "CSV",
            onAction: () => {
              trackEvent({
                object: FigAppObjectsEnum.REWARDS_EARNED_CHART,
                action: FigAppActionEnum.EXPORT_CSV_CLICKED,
              });
              exportData("csv");
            },
          },
          {
            content: "JSON",
            onAction: () => {
              trackEvent({
                object: FigAppObjectsEnum.REWARDS_EARNED_CHART,
                action: FigAppActionEnum.EXPORT_JSON_CLICKED,
              });
              exportData("json");
            },
          },
          ...(showLinkToDownloadsPage
            ? [
                {
                  content: "Custom date range?",
                  onAction: () => {
                    router.push("/reports?tab=daily-rewards");
                    trackEvent({
                      object: FigAppObjectsEnum.REWARDS_EARNED_CHART,
                      action: FigAppActionEnum.DAILY_REWARDS_LINK_CLICKED,
                    });
                  },
                },
              ]
            : []),
        ]}
      >
        <div className="flex gap-2 items-center border-solid cursor-pointer antialiased border transition ease-in-out bg-green-100 border-green-800 py-0.5 px-4 rounded-full no-underline">
          <Icon icon="DailyReportsIcon" color="green-1000" />
          <BodyText color="green-1000" size="sm">
            Export
          </BodyText>
        </div>
      </Dropdown>
    </div>
  );
}

function convertRewardsJSONtoCSV(
  data: RewardsEarned,
  duration: SupportedDurations
): {
  rewardsJSON: any;
  rewardsCSV: string;
} {
  const rewardsJSON =
    duration === SupportedDurations.OneMonth
      ? data.rewardsEarnedOneMonth
      : duration === SupportedDurations.OneYear
      ? data.rewardsEarnedOneYear
      : data.rewardsEarnedSixMonths;
  const rewardsArrayJSON = rewardsJSON.rewards;

  let rewardsCSV = "";

  if (rewardsArrayJSON) {
    rewardsCSV = json2csv(rewardsArrayJSON);
  }

  return {
    rewardsJSON,
    rewardsCSV,
  };
}

export default RewardsEarnedChart;
