import React, { useEffect, useCallback } from "react";
import BigNumber from "bignumber.js";
import { BabylonNetwork, Protocol } from "@figmentjs/protocols";
import {
  CurrencyTickers,
  useBabylonNetworkOverviewQuery,
  useProtocolPriceQuery,
} from "../../../../../../graphql/core/generated/gql";
import Wallet from "@figmentjs/wallet";
import {
  BabylonSuccess,
  BabylonAmount,
  BabylonStakingSummary,
} from "../../../../steps/babylon";
import { useStepper } from "../../../../../hooks/use-stepper";
import { useBabylonFlowData, babylonInitialValues } from "../use-flow-data";
import { BabylonFlowStep } from "./flow.types";
import {
  UniversalWidgetActionEnum as Actions,
  UniversalFlowTrackEventProperties,
  UniversalWidgetObjectEnum,
} from "@figmentjs/analytics/client";
import { useSegment } from "../../../../../hooks/use-segment/use-segment";
import { networkMap } from "../../../../steps/babylon/babylon-staking-summary/babylon-staking-summary";

type Props = {
  isStakeDisabled?: boolean;
  onCheck?: (values: {
    isTestnet: boolean;
    onBack: () => void;
    onNext: () => void;
  }) => React.FC<any>;
};

export const Flow: React.FC<Props> = ({ onCheck, isStakeDisabled }) => {
  const { step, setStep } = useStepper<BabylonFlowStep>(BabylonFlowStep.AMOUNT);
  const { flowData, updateFlowData } = useBabylonFlowData();
  const { network, setTransactionHash, walletName, displayedNetwork } =
    Wallet.useWallet<Protocol.BABYLON>();
  const { data } = useProtocolPriceQuery({ ticker: CurrencyTickers.Btc });
  const {
    data: babylonNetworkOverviewData,
    isLoading: isBabylonNetworkOverviewLoading,
  } = useBabylonNetworkOverviewQuery(
    { network: networkMap[displayedNetwork!]! },
    { enabled: !!displayedNetwork }
  );
  const price = (data?.protocolPrice.value || 0).toString();
  const { trackEvent } = useSegment();
  const trackStakingFlowEvent = useCallback(
    (action: Actions, properties: UniversalFlowTrackEventProperties) => {
      trackEvent(
        { object: UniversalWidgetObjectEnum.STAKING_FLOW, action },
        {
          amountToStake: flowData.amount.toNumber(),
          protocol: Protocol.BABYLON,
          network,
          walletName,
          ...properties,
        }
      );
    },
    [flowData.amount, network, trackEvent, walletName]
  );
  useEffect(() => {
    trackStakingFlowEvent(Actions.STEP_RENDERED, { step });
  }, [step, trackStakingFlowEvent]);

  const reset = () => {
    updateFlowData(babylonInitialValues);
    setStep(BabylonFlowStep.AMOUNT);
    setTransactionHash();
  };

  return (
    <div className="rounded-md bg-white overflow-hidden shadow-3xl">
      {((): React.ReactNode => {
        switch (step) {
          case BabylonFlowStep.AMOUNT:
            return (
              <BabylonAmount
                onSubmit={(values) => {
                  updateFlowData(values);

                  if (onCheck) {
                    setStep(BabylonFlowStep.CHECK);
                  } else {
                    setStep(BabylonFlowStep.SUMMARY);
                  }
                }}
                flowData={flowData}
                isLoading={isBabylonNetworkOverviewLoading}
                estimatedFee="0.00006"
                commission={5}
                price={price}
                isStakeDisabled={isStakeDisabled}
                data={babylonNetworkOverviewData}
              />
            );
          case BabylonFlowStep.CHECK:
            if (onCheck) {
              const CheckComponent = onCheck({
                isTestnet: network === BabylonNetwork.SIGNET,
                onBack: () => setStep(BabylonFlowStep.AMOUNT),
                onNext: () => setStep(BabylonFlowStep.SUMMARY),
              });

              return <CheckComponent />;
            }
          // Need this comment to avoid lint error: Expected a 'break' statement before 'case' no-fallthrough
          /* falls through */
          case BabylonFlowStep.SUMMARY:
            return (
              <BabylonStakingSummary
                amount={flowData.amount}
                usdAmount={BigNumber(flowData.amount || 0)
                  .times(price)
                  .toFormat(2)
                  .toString()}
                stakingDuration={
                  babylonNetworkOverviewData?.babylonNetworkOverview
                    .maxStakingTime
                }
                onBack={() => setStep(BabylonFlowStep.AMOUNT)}
                onConfirm={(values) => {
                  updateFlowData(values);
                  setStep(BabylonFlowStep.SUCCESS);
                }}
              />
            );
          case BabylonFlowStep.SUCCESS:
            return (
              <BabylonSuccess
                amount={flowData.amount}
                onDone={reset}
                transactionHash={flowData.transactionHash}
                buttonText="Stake More"
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};
