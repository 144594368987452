import React, { memo } from "react";
import { twMerge as tw } from "tailwind-merge";
import { Icon } from "../../graphics";
import { BannerProps } from "./Banner.types";
import { icons } from "../../graphics/Icon/Icons";
import { OverlayPalette, OverlayText } from "../shared/Overlay.props";

type IconOptions = keyof typeof icons;

/**
 * Renders the Banner component.
 *
 * @param BannerProps
 * @returns Banner component for displaying important details, usually within a form.
 */

const BannerIcon: Record<OverlayPalette, IconOptions> = {
  error: "MdError",
  info: "MdWarning",
  success: "MdCheckCircle",
};

const BannerColor: Record<OverlayPalette, string> = {
  error: "bg-error-200",
  info: "bg-black",
  success: "bg-green-100",
};

const Banner = ({
  className,
  children,
  noIcon,
  small = false,
  title,
  bannerId,
  palette = "info",
  actionComponent,
  closeComponent,
}: BannerProps) => {
  const showIcon = !noIcon;
  const bannerStyles = tw(
    "rounded-md p-4",
    BannerColor[palette],
    OverlayText[palette],
    small && "py-2"
  );
  const gridStyles = tw(
    "grid items-center gap-0",
    showIcon ? "grid-cols-[auto_1fr_auto_auto]" : "grid-cols-[1fr_auto_auto]",
    small ? "gap-y-0" : "gap-y-1"
  );

  const id = bannerId || title?.replaceAll(" ", "-");

  return (
    <div className={tw("w-full", className)}>
      <div
        tabIndex={0}
        className={tw(gridStyles, bannerStyles)}
        aria-live="polite"
        role={palette === OverlayPalette.error ? "alert" : "status"}
        aria-labelledby={`Header-${id}`}
        aria-describedby={children ? `Content-${id}` : undefined}
      >
        {showIcon && (
          <div className="mr-2">
            <Icon icon={BannerIcon[palette]} size="lg" />
          </div>
        )}

        {title && (
          <div
            className={tw(children && "font-semibold", "justify-self-start")}
            id={`Header-${id}`}
          >
            {title}
          </div>
        )}

        {actionComponent}
        {closeComponent}

        {children && (
          <div
            className={tw(
              showIcon ? "col-start-2" : "col-start-1",
              "col-span-full row-start-2"
            )}
            id={`Content-${id}`}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Banner);
