import { defaultStyles } from "@visx/tooltip";
import { theme } from "../../../../theme/tailwind.config";
import { BucketKey } from "../types";

const {
  colors: { green, black, white, teal, blue },
} = theme;

export const colorRange = [
  green["800"],
  green["700"],
  green["600"],
  green["500"],
  green["400"],
  green["300"],
  teal["600"],
  blue["500"],
];

export const barChartColorRange = colorRange.slice(0, 6);

export const startBucketingIndex = barChartColorRange.length - 1;

export const tooltipStyles: React.CSSProperties = {
  ...defaultStyles,
  backgroundColor: black.DEFAULT,
  color: white.DEFAULT,
  borderRadius: 4,
  padding: 0,
  boxShadow: "2px 2px 14px rgba(0, 0, 0, 0.4)",
  zIndex: 20,
  width: "max-content",
};

// The left margin here is a placeholder, it's optimized in the <Container />.
export const margin = { top: 8, right: 0, bottom: 64, left: 64 };

export const bucketKey: BucketKey = "Other";
