import React from "react";
import BigNumber from "bignumber.js";
import {
  getValidatorExplorerUrl,
  Protocol,
  protocols,
  SolanaNetwork,
  SolanaNetworkValidatorMap,
} from "@figmentjs/protocols";
import { useWallet } from "@figmentjs/wallet";
import { Summary } from "../../shared/summary";
import { useStakeSol } from "../../../flows/solana/hooks";
import { SolanaFlowData } from "../../../flows";
import { SolanaNetworks } from "../../../../../graphql/core/generated/gql";
import { DetailType } from "../../../../components/details/details.types";
import { useSegment } from "../../../../hooks/use-segment/use-segment";
import {
  UniversalWidgetActionEnum,
  UniversalWidgetObjectEnum,
} from "@figmentjs/analytics/apps";

export type StakingSummaryProps = {
  amount: BigNumber;
  usdAmount: string;
  customValidator?: {
    pubkey: string;
    label: string;
  };
  onConfirm: (values: Partial<SolanaFlowData>) => void;
  onBack: () => void;
  onError: (values: Partial<SolanaFlowData>) => void;
};

export const networkMap: { [key in SolanaNetwork]: SolanaNetworks } = {
  [SolanaNetwork.DEVNET]: SolanaNetworks.Devnet,
  [SolanaNetwork.MAINNET]: SolanaNetworks.Mainnet,
  [SolanaNetwork.TESTNET]: SolanaNetworks.Testnet,
};

export const SolanaStakingSummary: React.FC<StakingSummaryProps> = ({
  amount,
  usdAmount,
  customValidator,
  onConfirm,
  onBack,
  onError,
}) => {
  const { ticker } = protocols[Protocol.SOLANA];

  const { trackEvent } = useSegment();

  const { network, account } = useWallet<Protocol.SOLANA>();

  const { stake, isLoading } = useStakeSol({
    input: {
      network: networkMap[network!],
      amountSol: amount.toNumber(),
      fundingAccountPubkey: account!,
      voteAccountPubkey: customValidator
        ? customValidator.pubkey
        : SolanaNetworkValidatorMap[network!],
    },
    onError: onError,
    onCreation: onConfirm,
  });

  const handleConfirm = async () => {
    trackEvent(
      {
        object: UniversalWidgetObjectEnum.STAKING_FLOW,
        action: UniversalWidgetActionEnum.CONFIRM_STAKE_BTN_CLICKED,
      },
      {
        protocol: Protocol.SOLANA,
        network: networkMap[network!],
        amountToStake: amount.toNumber(),
      }
    );
    await stake();
  };

  return (
    <Summary
      onBack={onBack}
      onConfirm={handleConfirm}
      isConfirmButtonLoading={isLoading}
      details={[
        {
          type: DetailType.TOKEN,
          label: "Amount",
          value: {
            tokenDetails: { amount: amount.toString(), ticker, usdAmount },
          },
        },
        {
          type: DetailType.HREF,
          label: "Validator",
          value: {
            text: customValidator ? customValidator.label : "Figment",
            prependedIcon: customValidator ? undefined : "FigmentLogoIcon",
            href: getValidatorExplorerUrl(
              Protocol.SOLANA,
              customValidator
                ? customValidator.pubkey
                : SolanaNetworkValidatorMap[network!],
              network
            ),
          },
        },
      ]}
    />
  );
};
