import React from "react";
import {
  getTransactionExplorerUrl,
  Network,
  Protocol,
  protocols,
} from "@figmentjs/protocols";
import Wallet, { useCurrentWalletAddress } from "@figmentjs/wallet";
import {
  useInfiniteEthereumActivityTableStakingActivityQuery,
  Networks,
  EthereumStakingActivityConnectionNodeFragment,
  DepositStatuses,
  ExitRequestStatuses,
} from "../../../../graphql/core/generated/gql";
import {
  ACTIVITY_TABLE_PAGE_SIZE,
  ActivityTableBase as ActivityTable,
  ActivityTableStatus,
  ActivityTime,
} from "../activity-table-base/activity-table-base";
import { ActivityTableEmpty } from "../activity-table-empty/activity-table-empty";
import { ActivityTableLoading } from "../activity-table-loading/activity-table-loading";
import { hashQueryKey } from "@tanstack/react-query";
import { BodyText } from "@figmentjs/web-core";
import { ActivitySubtext } from "../components/activity-subtext";

const networkMap: {
  [key in Network]?: Networks;
} = {
  [Network.MAINNET]: Networks.Mainnet,
  [Network.GOERLI]: Networks.Goerli,
  [Network.HOLESKY]: Networks.Holesky,
};

const getActivityEta = (
  activityRecord: EthereumStakingActivityConnectionNodeFragment
): { start?: string | null; end?: string | null } =>
  activityRecord.__typename === "EthereumExitRequest"
    ? {
        start: activityRecord.estimatedWithdrawableAt,
        end: activityRecord.estimatedPrincipalReturnAt,
      }
    : activityRecord.__typename === "EthereumStakingDeposit"
    ? { start: activityRecord.estimatedActiveAt }
    : {
        start: undefined,
        end: undefined,
      };

const getActivityStatus = (
  activityRecord: EthereumStakingActivityConnectionNodeFragment
) =>
  activityRecord.__typename === "EthereumExitRequest"
    ? activityRecord.exitStatus
    : activityRecord.__typename === "EthereumStakingDeposit"
    ? activityRecord.depositStatus
    : undefined;

const getUser = (
  activityRecord: EthereumStakingActivityConnectionNodeFragment
) =>
  activityRecord.__typename === "EthereumExitRequest"
    ? activityRecord.user?.email
    : activityRecord.__typename === "EthereumStakingDeposit"
    ? activityRecord.provisioners?.[0]?.email
    : undefined;

const getStatus = (
  status?: ExitRequestStatuses | DepositStatuses
): ActivityTableStatus => {
  switch (status) {
    case DepositStatuses.Unfinalized:
      return {
        text: "Activating",
        subText: (start: ActivityTime) => {
          return (
            start && (
              <ActivitySubtext>
                <BodyText weight="semibold" size="sm" color="basic-800">
                  {`${start.time} ${start.unit}`}
                </BodyText>
                <BodyText size="sm" color="basic-800">
                  {" "}
                  until active
                </BodyText>
              </ActivitySubtext>
            )
          );
        },
        color: "bg-yellow-1000",
        tooltipText: () =>
          "Your ETH has been safely deposited. Your staked balance will update shortly.",
      };
    case DepositStatuses.Deposited:
      return {
        text: "Activating",
        subText: (start: ActivityTime) => {
          return (
            start && (
              <ActivitySubtext>
                <BodyText weight="semibold" size="sm" color="basic-800">
                  {`${start.time} ${start.unit}`}
                </BodyText>
                <BodyText size="sm" color="basic-800">
                  {" "}
                  until active
                </BodyText>
              </ActivitySubtext>
            )
          );
        },
        color: "bg-yellow-1000",
        tooltipText: (eta: string) =>
          `Your validators are activating. You will start earning rewards in about ${eta}.`,
      };
    case DepositStatuses.Activating:
      return {
        text: "Activating",
        subText: (start: ActivityTime) => {
          return (
            start && (
              <ActivitySubtext>
                <BodyText weight="semibold" size="sm" color="basic-800">
                  {`${start.time} ${start.unit}`}
                </BodyText>
                <BodyText size="sm" color="basic-800">
                  {" "}
                  until active
                </BodyText>
              </ActivitySubtext>
            )
          );
        },
        color: "bg-yellow-1000",
        tooltipText: (eta: string) =>
          `Your validators are activating. You will start earning rewards in about ${eta}.`,
      };
    case DepositStatuses.Activated:
      return {
        text: "Active",
        subText: undefined,
        color: "bg-green-500",
        tooltipText: () => "Your validators are active and earning rewards.",
      };
    case ExitRequestStatuses.ExitRequested:
      return {
        text: "Exiting",
        subText: (_: ActivityTime, end: ActivityTime) => {
          return (
            end && (
              <ActivitySubtext>
                <BodyText weight="semibold" size="sm" color="basic-800">
                  {`${end.time} ${end.unit}`}
                </BodyText>
                <BodyText size="sm" color="basic-800">
                  {" "}
                  until exit
                </BodyText>
              </ActivitySubtext>
            )
          );
        },
        color: "bg-yellow-1000",
        tooltipText: (eta: string) =>
          `Your exit request was submitted. Your validators are still earning rewards. Your ETH will be available in your wallet in ${eta} max.`,
      };
    case ExitRequestStatuses.Exiting:
      return {
        text: "Exiting",
        subText: (
          start: ActivityTime,
          _: ActivityTime,
          differenceInDays?: number | null | string
        ) => {
          return (
            start && (
              <ActivitySubtext>
                {start && (
                  <>
                    <BodyText weight="semibold" size="sm" color="basic-800">
                      {`${start.time} ${start.unit}`}
                    </BodyText>
                    <BodyText size="sm" color="basic-800">
                      {" "}
                      until exit
                    </BodyText>
                  </>
                )}
                {differenceInDays && (
                  <>
                    <BodyText size="sm" color="basic-800">
                      {" "}
                      +{" "}
                    </BodyText>
                    <BodyText size="sm" color="basic-800" weight="semibold">
                      {differenceInDays} more days{" "}
                    </BodyText>
                    <BodyText size="sm" color="basic-800">
                      until withdrawal
                    </BodyText>
                  </>
                )}
              </ActivitySubtext>
            )
          );
        },
        color: "bg-yellow-1000",
        tooltipText: (eta: string) =>
          `Your validators have exited and are no longer earning rewards. Your ETH is waiting to be swept to your wallet in ${eta} max.`,
      };
    case ExitRequestStatuses.PendingWithdrawal:
      return {
        text: "Exited",
        subText: (_: ActivityTime, end: ActivityTime) => {
          return (
            end && (
              <ActivitySubtext>
                <BodyText size="sm" color="basic-800">
                  Up to{" "}
                </BodyText>
                <BodyText weight="semibold" size="sm" color="basic-800">
                  {`${end.time} ${end.unit}`}
                </BodyText>
                <BodyText size="sm" color="basic-800">
                  {" "}
                  until withdrawal
                </BodyText>
              </ActivitySubtext>
            )
          );
        },
        color: "bg-yellow-1000",
        tooltipText: (eta: string) =>
          `Your validators have exited and are no longer earning rewards. Your ETH is waiting to be swept to your wallet in ${eta} max.`,
      };
    case ExitRequestStatuses.Completed:
      return {
        text: "Withdrawn",
        color: "bg-basic-600",
        tooltipText: () =>
          "Your ETH has been withdrawn and is available in your wallet.",
      };
    default:
      return {
        text: "In Progress",
        subText: undefined,
        color: "bg-yellow-1000",
        tooltipText: () => "",
      };
  }
};

type Props = {
  networkOverride?: Network;
  transparent?: boolean;
};

export const EthereumActivityTable: React.FC<Props> = ({
  networkOverride,
  transparent,
}) => {
  const { network } = Wallet.useWallet<Protocol.ETHEREUM>();
  const { currentWalletAddress } = useCurrentWalletAddress();

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteEthereumActivityTableStakingActivityQuery(
      "after",
      {
        protocol: "ethereum",
        network: networkMap[networkOverride || network!],
        first: ACTIVITY_TABLE_PAGE_SIZE,
      },
      {
        // cacheTime: 0 ensures cached data is cleared when the component unmounts,
        // which prevents duplicate data from being displayed when the component is re-mounted.
        cacheTime: 0,
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage.stakingActivity.pageInfo.hasNextPage &&
          lastPage.stakingActivity.pageInfo.endCursor
            ? { after: lastPage.stakingActivity.pageInfo.endCursor }
            : null,
        queryKeyHashFn: () =>
          hashQueryKey([
            "EthereumActivityTableStakingActivity.infinite",
            currentWalletAddress,
            networkOverride || network!,
          ]),
      }
    );

  if (isLoading) {
    return <ActivityTableLoading transparent={transparent} />;
  }

  if (!data?.pages?.[0]?.stakingActivity?.nodes?.length) {
    return <ActivityTableEmpty transparent={transparent} />;
  }

  const activities = data.pages
    .flatMap((group) => group.stakingActivity.nodes)
    .map((activity) => {
      const isStaking = activity.__typename === "EthereumStakingDeposit";
      const rawStatus = getActivityStatus(activity);
      const status = getStatus(rawStatus);
      const isComplete = rawStatus
        ? [DepositStatuses.Activated, ExitRequestStatuses.Completed].includes(
            rawStatus
          )
        : false;
      const eta = getActivityEta(activity);
      const user = getUser(activity);

      const txUrl = isStaking
        ? getTransactionExplorerUrl(
            Protocol.ETHEREUM,
            activity.txHash,
            activity.network === Networks.Holesky
              ? Network.HOLESKY
              : Network.MAINNET
          )
        : null;

      return {
        id: activity.id,
        amount: activity.amountEth,
        createdAt: activity.activityAt,
        isStaking: isStaking,
        isComplete: isComplete,
        status,
        eta: eta,
        txUrl,
        user,
      };
    });

  return (
    <ActivityTable
      ticker={
        protocols[Protocol.ETHEREUM].networks[
          data.pages[0].stakingActivity.nodes[0].network
        ].ticker
      }
      transparent={transparent}
      activities={activities}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      onFetchNextPage={fetchNextPage}
    />
  );
};
